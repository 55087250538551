var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "m-portlet m-portlet--mobile" }, [
        _c("div", { staticClass: "m-portlet__head" }, [
          _c("div", { staticClass: "m-portlet__head-caption" }, [
            _c("div", { staticClass: "m-portlet__head-title" }, [
              _c("h3", { staticClass: "m-portlet__head-text" }, [
                _vm._v(
                  "\n                            " +
                    _vm._s(this.title) +
                    "\n                        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-portlet__body" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-hover m-table" }, [
              _c(
                "tbody",
                _vm._l(this.details, function (element) {
                  return _c("DetailRow", {
                    key: element.id,
                    attrs: { element: element },
                  })
                }),
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          this.permits.update
            ? _c(
                "a",
                {
                  staticClass: "btn btn-block btn-primary",
                  attrs: { href: _vm.editRoute, id: "edit-route" },
                },
                [
                  _c("i", { staticClass: "fa fa-fw fa-pencil" }),
                  _vm._v(" Editar\n                "),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }