<template>
  <div class="c-delivery__container">
    <h2 class="c-delivery__main-title">Selecciona el método de envío:</h2>
    <!--Tabs/radio buttons-->
    <ul class="c-delivery__menu">

      <li class="c-delivery__menu-li" :class="{'is-active': activeTab === 'recurrent'}">
        <label class="c-delivery__menu-li-label m-radio" :disabled="nbReadOnly">
          <input type="radio" name="option-recurrent" class="c-delivery__menu-li-button" id="recurrent-option"
                 value="recurrent" :disabled="nbReadOnly" v-model="activeTab">
          <span class="c-delivery__menu-li-radio"></span>
          <div class="c-delivery__menu-li-title-box">
            <em class="c-delivery__menu-li-icon la la-clock-o"/>
            <h4 class="c-delivery__menu-li-title">Programación</h4>
          </div>
          <p class="c-delivery__menu-li-txt">
            Configura las fechas, días, horas ... para enviar una campaña de forma perdiódica.
          </p>
          <div class="c-delivery__status" v-if="recurrent">Activado</div>
          <div class="c-delivery__status--off" v-else>Desactivado</div>
        </label>
      </li>

      <li class="c-delivery__menu-li" :class="{'is-active': activeTab === 'trigger'}">
        <label class="c-delivery__menu-li-label m-radio" :disabled="nbReadOnly">
          <input type="radio" name="option-trigger" class="c-delivery__menu-li-button" id="trigger" value="trigger"
                 :disabled="nbReadOnly" v-model="activeTab">
          <span class="c-delivery__menu-li-radio"></span>
          <div class="c-delivery__menu-li-title-box">
            <em class="c-delivery__menu-li-icon la la-hand-o-right"/>
            <h4 class="c-delivery__menu-li-title">Disparador</h4>
          </div>
          <p class="c-delivery__menu-li-txt">Crea un disparador para que se envíe automáticamente.</p>
          <div class="c-delivery__status" v-if="triggerEnabled">Activado</div>
          <div class="c-delivery__status--off" v-else>Desactivado</div>
        </label>
      </li>

      <li class="c-delivery__menu-li" :class="{'is-active': activeTab === 'send'}">
        <label class="c-delivery__menu-li-label m-radio" :disabled="nbReadOnly">
          <input type="radio" name="option-send" class="c-delivery__menu-li-button" id="send" value="send"
                 :disabled="nbReadOnly" v-model="activeTab">
          <span class="c-delivery__menu-li-radio"></span>
          <div class="c-delivery__menu-li-title-box">
            <em class="c-delivery__menu-li-icon fa fa-send-o"/>
            <h4 class="c-delivery__menu-li-title">¡Envíalo ya!</h4>
          </div>
          <p class="c-delivery__menu-li-txt">Envía una campaña de forma manual y al instante.</p>
        </label>
      </li>

      <li class="c-delivery__menu-li" :class="{'is-active': activeTab === 'send-test'}">
        <label class="c-delivery__menu-li-label m-radio" :disabled="nbReadOnly">
          <input type="radio" name="option-send-test" class="c-delivery__menu-li-button" id="send-test"
                 value="send-test" :disabled="nbReadOnly" v-model="activeTab">
          <span class="c-delivery__menu-li-radio"></span>
          <div class="c-delivery__menu-li-title-box">
            <em class="c-delivery__menu-li-icon fa fa-envelope-o"/>
            <h4 class="c-delivery__menu-li-title">Envío de prueba</h4>
          </div>
          <p class="c-delivery__menu-li-txt">Realiza un envío de prueba para ver que todo está bien.</p>
        </label>
      </li>

    </ul>
    <!--fin tabs/radio buttons-->


    <!--tabs content-->
    <div class="c-delivery__config-options m-form">

      <!--tab envío recurrente-->
      <div class="c-delivery__config-option-container" :class="{'is-active': activeTab === 'recurrent'}">

        <div class="c-delivery__title-box">
          <em class="c-delivery__icon la la-clock-o"/>
          <h4 class="c-delivery__title">Opciones de programación</h4>
        </div>

        <div class="row m-0 pb-3">
          <input type="hidden" :value="recurrent ? '1' : '0'" name="recurrent" id="recurrent">

          <div class="col-md-12"></div>

          <div class="form-group m-form__group col-md-4">
            <div class="input-group m-input-group">
              <div class="input-group-prepend"><span class="input-group-text">Enviar por</span></div>
              <select class="form-select " id="scheduled_repeat_date--input" v-model="scheduledRepeatDate"
                      :disabled="nbReadOnly">
                <option :value="repeatDate.hour">{{ 'campaign_component.schedule_hour' | trans }}</option>
                <option :value="repeatDate.day">{{ 'campaign_component.schedule_day' | trans }}</option>
                <option :value="repeatDate.week">{{ 'campaign_component.schedule_week' | trans }}</option>
                <option :value="repeatDate.month">{{ 'campaign_component.schedule_month' | trans }}</option>
              </select>
            </div>
          </div>

          <div class="form-group m-form__group col-md-4">
            <div class="input-group m-input-group">
              <div class="input-group-prepend"><span class="input-group-text">Empezando el</span></div>
              <label></label>
              <input id="schedule_start"
                     type="date" class="form-control m-input"
                     min="1000-01-01" max="5000-01-01"
                     v-model="scheduledStart" :disabled="nbReadOnly || isNotRecurrent">
              <input type="hidden" name="scheduled_start" v-model="scheduledStartFormatted">
              <nb-error-block path="scheduled_start"/>
            </div>
          </div>

          <div class="form-group m-form__group col-md-4">
            <div class="input-group m-input-group">
              <div class="input-group-prepend"><span class="input-group-text">a las</span></div>
              <input id="scheduled_time" type="time" class="form-control m-input"
                     v-model="scheduledTime" :disabled="nbReadOnly || isNotRecurrent">
              <input type="hidden" :value="scheduledConditions" name="scheduled_conditions">
              <nb-error-block path="scheduled_conditions.time"/>
            </div>
          </div>

          <div class="col-12"></div>

          <div class="form-group m-form__group col-md-4">
            <div class="input-group m-input-group">
              <div class="input-group-prepend"><span class="input-group-text">Cada</span></div>
              <input id="schedule_repeat"
                     type="number" class="form-control m-input"
                     v-model="scheduledRepeat" :disabled="nbReadOnly || isNotRecurrent">
              <input type="hidden" :value="scheduledRepeat" name="scheduled_repeat">
              <input type="hidden" :value="scheduledRepeatDate" name="scheduled_repeat_date">
              <div class="input-group-append">
                <span class="input-group-text" v-if="scheduledRepeatDate === repeatDate.hour">
                  {{ 'campaign_component.schedule_hour' | trans }}
                </span>
                <span class="input-group-text" v-if="scheduledRepeatDate === repeatDate.day">
                  {{ 'campaign_component.schedule_day' | trans }}
                </span>
                <span class="input-group-text" v-if="scheduledRepeatDate === repeatDate.week">
                  {{ 'campaign_component.schedule_week' | trans }}
                </span>
                <span class="input-group-text" v-if="scheduledRepeatDate === repeatDate.month">
                  {{ 'campaign_component.schedule_month' | trans }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-12"></div>

          <div class="form-group m-form__group col-md-8">
            <div class="btn-group m-btn-group btn-block" role="group">
              <span class="mr-2" style="line-height: 37px;">Los días:</span>
              <button type="button" class="btn" @click="markDay(day)" :id="'day-' + day.id"
                      :class="{'btn-brand': viewDay(day.id), 'btn m-btn btn-secondary': !viewDay(day.id)}"
                      v-for="day in weekDays" :disabled="nbReadOnly || isNotRecurrent">
                <em class="la la-check mr-1" v-show="viewDay(day.id)"/>
                {{ day.value }}
              </button>
              <button type="button" class="btn" @click="markAllDays" id="all-days"
                      :class="{'btn-brand': allDaysMarked, 'btn m-btn btn-secondary': !allDaysMarked}"
                      :disabled="nbReadOnly || isNotRecurrent">
                <em class="la la-check mr-1" v-show="allDaysMarked"/>
                Todos
              </button>
            </div>
          </div>

          <div class="col-12"></div>

          <div class="form-group m-form__group col-md-4">
            <div class="input-group m-input-group">
              <input id="scheduled_shipments"
                     type="number"
                     class="form-control m-input"
                     v-model="scheduledShipments"
                     :disabled="nbReadOnly || isNotRecurrent"
                     :placeholder="getTrans('campaign_component.schedule_placeholder')">
              <div class="input-group-append"><span class="input-group-text">veces</span></div>
              <input type="hidden" :value="scheduledShipments" name="scheduled_shipments">
            </div>
            <div id="schedule_email_help" class="small form-text text-muted mb-2">
              {{ 'campaign_component.schedule_shipments_message' | trans }}
            </div>
          </div>

          <div class="form-group m-form__group col-md-4">
            <div class="input-group m-input-group">
              <div class="input-group-prepend"><span class="input-group-text">Y terminar el</span></div>
              <input id="schedule_end"
                     type="datetime-local" class="form-control"
                     min="1000-01-01T00:00" max="5000-01-01T00:00"
                     v-model="scheduledEnd" :disabled="nbReadOnly || isNotRecurrent">
              <input type="hidden" name="scheduled_end" v-model="scheduledEndFormatted">
            </div>
          </div>

        </div>
      </div>
      <!--fin tab envío recurrente-->

      <!--tab disparador-->
      <div class="c-delivery__config-option-container" :class="{'is-active': activeTab === 'trigger'}">

        <div class="c-delivery__title-box">
          <em class="c-delivery__icon la la-hand-o-right"/>
          <h4 class="c-delivery__title">Configuración del disparador</h4>
        </div>

        <div class="row m-0 pb-4">
          <select-with-create :nb-read-only="nbReadOnly"
                              :fieldName="'crm_trigger_id'"
                              :model="'triggers'"
                              :route="'API.triggers.options'"
                              :initial="selectedTriggerId"
                              :initialData="initialSelectedTrigger"
                              @dirty="updateDirty">
            <h5>Cuando las condiciones definidas en el disparador se cumplan, la campaña se enviará.</h5>
            <strong>Disparador</strong>
            <template #modalTitle>{{ 'campaign_component.trigger' | trans }}</template>
          </select-with-create>
        </div>
      </div>
      <!--fin tab disparador-->

      <!--tab envío-->
      <div class="c-delivery__config-option-container" :class="{'is-active': activeTab === 'send'}">

        <div class="c-delivery__title-box">
          <em class="c-delivery__icon fa fa-send-o"/>
          <h4 class="c-delivery__title">3, 2, 1 ... ¡Ya!</h4>
        </div>

        <div class="row m-0">
          <div class="form-group m-form__group col-12 d-flex mb-4 align-items-center">
            <h5 class="mb-0 mr-4">Envía ahora mismo la campaña a los usuarios seleccionados</h5>
            <button id="send-campaign"
                    class="btn btn-primary c-delivery__btn-send"
                    type="button" :disabled="nbReadOnly"
                    @click="sendCampaign">
              Enviar
              <em class="la la-angle-right"/>
            </button>
          </div>
        </div>
      </div>
      <!--fin tab envío-->

      <!--tab envío prueba-->
      <div class="c-delivery__config-option-container" :class="{'is-active': activeTab === 'send-test'}">

        <div class="c-delivery__title-box">
          <em class="c-delivery__icon fa fa-envelope-o"/>
          <h4 class="c-delivery__title">Escribe el destinatario</h4>
        </div>

        <div class="row m-0">
          <div class="form-group m-form__group col-md-9 mb-2 pb-3">
            <h5>Selecciona destinatarios para la prueba separados por punto y coma</h5>
            <div class="d-flex">
              <input id="test_emails" type="text" class="form-control" v-model="testEmails"
                     placeholder="Correos electrónicos separados por ;" :disabled="nbReadOnly">
              <button type="button" class="btn btn-primary c-delivery__btn-send" id="send-test-campaign"
                      @click="sendTestCampaign" :disabled="nbReadOnly">
                Enviar
                <em class="la la-angle-right"/>
              </button>
            </div>
            <div class="m--font-danger" v-if="this.errorEmails">Debe escribir los destinatarios</div>
          </div>
        </div>
      </div>
      <!--fin tab envío prueba-->

    </div>
    <!--fin tabs content-->

    <!--no repetir destinatario-->
    <div class="c-delivery__config-repeat-container" :class="{'is-active': activeTab !== ''}">

      <div class="c-delivery__title-box">
        <em class="c-delivery__icon la la-users"/>
        <h4 class="c-delivery__title">{{ 'campaign_component.dont_repeat_recipients' | trans }}</h4>
      </div>

      <div class="m-checkbox-list m-4">
        <input type="hidden" :value="dontRepeatRecipients ? '0' : '1'" name="repeat_recipients" id="repeat_recipients">
        <label class="m-checkbox" :disabled="nbReadOnly">
          <input id="toggle_recipients_repeat" type="checkbox" v-model="dontRepeatRecipients" :disabled="nbReadOnly">
          {{ 'campaign_component.dont_repeat_recipients_message' | trans }}
          <span></span>
        </label>
      </div>
    </div>
    <!--fin no repetir destinatario-->

  </div>
</template>

<script>
export default {
  name: 'message-component',
  props: {
    triggers: { type: Array, default: () => ([]) },
    initialSelectedTriggerId: { type: Number, default: null },
    initialSelectedTrigger: { type: Object, default: null },
    campaignId: { Number },
    scheduleStart: { type: String, default: null },
    scheduleEnd: { type: String, default: null },
    scheduleRepeat: { type: Number, default: 1 },
    scheduleRepeatDate: { type: Number, default: null },
    scheduleConditions: {
      Object, default: () => ({
        days: {},
        time: null
      })
    },
    scheduleShipments: { type: Number, default: 1 },
    recurrentActive: { Boolean, default: () => false },
    repeatRecipientsActive: { Boolean, default: () => false },
    repeatDate: {
      Object, default: () => ({
        day: 1,
        week: 2,
        month: 3,
        hour: 4,
      })
    },
    weekDays: {
      Object, default: () => ([
        { id: 1, value: 'Lunes' },
        { id: 2, value: 'Martes' },
        { id: 3, value: 'Miércoles' },
        { id: 4, value: 'Jueves' },
        { id: 5, value: 'Viernes' },
        { id: 6, value: 'Sábado' },
        { id: 7, value: 'Domingo' },
      ])
    }
  },
  data () {
    return {
      input: null,
      scheduledStart: null,
      scheduledEnd: null,
      scheduledShipments: null,
      scheduledRepeat: null,
      scheduledRepeatDate: null,
      dirty: false,
      scheduledDays: [],
      scheduledTime: null,
      testEmails: null,
      recurrent: false,
      triggerEnabled: false,
      dontRepeatRecipients: true,
      refTestModal: '#testModal',
      errorEmails: false,
      showModal: false,
      activeTab: '',
      selectedTriggerId: null
    }
  },
  watch: {
    activeTab (value) {
      if (value === 'recurrent') {
        this.triggerEnabled = false
        this.recurrent = true
        this.changeToRecurrent()
      }
      if (value === 'trigger') {
        this.recurrent = false
        this.triggerEnabled = true
        this.changeToTrigger()
      }
    },
    scheduledRepeat (val) {
      if (val !== null && this.scheduledRepeatDate === null) {
        this.scheduledRepeatDate = this.repeatDate.day
      }
      if (val < 1 && this.recurrent) {
        this.scheduledRepeat = 1
      }
    }
  },
  created () {
    this.scheduled = this.scheduledDate
    this.recurrent = this.recurrentActive
    this.triggerEnabled = !!this.initialSelectedTriggerId
    this.selectedTriggerId = this.initialSelectedTriggerId
    this.dontRepeatRecipients = !this.repeatRecipientsActive
    this.scheduledEnd = this.scheduleEnd
    this.scheduledShipments = this.scheduleShipments
    this.scheduledRepeat = this.scheduleRepeat
    this.scheduledRepeatDate = this.scheduleRepeatDate
    this.setScheduleStartOrDefault()
    if (this.scheduleConditions && this.scheduleConditions.days) {
      this.scheduledDays = this.scheduleConditions.days
    }
    this.setActiveTab()
  },
  mounted () {
    this.$watch('scheduled', () => this.dirty = true)
  },
  computed: {
    /**
     * UnixTS format
     */
    scheduledStartFormatted () {
      let date = this.scheduledTime ? this.scheduledStart + 'T' + this.scheduledTime : this.scheduledStart
      return this.dateFormat(date)
    },
    scheduledEndFormatted () {
      return this.dateFormat(this.scheduledEnd)
    },
    scheduledConditions () {
      let format = this.checkTime(this.scheduledTime) ? this.scheduledTime + ':00' : null

      return JSON.stringify({
        days: this.scheduledDays,
        time: format
      })
    },
    isNotRecurrent () {
      return !this.recurrent || this.nbReadOnly
    },
    allDaysMarked () {
      return this.scheduledDays.sort().join(',') === this.weekDays.map(d => d.id).sort().join(',')
    },
    isFormDirty () {
      let dirtyFields = []
      $('.dirty').each(d => {
        if (d.classList !== undefined && !d.classList.contains('c-delivery__menu-li-button')) {
          dirtyFields.push(d)
        }
      })
      return dirtyFields.length > 0
    }
  },
  methods: {
    setActiveTab () {
      if (this.recurrentActive) {
        this.activeTab = 'recurrent'
      } else if (this.initialSelectedTriggerId) {
        this.activeTab = 'trigger'
      }
    },
    getTrans (text) {
      return window.trans.get(text)
    },
    changeRepeatDate (val) {
      this.scheduledRepeatDate = val
    },
    changeToTrigger () {
      this.$nextTick(() => {
        this.scheduledStart = null
        this.scheduledEnd = null
        this.scheduledShipments = null
        this.scheduledDays = []
        this.scheduledRepeat = null
        this.scheduledRepeatDate = null
        this.scheduledTime = null
        this.scheduledRepeat = 1
        this.scheduledRepeatDate = this.repeatDate.day
      })
    },
    setScheduleStartOrDefault () {
      this.scheduledStart = this.scheduleStart ? this.scheduleStart : moment().format('YYYY-MM-DD')
      if (this.scheduleConditions && this.checkTime(this.scheduleConditions.time, true)) {
        this.scheduledTime = this.scheduleConditions.time.substr(0, 5)
      } else {
        this.scheduledTime = moment().format("HH:mm")
      }
    },
    changeToRecurrent () {
      this.$nextTick(() => {
        this.selectedTriggerId = null
        this.setScheduleStartOrDefault();
      })
    },
    updateDirty (field, value) {
      this.dirty = true
      if (field === 'crm_trigger_id') {
        this.selectedTriggerId = value.id
      }
    },
    markDay (val) {
      let position = this.scheduledDays.indexOf(val.id)

      if (position < 0) {
        this.scheduledDays.push(val.id)
      } else {
        this.scheduledDays.splice(position, 1)
      }
    },
    markAllDays () {
      if (!this.allDaysMarked) {
        this.scheduledDays = this.weekDays.map(d => d.id)
      } else {
        this.scheduledDays = []
      }
    },
    viewDay (id) {
      return this.scheduledDays.indexOf(id) >= 0
    },
    dateFormat (date) {
      if (!date) {
        return null
      }

      const format = new Date(date)

      return parseInt((format.getTime() / 1000).toFixed(0))
    },
    checkTime (time, full = false) {
      if (full) {
        return /([0-9]{2}):([0-9]{2}):([0-9]{2})/g.exec(time)
      }

      return /([0-9]{2}):([0-9]{2})/g.exec(time)
    },
    showTestModal () {
      if (this.isFormDirty || this.dirty) {
        this.$swal.fire('Existen cambios sin guardar, guarda antes de realizar las pruebas de envío.', '', 'warning')
      } else {
        this.showModal = true
      }
    },
    closeTestModal () {
      this.showModal = false
    },
    sendTestCampaign () {
      this.errorEmails = false
      if (this.testEmails) {
        this.testCampaign()
      } else {
        this.errorEmails = true
      }
    },
    async testCampaign () {
      mApp.block(this.refTestModal, {})
      try {
        const test = await axios.post(`/api/crm/campaigns/${this.campaignId}/schedules/test`, {
          emails: this.testEmails,
        })

        if (test.data.error !== false) {
          this.$swal.fire(test.data.error, '', 'error')
        } else {
          this.$swal.fire('Campaña lista', 'Prueba enviada', 'success')
          this.closeTestModal()
        }
      } catch (e) {
        let errors = e.response.data.errors
        let message

        for (let error in errors) {
          if (Array.isArray(errors[error])) {
            let list = errors[error]
            for (let keyList in list) {
              message = list[keyList]
              break
            }
            break
          } else {
            message = errors[error]
            break
          }
        }

        this.$swal.fire(message, '', 'error')
      } finally {
        mApp.unblock(this.refTestModal, {})
      }
    },
    async sendCampaign () {
      const $buttons = $('button')
      mApp.block($buttons, {})
      if (this.isFormDirty || this.dirty) {
        this.$swal.fire('Existen cambios sin guardar, guarda antes de realizar las pruebas de envío', '', 'warning')
        mApp.unblock($buttons)
        return false
      }

      try {
        const campaignResponse = await axios.post(`/api/crm/campaigns/${this.campaignId}/schedules`)

        if (campaignResponse.status === 200) {
          if (campaignResponse.data.error !== false) {
            this.$swal.fire(campaignResponse.data.error, '', 'error')
          } else {
            const sent = moment(campaignResponse.data.sentAt)
            this.$swal.fire('Campaña lista', 'El envío se hará efectivo ' + sent.fromNow(), 'success')
          }
        }

      } catch (e) {
        this.$swal.fire(e.data.message, '', 'error')
      } finally {
        mApp.unblock($buttons)
      }
    },
  },
}
</script>
