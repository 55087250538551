var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "scrolltop",
        attrs: { id: "kt_scrolltop", "data-kt-scrolltop": "true" },
      },
      [_c("i", { staticClass: "fa fa-arrow-up" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }