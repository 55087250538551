var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classField },
    [
      _vm.name
        ? _c(
            "label",
            { staticClass: "form-label", attrs: { for: _vm.field } },
            [_vm._v(_vm._s(_vm.name))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          (_vm.showInput ? "text" : "hidden") === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fieldValue,
                    expression: "fieldValue",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: _vm.field,
                  id: _vm.field,
                  disabled: _vm.disabled || _vm.nbReadOnly,
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.fieldValue)
                    ? _vm._i(_vm.fieldValue, null) > -1
                    : _vm.fieldValue,
                },
                on: {
                  input: _vm.emitValue,
                  change: function ($event) {
                    var $$a = _vm.fieldValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.fieldValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.fieldValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.fieldValue = $$c
                    }
                  },
                },
              })
            : (_vm.showInput ? "text" : "hidden") === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fieldValue,
                    expression: "fieldValue",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: _vm.field,
                  id: _vm.field,
                  disabled: _vm.disabled || _vm.nbReadOnly,
                  type: "radio",
                },
                domProps: { checked: _vm._q(_vm.fieldValue, null) },
                on: {
                  input: _vm.emitValue,
                  change: function ($event) {
                    _vm.fieldValue = null
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fieldValue,
                    expression: "fieldValue",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: _vm.field,
                  id: _vm.field,
                  disabled: _vm.disabled || _vm.nbReadOnly,
                  type: _vm.showInput ? "text" : "hidden",
                },
                domProps: { value: _vm.fieldValue },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.fieldValue = $event.target.value
                    },
                    _vm.emitValue,
                  ],
                },
              }),
          _vm._v(" "),
          _c("v-swatches", {
            style: _vm.swatchesStyle,
            attrs: {
              disabled: _vm.disabled || _vm.nbReadOnly,
              "show-fallback": _vm.showFallback,
              "fallback-input-type": _vm.fallbackInputType,
            },
            on: { input: _vm.emitValue },
            model: {
              value: _vm.fieldValue,
              callback: function ($$v) {
                _vm.fieldValue = $$v
              },
              expression: "fieldValue",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: _vm.field } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }