var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mb-6 form-group m-form__group form-group-sm col-12 noprefetch m--padding-left-30 m--padding-right-30 py-0 m-form",
    },
    [
      _c("input", {
        attrs: {
          type: "hidden",
          name: "scheduleGroupingType",
          id: "scheduleGroupingType",
        },
        domProps: { value: _vm.scheduleGroupingTypeId },
      }),
      _vm._v(" "),
      _c(
        "article",
        { staticClass: "block position-relative c-contact pt-4 mb-5" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-left": "20px",
                    "padding-right": "20px",
                  },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-4" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "field__span form-label field__span--required",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")("event.schedule_grouping_type")
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.scheduleGroupingType,
                              expression: "scheduleGroupingType",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.scheduleGroupingType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.scheduleGroupingTypes, function (type) {
                          return _c(
                            "option",
                            { key: type.id, domProps: { value: type } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(type.name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("Calendar", {
                attrs: {
                  "special-events": _vm.specialEvents,
                  "start-date-event": _vm.startDateEvent,
                  "end-date-event": _vm.endDateEvent,
                  "split-days": _vm.splitDays,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "accordion mb-8", attrs: { id: "kt_accordion_1" } },
      [
        _c("div", { staticClass: "accordion-item" }, [
          _c(
            "h2",
            {
              staticClass: "accordion-header",
              attrs: { id: "kt_accordion_1_header_1" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "accordion-button fs-4 fw-semibold",
                  attrs: {
                    type: "button",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#kt_accordion_1_body_1",
                    "aria-expanded": "true",
                    "aria-controls": "kt_accordion_1_body_1",
                  },
                },
                [
                  _vm._v(
                    "\n                        Instrucciones\n                    "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordion-collapse collapse show",
              attrs: {
                id: "kt_accordion_1_body_1",
                "aria-labelledby": "kt_accordion_1_header_1",
                "data-bs-parent": "#kt_accordion_1",
              },
            },
            [
              _c("div", { staticClass: "accordion-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("Editar titulo:")]),
                    _vm._v(" Hacer clic sobre el titulo."),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [_vm._v("cambiar de hora:")]),
                    _vm._v(
                      " mantener pulsado la card y moverla a la hora deseada."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [_vm._v("modificar duracion:")]),
                    _vm._v(
                      " poner el ratón en la base del card, hacer clic y arrastras a la hora fin deseada."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [_vm._v("eliminar:")]),
                    _vm._v(
                      " mantener pulsado sobre la card, hasta que aparezca la opción de borrar."
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }