var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-5 mx-4 mb-0" },
    [
      _c("div", { staticClass: "mb-5" }, [
        _c(
          "label",
          { staticClass: "d-block" },
          [
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Ponentes")]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.speakerArray,
                label: "name",
                "track-by": "id",
                multiple: true,
              },
              model: {
                value: _vm.speakers,
                callback: function ($$v) {
                  _vm.speakers = $$v
                },
                expression: "speakers",
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.speakers` },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "label",
            {
              staticClass:
                "form-check form-switch form-check-custom form-check-solid",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.show_questions,
                    expression: "show_questions",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox" },
                domProps: {
                  value: _vm.show_questions,
                  checked: Array.isArray(_vm.show_questions)
                    ? _vm._i(_vm.show_questions, _vm.show_questions) > -1
                    : _vm.show_questions,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.show_questions,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.show_questions,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.show_questions = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.show_questions = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.show_questions = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form-check-label" }, [
                _vm._v(
                  "\n                    Mostrar preguntas\n                "
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("conference-property-tutored-questions", {
        attrs: { lang: _vm.lang, questions: _vm.value.questions },
        on: { "update-questions": _vm.updateQuestions },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }