var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ticket-properties" }, [
    _c("div", { staticClass: "ticket-properties__tabs-container" }, [
      _c("ul", { staticClass: "ticket-properties__tabs" }, [
        _c(
          "li",
          {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "ticket-properties__tab",
            class: { active: _vm.activeTab === "properties" },
            attrs: { title: "Propiedades", id: "tab-properties" },
            on: {
              click: function ($event) {
                _vm.activeTab = "properties"
              },
            },
          },
          [
            _c("em", { staticClass: "fa fa-fw fa-cogs" }),
            _vm._v(" "),
            _c("span", { staticClass: "ticket-properties__tab-text" }, [
              _vm._v("Propiedades"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "ticket-properties__tab",
            class: { active: _vm.activeTab === "styles" },
            attrs: { title: "Apariencia", id: "tab-styles" },
            on: {
              click: function ($event) {
                _vm.activeTab = "styles"
              },
            },
          },
          [
            _c("em", { staticClass: "fa fa-fw fa-desktop" }),
            _vm._v(" "),
            _c("span", { staticClass: "ticket-properties__tab-text" }, [
              _vm._v("Apariencia"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "ticket-properties__tab",
            class: { active: _vm.activeTab === "visibility" },
            attrs: { title: "Visibilidad", id: "tab-visibility" },
            on: {
              click: function ($event) {
                _vm.activeTab = "visibility"
              },
            },
          },
          [
            _c("em", { staticClass: "fa fa-fw fa-eye" }),
            _vm._v(" "),
            _c("span", { staticClass: "ticket-properties__tab-text" }, [
              _vm._v("Visibilidad"),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ticket-properties__properties-options" },
      [
        _vm.activeElement && _vm.activeElement.blockPower <= _vm.powerUserLoged
          ? [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "properties",
                      expression: "activeTab === 'properties'",
                    },
                  ],
                },
                [
                  _c("CommonProperties", {
                    attrs: { powerUserLoged: _vm.powerUserLoged },
                  }),
                  _vm._v(" "),
                  _vm.activeElement.type === "text"
                    ? _c("TextFieldProperties")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeElement.type === "image"
                    ? _c("ImageFieldProperties")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeElement.type === "barcode"
                    ? _c("BarcodeFieldProperties")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeElement.type === "qr"
                    ? _c("QRCodeFieldProperties")
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "styles",
                      expression: "activeTab === 'styles'",
                    },
                  ],
                },
                [
                  _vm.activeElement.type === "text"
                    ? _c("TextFieldStyles")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeElement.type === "image"
                    ? _c("ImageFieldStyles")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeElement.type === "barcode"
                    ? _c("BarcodeFieldStyles")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeElement.type === "qr"
                    ? _c("QRCodeFieldStyles")
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("VisibilityTab", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab === "visibility",
                    expression: "activeTab === 'visibility'",
                  },
                ],
              }),
            ]
          : _c(
              "div",
              { staticClass: "ticket-properties__properties-message" },
              [_vm._v("\n            Selecciona un elemento\n        ")]
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }