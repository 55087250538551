<template>
  <div class="form-group m-form__group col-12">
    <div class="row">
      <div class="col-md-4">
        <select name="device_log_date" id="device_log_date" class="form-control m-input"
                v-model="selectedDate" @change="getDeviceLogs">
          <option :value="null">Seleccione fecha</option>
          <option :value="date" v-for="date in logDates">{{ date }}</option>
        </select>
      </div>
      <div class="col-md-4">
        <select name="device_log_limit" id="device_log_limit" class="form-control m-input"
                v-model="selectedLimit" @change="getDeviceLogs">
          <option :value="limit" v-for="limit in limits">
            <span v-if="limit === 'all'">Mostrar todos</span>
            <span v-else>Mostrar últimos {{ limit }} resultados</span>
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 pt-4" v-if="deviceLogs.length">
        <table class="table m-table table-striped table-bordered">
          <thead>
          <th>Fecha</th>
          <th>Tipo</th>
          <th>Información</th>
          <th>Resultado</th>
          </thead>
          <tbody>
          <tr v-for="deviceLog in deviceLogs">
            <td>{{ deviceLog.date }}</td>
            <td>{{ deviceLog.method }}</td>
            <td>{{ deviceLog.log.message }}</td>
            <td>{{ deviceLog.log.result }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 text-center pt-4" v-else>
        <h5 v-if="loading">
          Cargando logs
          <i class="fa fa-refresh fa-spin"></i>
        </h5>
        <h5 v-else>Este dispositivo no tiene logs para la fecha seleccionada.</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'device-logs',
  props: {
    deviceId: {type: Number},
    logDates: {type: Array, default: []}
  },
  data() {
    return {
      selectedDate: null,
      selectedLimit: 50,
      limits: [5, 10, 50, 100, 500, 1000, 'all'],
      deviceLogs: [],
      loading: false
    }
  },
  computed: {},
  methods: {
    async getDeviceLogs () {
      if (this.selectedDate && this.selectedLimit) {
        this.loading = true
        const response = await axios.get(`/api/devices/${this.deviceId}/logs`, { params: {
          date: this.selectedDate,
          limit: this.selectedLimit
        }})
        if (response.status === 200) {
          this.deviceLogs = response.data
        }
        this.loading = false
      }
    }
  },
}
</script>
