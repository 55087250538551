<template>
    <div class="mt-5 mx-4 mb-0">
        <div class="col-12 mb-4 text-end c-survey-component__btn-add-option">

            <button type="button" class="btn btn-primary m-btn m-btn--icon" @click="addFile"
                    :disabled="nbReadOnly" id="add-option">
                <i class="fa fa-plus"></i>
                {{ 'conference.properties.property_resources.add_resource' | trans }}
            </button>

        </div>
        <div class="fs-5 fw-bolder alert text-center my-20" v-if="!resources.length">
            {{ 'conference.properties.property_resources.no_resources' | trans }}
        </div>
        <ConferencePropertyResourcesFile v-else v-model="resources" :property-key="propertyKey"/>
    </div>
</template>


<script>
  import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'
  import ConferencePropertyResourcesFile from '@/js/components/conference/properties/resources/ConferencePropertyResourcesFile'

  export default {
    name: 'ConferencePropertyResources',
    components: {
      RepositoryButton,
      ConferencePropertyResourcesFile,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      lang: {
        type: String,
        default: null,
      },
      structure: {
        type: Object,
        required: true
      },
      propertyKey: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        resources: [],
      }
    },
    created () {
      this.resources = this.value.resources ? this.value.resources : []
    },
    watch: {
      resources (value) {
        this.emit({ resources: value })
      },
    },
    computed: {
      resourceTypes () {
        return Object.keys(this.structure)
      }
    },
    methods: {
      deleteOption (index) {
        this.resources.splice(index, 1)
      },
      getEmptyNewOption () {
        return {
          title: '',
          description: '',
          resource_id: null,
          resource_type: this.resourceTypes[0]
        }
      },
      addFile () {
        const index = this.resources.length
        this.$set(this.resources, index, this.getEmptyNewOption())
      },
      emit (value) {
        this.$emit('input', { ...this.value, ...value })
      },
    },
  }
</script>
