var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group mb-0" }, [
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.type,
              expression: "type",
            },
          ],
          attrs: { type: "radio" },
          domProps: {
            value: _vm.TYPES.TYPE_IMAGE,
            checked: _vm._q(_vm.type, _vm.TYPES.TYPE_IMAGE),
          },
          on: {
            change: function ($event) {
              _vm.type = _vm.TYPES.TYPE_IMAGE
            },
          },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("Imagen")]),
      ]),
      _vm._v(" "),
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.type,
              expression: "type",
            },
          ],
          attrs: { type: "radio" },
          domProps: {
            value: _vm.TYPES.TYPE_TEXT,
            checked: _vm._q(_vm.type, _vm.TYPES.TYPE_TEXT),
          },
          on: {
            change: function ($event) {
              _vm.type = _vm.TYPES.TYPE_TEXT
            },
          },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("Variable")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == _vm.TYPES.TYPE_IMAGE,
              expression: "type == TYPES.TYPE_IMAGE",
            },
          ],
        },
        [
          _c("label", [_vm._v("Ruta de imagen")]),
          _vm._v(" "),
          _c("repository-button", {
            key: _vm.activeElement.id,
            attrs: {
              "allowed-mime-types": _vm.allowedMimeTypes,
              "short-version": true,
              "class-field": "col-md-12 px-0",
            },
            on: { "clear-value": _vm.clearValue },
            model: {
              value: _vm.selectedImage,
              callback: function ($$v) {
                _vm.selectedImage = $$v
              },
              expression: "selectedImage",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == _vm.TYPES.TYPE_TEXT,
              expression: "type == TYPES.TYPE_TEXT",
            },
          ],
        },
        [
          _c("label", { attrs: { for: "image-value" } }, [_vm._v("Variable")]),
          _vm._v(" "),
          _c("SuggesterText", {
            attrs: {
              id: "elementContent",
              placeholder: "Valor del elemento",
              suggestions: _vm.availableImageVariables,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ suggestion }) {
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(suggestion) +
                        "\n                    "
                    ),
                    _vm.renderVariable(suggestion) !== suggestion
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "ml-2 m-badge m-badge--brand m-badge--wide",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.renderVariable(suggestion)) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedText,
              callback: function ($$v) {
                _vm.selectedText = $$v
              },
              expression: "selectedText",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }