var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderedNbError
    ? _c(
        "div",
        { ref: "errorSpan", staticClass: "form-control-feedback has-danger" },
        [
          _vm._l(_vm.renderedNbError, function (error, i) {
            return _vm.multiple
              ? [
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(error)),
                  ]),
                  _vm.renderedNbError[i + 1] ? _c("br") : _vm._e(),
                ]
              : [
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.renderedNbError[0])),
                  ]),
                ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }