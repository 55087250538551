var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group m-form__group col-md-6 c-locked",
      class: [..._vm.lockStatus],
    },
    [
      _c(
        "button",
        {
          staticClass: "c-locked__lock",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.lockStatus = "not-locked"
            },
          },
        },
        [
          _c("i", {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "icon flaticon-lock-1 c-locked__lock-icon-1",
            attrs: { title: "Bloquear/Desbloquear" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "icon flaticon-lock-1 c-locked__lock-icon-2",
            attrs: { title: "Bloquear/Desbloquear" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("label", { attrs: { for: "internal_id" } }, [
        _vm._v("ID Integración Web"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.currentValue,
            expression: "currentValue",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control m-input",
        attrs: {
          name: "internal_id",
          type: "number",
          min: "0",
          id: "internal_id",
          disabled: _vm.lockStatus === "is-locked",
        },
        domProps: { value: _vm.currentValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.currentValue = _vm._n($event.target.value)
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: "internal_id" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }