var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `form-group ${_vm.containerClass}` },
    [
      _vm.label.length
        ? _c(
            "label",
            { staticClass: "form-label", attrs: { for: _vm.field } },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12", attrs: { name: _vm.field } }, [
        _c("div", { staticClass: "row flex-grow-1" }, [
          _c(
            "div",
            {
              staticClass:
                "col-7 col-md-8 col-lg-9 col-xl-10 d-flex flex-column",
            },
            [
              _c("div", { staticClass: "card card-stretch card-bordered" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h4", { staticClass: "card-title" }, [_vm._v("Cuerpo")]),
                  _vm._v(" "),
                  _vm.preview
                    ? _c("div", { staticClass: "card-toolbar" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-light",
                            attrs: {
                              type: "button",
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#kt_modal_preview",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Previsualizar\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal fade",
                            attrs: { tabindex: "-1", id: "kt_modal_preview" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modal-dialog modal-dialog-centered modal-xl",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "modal-content shadow-none" },
                                  [
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "modal-body" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ratio ratio-16x9 shadow-sm rounded",
                                        },
                                        [
                                          _c("iframe", {
                                            staticClass: "scaled-frame",
                                            attrs: {
                                              src: _vm.preview,
                                              width: "100%",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(1),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body card-scroll" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-light rounded-3 p-2 pb-3 position-relative",
                    },
                    [
                      !_vm.rows.length
                        ? _c("div", { staticClass: "text-center" }, [
                            _c("input", {
                              attrs: {
                                name: _vm.field,
                                type: "hidden",
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _vm._m(3),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        {
                          class: [
                            _vm.rows.length === 0
                              ? "position-absolute top-0 start-0 end-0 bottom-0 p-2"
                              : "",
                            "row g-2",
                          ],
                          attrs: {
                            list: _vm.rows,
                            nested: true,
                            group: "contenido",
                            options: _vm.dragOptions,
                            handle: ".handle",
                            "ghost-class": "opacity-75",
                          },
                          on: { change: _vm.changeEvent },
                        },
                        _vm._l(_vm.rows, function (row, idx) {
                          return _c("cms-editor-item", {
                            key: row.id,
                            class:
                              "draggable position-relative " +
                              _vm.calculeWidth(row),
                            attrs: {
                              editor: _vm.self(),
                              row: row,
                              idx: idx,
                              field: _vm.field,
                              prefix: _vm.prefix,
                              items: row.childrens,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-5 col-md-4 col-lg-3 col-xl-2 d-flex flex-column pb-2",
            },
            [
              _c(
                "div",
                {
                  staticClass: "card shadow-sm card-stretch position-sticky",
                  staticStyle: { top: "80px" },
                },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body card-scroll" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass:
                            "row gy-1 gx-0 bg-light p-2 pb-3 rounded-2",
                          attrs: {
                            list: _vm.modules,
                            group: {
                              name: "contenido",
                              pull: "clone",
                              put: false,
                            },
                            clone: _vm.cloneModule,
                            sort: false,
                          },
                        },
                        _vm._l(_vm.modules, function (element) {
                          return _c(
                            "div",
                            {
                              key: element.id,
                              staticClass: "col-12 draggable",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-group-item draggable-handle border-dashed border-1 border-gray-400 rounded-2",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(element.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("nb-error-block", {
        attrs: { path: _vm.field, json: _vm.jsonErrors, subpath: _vm.prefix },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Previsualización")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "btn btn-icon btn-sm btn-active-light-primary ms-2",
          attrs: { "data-bs-dismiss": "modal", "aria-label": "Close" },
        },
        [_c("i", { staticClass: "bi bi-x-lg" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [
          _vm._v(
            "\n                                                Cerrar\n                                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "far fa-plus-square fa-10x" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Arrastra un módulo para empezar a crear la página "),
      _c("i", { staticClass: "fa fa-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Módulos")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }