<template>
    <div>
        <CmsSection
            :sections="arraySections"
            :locale="locale"
            :defaultLangValue="defaultLangValue"
            @addSection="addSection"
        />
        <CmsSelectorPage
            :links="links"
            :pages="pages"
            :sections="arraySections"
            :locale="locale"
            :defaultLangValue="defaultLangValue"
        />
    </div>
</template>

<script>
import CmsSelectorPage from './CmsSelectorPage.vue';
import CmsSection from './CmsSection.vue';

export default {
    name: 'CmsHeader',
    components: { CmsSelectorPage, CmsSection },
    props: {
        links: {
            type: Array,
            default: () => []
        },
        pages: {
            type: Object,
            default: () => {}
        },
        sections: {
            type: Array,
            default: () => []
        },
        locale: {
            type: String
        },
        defaultLangValue: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            arraySections: [],
            newSection: {
                id: null,
                name: null,
            }
        };
    },
    created() {
        if (this.sections.length > 0) {
            this.arraySections = this.sections.map(object => {
                return {
                    id: object.id,
                    name: this.getLocale(
                        object.id,
                        this.locale,
                        object.name
                    ),
                    defaultLangValue: this.getLocale(object.id, "es")
                };
            });
        }
    },
    methods: {
        addSection() {
            this.arraySections.push(Object.assign({}, this.newSection));
        },
        getLocale(id, locale, name) {
            let lang = null;
            if (id !== null) {
                let object = this.sections.find(object => object.id === id);

                if (object.translations !== undefined) {
                    lang = object.translations.find(
                        translation => translation.locale === locale
                    );
                    if (lang !== undefined) {
                        lang = lang.name;
                    }
                } else {
                    lang = object.name;
                }
            }else{
                lang=name;
            }
            return lang;
        }
    }
}

</script>