var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      attrs: { type: "hidden", name: "permissions" },
      domProps: { value: _vm.permissionsJSON },
    }),
    _vm._v(" "),
    _c("label", { staticClass: "form-label", attrs: { for: "name" } }, [
      _vm._v(_vm._s(_vm._f("trans")("customer_types.permissions.title"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.permissions, function (permission, key) {
        return _c(
          "div",
          { key: key, staticClass: "form-group mb-6 col-md-4" },
          [
            _c(
              "label",
              {
                staticClass:
                  "form-check form-switch form-check-custom form-check-solid",
              },
              [
                _c("input", {
                  staticClass: "form-check-input",
                  attrs: {
                    type: "checkbox",
                    name: `permission_${permission.name}`,
                    id: `permission_${permission.id}`,
                    value: "1",
                  },
                  domProps: {
                    checked: _vm.selectedPermissions.includes(permission.id),
                  },
                  on: {
                    input: function ($event) {
                      return _vm.inputPermission(permission.id)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "form-check-label" }, [
                  _vm._v(
                    "\n            " + _vm._s(permission.name) + "\n        "
                  ),
                ]),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }