import Vue from 'vue'
import DetailView from '@/js/components/templating/detail-view/DetailView.vue'
import PermissionsComponent from '@/js/components/permissions/PermissionsComponent.vue'
import SpecialPermissionsComponent from '@/js/components/permissions/SpecialPermissionsComponent.vue'
import TaggerComponent from '@/js/components/tagger/Tagger'
import QuestionComponent from '@/js/components/question/QuestionComponent'
import SurveyComponent from '@/js/components/survey/SurveyComponent'
import HtmlEditor from '@/js/components/html_editor/HtmlEditor'
// TODO: EDITOR VISUAL Generalizar
import TicketEditor from '@/js/components/ticket_editor/TicketEditor'
import MetasGenerator from '@/js/components/metas/MetasGenerator'
import MessageComponent from '@/js/components/campaign/MessageComponent'
import CampaignStatistics from '@/js/components/campaign/CampaignStatistics'
import ListBoxEntity from '@/js/components/reports/ListBox-entity'
import ListBoxGroups from '@/js/components/reports/ListBox-groups'
import ListBoxSelect from '@/js/components/reports/ListBox-select'
import ListBoxFields from '@/js/components/reports/ListBox-fields'
import ReportGroupings from '@/js/components/reports/ReportGroupings'
import ReportDatePicker from '@/js/components/reports/ReportDatePicker'
import ReportNumber from '@/js/components/reports/ReportNumber'
import ReportCheckbox from '@/js/components/reports/ReportCheckbox'
import SortableComponent from '@/js/components/sortable/SortableComponent'
import Tokens from '@/js/components/crm/Tokens'
import EvaluatorInstances from '@/js/components/campaign/EvaluatorInstances'
import TriggerEvaluatorInstances from '@/js/components/campaign/TriggerEvaluatorInstances'
import UploadFiles from '@/js/components/repository_explorer/UploadFiles'
import EvaluatorInclude from '@/js/components/campaign/evaluators/EvaluatorInclude'
import EvaluatorOperator from '@/js/components/campaign/evaluators/EvaluatorOperator'
import EvaluatorGroups from '@/js/components/campaign/evaluators/EvaluatorGroups'
import EvaluatorDate from '@/js/components/campaign/evaluators/EvaluatorDate'
import SchedulingComponent from '@/js/components/reports/SchedulingComponent'
import CmsSelectorType from '@/js/components/cms/CmsSelectorType'
import CmsSelectorPage from '@/js/components/cms/CmsSelectorPage'
import CmsMenu from '@/js/components/cms/CmsMenu'
import Repository from '@/js/components/repository_explorer/Repository'
import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'
import ManagerList from '@/js/components/user/ManagerList'
import DashboardFilters from '@/js/components/dashboard/DashboardFilters'
import TranslationsForm from '@/js/components/translations/TranslationsForm'
import ModalComponent from '@/js/components/widgets/ModalComponent'
import QrCode from '@/js/components/widgets/QrCode'
import VueColorPicker from '@/js/components/widgets/VueColorPicker'
import DualMultiselect from '@/js/components/widgets/DualMultiselect'
import AutonomousMultiselect from '@/js/components/widgets/AutonomousMultiselect'
import CampaingDataFields from '@/js/components/campaign/CampaingDataFields'
import EditList from '@/js/components/widgets/EditList'
import ListSimple from '@/js/components/widgets/ListSimple'
import SelectWithCreate from '@/js/components/widgets/SelectWithCreate'
import ButtonUpload from '@/js/components/widgets/ButtonUpload'
import InternalIdField from '@/js/components/organization/InternalIdField'
import PreviewComponent from "@/js/components/reports/PreviewComponent"
import FooterWarning from "@/js/components/widgets/FooterWarning"
import QuickSearch from '@/js/components/quick_search/quick-search'
import ActionsSubmenu from '@/js/components/panel/actions-submenu'
import MenuSections from '@/js/components/panel/menu-sections'
import SupportCenter from '@/js/components/panel/SupportCenter'
import ScrolltopArrow from '@/js/components/panel/scrolltop-arrow'
import FavoriteCards from '@/js/components/home/FavoriteCards'
import NbErrorBlock from '@/js/components/error_block/nb-error-block'
import CustomerActivationsComponent from '@/js/components/crm/CustomerActivationsComponent'
import BillingAlert from '@/js/components/crm/BillingAlert'
import CustomerAddressesComponent from '@/js/components/crm/CustomerAddressesComponent'
import ImportComponent from '@/js/components/import/ImportComponent'
import MyReports from '@/js/components/reports/MyReports'
import CustomerName from '@/js/components/crm/CustomerName'
import CustomerCombine from '@/js/components/crm/CustomerCombine'
import CustomerSendMail from '@/js/components/crm/CustomerSendMail'
import CustomerNotifications from '@/js/components/crm/CustomerNotifications'
import DashboardComponent from '@/js/components/dashboard/DashboardComponent'
import BlogPostPostedFeaturedComponent from '@/js/components/BlogPostPostedFeaturedComponent'
import ConferenceFilesComponent from '@/js/components/conference/ConferenceFilesComponent'
import ConferenceQuestionsComponent from '@/js/components/conference/ConferenceQuestionsComponent'
import ConferenceProperties from '@/js/components/conference/properties/ConferenceProperties'
import ConferencePropertySchedule from '@/js/components/conference/properties/ConferencePropertySchedule'
import ConferencePropertyResources from '@/js/components/conference/properties/ConferencePropertyResources'
import ConferencePropertyResourcesFile from '@/js/components/conference/properties/resources/ConferencePropertyResourcesFile'
import ConferencePropertyTutored from '@/js/components/conference/properties/ConferencePropertyTutored'
import ConferencePropertyTutoredQuestions from '@/js/components/conference/properties/tutored/ConferencePropertyTutoredQuestions'
import ConferencePropertyVideomonitorable from '@/js/components/conference/properties/ConferencePropertyVideomonitorable'
import ConferencePropertySurveyable from '@/js/components/conference/properties/ConferencePropertySurveyable'
import ConferencePropertyRegistrable from '@/js/components/conference/properties/ConferencePropertyRegistrable'
import ConferencePropertyChat from '@/js/components/conference/properties/ConferencePropertyChat'
import ConferenceSurveyDashboard from '@/js/components/conference/ConferenceSurveyDashboard'
import ItemQuestionComponent from '@/js/components/survey/ItemQuestionComponent'
import ListQuestionsComponent from '@/js/components/survey/ListQuestionsComponent'
import SurveyResponsesComponent from '@/js/components/survey/SurveyResponsesComponent'
import SurveyResponsesTableComponent from '@/js/components/survey/SurveyResponsesTableComponent'
import Statistics from '@/js/components/statistics/Statistics'
import Calendar from '@/js/components/events/Calendar'
import Schedule from '@/js/components/events/Schedule'
import Payment from '@/js/components/events/Payment'
import FeaturesComponent from '@/js/components/features/FeaturesComponent'
import ExternalRegisterConfig from '@/js/components/crm/ExternalRegisterConfig'
import CustomForm from '@/js/components/custom_form/CustomForm'
import SoundSelectDevice from '@/js/components/device/SoundSelectDevice'
import DisableUnlinkDevice from '@/js/components/device/DisableUnlinkDevice'
import SyncDevice from '@/js/components/device/SyncDevice'
import DeviceLogs from '@/js/components/device/DeviceLogs'
import CmsPageContentBlocks from '@/js/components/cms/CmsPageContentBlocks'
import HtmlParameters from '@/js/components/html_editor/HtmlParameters'
import ExtraCustomForm from '@/js/components/ExtraCustomForm'
import EntityType from '@/js/components/activity_log/EntityType'
import CustomFormType from '@/js/components/cms/CustomFormType'
import CustomerTypePermissions from '@/js/components/crm/CustomerTypePermissions'
import CustomerImport from '@/js/components/crm/CustomerImport'
import AccessControlLogModal from '@/js/components/conference/AccessControlLogModal'
import SelectAdditionalRooms from '@/js/components/conference/SelectAdditionalRooms'
import AccreditationDisableUnlinkDevice from '@/js/components/accreditation_device/DisableUnlinkDevice'
import AccreditationSyncDevice from '@/js/components/accreditation_device/SyncDevice'
import AccreditationDeviceLogs from '@/js/components/accreditation_device/DeviceLogs'


Vue.component('modal-component', ModalComponent)
Vue.component('access-control-log-modal', AccessControlLogModal)
require('../../../../vendor/netberry/cms-editor/resources/js/app.js')

export default {
  InternalIdField,
  ButtonUpload,
  CampaignStatistics,
  SelectWithCreate,
  ListSimple,
  EditList,
  CampaingDataFields,
  AutonomousMultiselect,
  DualMultiselect,
  QrCode,
  VueColorPicker,
  ModalComponent,
  QuestionComponent,
  SurveyComponent,
  HtmlEditor,
  TicketEditor,
  DetailView,
  PermissionsComponent,
  SpecialPermissionsComponent,
  TaggerComponent,
  MetasGenerator,
  MessageComponent,
  ListBoxEntity,
  ListBoxGroups,
  ListBoxSelect,
  ListBoxFields,
  ReportGroupings,
  ReportDatePicker,
  ReportNumber,
  ReportCheckbox,
  SortableComponent,
  Tokens,
  EvaluatorInstances,
  TriggerEvaluatorInstances,
  UploadFiles,
  EvaluatorInclude,
  EvaluatorOperator,
  EvaluatorGroups,
  EvaluatorDate,
  SchedulingComponent,
  CmsSelectorType,
  CmsSelectorPage,
  CmsMenu,
  Repository,
  RepositoryButton,
  ManagerList,
  DashboardFilters,
  TranslationsForm,
  PreviewComponent,
  FooterWarning,
  QuickSearch,
  ActionsSubmenu,
  MenuSections,
  SupportCenter,
  ScrolltopArrow,
  FavoriteCards,
  NbErrorBlock,
  CustomerActivationsComponent,
  BillingAlert,
  CustomerAddressesComponent,
  ImportComponent,
  MyReports,
  CustomerName,
  CustomerCombine,
  CustomerSendMail,
  CustomerNotifications,
  DashboardComponent,
  BlogPostPostedFeaturedComponent,
  ConferenceFilesComponent,
  ItemQuestionComponent,
  ListQuestionsComponent,
  ConferenceQuestionsComponent,
  Statistics,
  Calendar,
  Schedule,
  SurveyResponsesComponent,
  SurveyResponsesTableComponent,
  Payment,
  FeaturesComponent,
  SurveyResponsesComponent,
  SurveyResponsesTableComponent,
  ExternalRegisterConfig,
  CustomForm,
  SoundSelectDevice,
  DisableUnlinkDevice,
  SyncDevice,
  DeviceLogs,
  CmsPageContentBlocks,
  HtmlParameters,
  ExtraCustomForm,
  EntityType,
  CustomerImport,
  AccessControlLogModal,
  ConferenceProperties,
  ConferencePropertySchedule,
  ConferencePropertyResources,
  ConferencePropertyResourcesFile,
  ConferencePropertyTutored,
  ConferencePropertyTutoredQuestions,
  ConferencePropertyVideomonitorable,
  ConferencePropertySurveyable,
  ConferencePropertyRegistrable,
  SelectAdditionalRooms,
  ConferencePropertyChat,
  ConferenceSurveyDashboard,
  AccreditationDisableUnlinkDevice,
  AccreditationSyncDevice,
  AccreditationDeviceLogs,
  CustomFormType,
  CustomerTypePermissions,
  CustomerImport,
}
