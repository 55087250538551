<template>
    <div class="form-group m-form__group  col-12 text-right">
        <button :disabled="disabled" @click="show" type="button" id="deviceQRModalButton"
                class="btn btn-primary m-btn m-btn--icon-right">
            Sincronizar
        </button>

        <modal-component id="deviceQRModal"
                         :visible.sync="visibleModal"
                         :backdrop="true"
                         :refreshing.sync="refreshingModal">
            <template #title>
                Escanea el código con el dispositivo
            </template>
            <template>
                <span v-if="status === 0">Generando el código.</span>
                <span v-else-if="status === 1">Ha habido un error generando el código.</span>
                <qr-code v-else :uuid="uuid" :endpoint="endpoint"></qr-code>
                Email:
                <div class="c-qr-code__form">
                    <div class="c-qr-code__form-input">
                        <input ref="email" type="text" class="form-control m-input m-input--solid">
                    </div>
                    <button class="c-qr-code__form-submit btn btn-primary" @click="sendMail">Enviar</button>
                    <div class="c-qr-code__form-msg" :class="classMsg">
                        <em class="c-qr-code__form-msg-icon la la-info-circle"></em>
                        <span class="c-qr-code__form-msg-txt" ref="response">
                        </span>
                    </div>
                </div>
            </template>
            <template #footer>
                <button type="button" class="btn btn-primary" @click="visibleModal = false">Cerrar</button>

            </template>
        </modal-component>
    </div>
</template>

<script>
  import QrCode from '@/js/components/widgets/QrCode'

  export default {
    name: 'sync-device',
    components: {
        QrCode,
    },
    props: {
      disabled: { type: Boolean, default: true },
      id: { type: Number },
      endpoint: { type: String, required: true },
    },
    data () {
      return {
        uuid: null,
        visibleModal: false,
        visibleMessage: false,
        refreshingModal: false,
        status: 0, // 1=Sin sincronizar, 2=QRCode, 3=Modal erroneo.
        errorSendMail: false,
      }
    },
    watch: {
      visibleModal: function () {
        if (this.visibleModal === false) {
          this.status = 0
          this.visibleMessage = false
        }
      }
    },
    computed: {
      classMsg () {
        if (this.visibleMessage) {
          return this.errorSendMail ? 'c-qr-code__form-msg--error' : 'c-qr-code__form-msg--success'
        }
        return ''
      }
    },
    methods: {
      async show () {
        this.visibleModal = true
        const response = await axios.patch(window.route('API.device.regenerate-uuid', { id: this.id }))
        if (this.visibleModal !== true) {
          return
        }
        if (response.status === 200) {
          this.uuid = response.data.uuid
          this.status = 2
        } else {
          this.status = 1
        }
      },
      async sendMail (event) {
        event.preventDefault()
        this.$refs.response.innerHTML = ''
        this.refreshingModal = true
        this.visibleMessage = false
        const response = await axios.post(window.route('API.device.send-uuid', {
          id: this.id,
          email: this.$refs.email.value
        }))
          .then(() => {
            this.$refs.response.innerHTML = 'Correo enviado'
            this.errorSendMail = false
          })
          .catch((e) => {
            this.$refs.response.innerHTML = 'No se pudo enviar el correo'
            this.errorSendMail = true
          })
        this.refreshingModal = false
        this.visibleMessage = true
      }
    },
  }
</script>
