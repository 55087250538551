<template>
    <div class="re-wrapper" v-if="visible">
        <div class="re-header d-flex">
            <h5 class="align-self-center mt-2">SELECCIONA EL FICHERO</h5>

            <form action="" autocomplete="off" class="d-flex ml-auto" style="width: 400px">
                <div class="d-flex flex-row flex-grow-1">
                    <div class="m-input-icon m-input-icon--right mr-2 w-50">
                        <input type="text" class="form-control m-input" id="update-filter"
                               placeholder="Buscar por nombre" v-model="filter"
                               @input="filterUpdated" :disabled="nbReadOnly">
                        <span class="m-input-icon__icon m-input-icon__icon--right"><span><i
                                class="la la-search"></i></span></span>
                    </div>

                    <select class="form-select  w-50" name="type"
                            id="type" v-model="type"
                            @input="filterUpdated"
                            title="Formato de fichero"
                            :disabled="nbReadOnly || disabledSearch">
                        <option :value="null"></option>
                        <option :value="type.id" v-for="type of fileTypes">{{ type.name }}</option>
                    </select>
                </div>
            </form>

            <div class="ml-4">
                <button type="button" class="btn btn-link close-modal" id="close-repo" @click="closeModal">
                    <i class="fa fa-fw fa-close"></i>
                </button>
            </div>
        </div>
        <div class="re-body">
            <div class="d-flex flex-wrap" v-if="files !== null">
                <div class="card image-card" v-for="file in files.data" @click="$emit('input', file)">
                    <div class="card-body">
                        <template v-if="file.is_image">
                            <img :src="file.url" alt="">
                            <div class="bordered bg-white rounded file-title align-self-baseline">
                                {{ file.name|truncate }}
                            </div>
                        </template>
                        <template v-else>
                            <h4>{{ file.name|truncate(15) }}</h4>
                            <p v-if="file.description">{{ file.description|truncate(100) }}</p>
                            <span class="font-weight-bold">
                                Tipo: {{ fileTypes.find(t => t.id === file.type_id).name }}
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="re-footer d-flex justify-content-around py-3">
            <nav aria-label="Page navigation" v-if="files">
                <ul class="pagination">
                    <li class="page-item" :class="currentPage === 1 ? 'disabled' : ''">
                        <a class="page-link" aria-label="Previous" @click.stop.prevent="loadFiles(currentPage - 1)"
                           id="previous-page">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only"><i class="fa fa-fw fa-arrow-left"></i></span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in files.last_page"
                        :class="currentPage === page ? 'active' : ''">
                        <a class="page-link" @click.stop.prevent="loadFiles(page)" id="load-files">{{ page }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" id="next-page"
                           :class="currentPage === files.last_page ? 'disabled' : ''"
                           @click.stop.prevent="loadFiles(currentPage + 1)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only"><i class="fa fa-fw fa-arrow-right"></i></span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
  import axios from 'axios'
  import TextHelpers from '@/js/mixins/TextHelpers'

  export default {
    name: 'repository-explorer',

    mixins: [TextHelpers],

    data () {
      return {
        visible: true,
        files: null,
        fileTypes: [],
        filter: null,
        type: null,
        currentPage: 1,
      }
    },

    props: {
      defaultFileType: { type: Number, default: null },
      disabledSearch: { type: Boolean, default: false },
    },

    async mounted () {
      await this.loadFileTypes()
      this.type = this.defaultFileType
      await this.loadFiles()
    },
    created () {
      this.type = this.defaultFileType
    },

    methods: {
      closeModal () {
        this.visible = false
        this.$emit('closedModal')
      },

      async loadFileTypes () {
        try {
          const typesRequest = await axios.get('/api/file-repository/types')

          this.fileTypes = typesRequest.data
        } catch (e) {
          console.error(e)
          this.fileTypes = []
        }
      },

      async loadFiles (page = null) {
        if (page < 1) page = 1
        if (this.files && page > this.files.last_page) page = this.files.last_page

        try {
          const filesRequest = await axios.post('/api/file-repository/files', {
            filter: this.filter,
            type: this.type,
            page,
          })

          this.files = filesRequest.data
          this.currentPage = this.files.current_page
        } catch (e) {
          console.error(e)
          this.files = null
        }
      },

      filterUpdated: _.debounce(function () { this.loadFiles() }, 500),
    },
  }
</script>

<style lang="scss" scoped>
    $margin: 100px;

    // Cambiar si se encuentra manera de enlazar al fichero de configuración del aside
    $menuWidth: 200px;
    $reducedMenuWidth: 30px;

    body.m-aside-left--enabled {
        @media (min-width: 991px) {
            .re-wrapper {
                width: calc(100vw - (2 * #{$margin}) - #{$menuWidth});
                left: calc(#{$margin} + #{$menuWidth} / 2);
            }
        }

        &.m-aside-left--minimize {
            .re-wrapper {
                width: calc(100vw - (2 * #{$margin}) - #{$reducedMenuWidth});
                left: #{$reducedMenuWidth};
            }
        }
    }

    .re-wrapper {
        padding: 0;
        border: solid 1px #CCC;
        background: #FFF;
        z-index: 99;
        border-radius: 5px;
        margin: $margin;
        position: fixed !important;
        width: calc(100vw - 2 * #{$margin});
        height: calc(100vh - 2 * #{$margin});
        left: 0;
        top: 0 !important;

        .re-header {
            border: solid 0 #EEE;
            margin: 0;
            padding: 20px;
            border-bottom-width: 1px;
        }

        $footerHeight: 40px;

        .re-body {
            padding: 10px;
            overflow: hidden;
            overflow-y: scroll;
            height: calc(100% - #{$margin} - #{$footerHeight});
        }

        .re-footer {
        }
    }

    $imageWidth: 180px;
    $imageHeight: 180px;
    .image-card {
        width: $imageWidth;
        height: $imageHeight;
        overflow: hidden;
        cursor: pointer;
        margin: 10px;

        .card-body {
            padding: 5px;

            .file-title {
                margin-top: 2px;
                height: 20px;
                overflow: hidden;
            }
        }

        img {
            width: calc(#{$imageWidth} - 10px);
            height: calc(#{$imageHeight} - 35px);
        }
    }

    .close-modal {
        position: absolute;
        right: 1px;
        top: 1px;
        padding: 2px;
        margin: 2px;
    }
</style>
