var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-md-12" }, [
    _c("input", {
      attrs: { type: "hidden", name: "features" },
      domProps: { value: _vm.featuresJSON },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-sm m-btn m-btn m-btn--icon",
            attrs: {
              type: "button",
              disabled: _vm.nbReadOnly,
              id: "add-feature",
            },
            on: {
              click: function ($event) {
                return _vm.addFeature()
              },
            },
          },
          [_vm._m(1)]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table table-striped table-bordered m-table" }, [
      _vm._m(2),
      _vm._v(" "),
      _vm.featuresList.length
        ? _c(
            "tbody",
            _vm._l(_vm.featuresList, function (feature, index) {
              return _c("tr", { staticClass: "align-top" }, [
                _c(
                  "td",
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: feature.name,
                          expression: "feature.name",
                        },
                      ],
                      staticClass: "ms-2 form-control m-input",
                      attrs: {
                        disabled: _vm.nbReadOnly,
                        id: `feature-name-${index}`,
                      },
                      domProps: { value: feature.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(feature, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("nb-error-block", {
                      attrs: { path: `features.${index}.name` },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-center" }, [
                  _c("label", { staticClass: "m-checkbox pl-4 mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: feature.is_required,
                          expression: "feature.is_required",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        disabled: _vm.nbReadOnly,
                        id: `feature-required-${index}`,
                      },
                      domProps: {
                        checked: Array.isArray(feature.is_required)
                          ? _vm._i(feature.is_required, null) > -1
                          : feature.is_required,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = feature.is_required,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  feature,
                                  "is_required",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  feature,
                                  "is_required",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(feature, "is_required", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-danger m-btn m-btn--icon m-btn--icon-only",
                      attrs: {
                        type: "button",
                        disabled: _vm.nbReadOnly,
                        id: `delete-feature-${index}`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.delFeature(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "p-0 fa fa-times" })]
                  ),
                ]),
              ])
            }),
            0
          )
        : _c("tbody", [_vm._m(3)]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("h4", [
        _c("em", { staticClass: "flaticon-settings mr-1" }),
        _vm._v("Atributos del pago*\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "la la-plus" }),
      _vm._v(" "),
      _c("span", [_vm._v("Añadir atributo")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Atributo")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { width: "5%" } }, [
          _vm._v("Requerido"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { width: "1%" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center py-4", attrs: { colspan: "3" } }, [
        _vm._v("\n                Añade un atributo\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }