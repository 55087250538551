var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group m-form__group col-12 text-right" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-dark m-btn m-btn--icon-right",
          attrs: {
            id: "unlink-button",
            type: "button",
            disabled: _vm.nbReadOnly || _vm.unlinkStatus > 0,
          },
          on: { click: _vm.unlinkDevice },
        },
        [
          _vm.unlinkStatus === 0
            ? _c("span", [_vm._v("Desvincular")])
            : _vm.unlinkStatus === 1
            ? _c("span", [_vm._v("Desvinculando...")])
            : _c("span", [_vm._v("Desvinculado")]),
          _vm._v(" "),
          _c("em", { staticClass: "fa fa-unlink" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-danger m-btn m-btn--icon-right",
          attrs: {
            id: "desactivation-button",
            type: "button",
            disabled: _vm.nbReadOnly || _vm.disableStatus > 0,
          },
          on: { click: _vm.disableDevice },
        },
        [
          _vm.disableStatus === 0
            ? _c("span", [_vm._v("Deshabilitar")])
            : _vm.disableStatus === 1
            ? _c("span", [_vm._v("Deshabilitando...")])
            : _c("span", [_vm._v("Deshabilitado")]),
          _vm._v(" "),
          _c("em", { staticClass: "fa fa-power-off" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }