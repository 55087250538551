var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showBox,
          expression: "showBox",
        },
      ],
      staticClass: "col-12",
    },
    [
      _c(
        "div",
        { staticClass: "row m--padding-report" },
        _vm._l(_vm.groupingIds, function (groupingId, index) {
          return !_vm.live || _vm.customizableGroupings[index]
            ? _c(
                "div",
                { staticClass: "form-group m-form__group col-md-3" },
                [
                  !_vm.live
                    ? _c("div", { staticClass: "d-flex flex-row" }, [
                        _c("input", {
                          attrs: {
                            name: `grouping_checkbox[${index}]`,
                            type: "hidden",
                          },
                          domProps: {
                            value: _vm.customizableGroupings[index] ? 1 : 0,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-2" }, [
                          _c("label", { staticClass: "m-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customizableGroupings[index],
                                  expression: "customizableGroupings[index]",
                                },
                              ],
                              attrs: {
                                disabled: _vm.disabledGroup(index),
                                type: "checkbox",
                                id: "customize-group-" + index,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.customizableGroupings[index]
                                )
                                  ? _vm._i(
                                      _vm.customizableGroupings[index],
                                      null
                                    ) > -1
                                  : _vm.customizableGroupings[index],
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.customizableGroupings[index],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.customizableGroupings,
                                          index,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.customizableGroupings,
                                          index,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.customizableGroupings,
                                      index,
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("trans")("report_groupings.level", {
                                    level: index + 1,
                                  })
                                ) +
                                "\n                        "
                            ),
                            _c("span"),
                          ]),
                        ]),
                      ])
                    : _c("label", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("trans")("report_groupings.level", {
                                level: index + 1,
                              })
                            ) +
                            "\n            "
                        ),
                      ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.groupingIds[index],
                          expression: "groupingIds[index]",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: `${_vm.prefix}[${index}]`,
                        disabled: _vm.disabledGroup(index),
                        id: "group-" + _vm.prefix + index,
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.groupingIds,
                            index,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v(
                          _vm._s(_vm._f("trans")("report_groupings.none"))
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.defaultGroupings, function (grouping) {
                        return _c(
                          "option",
                          { domProps: { value: grouping.id } },
                          [_vm._v(_vm._s(grouping.name))]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("nb-error-block", { attrs: { path: `filters.${index}` } }),
                ],
                1
              )
            : _vm._e()
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }