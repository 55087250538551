var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SuggesterText", {
    attrs: {
      id: "elementContent",
      placeholder: "Valor del elemento",
      suggestions: _vm.availableVariables,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("layoutChange")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ suggestion }) {
          return [
            _vm._v("\n        " + _vm._s(suggestion) + "\n        "),
            _vm.renderVariable(suggestion) !== suggestion
              ? _c(
                  "span",
                  { staticClass: "ml-2 m-badge m-badge--brand m-badge--wide" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.renderVariable(suggestion)) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.activeElement.value,
      callback: function ($$v) {
        _vm.$set(_vm.activeElement, "value", $$v)
      },
      expression: "activeElement.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }