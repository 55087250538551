<template>
    <div>
        <div v-if="showFilter" class="col-8 mb-3">
            <multiselect v-model="selectedOptions" :options="optionsFilter" label="name" track-by="id" :multiple="true"
                :placeholder="filterPlaceholder" />
        </div>
        <div ref="surveyVizPanel" />

         <template v-if="dashboardRendered">
            <component
                v-for="component in customComponents"
                :key="component"
                :is="component"
                :table="vizPanel"
                :survey-structure="surveyStructure"
                :responses="responses"
            />
        </template>
    </div>
</template>

<script>
import 'survey-analytics/survey.analytics.min.css';
import { Model } from 'survey-core';
import { VisualizationPanel, localization } from 'survey-analytics';
import SurveyDashboardFilter from '@/js/mixins/SurveyDashboardFilter'

const vizPanelOptions = {
    haveCommercialLicense: true,
}

export default {
    name: 'SurveyResponsesComponent',
    mixins: [SurveyDashboardFilter],
    props: {
        customComponents: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dashboardRendered: false,
        }
    },
    mounted() {

        localization.currentLocale = "es";
        const survey = new Model(this.surveyStructure);
        this.vizPanel = new VisualizationPanel(
            survey.getAllQuestions(),
            this.filterData,
            vizPanelOptions,
        );

        this.vizPanel.render(this.$refs.surveyVizPanel)
        this.dashboardRendered = true;

        this.surveyStructure.pages[0].elements
            .filter(item => item.visible === false)
            .forEach(item => {
                this.vizPanel.hideElement(item.name);
            });

        this.$nextTick(() => {
            setTimeout(() => {
                this.vizPanel.refresh();
            }, 3000);

        });

    },
    watch: {
        surveyStructure: {
            handler(newStructure) {
                const survey = new Model(newStructure);
                this.vizPanel = new VisualizationPanel(
                    survey.getAllQuestions(),
                    this.filterData,
                    vizPanelOptions,
                );

                if (this.$refs.surveyVizPanel) {
                    this.vizPanel.render(this.$refs.surveyVizPanel);
                } else {
                    console.warn('surveyVizPanel is not yet available');
                }
            },
            immediate: true,
        },
        selectedOptions() {
            this.vizPanel.updateData(this.filterData);
        }
    }
}
</script>
