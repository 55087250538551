import { render, staticRenderFns } from "./CustomerPermissionsForum.vue?vue&type=template&id=5f3117c3&"
import script from "./CustomerPermissionsForum.vue?vue&type=script&lang=js&"
export * from "./CustomerPermissionsForum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f3117c3')) {
      api.createRecord('5f3117c3', component.options)
    } else {
      api.reload('5f3117c3', component.options)
    }
    module.hot.accept("./CustomerPermissionsForum.vue?vue&type=template&id=5f3117c3&", function () {
      api.rerender('5f3117c3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/crm/CustomerPermissionsForum.vue"
export default component.exports