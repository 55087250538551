<template>
    <div class="mt-5 mx-4 mb-0">

        <div class="col-12 mb-4 text-end c-survey-component__btn-add-option">
            <button type="button" class="btn btn-primary m-btn m-btn--icon" @click="addSurvey" :disabled="nbReadOnly"
                id="add-option">
                <i class="fa fa-plus"></i>
                {{ 'conference.properties.property_surveyable.add_survey' | trans }}
            </button>

        </div>
        <div class="fs-5 fw-bolder alert text-center my-20" v-if="!surveys.length">
            {{ 'conference.properties.property_surveyable.no_surveys' | trans }}
        </div>
        <ConferencePropertySurveyableSurvey v-else v-model="surveys" :property-key="propertyKey"
            :survey-array="surveyArray" />
    </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import ConferencePropertySurveyableSurvey from "./surveyable/ConferencePropertySurveyableSurvey.vue";

export default {
    name: 'ConferencePropertySurveyable',
    props: {
        lang: {
            type: String,
            default: null,
        },
        value: {
            type: Object,
            default: () => {},
        },
        surveyOptions: {
            type: Array,
            required: true
        },
        propertyKey: {
            type: String,
            required: true
        }
    },
    components: {
        NbErrorBlock,
        ConferencePropertySurveyableSurvey
    },
    data() {
        return {
            surveyArray: Object.values(this.surveyOptions),
            surveys: [],
        }
    },
    created() {

         this.surveys = this.value.surveys || []

         if(!this.surveys.length) {
            this.addSurvey()
        }
    },
    watch: {
        surveys(value) {
            this.emit({ surveys: value })
        },
    },
    methods: {
        addSurvey() {
            const index = this.surveys.length
            let newSurvey = {
                start_date: null,
                end_date: null,
                survey: null,
            }
            this.$set(this.surveys, index, newSurvey)
        },
        emit(value) {
            this.$emit('input', { ...this.value, ...value })
        },
        parseDate(date) {
            return moment(date).format('YYYY-MM-DDTHH:mm')
        }
    },
}
</script>
