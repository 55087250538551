var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-2" }, [
    _c("div", { staticClass: "form-group col-12" }, [
      _c("label", { attrs: { for: "elementName" } }, [_vm._v("Nombre")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.activeElement.name,
            expression: "activeElement.name",
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: {
          type: "text",
          id: "elementName",
          placeholder: "Nombre del elemento",
        },
        domProps: { value: _vm.activeElement.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.activeElement, "name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-6" }, [
      _c("label", { attrs: { for: "elementWidth" } }, [_vm._v("Anchura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.activeElement.width,
            expression: "activeElement.width",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", id: "elementWidth", placeholder: "Ancho" },
        domProps: { value: _vm.activeElement.width },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.activeElement, "width", _vm._n($event.target.value))
            },
            _vm.layoutChange,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-6" }, [
      _c("label", { attrs: { for: "elementHeight" } }, [_vm._v("Altura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.activeElement.height,
            expression: "activeElement.height",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", id: "elementHeight", placeholder: "Ancho" },
        domProps: { value: _vm.activeElement.height },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.activeElement, "height", _vm._n($event.target.value))
            },
            _vm.layoutChange,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-6" }, [
      _c("label", { attrs: { for: "elementX" } }, [_vm._v("Posición Eje X")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.activeElement.x,
            expression: "activeElement.x",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", id: "elementX", placeholder: "X" },
        domProps: { value: _vm.activeElement.x },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.activeElement, "x", _vm._n($event.target.value))
            },
            _vm.layoutChange,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-6" }, [
      _c("label", { attrs: { for: "elementY" } }, [_vm._v("Posición Eje Y")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.activeElement.y,
            expression: "activeElement.y",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", id: "elementY", placeholder: "Y" },
        domProps: { value: _vm.activeElement.y },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.activeElement, "y", _vm._n($event.target.value))
            },
            _vm.layoutChange,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-12 text-content-check" }, [
      _c("label", { staticClass: "m-checkbox mb-0" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.activeElement.allowOverflow,
              expression: "activeElement.allowOverflow",
            },
          ],
          attrs: {
            type: "checkbox",
            checked: "checked",
            id: "elementOverflow",
          },
          domProps: {
            checked: Array.isArray(_vm.activeElement.allowOverflow)
              ? _vm._i(_vm.activeElement.allowOverflow, null) > -1
              : _vm.activeElement.allowOverflow,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.activeElement.allowOverflow,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.activeElement,
                      "allowOverflow",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.activeElement,
                      "allowOverflow",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.activeElement, "allowOverflow", $$c)
              }
            },
          },
        }),
        _vm._v(
          "\n            ¿El contenido puede salir del contenedor?\n            "
        ),
        _c("span"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-12 text-content-check" }, [
      _c("label", { staticClass: "m-checkbox mb-0" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.blocked,
              expression: "blocked",
            },
          ],
          attrs: {
            type: "checkbox",
            id: "elementBlock",
            disabled: _vm.powerUserLoged < _vm.blockPower,
          },
          domProps: {
            checked: _vm.blocked,
            checked: Array.isArray(_vm.blocked)
              ? _vm._i(_vm.blocked, null) > -1
              : _vm.blocked,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.blocked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.blocked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.blocked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.blocked = $$c
              }
            },
          },
        }),
        _vm._v(
          "\n            Bloquear elemento (Prioridad " +
            _vm._s(_vm.blockPower) +
            ")\n            "
        ),
        _c("span"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }