
export function init(Survey, secretKey) {
  window.captchaSuccess = function (response) {
    console.log("CAPTCHA completado con éxitooo");
  };

  window.captchaError = function (error) {
    console.error("Error en el CAPTCHA", error);
  }

  const widget = {
    name: "captcha",
    title: "Captcha",
    iconName: "",
    widgetIsLoaded: function () {
      return true;
    },
    isFit: function (question) {
      return question.getType() === 'captcha';
    },
    activatedByChanged: function () {
      Survey.JsonObject.metaData.addClass("captcha", [], null, "text");
    },
    isDefaultRender: false,
    htmlTemplate: "<div id='g-recaptcha' class='form-group g-recaptcha' data-callback='captchaSuccess' data-error-callback='captchaError' data-sitekey='"+ secretKey +"'></div>",
    afterRender: function (question, el) {
      if (question.getType() === 'captcha') {

        question.name = "captcha";
        question.titleLocation = "hidden";
      }

      var recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
      document.head.appendChild(recaptchaScript);
    }
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}