var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "statistic-chart" },
    [
      _c("VueApexCharts", {
        attrs: {
          type: _vm.chartType,
          options: _vm.options,
          height: _vm.height,
          series: _vm.series,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }