var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border rounded w-100 p-2" }, [
    _c(
      "div",
      {
        staticClass: "conditions-list",
        class: {
          "conditions-list-height":
            _vm.activeElement.visibility.conditions.length,
        },
        attrs: { id: "js-table-wrapper" },
      },
      _vm._l(_vm.activeElement.visibility.conditions, function (condition, i) {
        return _c("div", { staticClass: "conditions-list-box" }, [
          _c(
            "div",
            { staticClass: "form-group mb-1" },
            [
              _c(
                "select",
                {
                  staticClass:
                    "form-select form-select-sm js-condition-variable conditions-select",
                  attrs: {
                    title: "variable",
                    id: "condition-" + i,
                    required: "",
                  },
                  domProps: { value: condition.variable },
                  on: {
                    input: function ($event) {
                      return _vm.inputCondition($event, condition, "variable")
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("Variable")]),
                  _vm._v(" "),
                  _vm._l(_vm.availableVariables, function (variable) {
                    return _c("option", { domProps: { value: variable } }, [
                      _vm._v(_vm._s(variable)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("nb-error-block", {
                attrs: {
                  path: `elements.${_vm.index}.visibility.conditions.${i}.variable`,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-1" },
            [
              _c(
                "select",
                {
                  staticClass: "form-select form-select-sm conditions-select",
                  attrs: {
                    title: "Comparador",
                    id: "condition-comparer-" + i,
                    required: "",
                  },
                  domProps: { value: condition.comparer },
                  on: {
                    input: function ($event) {
                      return _vm.inputCondition($event, condition, "comparer")
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Comparación"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "<" } }, [
                    _vm._v("Menor que"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "<=" } }, [
                    _vm._v("Menor o igual que"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: ">" } }, [
                    _vm._v("Mayor que"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: ">=" } }, [
                    _vm._v("Mayor o igual que"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "=" } }, [_vm._v("Igual a")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "!=" } }, [
                    _vm._v("Distinto de"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "LIKE" } }, [
                    _vm._v("Contiene"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "EMPTY" } }, [
                    _vm._v("Vacío"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "NOT_EMPTY" } }, [
                    _vm._v("No vacío"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("nb-error-block", {
                attrs: {
                  path: `elements.${_vm.index}.visibility.conditions.${i}.comparer`,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-2" },
            [
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  staticClass: "form-control form-control-sm",
                  attrs: {
                    type: "text",
                    title: "Valor",
                    placeholder: "Valor",
                    id: "condition-value-" + i,
                    required: !_vm.disabledValueField(condition),
                    disabled: _vm.disabledValueField(condition),
                  },
                  domProps: { value: condition.value },
                  on: {
                    input: function ($event) {
                      return _vm.inputCondition($event, condition, "value")
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger m-btn--icon btn-sm",
                      attrs: { type: "button", id: "delete-condition-" + i },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.deleteCondition(condition)
                        },
                      },
                    },
                    [_vm._m(0, true)]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("nb-error-block", {
                attrs: {
                  path: `elements.${_vm.index}.visibility.conditions.${i}.value`,
                },
              }),
            ],
            1
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-block btn-primary btn-sm m-btn--icon",
        attrs: { id: "add-condition", type: "button" },
        on: { click: _vm.insertCondition },
      },
      [
        _c("i", { staticClass: "fa fa-fw fa-plus" }),
        _vm._v(" Añadir condición\n    "),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa fa-trash" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }