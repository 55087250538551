var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _c("div", { staticClass: "m-entity-list-search m-0 px-1" }, [
      _c("div", { staticClass: "d-flex align-content-start flex-wrap m-0" }, [
        _c(
          "div",
          { staticClass: "col-4 mb-3" },
          [
            _c("label", [_vm._v("Suceso")]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                multiple: true,
                label: "label",
                "track-by": "value",
                options: _vm.filterOptions.availableActions,
              },
              model: {
                value: _vm.actionsFilter,
                callback: function ($$v) {
                  _vm.actionsFilter = $$v
                },
                expression: "actionsFilter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4 mb-3" },
          [
            _c("label", [_vm._v("Clientes")]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                multiple: true,
                label: "label",
                "track-by": "value",
                options: _vm.filterOptions.availableCustomers,
              },
              model: {
                value: _vm.customersFilter,
                callback: function ($$v) {
                  _vm.customersFilter = $$v
                },
                expression: "customersFilter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c("label", { attrs: { for: "date-start" } }, [
            _vm._v("Fecha de inicio"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dateStart,
                expression: "dateStart",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "date-start", type: "datetime-local" },
            domProps: { value: _vm.dateStart },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.dateStart = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c("label", { attrs: { for: "date-end" } }, [_vm._v("Fecha de fin")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dateEnd,
                expression: "dateEnd",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "date-end", type: "datetime-local" },
            domProps: { value: _vm.dateEnd },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.dateEnd = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "m-content" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "form-group form-inline" }, [
            _vm._v("\n                    Mostrar\n                    "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.offset,
                    expression: "offset",
                  },
                ],
                staticClass: "fform-select w-auto mx-2",
                attrs: { title: "length", name: "length", id: "length" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.offset = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: 10 } }, [_vm._v("10")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 25 } }, [_vm._v("25")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 50 } }, [_vm._v("50")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 100 } }, [_vm._v("100")]),
              ]
            ),
            _vm._v("\n                    entradas\n                "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 d-flex" }, [
          _c("ul", { staticClass: "pagination ml-auto" }, [
            _c(
              "li",
              {
                staticClass: "paginate_button page-item first",
                class: { disabled: _vm.page <= 1 },
                attrs: { id: "first_page" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.page = 1
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-angle-double-left" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "paginate_button page-item previous",
                class: { disabled: _vm.page <= 1 },
                attrs: { id: "m_table_1_previous" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.subPage.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-angle-left" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c("li", { staticClass: "align-self-center p-2" }, [
              _c("span", [
                _vm._v(
                  "Página " + _vm._s(this.page) + " de " + _vm._s(this.lastPage)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "paginate_button page-item next",
                attrs: { id: "m_table_1_next" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.addPage.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-angle-right" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "paginate_button page-item last",
                attrs: { id: "m_table_1_last" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.page = _vm.lastPage
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-angle-double-right" })]
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table m-table m-table--head-no-border w-100" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.analyticRecords, function (analytic) {
              return _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(analytic.event_type_name) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(analytic.customer_name) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("moment")(analytic.created_at)) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "text-right income",
                    class: { profitable: !!analytic.profitable },
                  },
                  [
                    analytic.income
                      ? [
                          analytic.profitable
                            ? _c("span", [_vm._v("+")])
                            : _c("span", [_vm._v("~")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(analytic.income) + " €")]),
                        ]
                      : _c("span", [_vm._v("--")]),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("tfoot", [
            _c("tr", [
              _c(
                "td",
                { staticClass: "text-right bg-light", attrs: { colspan: "7" } },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "Ingreso total adquirido: " +
                        _vm._s(_vm.totalIncome) +
                        " €"
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Suceso")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cliente")]),
        _vm._v(" "),
        _c("th", [_vm._v("Fecha")]),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-center text-nowrap",
            staticStyle: { width: "1%" },
          },
          [_vm._v("Ingresos")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }