var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "re-wrapper" }, [
        _c("div", { staticClass: "re-header d-flex" }, [
          _c("h5", { staticClass: "align-self-center mt-2" }, [
            _vm._v("SELECCIONA EL FICHERO"),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "d-flex ml-auto",
              staticStyle: { width: "400px" },
              attrs: { action: "", autocomplete: "off" },
            },
            [
              _c("div", { staticClass: "d-flex flex-row flex-grow-1" }, [
                _c(
                  "div",
                  { staticClass: "m-input-icon m-input-icon--right mr-2 w-50" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter",
                        },
                      ],
                      staticClass: "form-control m-input",
                      attrs: {
                        type: "text",
                        id: "update-filter",
                        placeholder: "Buscar por nombre",
                        disabled: _vm.nbReadOnly,
                      },
                      domProps: { value: _vm.filter },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.filter = $event.target.value
                          },
                          _vm.filterUpdated,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.type,
                        expression: "type",
                      },
                    ],
                    staticClass: "form-select w-50",
                    attrs: {
                      name: "type",
                      id: "type",
                      title: "Formato de fichero",
                      disabled: _vm.nbReadOnly || _vm.disabledSearch,
                    },
                    on: {
                      input: _vm.filterUpdated,
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.type = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }),
                    _vm._v(" "),
                    _vm._l(_vm.fileTypes, function (type) {
                      return _c("option", { domProps: { value: type.id } }, [
                        _vm._v(_vm._s(type.name)),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-link close-modal",
                attrs: { type: "button", id: "close-repo" },
                on: { click: _vm.closeModal },
              },
              [_c("i", { staticClass: "fa fa-fw fa-close" })]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "re-body" }, [
          _vm.files !== null
            ? _c(
                "div",
                { staticClass: "d-flex flex-wrap" },
                _vm._l(_vm.files.data, function (file) {
                  return _c(
                    "div",
                    {
                      staticClass: "card image-card",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", file)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          file.is_image
                            ? [
                                _c("img", {
                                  attrs: { src: file.url, alt: "" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bordered bg-white rounded file-title align-self-baseline",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm._f("truncate")(file.name)) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(_vm._f("truncate")(file.name, 15))
                                  ),
                                ]),
                                _vm._v(" "),
                                file.description
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("truncate")(
                                            file.description,
                                            100
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n                            Tipo: " +
                                        _vm._s(
                                          _vm.fileTypes.find(
                                            (t) => t.id === file.type_id
                                          ).name
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "re-footer d-flex justify-content-around py-3" },
          [
            _vm.files
              ? _c("nav", { attrs: { "aria-label": "Page navigation" } }, [
                  _c(
                    "ul",
                    { staticClass: "pagination" },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "page-item",
                          class: _vm.currentPage === 1 ? "disabled" : "",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link",
                              attrs: {
                                "aria-label": "Previous",
                                id: "previous-page",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.loadFiles(_vm.currentPage - 1)
                                },
                              },
                            },
                            [
                              _c("span", { attrs: { "aria-hidden": "true" } }, [
                                _vm._v("«"),
                              ]),
                              _vm._v(" "),
                              _vm._m(1),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.files.last_page, function (page) {
                        return _c(
                          "li",
                          {
                            staticClass: "page-item",
                            class: _vm.currentPage === page ? "active" : "",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { id: "load-files" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.loadFiles(page)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(page))]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("li", { staticClass: "page-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "page-link",
                            class:
                              _vm.currentPage === _vm.files.last_page
                                ? "disabled"
                                : "",
                            attrs: { id: "next-page" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.loadFiles(_vm.currentPage + 1)
                              },
                            },
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("»"),
                            ]),
                            _vm._v(" "),
                            _vm._m(2),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "m-input-icon__icon m-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "la la-search" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "sr-only" }, [
      _c("i", { staticClass: "fa fa-fw fa-arrow-left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "sr-only" }, [
      _c("i", { staticClass: "fa fa-fw fa-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }