var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-md-8 mb-10" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "label",
          {
            staticClass:
              "form-check form-switch form-check-custom form-check-solid",
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "enabled", id: "enabled" },
              domProps: { value: _vm.enabled },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.boolean",
                  value: _vm.enabled,
                  expression: "enabled",
                  modifiers: { boolean: true },
                },
              ],
              staticClass: "form-check-input",
              attrs: {
                type: "checkbox",
                name: "enabled--check",
                disabled: _vm.nbReadOnly,
                id: "enabled--check",
              },
              domProps: {
                checked: _vm.enabled,
                checked: Array.isArray(_vm.enabled)
                  ? _vm._i(_vm.enabled, null) > -1
                  : _vm.enabled,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.enabled,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.enabled = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.enabled = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "form-check-label" }, [
              _vm._v(_vm._s(_vm._f("trans")("forms_crm_customers.enabled"))),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "label",
          {
            staticClass:
              "form-check form-switch form-check-custom form-check-solid",
          },
          [
            _c("input", {
              attrs: {
                type: "hidden",
                name: "email_verified",
                id: "email_verified",
              },
              domProps: { value: _vm.emailVerified },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.boolean",
                  value: _vm.emailVerified,
                  expression: "emailVerified",
                  modifiers: { boolean: true },
                },
              ],
              staticClass: "form-check-input",
              attrs: {
                type: "checkbox",
                name: "email_verified--check",
                disabled: _vm.nbReadOnly,
                id: "email_verified--check",
              },
              domProps: {
                checked: _vm.emailVerified,
                checked: Array.isArray(_vm.emailVerified)
                  ? _vm._i(_vm.emailVerified, null) > -1
                  : _vm.emailVerified,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.emailVerified,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.emailVerified = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.emailVerified = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.emailVerified = $$c
                    }
                  },
                  _vm.updateEmailVerified,
                ],
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "form-check-label" }, [
              _vm._v(
                _vm._s(_vm._f("trans")("forms_crm_customers.email_verified"))
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }