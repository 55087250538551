<template>
    <div class="form-group m-form__group col-md-12">
        <input type="hidden" :value="featuresJSON" name="features">
        <div class="row mb-3">
            <div class="col-6">
                <h4>
                    <em class="flaticon-settings mr-1"></em>Atributos del pago*
                </h4>
            </div>
            <div class="col-6 text-right">
                <button type="button" :disabled="nbReadOnly" @click="addFeature()"
                        class="btn btn-primary btn-sm m-btn m-btn m-btn--icon" id="add-feature">
                    <span>
                        <i class="la la-plus"></i>
                        <span>Añadir atributo</span>
                    </span>
                </button>
            </div>
        </div>
        <table class="table table-striped table-bordered m-table">
            <thead>
            <tr>
                <th>Atributo</th>
                <th class="text-center" width="5%">Requerido</th>
                <th class="text-center" width="1%"></th>
            </tr>
            </thead>
            <tbody v-if="featuresList.length">
            <tr v-for="(feature, index) in featuresList" class="align-top">
                <td>
                    <input v-model="feature.name" class="ms-2 form-control m-input"
                           :disabled="nbReadOnly" :id="`feature-name-${index}`">
                    <nb-error-block :path="`features.${index}.name`"></nb-error-block>
                </td>
                <td class="text-center">
                    <label class="m-checkbox pl-4 mt-2">
                        <input type="checkbox" v-model="feature.is_required" :disabled="nbReadOnly"
                               :id="`feature-required-${index}`">
                        <span></span>
                    </label>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-danger m-btn m-btn--icon m-btn--icon-only"
                            :disabled="nbReadOnly" @click="delFeature(index)" :id="`delete-feature-${index}`">
                        <i class="p-0 fa fa-times"></i>
                    </button>
                </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
                <td colspan="3" class="text-center py-4">
                    Añade un atributo
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
  export default {
    name: 'FeaturesComponent',
    props: {
      features: {
        type: Array,
      },
    },
    data: function () {
      return {
        featuresList: [],
        newFeature: {
          name: null,
          is_required: false,
        },
      }
    },
    computed: {
      featuresJSON () {
        return JSON.stringify(this.featuresList)
      },
    },
    created: function () {
      this.featuresList = this.features
    },
    methods: {
      addFeature () {
        this.featuresList.push(Object.assign({}, this.newFeature))
      },
      delFeature (index) {
        this.featuresList.splice(index, 1)
      },
    },
  }
</script>

