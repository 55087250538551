<template>
  <div>
    <input type="hidden" name="permissions" :value="permissionsJSON" />
    <label for="name" class="form-label">{{ 'customer_types.permissions.title' | trans }}</label>
    <div class="row">
      <div class="form-group mb-6 col-md-4" v-for="(permission, key) in permissions" :key="key">
        <label class="form-check form-switch form-check-custom form-check-solid">
          <input
              type="checkbox"
              :name="`permission_${permission.name}`"
              :id="`permission_${permission.id}`"
              class="form-check-input"
              :checked="selectedPermissions.includes(permission.id)"
              @input="inputPermission(permission.id)"
              value="1"
          />
          <span class="form-check-label">
              {{ permission.name }}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
  name: 'CustomerTypePermissions',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NbErrorBlock,
  },
  data () {
    return {
      selectedPermissions: [],
    }
  },
  created () {
    this.selectedPermissions = this.value ? this.value : []
  },
  computed: {
    permissionsJSON () {
      return JSON.stringify(this.selectedPermissions)
    }
  },
  methods: {
    inputPermission (newPermission) {
      console.log(newPermission)
      if (this.selectedPermissions.includes(newPermission)) {
        const index = this.selectedPermissions.findIndex(permission => permission === newPermission)
        this.selectedPermissions.splice(index, 1)
      } else {
        this.selectedPermissions.push(newPermission)
      }
    }
  },
}
</script>

<style scoped>

</style>
