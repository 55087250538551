var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      {
        staticClass:
          "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "fw-bold" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm._f("trans")(
                      "conference.properties.property_surveyable.start_date"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "fw-bold" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm._f("trans")(
                      "conference.properties.property_surveyable.end_date"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "fw-bold" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm._f("trans")(
                      "conference.properties.property_surveyable.survey"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            !_vm.lang ? _c("th", { attrs: { width: "1%" } }) : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.surveys, function (survey, index) {
              return [
                _c("conference-property-surveyable-survey-row", {
                  key: index,
                  attrs: {
                    index: index,
                    "survey-array": _vm.surveyArray,
                    lang: _vm.lang,
                    "property-key": _vm.propertyKey,
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.deleteOption(index)
                    },
                  },
                  model: {
                    value: _vm.surveys[index],
                    callback: function ($$v) {
                      _vm.$set(_vm.surveys, index, $$v)
                    },
                    expression: "surveys[index]",
                  },
                }),
              ]
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }