
export function init(Survey) {

  const widget = {
    name: "captcha_honeypot",
    title: "Captcha Honeypot",
    iconName: "",
    widgetIsLoaded: function () {
      return true;
    },
    isFit: function (question) {
      return question.getType() === 'captcha_honeypot';
    },
    activatedByChanged: function () {
      Survey.JsonObject.metaData.addClass("captcha_honeypot", [], null, "text");
    },
    isDefaultRender: false,
    htmlTemplate: "<input id='code' name='code'></input>",
    afterRender: function (question, el) {
      if (question.getType() === 'captcha_honeypot') {
        question.name = "captcha_honeypot";
        question.titleLocation = "hidden";
        question.validators.push(
          new Survey.ExpressionValidator("{captcha_honeypot} empty")
        );

      }
      
    }
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}