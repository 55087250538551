var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "col-12 mb-4 text-right c-survey-component__btn-add-option",
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "conferenceFiles" },
          domProps: { value: _vm.conferenceFilesJSON },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary m-btn m-btn--icon",
            attrs: {
              type: "button",
              disabled: _vm.nbReadOnly,
              id: "add-option",
            },
            on: { click: _vm.addFile },
          },
          [_vm._m(0)]
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.files.length
      ? _c("div", { staticClass: "fs-5 fw-bolder alert text-center my-20" }, [
          _vm._v("\n    No hay archivos, añade los que quieras\n  "),
        ])
      : _c(
          "table",
          {
            staticClass:
              "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Título*")]),
                _vm._v(" "),
                _c("th", [_vm._v("Descripción")]),
                _vm._v(" "),
                _c("th", [_vm._v("Archivo*")]),
                _vm._v(" "),
                !_vm.lang ? _c("th", { attrs: { width: "1%" } }) : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.files, function (file, indexFile) {
                return _c("tr", { key: indexFile, staticClass: "align-top" }, [
                  _c("td", [
                    _c("div", { staticClass: "m-form__control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: file.title,
                            expression: "file.title",
                          },
                        ],
                        staticClass: "form-control form-control-sm m-input",
                        attrs: {
                          placeholder: "Opcion",
                          disabled: _vm.nbReadOnly,
                          id: "option-" + indexFile,
                        },
                        domProps: { value: file.title },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(file, "title", $event.target.value)
                            },
                            _vm.emit,
                          ],
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "m-form__control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: file.description,
                            expression: "file.description",
                          },
                        ],
                        staticClass: "form-control form-control-sm m-input",
                        attrs: {
                          placeholder: "Opcion",
                          disabled: _vm.nbReadOnly,
                          id: "option-" + indexFile,
                        },
                        domProps: { value: file.description },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(file, "description", $event.target.value)
                            },
                            _vm.emit,
                          ],
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "table__td-file" },
                    [
                      _c("repository-button", {
                        attrs: { "is-disabled": false },
                        on: { input: _vm.emit },
                        model: {
                          value: file.file_id,
                          callback: function ($$v) {
                            _vm.$set(file, "file_id", $$v)
                          },
                          expression: "file.file_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-nowrap" }, [
                    _c("div", { staticClass: "actions text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-icon btn-danger btn-sm",
                          attrs: {
                            type: "button",
                            disabled: _vm.nbReadOnly,
                            id: "delete-option-" + indexFile,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteOption(indexFile)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-trash" })]
                      ),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-plus" }),
      _vm._v(" "),
      _c("span", [_vm._v("Añadir archivo")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }