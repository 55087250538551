const BackgroundColorParameters = {
    White: 'white',
    Light: 'light',
    Primary: 'primary',
}

const WidthParameters = {
    Content: 'content',
    Fluid: 'fluid',
}


export { BackgroundColorParameters, WidthParameters }
