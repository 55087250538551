<template>
  <div class="columns columns--fields row mb-2 g-1 g-md-2">
    <div class="column col-md-4">
      <div class="form-group">
        <multiselect
            v-model="member"
            :options="validTags"
            track-by="tagName"
            label="tagName"
        />
      </div>
    </div>
    <div class="column col-md-3">
      <select v-model="operator" :disabled="!member" :placeholder="getOperatorPlaceholder()" class="form-select">
        <option v-for="availableOperator in operators" :key="availableOperator" :value="availableOperator">
          {{ `statistics.operators.${availableOperator}` | trans }}
        </option>
      </select>
    </div>
    <div class="column col">
      <div v-if="inputValues">
        <input v-if="filterType === 'time'"
               v-model="values"
               type="date"
               class="form-control"
               id="date"
               :placeholder="$options.filters.trans('statistics.filters.valuesPlaceholder')"
               @input="setValues"
        />
        <multiselect v-else
                     v-model="values"
                     field="name"
                     :tag-placeholder="$options.filters.trans('statistics.filters.tagPlaceholder')"
                     :taggable="true"
                     :multiple="true"
                     :placeholder="$options.filters.trans('statistics.filters.valuesPlaceholder')"
                     class="field--select field--select-grey"
                     :options="[]"
                     :show-no-results="false"
                     @tag="addTag"
        >
          <template v-slot:noOptions>
            {{ 'statistics.filters.emptyList' | trans }}
          </template>
        </multiselect>
      </div>
    </div>
    <div class="column__action col-auto">
      <button class="btn btn-icon btn-light-danger"  type="button" @click="emitDelete"><i class="fas fa-minus"></i></button>
    </div>
  </div>
</template>

<script>
import { StringOperators, DateOperators, NumberOperators } from '@/js/enums/Operators'

export default {
  name: 'StatisticsFilter',
  props: {
    value: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      operators: [],
      filterType: null,
      DateOperators,
      StringOperators,
      NumberOperators,
      inputValues: true,
      filterText: '',
      options: [],
      values: [...this.value.values]
    }
  },
  computed: {
    member: {
      get () {
        return this.value.member
      },
      set (value) {
        this.emit({ member: value, values: [] })
      },
    },
    operator: {
      get () {
        return this.value.operator
      },
      set (value) {
        // Si el tipo de operador es set o notSet no necesita values
        if (value === StringOperators.Set || value === StringOperators.NotSet) {
          this.inputValues = false
        } else {
          this.inputValues = true
        }
        this.emit({ operator: value, values: [] })
      },
    },

    validTags () {
      return this.filterText.length ? this.filters.filter(tag =>
        tag.tagName
          .toString()
          .toLowerCase()
          .indexOf(this.filterText.toLowerCase()) >= 0
      ) : this.filters
    },
  },
  watch: {
    member: {
      immediate: true,
      handler (val) {
        if (val) {
          this.filterType = val.type
          this.getOperators()
        }
      },
    },
  },
  methods: {
    getOperators () {
      switch (this.filterType) {
        case 'string':
          this.operators = Object.values(StringOperators)
          break
        case 'time':
          this.operators = Object.values(DateOperators)
          break
        case 'number':
          this.operators = Object.values(NumberOperators)
          break
      }
    },
    getOperatorPlaceholder () {
      return this.member ? this.$options.filters.trans('statistics.filters.operatorPlaceholder')
        : this.$options.filters.trans('statistics.filters.operatorPlaceholderNoMember')
    },
    emit (filterConfiguration) {
      this.$emit('input', { ...this.value, ...filterConfiguration })
    },
    emitDelete () {
      this.$emit('delete-filter')
    },
    addTag (newTag) {
      this.options.push(newTag)
      this.values.push(newTag)
      this.setValues()
    },
    setValues () {
      if (this.filterType === 'time' && this.operator !== DateOperators.InDateRange) {
        this.emit({ values: [this.values] })
      } else {
        this.emit({ values: this.values })
      }
    }
  },
}
</script>
