<template>
    <div class="form-group m-form__group col-md-6 mb-6">
        <div class="d-flex flex-row" v-if="!live">
            <input type="hidden" :name="`${name}_checkbox`" :value="customizeCheckbox ? 1 : 0">
            <div class="p-2">
                <label class="m-checkbox">
                    <input type="checkbox" @click="changeCheckBox()" title="Modificable" v-model="customizeCheckbox"
                           :disabled="nbReadOnly && !live" :id="'customize-check-' + prefix + name">
                    {{label}}
                    <span></span>
                </label>
            </div>
        </div>
        <label v-else>
            {{label}}
        </label>
        <div class="row">
            <div class="col-12">
                <input type="hidden" :name="`${prefix}[${name}][type]`" :value="type">
                <div class="btn-group btn-block m-btn-group" role="group">
                    <button type="button" class="btn m-btn" @click="changeType('operator')" :disabled="nbReadOnly && !live"
                            :class="{'btn-brand': type === 'operator', 'btn-secondary': type !== 'operator'}"
                            id="operator-type" v-if="!onlyRange">
                        Operador
                    </button>
                    <button type="button" class="btn m-btn" @click="changeType('day')" :disabled="nbReadOnly && !live"
                            :class="{'btn-primary': type === 'day', 'btn-secondary': type !== 'day'}" id="days"
                            v-if="!onlyRange">
                        Días
                    </button>
                    <button type="button" class="btn m-btn" @click="changeType('range')" :disabled="nbReadOnly && !live"
                            :class="{'btn-brand': type === 'range', 'btn-secondary': type !== 'range'}" id="range">
                        Rangos
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="row" v-if="type === 'operator' && !onlyRange">
                    <div class="col-2">
                        <input type="hidden" :name="`${prefix}[${name}][operator]`" :value="operator">
                        <select class="form-select " :disabled="nbReadOnly && !live" v-model="operator" id="operator-select">
                            <option :value="key" v-for="(operator, key) in operators">{{ operator }}</option>
                        </select>
                    </div>
                    <div class="col-10 row">
                        <input type="hidden" :name="`${prefix}[${name}][date]`" :value="date">
                        <input type="date" v-model="dateDay" class="form-control col-8" id="date"/>
                        <input type="time" v-model="dateHour" class="form-control col-4" id="hour"/>
                    </div>
                </div>
                <div class="row" v-if="type === 'day' && !onlyRange">
                    <div class="col-12 mb-3">
                        <input type="hidden" :name="`${prefix}[${name}][dayOperator]`" :value="dayOperator">
                        <div class="btn-group btn-block m-btn-group" role="group">
                            <button type="button" class="btn m-btn" @click="changeDayOperator(operatorKey)"
                                    :disabled="nbReadOnly && !live" id="operator-date"
                                    :class="getClassDay(dayOperator, operatorKey)"
                                    v-for="(operatorShow, operatorKey) in operatorsTexts">
                                {{ operatorShow }}
                                <i class="fa fa-fw fa-info-circle" :title="operatorsPlaceholder[operatorKey]"
                                   v-tooltip></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="type === 'day' && !onlyRange">
                    <div class="col-4">
                        <input type="hidden" :name="`${prefix}[${name}][day]`" :value="day">
                        <div class="btn-group btn-block m-btn-group" role="group">
                            <button type="button" class="btn m-btn" @click="changeDay(dayKey)" :disabled="nbReadOnly && !live"
                                    :class="{'btn-brand': day === dayKey, 'btn-secondary': day !== dayKey}"
                                    v-for="(dayShow, dayKey) in days" :id="'day-' + dayKey">
                                {{ dayShow }}
                            </button>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <input type="hidden" :name="`${prefix}[${name}][dayNumber]`" :value="dayNumber">
                        <input type="number" v-model="dayNumber" class="form-control" :disabled="dayDisabled()"
                               id="day-number"/>
                    </div>
                    <div class="col-6">
                        <input type="hidden" :name="`${prefix}[${name}][dayGroup]`" :value="dayGroup">
                        <div class="btn-group btn-block m-btn-group" role="group">
                            <button type="button" class="btn m-btn" :id="'day-group-' + dayKey"
                                    @click="changeDayGroup(dayKey)"
                                    :class="{'btn-brand': dayGroup === dayKey, 'btn-secondary': dayGroup !== dayKey}"
                                    :disabled="dayDisabled()"
                                    v-for="(dayShow, dayKey) in daysGroup">
                                {{ dayShow }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="type === 'range'">
                    <div class="col-2 text-center">
                        <div class="m-type">
                            Desde
                        </div>
                    </div>
                    <div class="col-4">
                        <input type="hidden" :name="`${prefix}[${name}][startDate]`" :value="startDate">
                        <input type="date" v-model="dateStartDay" :disabled="nbReadOnly && !live" class="form-control"
                               id="start-date-day"/>
                        <input type="time" v-model="dateStartHour" :disabled="nbReadOnly && !live" class="form-control"
                               id="start-date-hour"/>
                    </div>
                    <div class="col-2 text-center">
                        <div class="m-type">
                            Hasta
                        </div>
                    </div>
                    <div class="col-4">
                        <input type="hidden" :name="`${prefix}[${name}][endDate]`" :value="endDate">
                        <input type="date" v-model="dateEndDay" :disabled="nbReadOnly && !live" class="form-control"
                               id="end-date-day"/>
                        <input type="time" v-model="dateEndHour" :disabled="nbReadOnly && !live" class="form-control"
                               id="end-date-hour"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <button type="button" class="btn m-btn btn-secondary col-12" :disabled="nbReadOnly"
                        id="delete-dates" @click="deleteDates">
                    Limpiar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'report-date-picker',
    props: {
      onlyRange: {
        type: Boolean,
        default: false,
      },
      live: {
        type: Boolean,
        default: false
      },
      prefix: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      customizable: {
        type: Boolean,
        default: false
      },
      default_value: {
        type: Object,
        default: () => ({
          type: 'operator',
          operator: 'same',
          date: 'now',
          day: 'now',
          dayOperator: 'same',
          dayGroup: 'day',
          dayNumber: 1,
          startDate: 'now',
          endDate: 'now'
        })
      },
      operators: {
        type: Object,
        default: () => ({
          same: '=',
          greaterThan: '>',
          smallerThan: '<',
          greaterThanOrEqual: '>=',
          smallerThanOrEqual: '<=',
        })
      },
      operatorsTexts: {
        type: Object,
        default: () => ({
          same: 'Igual a',
          greaterThanOrEqual: 'Desde',
          smallerThanOrEqual: 'Hasta',
        })
      },
      operatorsPlaceholder: {
        type: Object,
        default: () => ({
          same: 'Igual a la fecha relativa indicada',
          greaterThanOrEqual: 'Desde la fecha relativa indicada',
          smallerThanOrEqual: 'Hasta la fecha relativa indicada',
        })
      },
      days: {
        type: Object,
        default: () => ({
          now: 'Hoy',
          less: 'Menos',
          plus: 'Más'
        })
      },
      daysGroup: {
        type: Object,
        default: () => ({
          day: 'Día/s',
          week: 'Semana/s',
          month: 'Mes/es',
        })
      },
      id: {
        type: Number,
        default: 0
      },
    },
    mounted: function () {
      this.type = this.default_value.type
      this.operator = this.default_value.operator
      this.day = this.default_value.day
      this.dayOperator = this.default_value.dayOperator
      this.dayGroup = this.default_value.dayGroup
      this.dayNumber = this.default_value.dayNumber
      this.customizeCheckbox = this.customizable

      this.date = this.dateFormat(this.default_value.date)
      this.endDate = this.dateFormat(this.default_value.endDate)
      this.startDate = this.dateFormat(this.default_value.startDate)
      const date = this.date ? this.date.split('T') : []
      const end = this.endDate ? this.endDate.split('T') : []
      const start = this.startDate ? this.startDate.split('T') : []
      this.dateDay = date[0]
      this.dateHour = date[1] || '00:00'
      this.dateEndDay = end[0]
      this.dateEndHour = end[1] || '23:59'
      this.dateStartDay = start[0]
      this.dateStartHour = start[1] || '00:00'

      if (this.onlyRange) {
        this.type = 'range'
      }

      if (this.onlyRange && this.default_value.endDate === 'now' && this.default_value.startDate === 'now') {
        this.dateEndHour = '23:59'
        this.dateStartHour = '00:00'
      }
    },
    data () {
      return {
        type: 'operator',
        operator: 'same',
        date: 'now',
        day: 'now',
        dayOperator: 'same',
        dayGroup: 'day',
        dayNumber: 1,
        startDate: 'now',
        endDate: 'now',
        dataValue: '',
        customizeCheckbox: false,
        dateDay: null,
        dateHour: '00:00',
        dateStartDay: null,
        dateStartHour: '00:00',
        dateEndDay: null,
        dateEndHour: '23:59',
      }
    },
    methods: {
      dateSetTime (date, hour, minutes, seconds, millis) {
        date.setHours(hour)
        date.setMinutes(minutes)
        date.setSeconds(seconds)
        return moment(date).format('YYYY-MM-DD[T]HH:mm')
      },
      dateFormat (date) {
        if (date === 'now') {
          date = moment().format('YYYY-MM-DD[T]HH:mm')
        }

        return date
      },
      dayDisabled () {
        return this.day === 'now' || this.nbReadOnly && !this.live
      },
      changeType (type) {
        this.type = type
        if (this.type === 'day' && !this.day) {
          this.day = 'now'
        }
      },
      changeDay (day) {
        this.day = day
      },
      changeDayOperator (operator) {
        this.dayOperator = operator
      },
      changeDayGroup (group) {
        this.dayGroup = group
      },
      changeCheckBox () {
        this.customizeCheckbox = !this.customizeCheckbox
        EventBus.$emit('filterStatusChange', this.id, this.label, !this.customizeCheckbox)
      },
      getClassDay (dayOperator, operatorKey) {
        return dayOperator === operatorKey ? 'btn-brand' : 'btn-secondary'
      },
      deleteDates () {
        this.date = null
        this.endDate = null
        this.startDate = null
        this.dateDay = null
        this.dateHour = '00:00'
        this.dateStartDay = null
        this.dateStartHour = '00:00'
        this.dateEndDay = null
        this.dateEndHour = '23:59'
      },
      refreshDate (dateDay, dateHour) {
        if (dateDay) {
          return `${dateDay}T${dateHour || '00:00'}`
        } else {
          return null
        }
      }
    },
    watch: {
      dayNumber: function (val) {
        if (val < 1) {
          this.dayNumber = 1
        }
      },
      dateDay () {
        this.date = this.refreshDate(this.dateDay, this.dateHour)
      },
      dateHour () {
        this.date = this.refreshDate(this.dateDay, this.dateHour)
      },
      dateStartDay () {
        this.startDate = this.refreshDate(this.dateStartDay, this.dateStartHour)
      },
      dateStartHour () {
        this.startDate = this.refreshDate(this.dateStartDay, this.dateStartHour)
      },
      dateEndDay () {
        this.endDate = this.refreshDate(this.dateEndDay, this.dateEndHour)
      },
      dateEndHour () {
        this.endDate = this.refreshDate(this.dateEndDay, this.dateEndHour)
      },
    },
  }
</script>

<style scoped>

</style>
