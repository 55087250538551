<template>
    <div class="w-100">

        <div class="m-entity-list-search m-0 px-1">
            <div class="d-flex align-content-start flex-wrap m-0">
                <div class="col-4 mb-3">
                    <label>Suceso</label>
                    <multiselect :multiple="true" v-model="actionsFilter"
                                 label="label" track-by="value"
                                 :options="filterOptions.availableActions"></multiselect>
                </div>
                <div class="col-4 mb-3">
                    <label>Clientes</label>
                    <multiselect :multiple="true" v-model="customersFilter"
                                 label="label" track-by="value"
                                 :options="filterOptions.availableCustomers"></multiselect>
                </div>
                <div class="col-3">
                    <label for="date-start">Fecha de inicio</label>
                    <input class="form-control" id="date-start" v-model="dateStart" type="datetime-local">
                </div>
                <div class="col-3">
                    <label for="date-end">Fecha de fin</label>
                    <input class="form-control" id="date-end" v-model="dateEnd" type="datetime-local">
                </div>
            </div>
        </div>

        <div class="m-content">
            <div class="row">
                <div class="col-6">
                    <div class="form-group form-inline">
                        Mostrar
                        <select v-model="offset" class="fform-select  w-auto mx-2"
                                title="length" name="length" id="length">
                            <option :value="10">10</option>
                            <option :value="25">25</option>
                            <option :value="50">50</option>
                            <option :value="100">100</option>
                        </select>
                        entradas
                    </div>
                </div>
                <div class="col-6 d-flex">
                    <ul class="pagination ml-auto">
                        <li class="paginate_button page-item first" :class="{disabled: page <= 1}" id="first_page">
                            <button href="#" class="page-link"
                                    @click.stop.prevent="page = 1">
                                <i class="la la-angle-double-left"></i>
                            </button>
                        </li>

                        <li class="paginate_button page-item previous"
                            :class="{disabled: page <= 1}"
                            id="m_table_1_previous">
                            <button href="#" class="page-link"
                                    @click.stop.prevent="subPage">
                                <i class="la la-angle-left"></i>
                            </button>
                        </li>

                        <li class="align-self-center p-2">
                            <span>Página {{ this.page }} de {{ this.lastPage }}</span>
                        </li>

                        <li class="paginate_button page-item next" id="m_table_1_next">
                            <a href="#" class="page-link" @click.stop.prevent="addPage">
                                <i class="la la-angle-right"></i></a>
                        </li>
                        <li class="paginate_button page-item last" id="m_table_1_last">
                            <a href="#" class="page-link" @click.stop.prevent="page=lastPage">
                                <i class="la la-angle-double-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <table class="table m-table m-table--head-no-border w-100">
                <thead>
                <tr>
                    <th>Suceso</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <!-- TODO: Ver si esto de ingresos aplica al proyecto base o mejor borrarlo -->
                    <th class="text-center text-nowrap" style="width: 1%">Ingresos</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="analytic of analyticRecords">
                    <td>
                        {{ analytic.event_type_name }}
                    </td>
                    <td>
                        {{ analytic.customer_name }}
                    </td>
                    <td>
                        {{ analytic.created_at|moment }}
                    </td>
                    <td class="text-right income" :class="{'profitable': !!analytic.profitable }">
                        <template v-if="analytic.income">
                            <span v-if="analytic.profitable">+</span>
                            <span v-else>~</span>
                            <span>{{ analytic.income }} €</span>
                        </template>
                        <span v-else>--</span>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="7" class="text-right bg-light">
                        <span class="font-weight-bold">Ingreso total adquirido: {{ totalIncome }} €</span>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
  import axios from 'axios'
  import DateHelpers from '@/js/mixins/DateHelpers'

  export default {
    name: 'CampaignAnalytics',
    mixins: [DateHelpers],
    data () {
      return {
        page: 1,
        lastPage: null,
        offset: 10,
        total: 0,

        analyticRecords: [],
        totalIncome: 0,

        actionsFilter: [],
        customersFilter: [],
        dateStart: null,
        dateEnd: null,
      }
    },
    props: {
      campaignId: { type: Number, required: true },
      filterOptions: {
        type: Object, default: () => ({
          availableAnalyticsEvents: [],
          availableActions: [],
          availableCustomers: [],
        })
      },
    },
    watch: {
      page: 'loadAnalyticRecords',
      offset: 'loadAnalyticRecords',
      filterRequest: 'loadAnalyticRecords',
    },
    mounted () {
      this.actionsFilter = [...this.filterOptions.availableActions.filter(e => e.is_default)]
      this.loadAnalyticRecords()
    },
    methods: {
      subPage () { this.page > 1 && this.page-- },
      addPage () { this.page < this.lastPage && this.page++ },
      async loadAnalyticRecords () {
        try {
          const response = (await axios.get(window.route('API.campaign.records', { campaignId: this.campaignId }), {
            params: {
              page: this.page,
              length: this.offset,
              ...this.filterRequest,
            }
          })).data

          this.total = response.total
          this.analyticRecords = response.items
          this.lastPage = response.lastPage
          this.totalIncome = response.total_income
        } catch (e) {
          console.error(e)
        } finally {
          (this.lastPage < this.page) && (this.page = this.lastPage)
        }
      }
    },
    computed: {
      filterRequest () {
        return {
          actionsFilter: this.actionsFilter.map(f => f.value),
          customersFilter: this.customersFilter.map(f => f.value),
          dateStartFilter: this.dateStart,
          dateEndFilter: this.dateEnd,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
    .income {
        color: #9598a6;
        font-style: italic;
        font-variant-numeric: slashed-zero;
        font-size: 0.9rem;

        &.profitable {
            font-size: 1.1rem;
            font-style: normal;
            font-weight: bolder;
            color: #0fb900;
        }
    }
</style>
