var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "div",
        {
          staticClass: "btn btn-secondary js-custom-control-button",
          attrs: { "data-js-action": "addItems", "data-check-selected": false },
          on: { click: _vm.changeVisible },
        },
        [
          _c("span", [
            _c("em", { staticClass: "fa fa-user fa-fw me-1" }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("trans")(
                    "forms_crm_customers.customersSendMail.buttonTitle"
                  )
                )
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            refreshing: _vm.refreshing,
            backdrop: true,
            classesModalDialog: ["modal-xl modal-dialog-scrollable"],
            visible: _vm.visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("trans")(
                          "forms_crm_customers.customersSendMail.title",
                          { number: _vm.customersNumber }
                        )
                      ) +
                      "\n        "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            _c(
              "div",
              { staticClass: "m-form" },
              [
                _vm.message
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "m-alert m-alert--square alert alert-dismissible fade show",
                        class: [
                          _vm.successMessage ? "alert-success" : "alert-danger",
                        ],
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "m-alert__text" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.message) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-alert__close" }, [
                          _c("button", {
                            staticClass: "close",
                            attrs: {
                              type: "button",
                              "data-bs-dismiss": "alert",
                              "aria-label": "Close",
                            },
                          }),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(
                  Object.values(_vm.CustomEmailSendingType),
                  function (sendingType, key) {
                    return _c("tr", { key: key, staticClass: "align-top" }, [
                      _c("td", [
                        _c("label", { staticClass: "form-check-label ms-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedSendingType,
                                expression: "selectedSendingType",
                              },
                            ],
                            staticClass: "form-check-input me-1",
                            attrs: {
                              id: "RegistrableTypeRadioPaid",
                              type: "radio",
                            },
                            domProps: {
                              value: sendingType,
                              checked: _vm._q(
                                _vm.selectedSendingType,
                                sendingType
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectedSendingType = sendingType
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "fw-bold fs-6" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("trans")(
                                  `forms_crm_customers.customersSendMail.sendingTypes.${sendingType}`
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "fw-bold fs-6 mt-4" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")(
                            "forms_crm_customers.customersSendMail.sendingModesTitle"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      Object.values(_vm.CustomEmailSendingMode),
                      function (sendingMode, key) {
                        return _c(
                          "tr",
                          { key: key, staticClass: "align-top" },
                          [
                            _c("td", [
                              _c(
                                "label",
                                { staticClass: "form-check-label ms-0 mt-1" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedSendingMode,
                                        expression: "selectedSendingMode",
                                      },
                                    ],
                                    staticClass: "form-check-input me-1",
                                    attrs: {
                                      id: "RegistrableTypeRadioPaid",
                                      type: "radio",
                                    },
                                    domProps: {
                                      value: sendingMode,
                                      checked: _vm._q(
                                        _vm.selectedSendingMode,
                                        sendingMode
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectedSendingMode = sendingMode
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "fw-bold fs-6" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("trans")(
                                          `forms_crm_customers.customersSendMail.sendingModes.${sendingMode}`
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt-4" }, [
                  _c("div", { staticClass: "col-12 col-lg-3 mb-4" }, [
                    _c("label", { staticClass: "d-block" }, [
                      _c("span", { staticClass: "fw-bold" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("trans")(
                                "forms_crm_customers.customersSendMail.subjectTitle"
                              )
                            ) +
                            "\n                          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mail.subject,
                            expression: "mail.subject",
                          },
                        ],
                        staticClass: "form-control mr-4",
                        attrs: {
                          disabled: _vm.nbReadOnly,
                          placeholder: "Introduce un asunto",
                          name: "subject",
                        },
                        domProps: { value: _vm.mail.subject },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.mail, "subject", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "m-form__help" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")(
                            "forms_crm_customers.customersSendMail.helpBlocks.plainText"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.selectedSendingMode == "email"
                    ? _c(
                        "div",
                        { staticClass: "col-lg-9 col-xl-10" },
                        [
                          _c("HtmlEditor", {
                            attrs: {
                              value: _vm.mail.body,
                              field: "body",
                              height: 220,
                              label: _vm.$options.filters.trans(
                                "forms_crm_customers.customersSendMail.bodyTitle"
                              ),
                              buttons: _vm.htmlButtons,
                            },
                            model: {
                              value: _vm.mail.body,
                              callback: function ($$v) {
                                _vm.$set(_vm.mail, "body", $$v)
                              },
                              expression: "mail.body",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "m-form__help" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("trans")(
                                  "forms_crm_customers.customersSendMail.helpBlocks.richText"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "col-lg-9 col-xl-10" }, [
                        _c("span", { staticClass: "fw-bold" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm._f("trans")(
                                  "forms_crm_customers.customersSendMail.bodyTitle"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mail.body,
                              expression: "mail.body",
                            },
                          ],
                          staticClass: "form-control mr-4",
                          attrs: { disabled: _vm.nbReadOnly },
                          domProps: { value: _vm.mail.body },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.mail, "body", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "m-form__help" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")(
                                "forms_crm_customers.customersSendMail.helpBlocks.plainText"
                              )
                            )
                          ),
                        ]),
                      ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary m-btn m-btn--icon",
                    attrs: { type: "button" },
                    on: { click: _vm.sendMail },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")(
                              "forms_crm_customers.customersSendMail.buttonTitle"
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }