<template>
    <div class="mt-5 mx-4 mb-0">
        <div class="mb-5">
            <label class="d-block">
                <span class="fw-bold">Ponentes</span>
                <multiselect v-model="speakers" :options="speakerArray" label="name" track-by="id" :multiple="true" />
                <NbErrorBlock :path="`properties.${propertyKey}.speakers`" />
            </label>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="form-check form-switch form-check-custom form-check-solid">
                    <input type="checkbox" :value="show_questions" v-model="show_questions" class="form-check-input" />
                    <span class="form-check-label">
                        Mostrar preguntas
                    </span>
                </label>
            </div>
        </div>
        <conference-property-tutored-questions :lang="lang" @update-questions="updateQuestions"
            :questions="value.questions" />
    </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import ConferencePropertyTutoredQuestions from '@/js/components/conference/properties/tutored/ConferencePropertyTutoredQuestions'

export default ({
    name: 'ConferencePropertyTutored',
    components: { ConferencePropertyTutoredQuestions, NbErrorBlock },
    props: {
        speakersOptions: {
            type: Array,
            default: () => [],
        },
        lang: {
            type: String,
            default: null,
        },
        value: {
            type: Object,
            default: () => { },
        },
        propertyKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            speakerArray: Object.values(this.speakersOptions),
        }
    },
    computed: {
        speakers: {
            get() {
                return this.value.speakers ? this.value.speakers.map(item => this.speakerArray.find(speaker => speaker.id === item.id)) : []
            },
            set(value) {
                this.$emit('input', { ...this.value, speakers: value });
            }
        },
        show_questions: {
            get() {
                return this.value.show_questions;
            },
            set(value) {
                this.$emit('input', { ...this.value, show_questions: value });
            }
        },
    },
    methods: {
        updateQuestions(questions) {
            this.value.questions = questions;
            this.$emit('input', { ...this.value });
        },
    }
})
</script>
