<template>
    <div class="row col-12">
        <input type="hidden" :value="allwaysADay, allwaysADate, allwaysaFrequencySelected">
        <input type="hidden" name="schedule_frequency" :value="parseFrecuency">
        <div class="row col-md-8" :class="frequencySelected.id === 5 ? '' : 'float-left'">
            <div class="form-group m-form__group col-md-12">
                <label>Frecuencia en el que deseas enviar el informe (TEMP)</label>
                <multiselect v-model="frequencySelected" :options="frequency" name="repeat"
                             track-by="id" label="name" id="repeat"/>
            </div>
        </div>
        <div class="row mt-3">
            <div class="form-group m-form__group col-md-8" v-if="frequencySelected.id === 5">
                <div class="col-md-12 mb-3">
                    <h5>Recurrencia personalizada</h5>
                    <div class="input-group m-input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Repetir cada</span>
                        </div>
                        <input type="number" class="form-control m-input col-md-2 text-right"
                               min="1" step="1" v-model="everyIntervalRepeat" id="every-interval">
                        <div class="input-group-append">
                            <select class="form-select " v-model="everyRepeat" id="every-repeat">
                                <option v-for="item in everyRepeats" :value="item" @input="setValuesEveryRepeat">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-if="everyRepeat.id === 2" class="col-md-12 mb-3">
                    <label>Repetir el:</label>
                    <multiselect v-model="day" :options="days" name="repeat" id="repeat-days"
                                 track-by="id" label="name" multiple/>
                </div>
                <div v-if="everyRepeat.id === 3" class="col-md-12 mb-3 form-inline">
                    <div class="input-group m-input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">El día</span>
                        </div>
                        <input type="number" min="1" max="28" v-model="dayToRepeat"
                               class="form-control text-right" id="repeat-day">
                    </div>
                </div>
                <div class="col-md-12">
                    <label>Finaliza</label>
                    <div class="m-radio-list">
                        <label class="m-radio">
                            <input type="radio" v-model="finish" value="1" id="repeat-1"> Nunca
                            <span/>
                        </label>
                        <label class="m-radio">
                            <input type="radio" v-model="finish" value="2" id="repeat-2">
                            <div class="input-group m-input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">El día</span>
                                </div>
                                <input type="date" v-model="finishDay" class="form-control col-md-6" id="finish-day">
                            </div>
                            <nb-error-block path="schedule_frequency.data.stop"/>
                            <span class="mt-2"/>
                        </label>
                        <label class="m-radio">
                            <input type="radio" v-model="finish" value="3" id="repeat-3">
                            <div class="input-group m-input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Despues de</span>
                                </div>
                                <input type="number" class="form-control m-input col-md-2 text-right"
                                       min="1" step="1" v-model="ocurrences" id="occurences">
                                <div class="input-group-append">
                                    <span class="input-group-text">ocurrencias</span>
                                </div>
                            </div>
                            <span class="mt-2"/>
                        </label>
                    </div>
                </div>
            </div>

            <div :class="frequencySelected.id === 5 ? 'form-group m-form__group col-md-4' : 'float-left'">

                <div class="input-group m-input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Hora</span>
                    </div>
                    <input type="time" class="form-control col-md-12" v-model="hour" id="repeat-hour">
                </div>

                <v-date-picker v-model="date" :show-day-popover='false'
                               name="date" is-inline/>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    props: {
      frequency: { type: Array, default: () => [] },
      scheduleFrequency: { type: [String, Object] },
    },
    data () {
      return {
        time: new Date(),
        date: new Date(),
        day: [],
        hour: new Date().getHours() + ':' + new Date().getMinutes(),
        dayToRepeat: 1,
        everyIntervalRepeat: 2,
        ocurrences: 2,
        finish: 1,
        finishDay: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        frequencySelected: null,
        everyRepeat: null,
        everyRepeats: [
          { id: 1, name: 'días' },
          { id: 2, name: 'semanas' },
          { id: 3, name: 'meses' },
        ],
        days: [
          { id: 1, name: 'Lunes' },
          { id: 2, name: 'Martes' },
          { id: 3, name: 'Miércoles' },
          { id: 4, name: 'Jueves' },
          { id: 5, name: 'Viernes' },
          { id: 6, name: 'Sábado' },
          { id: 0, name: 'Domingo' },
        ],
        schedule_frequency: {
          cron: null,
          data: {
            hour: null,
            frequency: null,
            start: null,
            stop: null,
            isCustom: false,
            everyIntervalRepeat: 2,
            daysOfWeek: null,
            everyRepeatId: null,
            dayToRepeat: 1,
          }
        }
      }
    },
    created: function () {

      this.frequencySelected = this.frequency.find(frequency => frequency.id === 1)
      this.everyRepeat = this.everyRepeats.find(repeat => repeat.id === 1)

      if (this.scheduleFrequency !== null) {
        let schedule = this.scheduleFrequency
        if (typeof this.scheduleFrequency === 'object') {
          schedule = JSON.stringify(schedule)
        }
        schedule = JSON.parse(`${schedule}`)
        this.frequencySelected = this.frequency.find(frequency => frequency.id === schedule.data.frequency)
        this.date = new Date(schedule.data.start)
        this.hour = schedule.data.hour
        if (schedule.data.isCustom === true) {
          this.everyRepeat = this.everyRepeats.find(repeat => repeat.id === schedule.data.everyRepeatId)
          this.finish = this.setStop(schedule.data.stop)
          this.everyIntervalRepeat = schedule.data.everyIntervalRepeat
          this.dayToRepeat = schedule.data.dayToRepeat
          this.day = this.days.filter(day => schedule.data.daysOfWeek.includes(day.id))
        }
      }

    },
    computed: {
      parseFrecuency () {
        this.schedule_frequency.data.frequency = this.frequencySelected === null ? 1 : this.frequencySelected.id
        this.schedule_frequency.data.hour = this.hour
        this.schedule_frequency.data.start = this.setDateTime(this.date)

        if (this.frequencySelected.id !== 5) {
          this.schedule_frequency.cron = this.generateFixedCron()
          if (this.frequencySelected.id === 1) {
            let start = new Date(this.schedule_frequency.data.start)
            let stop = start.setMinutes(start.getMinutes() + 1)
            this.schedule_frequency.data.stop = moment(stop).format('YYYY-MM-DD HH:mm')
          } else {
            this.schedule_frequency.data.stop = null
          }
        } else {
          this.schedule_frequency.data.isCustom = true
          this.schedule_frequency.data.everyRepeatId = this.everyRepeat.id
          this.schedule_frequency.cron = this.generateCustomCron()
          this.schedule_frequency.data.stop = this.finishSend()
          this.schedule_frequency.data.everyIntervalRepeat = this.everyIntervalRepeat
          this.schedule_frequency.data.daysOfWeek = this.daysOfWeek()
          this.schedule_frequency.data.dayToRepeat = this.dayToRepeat
        }
        return JSON.stringify(this.schedule_frequency)
      },
      allwaysADay () {
        if (this.day.length <= 0) {
          this.day.push(this.days.find(day => day.id === this.time.getDay()))
        }
      },
      allwaysADate () {
        if (this.date === null) {
          this.date = new Date()
        }
      },
      allwaysaFrequencySelected () {
        if (this.frequencySelected === null) {
          this.frequencySelected = this.frequency.find(frequency => frequency.id === 1)
        }
      },
    },
    methods: {
      setValuesEveryRepeat () {
        if (this.everyRepeat !== 2) {
          this.day = []
        }
      },
      finishSend () {
        let finishRecurrence = 0
        if (parseInt(`${this.finish}`) === 2) {
          finishRecurrence = this.setDateTime(this.finishDay)
        } else if (parseInt(`${this.finish}`) === 3) {
          finishRecurrence = parseInt(`${this.ocurrences}`)
        }
        return finishRecurrence
      },
      daysOfWeek () {
        return this.day.map(day => day.id)
      },
      setDateTime (date) {
        let time = this.hour.split(':')
        let dateTime = date
        dateTime.setHours(parseInt(time[0]), parseInt(time[1]))
        return moment(dateTime).format('YYYY-MM-DD HH:mm')
      },
      setStop (stop) {
        let finished = null
        if (stop === 0) {
          finished = 1
        } else if (typeof stop === 'string') {
          finished = 2
          let date = new Date(stop)
          this.finishDay = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
        } else if (typeof stop === 'number') {
          finished = 3
          this.ocurrences = stop
        }

        return finished
      },
      generateCustomCron () {
        let time = this.hour.split(':')
        let every = this.everyIntervalRepeat
        let frecuency = this.everyRepeat.id
        let cron = null

        switch (frecuency) {
          case 1:
            if (every > 1) {
              cron = `${time[1]} ${time[0]} */${every} * *`
            } else {
              cron = `${time[1]} ${time[0]} * * *`
            }
            break
          case 2:
            cron = `${time[1]} ${time[0]} */${7 * every} * ${this.daysOfWeek().toString()}`
            break
          case 3:
            cron = `${time[1]} ${time[0]} * */${every} ${this.dayToRepeat}`
            break
        }
        return cron
      },
      generateFixedCron () {
        let time = this.hour.split(':')
        let auxTime = 1
        let cron = null

        cron = this.frequencySelected.cron.replace(/\?/g, () => time[auxTime - 1])

        if (this.frequencySelected.id === 3) {
          cron = cron.replace('w', this.date.getDay())
        } else if (this.frequencySelected.id === 4) {
          cron = cron.replace('t', this.date.getDate())
        }

        return cron
      }
    }
  }

</script>
