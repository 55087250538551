<template>
    <div class="form-group m-form__group">
        <div class="mb-2">
            <div class="form-label">
                {{ `page.parameters.title` | trans }}
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-12">
                <div class="mb-4">
                    <label for="parameter_width" class="form-label">
                        <small> {{ `page.parameters.width.title` | trans }}</small>
                    </label>
                    <select class="form-select  form-select-sm" v-model="width" id="parameter_width"
                            name="parameter_width">
                        <option v-for="(parameter, index) in Object.values(WidthParameters)" :key="index"
                                :value="parameter">
                            {{ `page.parameters.width.${parameter}` | trans }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 col-lg-12">
                <div class="mb-4">
                    <label for="parameter_background_color" class="form-label">
                        <small> {{ `page.parameters.background_color.title` | trans }}</small>
                    </label>
                    <select class="form-select  form-select-sm" v-model="backgroundColor"
                            id="parameter_background_color"
                            name="parameter_background_color">
                        <option v-for="(parameter, index) in Object.values(BackgroundColorParameters)" :key="index"
                                :value="parameter">
                            {{ `page.parameters.background_color.${parameter}` | trans }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {WidthParameters, BackgroundColorParameters} from '@/js/enums/Parameters'

export default {
    name: 'HtmlParameters',
    props: {
        value: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            parameters: [],
            WidthParameters,
            BackgroundColorParameters,
        }
    },
    computed: {
        width: {
            get() {
                return this.value.width ?? null
            },
            set(value) {
                this.emit({width: value})
            },
        },
        backgroundColor: {
            get() {
                return this.value.background_color ?? null
            },
            set(value) {
                this.emit({background_color: value})
            },
        },
    },
    created() {
        this.parameters = this.currentParameters
    },
    methods: {
        emit(parameter) {
            this.$emit('input', {...this.value, ...parameter})
        },
    }
}
</script>

<style scoped>

</style>
