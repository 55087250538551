var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading && _vm.show
    ? _c("div", [
        _c("input", {
          attrs: {
            type: "hidden",
            name: "new_extra",
            id: `new_extra[${_vm.formData.name}]`,
          },
          domProps: { value: _vm.currentDataJson },
        }),
        _vm._v(" "),
        _c("div", { attrs: { id: _vm.formData.name } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }