var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-xl-4 col-lg-6 col-md-8 col-sm-10" }, [
          _c("div", { staticClass: "row mb-4" }, [
            _c("input", {
              staticClass: "form-control form-control-solid",
              attrs: {
                type: "email",
                id: "login-email",
                placeholder: "Email",
                name: "email",
                autocomplete: "off",
                disabled: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-4" }, [
            _c("input", {
              staticClass: "form-control form-control-solid",
              attrs: {
                id: "login-password",
                type: "password",
                placeholder: "Password",
                name: "password",
                autocomplete: "off",
                disabled: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row my-4" }, [
            _c("div", { staticClass: "col d-flex mb" }, [
              _c("label", { staticClass: "m-checkbox" }, [
                _c("input", { attrs: { type: "checkbox", disabled: "" } }),
                _vm._v(" Recordarme\n\n                    "),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col d-flex" }, [
              _c("span", [
                _vm._v(
                  "\n                        ¿Has olvidado tu contraseña?\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mb-4",
                attrs: { type: "button", disabled: "" },
              },
              [_vm._v("\n                    Iniciar sesión\n                ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "py-3 text-center border-top" }, [
            _vm._v(
              "\n                ¿No tienes cuenta? Crear cuenta\n            "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }