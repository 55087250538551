<template>
  <div class="mb-6 form-group m-form__group form-group-sm col-12 noprefetch m--padding-left-30 m--padding-right-30 py-0 m-form">
    <input type="hidden" name="specialEvents" id="specialEvents" :value="specialEventsJSON" />
    <article class="block position-relative c-contact pt-4 mb-5">
      <div class="container">
          <!-- TODO: MAQ-AGENDA usar :events="splitEvents" con :split-days="splitDays" y :events="events" con :split-days="[]" ---- Se queda como esta -->
          <!-- TODO: MAQ-AGENDA Probar las combinaciones de show-all-day-events y stricky-split-labels No haría falta soportar todas, pero al menos elegir la que se vea más adecuada y dejarla fina ---- Se queda como esta -->
          <!-- TODO: PRO-AGENDA ajustar time-from y time-to al contenido -->

          <div class="d-flex flex-row-reverse">
            <button class="btn btn-primary float-end mb-4" type="button" @click="addNewEvent">
              {{ 'event.addEventButton' | trans }}
            </button>
          </div>

        <vue-cal
          :disable-views="['years', 'year', 'month']"

          :events="events"
          :split-days="splitDays"
          locale="es"

          :show-all-day-events="false"

          :min-date="minDate"
          :max-date="maxDate"
          :selected-date="selectedDate"
          hide-weekends
          :time-from="7 * 60"
          :time-to="24 * 60"
          :time-step="30"
          :time-cell-height="40"
          :class="{'arrow--prev': (startDate > minDate), 'arrow--next': (endDate < maxDate)}"
          sticky-split-labels
          editable-events
          :snap-to-time="5"
          @view-change="viewChanged($event)"
          @cell-click="changeSelectedDay"
          @event-drag-create="addEvent($event)"
          @event-change="changeEvent($event)"
          @event-delete="deleteEvent($event)"
        >

            <!-- titulo con icono -->
          <template v-slot:split-label="{ split, view }">
            <b-icon
              :style="`color: ${split.color}`"
              class="me-2 d-none d-sm-inline-block"
              icon="calendar3"
              aria-hidden="true"
            ></b-icon>
            <strong :style="`color: ${split.color}`">{{
              split.label
            }}</strong>
          </template>
            <!-- titulo con icono -->

          <template v-slot:event="{ event, view }">
            <div
              class="h-100 w-100 scroll-y p-2"
            >
              <div
                class="border-start border-dark text-start ps-2"
                style="border-left-width: 2px !important"
              >
                <div
                  class="vuecal__event-title vuecal__event-title--edit text-start font-weight-bold overflow-hidden text-nowrap vac-text-ellipsis"
                  contenteditable
                  @blur="changeEventTitle(event, $event.target.innerHTML)"
                  v-html="event.title"
                />
                <div
                  class="vuecal__event-time font-size--t11 text-left overflow-hidden text-nowrap vac-text-ellipsis"
                >
                  <span>{{ event.start.formatTime("HH:mm") }}</span> - <span>{{ event.end.formatTime("HH:mm") }}</span>
                </div>
              </div>
            </div>
          </template>
        </vue-cal>
      </div>
    </article>
  </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import uuidv4 from 'uuid/v4'

export default {
  name: 'Calendar',
  components: {
    VueCal,
  },
  props: {
    specialEvents: {
      type: Array,
      required: true,
    },
    startDateEvent: [String, null],
    endDateEvent: [String, null],
    splitDays: {
      type: Array,
      required: true,
    },
  },
  watch: {
    splitDays: {
      handler (value) {
        if (value.length) {
          this.events.map(event => {
            if (!event.split) {
              event.split = this.splitDays[0].id
            }
          })
        } else {
          this.selectedSplit = null
          this.events.map(event => {
            if (event.split) {
              event.split = null
            }
          })
        }
      }
    }
  },
  computed: {
    specialEventsJSON () {
      return JSON.stringify(this.events)
    },
    lastId () {
      const eventIds = this.events.map(event => event.id)
      console.log(eventIds)
      return Math.max(...eventIds)
    }
  },
  data() {
    return {
      loading: false,
      minDate: null,
      maxDate: null,
      selectedDate: new Date(),
      selectedSplit: null,
      startDate: null,
      endDate: null,
      events: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    viewChanged ($event) {
      this.startDate = $event.startDate
      this.endDate = $event.endDate
    },
    init () {
      this.events = this.specialEvents.map((event) => {
        event.start = new Date(event.start)
        event.end = new Date(event.end)
        event.title = event.title
        return event
      })
      if (this.startDateEvent && this.endDateEvent) {
        this.minDate = new Date(this.startDateEvent)
        this.maxDate = new Date(this.endDateEvent)
        this.selectedDate = this.minDate
      }
    },
    changeSelectedDay (date) {
      this.selectedSplit = date.split ?? null
      this.selectedDate = date.date ?? date
    },
    addNewEvent () {
      // const split = this.splitDays ? this.splitDays[0].id : null
      // const split = this.splitDays ? this.splitDays[0].id : null
      this.events.push({
        _eid: null,
        id: this.lastId + 1,
        start: this.selectedDate,
        end: this.selectedDate.addHours(1),
        title: 'Añade un título...',
        class: 'text-primary,bg-secondary',
        split: this.selectedSplit
      })
    },
    addEvent (event) {
      this.events.push({
        _eid: event.eid,
        id: this.lastId + 1,
        start: event.start,
        end: event.end,
        title: 'Añade un título...',
        class: 'text-primary,bg-secondary',
        split: event.split,
      })
    },
    changeEvent ({ event, originalEvent }) {
      // Solo se cambia si tiene originalEvent porque también se lanza al crear un nuevo evento, lo cual ya manejamos por event-drag-create
      if (originalEvent) {
        const index = this.events.findIndex(currentEvent => currentEvent.id === event.id)
        this.events.splice(index, 1)

        this.events.push({
          _eid: event.eid,
          id: event.id,
          start: event.start,
          end: event.end,
          title: event.title,
          class: event.class,
          split: event.split,
        })
      }
    },
    deleteEvent (deletedEvent) {
      const index = this.events.findIndex(event => event.id === deletedEvent.id)
      this.events.splice(index, 1)
    },
    changeEventTitle (event, name) {
        const originalEvent = {...event}
        event.title = name
        this.changeEvent({ event, originalEvent })
    }
  }
}
</script>

<style lang="scss" scoped>
.vuecal__event--dragging {background-color: rgba(60, 60, 60, 0.3);}

</style>
