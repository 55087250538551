var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.fieldLoader, {
    tag: "component",
    attrs: {
      popup_field: _vm.popup_field,
      row: _vm.row,
      field_type: _vm.field_type,
      prefix: _vm.prefix,
      field: _vm.field,
      idx: _vm.idx,
      editor: _vm.editor,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }