var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group mb-6 col-md-6" },
    [
      _c("input", {
        attrs: { type: "hidden", name: "additionalRooms" },
        domProps: { value: _vm.jsonAdditionalRooms },
      }),
      _vm._v(" "),
      _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          options: _vm.rooms,
          label: "name",
          "track-by": "id",
          multiple: true,
        },
        model: {
          value: _vm.roomsSelect,
          callback: function ($$v) {
            _vm.roomsSelect = $$v
          },
          expression: "roomsSelect",
        },
      }),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: _vm.additionalRooms } }),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: _vm.getPathError() } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }