<template>
  <div :class="classField">
    <label class="form-label" v-if="name" :for="field">{{ name }}</label>
    <div class="d-flex">
      <input :type="showInput ? 'text' : 'hidden'" class="form-control " :name="field" :id="field"
             :disabled="disabled || nbReadOnly"
             v-model="fieldValue" @input="emitValue">
      <v-swatches :style="swatchesStyle"
                  :disabled="disabled || nbReadOnly"
                  :show-fallback="showFallback"
                  :fallback-input-type="fallbackInputType"
                  v-model="fieldValue" @input="emitValue"></v-swatches>
    </div>
    <nb-error-block :path="field"></nb-error-block>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import NbErrorBlock from '@/js/components/error_block/nb-error-block'

export default {
  name: 'vue-color-picker',
  components: {VSwatches, NbErrorBlock},
  props: {
    name: {type: String, default: ''}, // field label
    field: {type: String, default: ''}, // field machine name
    value: {type: String, default: '#000'}, // v-model
    classField: {type: String, default: 'form-group m-form__group col-md-12'}, // default laravel form fields
    showFallback: {type: Boolean, default: true}, // vswatches prop: Shows an input to change the color from there as well.
    fallbackInputType: {type: String, default: 'color'}, // vswatches prop: Sets the input type for the fallback input.
    disabled: {type: Boolean, default: false}, // vswatches prop: Disables the color picker
    showInput: {type: Boolean, default: true}, // show input text for HEX color
    needsTarget: {type: Boolean, default: false} // some components need $ev.target.value for their emits
  },
  mounted() {
    this.fieldValue = this.value
  },
  data() {
    return {
      fieldValue: null,
    }
  },
  computed: {
    swatchesStyle () {
      return 'vertical-align: middle; padding-left: 4px'
    }
  },
  methods: {
    emitValue () {
      this.$emit('input', this.needsTarget ? {target: {value: this.fieldValue}} : this.fieldValue)
      this.$emit('change', this.needsTarget ? {target: {value: this.fieldValue}} : this.fieldValue)
    }
  }
}
</script>

<style lang="scss">
.vue-swatches .vue-swatches__trigger__wrapper{
    display: block;
}
.vue-swatches__trigger {
  border-width: 0 !important;
  height: 38px !important;
  //width: 100% !important;
  border-radius: 0 !important;
}
</style>
