<template>
    <div class="form-group m-form__group col-md-6 mb-6">
        <label>{{ selectLabel | trans }}</label>
        <multiselect v-model="current_value"
                     :name="name"
                     :selectLabel="selectLabel"
                     :selectGroupLabel="selectGroupLabel"
                     :selectedLabel="selectedLabel"
                     :deselectLabel="deselectLabel"
                     :deselectGroupLabel="deselectGroupLabel"
                     :showLabels="showLabels"
                     :limit="limit"
                     :maxHeight="maxHeight"
                     :limitText="limitText"
                     :loading="loading"
                     :disabled="disabled"
                     :openDirection="openDirection"
                     :showNoOptions="showNoOptions"
                     :showNoResults="showNoResults"
                     :tabindex="tabindex"
                     :internalSearch="internalSearch"
                     :options="options"
                     :value="value"
                     :label="label"
                     :multiple="multiple"
                     :id="multiselectId"
                     :trackBy="trackBy"
                     :searchable="searchable"
                     :clearOnSelect="clearOnSelect"
                     :hideSelected="hideSelected"
                     :placeholder="placeholder"
                     :allowEmpty="allowEmpty"
                     :resetAfter="resetAfter"
                     :closeOnSelect="closeOnSelect"
                     :customLabel="customLabel"
                     :taggable="taggable"
                     :tagPlaceholder="tagPlaceholder"
                     :tagPosition="tagPosition"
                     :max="max"
                     :optionsLimit="optionsLimit"
                     :groupValues="groupValues"
                     :groupLabel="groupLabel"
                     :groupSelect="groupSelect"
                     :blockKeys="blockKeys"
                     :preserveSearch="preserveSearch"
                     :preselectFirst="preselectFirst"
                     :showPointer="showPointer"
                     :optionHeight="optionHeight">
        </multiselect>
        <input type="hidden" :name="id" :id="id" v-model="currentHiddenValue">
        <nb-error-block :path="name"></nb-error-block>
    </div>
</template>

<script>
  export default {
    name: 'AutonomousMultiselect',
    props: {
      name: { type: String },
      selectLabel: { type: String },
      selectGroupLabel: { type: String },
      selectedLabel: { type: String },
      deselectLabel: { type: String },
      deselectGroupLabel: { type: String },
      showLabels: { type: Boolean, default: true },
      limit: { type: Number },
      maxHeight: { type: Number },
      limitText: { type: Function },
      loading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      openDirection: { type: String },
      showNoOptions: { type: Boolean, default: true },
      showNoResults: { type: Boolean, default: true },
      tabindex: { type: Number },
      internalSearch: { type: Boolean, default: true },
      options: { type: Array, required: true },
      multiple: { type: Boolean, default: false },
      value: { type: null },
      trackBy: { type: String },
      label: { type: String },
      searchable: { type: Boolean, default: true },
      clearOnSelect: { type: Boolean, default: true },
      hideSelected: { type: Boolean, default: false },
      placeholder: { type: String },
      allowEmpty: { type: Boolean, default: true },
      resetAfter: { type: Boolean, default: false },
      closeOnSelect: { type: Boolean, default: true },
      customLabel: { type: Function },
      taggable: { type: Boolean, default: false },
      tagPlaceholder: { type: String },
      tagPosition: { type: String },
      max: { type: [Number, Boolean], default: false },
      id: {},
      optionsLimit: { type: Number },
      groupValues: { type: String },
      groupLabel: { type: String },
      groupSelect: { type: Boolean, default: false },
      blockKeys: { type: Array },
      preserveSearch: { type: Boolean, default: false },
      preselectFirst: { type: Boolean, default: false },
      showPointer: { type: Boolean, default: true },
      optionHeight: { type: Number }
    },

    data () {
      return {
        current_value: null
      }
    },
    computed: {
      currentHiddenValue () {
        if (this.current_value !== null && this.trackBy !== null) {
          return this.current_value[this.trackBy]
        }
        return null
      },
      multiselectId () {
        return this.id + 'multiselect'
      },
    },
    created () {
      this.current_value = _.cloneDeep(this.value)
    },
  }
</script>
