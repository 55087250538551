var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-6", class: _vm.getClass },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.field, id: _vm.field },
        domProps: { value: _vm.getFieldValue },
      }),
      _vm._v(" "),
      _vm.name
        ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.name))])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "list-files form-control" }, [
        _vm.listFiles.length && _vm.multiple
          ? _c(
              "div",
              {
                staticClass: "list-show",
                class: { "list-show-read-only": _vm.nbReadOnly },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.listFiles, function (file, key) {
                    return _c(
                      "div",
                      {
                        staticClass: "list-show-col",
                        class: {
                          "col-6": !_vm.largeVersion,
                          "col-4 list-show-col-large": _vm.largeVersion,
                        },
                      },
                      [
                        _c("div", { staticClass: "list-show-file" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.nbReadOnly,
                                  expression: "!nbReadOnly",
                                },
                              ],
                              staticClass: "list-show-file-delete",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "m-btn btn btn-danger m-btn--icon btn-sm m-btn--icon-only",
                                  attrs: {
                                    type: "button",
                                    id: "remove-file-" + key,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile(key)
                                    },
                                  },
                                },
                                [_c("em", { staticClass: "fa fa-trash fa-fw" })]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "list-show-file-text",
                              class: {
                                "list-show-file-text-short": _vm.thumb,
                                "list-show-file-text-read-only": _vm.nbReadOnly,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(file.name) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.thumb && _vm.isShowable(file)
                            ? _c("div", { staticClass: "list-show-file-img" }, [
                                _c("img", {
                                  attrs: { src: file.url, alt: file.name },
                                  on: {
                                    error: _vm.imgDefault,
                                    click: function ($event) {
                                      return _vm.showModalView(file)
                                    },
                                  },
                                }),
                              ])
                            : _c(
                                "div",
                                { staticClass: "list-show-file-download" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "m-btn btn btn-brand m-btn--icon btn-sm m-btn--icon-only",
                                      attrs: {
                                        type: "button",
                                        id: "download-file-" + key,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(file)
                                        },
                                      },
                                    },
                                    [
                                      _c("em", {
                                        staticClass: "fa fa-download fa-fw",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.listFiles.length && _vm.showFile
          ? _c(
              "div",
              {
                staticClass: "show-file",
                class: {
                  "show-file-short-version": _vm.shortVersion,
                  "show-file-read-only": _vm.nbReadOnly,
                },
              },
              [
                _vm.thumb && _vm.isShowable(_vm.showFile)
                  ? _c("img", {
                      attrs: { src: _vm.showFile.url, alt: _vm.showFile.name },
                      on: {
                        click: function ($event) {
                          return _vm.showModalView(_vm.showFile)
                        },
                        error: _vm.imgDefault,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.showFile.name))]),
                _vm._v(" "),
                !(_vm.thumb && _vm.isShowable(_vm.showFile))
                  ? _c("div", { staticClass: "list-show-file-download" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "m-btn btn btn-brand m-btn--icon btn-sm m-btn--icon-only",
                          attrs: { type: "button", id: "show-download" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadFile(_vm.showFile)
                            },
                          },
                        },
                        [_c("em", { staticClass: "fa fa-download fa-fw" })]
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.nbReadOnly
          ? _c("div", [
              !_vm.listFiles.length && !_vm.multiple
                ? _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("trans")("forms_repository.file_not_selected")
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.listFiles.length && _vm.multiple
                ? _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("trans")("forms_repository.files_not_selected")
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isDisabled
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.nbReadOnly,
                    expression: "!nbReadOnly",
                  },
                ],
                staticClass: "row",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-center list-files-select",
                    class: {
                      "col-12": _vm.shortVersion,
                      "col-6": !_vm.shortVersion && !_vm.largeVersion,
                      "col-10": _vm.largeVersion,
                    },
                  },
                  [
                    _vm.multiple
                      ? _c(
                          "span",
                          {
                            staticClass: "m-badge m-badge--info m-badge--wide",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.listFiles.length) +
                                " " +
                                _vm._s(
                                  _vm._f("trans")("forms_repository.selected")
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.multiple
                      ? _c("div", [
                          _vm.listFiles.length
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "m-btn btn btn-danger m-btn--icon btn-sm",
                                  class: { "btn-block mb-2": _vm.shortVersion },
                                  attrs: {
                                    type: "button",
                                    id: "remove-all-files",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAllFile()
                                    },
                                  },
                                },
                                [
                                  _c("em", {
                                    staticClass: "fa fa-trash fa-fw me-1",
                                  }),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "forms_repository.clear_files"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "list-files-select-text text-muted",
                                  class: { "mb-3": _vm.shortVersion },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "forms_repository.selecte_file"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text-end",
                    class: {
                      "col-12": _vm.shortVersion,
                      "col-6": !_vm.shortVersion && !_vm.largeVersion,
                      "col-2": _vm.largeVersion,
                    },
                  },
                  [
                    !_vm.listFiles.length && !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "p-0 m-btn btn btn-brand m-btn--icon btn-sm btn-block",
                            attrs: {
                              type: "button",
                              id: "short-version-" + _vm.field,
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showRepository = true
                              },
                            },
                          },
                          [
                            _vm.loading
                              ? _c("div", {
                                  staticClass:
                                    "m-loader m-loader--sm m-loader--light py-3",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.listFiles.length && !_vm.loading
                              ? _c("span", [
                                  _c("em", {
                                    staticClass: "fa fa-plus fa-fw me-1",
                                  }),
                                  _vm._v(" "),
                                  _vm.multiple
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("trans")(
                                              "forms_repository.add_files"
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("trans")(
                                              "forms_repository.add_file"
                                            )
                                          )
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listFiles.length && !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-dark m-btn btn btn-brand m-btn--icon btn-sm btn-block",
                            attrs: {
                              type: "button",
                              id: "short-version-" + _vm.field,
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showRepository = true
                              },
                            },
                          },
                          [
                            _vm.loading
                              ? _c("div", {
                                  staticClass:
                                    "m-loader m-loader--sm m-loader--light py-3",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _c("em", {
                                staticClass: "fa fa-pencil-alt fa-fw me-1",
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("trans")(
                                      "forms_repository.edit_files"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: _vm.field } }),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            id: "repositoryModal",
            visible: _vm.openModal,
            backdrop: true,
            keyboard: true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " + _vm._s(_vm.image.name) + "\n        "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            _c("div", { staticClass: "modal-body-image" }, [
              _c("img", {
                attrs: { src: _vm.image.url, alt: _vm.image.name },
                on: { error: _vm.imgDefault },
              }),
            ]),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            id: "repositoryNavigation",
            tabindex: "-1",
            visible: _vm.showRepository,
            backdrop: true,
            keyboard: true,
            classesBody: ["box-scroll"],
            classesModalDialog: ["modal-dialog-centered", "modal-xl"],
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRepository = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("trans")("forms_repository.list_files")) +
                        "\n        "
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.multiple
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "m-btn btn btn-brand m-btn--icon",
                            attrs: {
                              type: "button",
                              id: "options-repo",
                              disabled: !_vm.button,
                            },
                            on: { click: _vm.setOptions },
                          },
                          [
                            !_vm.listFiles.length
                              ? _c("span", [
                                  _c("em", {
                                    staticClass: "fa fa-plus fa-fw me-1",
                                  }),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "forms_repository.add_files"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.listFiles.length
                              ? _c("span", [
                                  _c("em", {
                                    staticClass:
                                      "fa fa-pencil-square fa-fw me-1",
                                  }),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "forms_repository.edit_files"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          [
            _vm.showRepository
              ? _c("repository", {
                  attrs: {
                    "active-select": true,
                    types: _vm.getListTypes,
                    "type-default": _vm.typeDefault,
                    field: true,
                    "select-files": _vm.listFiles,
                    multiple: _vm.multiple,
                    "clear-select": _vm.clearSelect,
                    "allowed-mime-types": _vm.allowedMimeTypes,
                    "group-id": _vm.groupId,
                    "group-type": _vm.groupType,
                    isPrivateUpload: _vm.isPrivateUpload,
                  },
                  on: {
                    repositoryInput: _vm.setInput,
                    repositorySetOptions: _vm.setOptions,
                    close: function ($event) {
                      _vm.showRepository = false
                    },
                  },
                })
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }