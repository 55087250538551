<template>
    <div>
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
            <thead>
                <tr>
                    <th class="fw-bold">
                        {{ 'conference.properties.property_surveyable.start_date' | trans }}
                    </th>
                    <th class="fw-bold">
                        {{ 'conference.properties.property_surveyable.end_date' | trans }}
                    </th>
                    <th class="fw-bold">
                        {{ 'conference.properties.property_surveyable.survey' | trans }}
                    </th>
                    <th width="1%" v-if="!lang"></th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(survey, index) in surveys" >
                    <conference-property-surveyable-survey-row
                        :key="index"
                        v-model="surveys[index]"
                        :index="index"
                        :survey-array="surveyArray"
                        :lang="lang"
                        :property-key="propertyKey"
                        @delete="deleteOption(index)"
                    />
                </template>

            </tbody>
        </table>
    </div>
</template>


<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'
import ConferencePropertySurveyableSurveyRow from '@/js/components/conference/properties/surveyable/ConferencePropertySurveyableSurveyRow'

export default {
    name: 'ConferencePropertySurveyableSurvey',
    components: {
        ConferencePropertySurveyableSurveyRow,
        RepositoryButton,
        NbErrorBlock,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        lang: {
            type: String,
            default: null,
        },
        propertyKey: {
            type: String,
            required: true
        },
        surveyArray: {
            type: Array,
            required: true
        }
    },
    computed: {
        surveys: {
            get() {
                return this.value ? this.value : []
            },
            set() {
                this.emit()
            },
        },
    },
    methods: {
        deleteOption(index) {
            Vue.delete(this.surveys, index)
        },
        emit() {
            this.$emit('input', this.surveys)
        },
        parseDate(date) {
            return moment(date).format('YYYY-MM-DDTHH:mm')
        }
    },
}
</script>

<style lang="scss" scoped>
.table__td-survey>.form-group {
    width: 100% !important;
    margin-bottom: 0 !important;
}
</style>
