var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group" }, [
    _c("input", {
      attrs: { type: "hidden", name: "contentBlocks", id: "contentBlocks" },
      domProps: { value: _vm.contentBlocksJSON },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-row-reverse" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary float-end mb-4",
          attrs: { type: "button" },
          on: { click: _vm.addContentBlock },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("trans")("page.addContentBlockButton")) +
              "\n        "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-label" }, [
      _vm._v(_vm._s(_vm._f("trans")("page.sections"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group" },
      _vm._l(_vm.contentBlocks, function (contentBlock, index) {
        return _c("div", { key: index, staticClass: "list-group-item" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-9 col-xl-10" },
              [
                _c("HtmlEditor", {
                  key: contentBlock.id,
                  attrs: {
                    value: contentBlock.html,
                    defaultLangValue: _vm.defaultLangValue,
                    field: _vm.field,
                    label: _vm.label,
                    height: _vm.height,
                    multiple: _vm.multiple,
                    name: _vm.name,
                    fieldItem: _vm.fieldItem,
                    disabled: _vm.disabled,
                    enableUnsubcribePlugin: _vm.enableUnsubcribePlugin,
                    helpMessage: _vm.helpMessage,
                    colorList: _vm.colorList,
                    buttons: _vm.buttons,
                  },
                  model: {
                    value: contentBlock.html,
                    callback: function ($$v) {
                      _vm.$set(contentBlock, "html", $$v)
                    },
                    expression: "contentBlock.html",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-xl-2" }, [
              _c("div", { staticClass: "row align-items-end" }, [
                _c(
                  "div",
                  { staticClass: "col-md-9 col-lg-12" },
                  [
                    _c("HtmlParameters", {
                      model: {
                        value: contentBlock.parameters,
                        callback: function ($$v) {
                          _vm.$set(contentBlock, "parameters", $$v)
                        },
                        expression: "contentBlock.parameters",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 col-lg-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger mb-4 d-block w-100",
                      attrs: { type: "button", id: "delete-block-" + index },
                      on: {
                        click: function ($event) {
                          return _vm.deleteContentBlock(index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm._f("trans")("page.deleteContentBlockButton")
                          ) +
                          "\n                            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }