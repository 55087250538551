<template>
    <!-- contenido -->
    <div>
        <div
            @dblclick.stop="editor.openDrawer(row)"
            class="border-dashed border-1 border-gray-400 bg-white rounded-2 draggable-handle px-2 py-3">
            <div class="handle">
                <div class="text-muted align-top">{{ editor.options['type'][row.type] }}</div>
                <!-- REPRESENTACION VISUAL ESQUEMATICA -->
                <cms-editor-view v-if="editor.renderComponent" v-bind:row="row"></cms-editor-view>

            </div>
            <!-- CONTAINER -->
            <template v-if="row.type=='container'">
                <input v-if="row.childrens.length === 0" :name="field + '[' + prefix + idx +'][childrens]'" type="hidden" value="">
                <draggable
                    :list="row.childrens"
                    :nested="true"
                    group="contenido"
                    :options="editor.dragOptions"
                    :class="[row.childrens.length === 0 ? 'empty-draggable handle w-100 p-2' : '', 'row g-2 mt-2 pb-2', row.hasOwnProperty('background') ? 'bg-' + row.background : '', row.hasOwnProperty('fit') && row.fit == 'fitcontent' ? 'px-20' : 0]"
                    handle=".handle"
                    @change="changeEvent"
                    ghost-class="opacity-75"
                >
                    <cms-editor-item :editor="editor" v-for="(row2, idx2) in row.childrens"
                        :key="row2.id"
                        :row="row2"
                        :idx="idx2"
                        :field="field + '[' + prefix + idx +'][childrens]'"
                        :items="row.childrens"
                        :class="'draggable position-relative ' + editor.calculeWidth(row2)">
                    </cms-editor-item>
                </draggable>
            </template>
        </div>
        <!-- BUTTONS -->
        <div class="py-1 position-absolute top-0 end-0 me-3 toolbar-hoverable">
            <button type="button" title="Editar" noprefetch=""
                    class="btn btn-sm btn-icon btn-light-info"
                    @click="editor.openDrawer(row)"><span><i
                class="fa fa-edit"></i></span></button>
            <button type="button" title="Eliminar" noprefetch="" @click="editor.removeRow(row,$parent)"
                    class="btn btn-sm btn-icon btn-light-danger js-btn-delete"><span><i
                class="fa fa-trash"></i></span></button>
        </div>
        <!-- DRAWER -->
        <div :id="'popup_' + editor.sanitizedField() + '_' + prefix + editor.nextUnique(row)" @dblclick.stop="" @mousedown.stop=""
                class="bg-white drawer-container"
                data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-permanent="true"
                data-kt-drawer-escape="false"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="625px"
        >
            <div class="card rounded-0 w-100">
                <div class="card-header pe-5">
                    <h5 class="card-title">Editar Modulo</h5>
                    <!--begin::Close-->
                    <div
                        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        @click="editor.closeDrawer(row, $parent)" aria-label="Close">
                        <i class="bi bi-x-lg"></i>
                    </div>
                    <!--end::Close-->
                </div>
                <div class="card-body hover-scroll-overlay-y" v-if="editor.showDrawer">
                    <div class="row ml-2 mr-2">
                        <div v-for="(popup_field, key) in editor.formModal[row.type]"
                                :class="'form-group mb-6 ' + popup_field.class">
                            <label :for="key" class="form-label">{{
                                    popup_field.label
                                }}</label>

                            <cms-field-hub
                                :type="popup_field.type"
                                :popup_field="popup_field"
                                :row="row"
                                :field_type="key"
                                :prefix="prefix"
                                :field="field"
                                :idx="idx"
                                :editor="editor"></cms-field-hub>

                            <div
                                v-if="row.hasOwnProperty('errores') && row.errores.hasOwnProperty(key)"
                                class="fv-plugins-message-container invalid-feedback">
                                {{ row.errores[key] }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-light"
                            @click="editor.closeDrawer(row, $parent)">Cancelar
                    </button>
                    <button type="button" class="btn btn-primary"
                            @click="editor.saveDrawer(row)">Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- xxxxxxxxx -->
</template>

<script>
import draggable from "vuedraggable";
import CmsEditorView from './CmsEditorView'
import CmsFieldHub from './CmsFieldHub'



export default {
    name: 'cms-editor-item',
    components: {draggable, CmsEditorView, CmsFieldHub},
    props: {
        prefix: {type: String, default: ''},
        field: {type: String, default: ''},
        idx: {type: Number, default: 0},
        row: {type: Object, default: () => ({})},
        editor: {type: Object, default: () => ({})},
        items: {type: Array, default: () => []},
    },
    methods: {
        changeEvent: function (evt) {
            if (evt.hasOwnProperty('added')) {
                if (this.items[evt.added.newIndex].type == 'container') {
                    console.log('No se permite anidacion');
                    this.items.splice(evt.added.newIndex, 1)
                    return;
                }
                this.editor.showDrawer=false;
                this.$nextTick(() => {
                    this.editor.showDrawer = true;
                    this.editor.openDrawer(this.items[evt.added.newIndex]);
                });
            }
        },
    },

}
</script>

