<template>
    <div>
        <div :class="`click-prevent-layer ${savingLayerClass}`"></div>

        <div class="row">
            <div class="col">
                <p class="permissions-manager__intro" v-if="role">
                    {{ 'permission_component.grantsForRole' | trans }} <strong>{{ role.visible_name }}</strong>
                </p>
                <div v-if="user">
                    <small class="text-muted pull-right">
                        <i class="fa fa-fw fa-info-circle"></i>
                        {{ 'permission_component.showInheritedGroupGrants' | trans }}
                        <strong>{{ userRoleInfo.role.visible_name }}</strong>
                    </small>
                    <p>{{ 'permission_component.grantsForUser' | trans }} <strong>{{ user.name }}</strong></p>
                </div>
            </div>
        </div>

      <div class="row">
        <div class="col-12 col-md-4 permissions-manager__access">
          <strong class="permissions-manager__title">{{ 'permission_component.sectionGrants' | trans }}</strong>
          <span>
              <div class="m-input-icon m-input-icon--right position-relative">
                  <input autocomplete="off" type="text" name="section_search"
                         class="pe-10 form-control " placeholder="Buscar"
                         v-model="sectionSearch" @input.prevent.stop="performSectionSearch"
                         id="section-search">
                  <div class="position-absolute translate-middle-y top-50 end-0 me-3">
                      <span class="svg-icon"> <i class="fa fa-fw fa-search fa-lg"></i> </span>
                  </div>
              </div>
          </span>

          <div class="permission-siblings">
            <table class="table table-hover">
              <section-item
                  v-for="(section, index) in sectionSearchResults"
                  @sectionUpdated="sectionUpdated(section.id)"
                  :userRoleSection="userRoleInfo ? userRoleInfo.sections[index] : null"
                  :section="section"
                  :index="`0_${index}`"
                  :key="section.id"
                  :depth="0"
                  :nbReadOnly="nbReadOnly"/>
            </table>
          </div>
        </div>
        <div class="col-12 col-md-8 permissions-manager__auth">
          <strong class="permissions-manager__title">{{ 'permission_component.entityGrants' | trans }}</strong>
          <span>
            <div class="m-input-icon m-input-icon--right position-relative">
                  <input autocomplete="off" type="text" name="entity_search"
                         class="pe-10 form-control " placeholder="Buscar"
                         v-model="entitySearch" @input.prevent.stop="performEntitySearch"
                         id="entity-search">
                  <div class="position-absolute translate-middle-y top-50 end-0 me-3">
                      <span class="svg-icon"> <i class="fa fa-fw fa-search fa-lg"></i> </span>
                  </div>
              </div>
          </span>

          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th></th>
                <th v-for="permissionType in permissionTypes"
                    class="text-center permission-type text-nowrap">
                    <label class="form-check form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" :id="'permission-type-' + permissionType.id"
                        @input="updatePermissions($event, permissionType)" :disabled="nbReadOnly">
                        <span class="form-check-label">
                            {{ permissionType.name }}
                        </span>
                    </label>
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-for="entity of entitySearchResults">
                <permission-row
                    :key="entity.id"
                    :entity="entity"
                    :userRoleInfo="userRoleInfo"
                    :permissions="permissions"
                    :permissionTypes="permissionTypes"
                    :nbReadOnly="nbReadOnly"/>

                <permission-row
                    v-for="childrenEntity of entitySearchResults.filter(e => e.parent_id === entity.id)"
                    :key="'child-' + childrenEntity.id"
                    :entity="childrenEntity"
                    :userRoleInfo="userRoleInfo"
                    :permissions="permissions"
                    :permissionTypes="permissionTypes"
                    :nbReadOnly="nbReadOnly"/>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import sectionItem from './SectionItemComponent.vue'
  import permissionRow from './PermissionRowComponent.vue'
  import { EventBus } from '@/js/vue/services/event-bus'
  import Fuse from "fuse.js";
  import SpecialPermissionItem from "@/js/components/permissions/SpecialPermissionItemComponent";

  export default {
    name: 'permissions-manager',
    components: {
      SpecialPermissionItem,
      sectionItem,
      permissionRow,
    },
    data () {
      return {
        sectionSearch: null,
        specialSearch: null,
        entitySearch: null,
        specialSearchResults: [],
        sectionSearchResults: [],
        entitySearchResults: [],
      }
    },
    mounted () {
      this.sectionSearchResults = this.sections
      this.specialSearchResults = this.specialPermissions
      this.entitySearchResults = this.entities
    },
    methods: {
      setSectionPermissionToNull (sectionId) {
        this.$emit('setSectionPermissionToNull', sectionId)
      },
      sectionUpdated (sectionId) {
        EventBus.$emit('sectionUpdated', sectionId)
      },
      specialPermissionUpdated (specialPermissionId) {
        EventBus.$emit('specialPermissionUpdated', specialPermissionId)
      },
      performSectionSearch () {
        if (!this.sectionSearch || !this.sectionSearch.length) {
          this.sectionSearchResults = this.sections
        } else {
          this.sectionSearchResults = this.searchText(this.sections, this.sectionSearch, ['name', 'children.name'])
        }
      },
      performSpecialSearch () {
        if (!this.specialSearch || !this.specialSearch.length) {
          this.specialSearchResults = this.specialPermissions
        } else {
          this.specialSearchResults = this.searchText(this.specialPermissions, this.specialSearch, ['name', 'method_name'])
        }
      },
      performEntitySearch () {
        if (!this.entitySearch || !this.entitySearch.length) {
          this.entitySearchResults = this.entities
        } else {
          this.entitySearchResults = this.searchText(this.entities, this.entitySearch, ['name', 'visible_name'])
        }
      },
      searchText (list, text, keys) {
        return (new Fuse(list, {
          shouldSort: false,
          keys: keys,
          threshold: 0.3,
        })).search(text)
      },
      updatePermissions (ev, permissionType) {
        this.permissions.map(permission => {
          let permit = permission.permits.find(p => p.id === permissionType.id)
          if (permit) {
            permit.granted = ev.target.checked
          }
        })
      }
    },
    computed: {
      savingLayerClass () {
        return this.isSaving ? 'prominent' : ''
      },
    },
    props: {
      role: {
        default: null,
        validator: value => value instanceof Object,
      },
      user: {
        default: null,
        validator: value => value instanceof Object,
      },
      sections: {
        default: null,
        validator: value => value instanceof Array,
      },
      specialPermissions: {
        default: null,
        validator: value => value instanceof Array,
      },
      entities: {
        default: null,
        validator: value => value instanceof Array,
      },
      permissions: {
        default: null,
        validator: value => value instanceof Array,
      },
      permissionTypes: {
        default: null,
        validator: value => value instanceof Array,
      },
      userRoleInfo: {
        default: null,
        validator: value => value instanceof Object,
      },
      isSaving: {
        default: false,
        validator: value => typeof value === 'boolean',
      },
    },
  }
</script>

<style lang="scss" scoped>
a[aria-expanded] {
  text-decoration: none !important;
}

a[aria-expanded="false"] {
  .fa-angle-down {
    display: none !important;
  }

  .fa-angle-right {
    display: inline-block !important;
  }
}

a[aria-expanded="true"] {
  .fa-angle-down {
    display: inline-block !important;
  }

  .fa-angle-right {
    display: none !important;
  }
}

    table th, table td {
        overflow: hidden;
    }

    .list-group.list-group-root .list-group {
        margin-bottom: 0;
    }

    .list-group.list-group-root .list-group-item {
        border-radius: 0;
        border-width: 1px 0 0 0;
    }

    .list-group.list-group-root > .list-group-item:first-child {
        border-top-width: 1px;
    }

    .list-group.list-group-root {
        padding: 0;
        overflow: hidden;
    }

    .click-prevent-layer.prominent {
        background-color: #fff;
        opacity: 0;
        height: 100%;
        width: 100%;
        z-index: 5;
        left: 0;
        position: absolute;
        top: 0;
        cursor: wait;
        pointer-events: all;
    }

    .click-prevent-layer {
        z-index: -1;
    }
</style>
