<template>
    <div class="form-group m-form__group col-12 text-right">
        <button id="unlink-button" type="button" @click="unlinkDevice"
                class="btn btn-dark m-btn m-btn--icon-right" :disabled="nbReadOnly || unlinkStatus > 0">
            <span v-if="unlinkStatus === 0">Desvincular</span>
            <span v-else-if="unlinkStatus === 1">Desvinculando...</span>
            <span v-else>Desvinculado</span>
            <em class="fa fa-unlink"/>
        </button>
        <button id="desactivation-button" type="button" @click="disableDevice"
                class="btn btn-danger m-btn m-btn--icon-right" :disabled="nbReadOnly || disableStatus > 0">
            <span v-if="disableStatus === 0">Deshabilitar</span>
            <span v-else-if="disableStatus === 1">Deshabilitando...</span>
            <span v-else>Deshabilitado</span>
            <em class="fa fa-power-off"/>
        </button>
    </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'accreditation-disable-unlink-device',
    props: {
      id: { type: Number, required: true },
    },
    data () {
      return {
        unlinkStatus: 0,
        disableStatus: 0
      }
    },
    methods: {
      async unlinkDevice () {
        this.unlinkStatus = 1

        const confirmation = await this.$swal.fire({
          title: '¿Desvincular dispositivo?',
          text: 'Esta acción desvinculará el dispositivo del sistema y para utilizarlo deberá efectuar de nuevo la sincronización. ¿Proceder?',
          showCancelButton: true,
          type: 'question',
        })

        if (confirmation.value) {
          const response = await axios.patch(window.route('API.accreditation_device.unlink', { id: this.id }))
          if (response.status === 200) {
            this.unlinkStatus = 2
            await Swal.fire(
              'Dispositivo desvinculado',
              'El dispositivo se ha desvinculado correctamente',
              'success'
            )
          } else {
            this.unlinkStatus = 0
          }
        } else {
          this.unlinkStatus = 0
        }

      },
      async disableDevice () {
        this.disableStatus = 1

        const confirmation = await this.$swal.fire({
          title: '¿Desactivar dispositivo?',
          text: 'Una vez desactivado no podrá volver a activarse.',
          showCancelButton: true,
          type: 'question',
        })

        if (confirmation.value) {
          const response = await axios.patch(window.route('API.accreditation_device.disable', { id: this.id }))
          if (response.status === 200) {
            this.disableStatus = 2
            Swal.fire(
              'Dispositivo desactivado',
              'El dispositivo se ha desactivado correctamente',
              'success'
            ).then(async result => {
              //window.location.reload()
            })
          } else {
            this.disableStatus = 0
          }
        } else {
          this.disableStatus = 0
        }

      },
    },
  }
</script>
