<template>
  <div class="field is-marginless form-group mb-6">
    <label class="field__span form-label">{{ title }}</label>
    <multiselect
      v-model="selectedTags"
      :options="validTags"
      track-by="tagName"
      :disabled="disabled"
      label="tagName"
      :multiple="true"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tagsArray: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    emptyMessage: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },
  data () {
    return {
      filterText: '',
    }
  },
  computed: {
    selectedTags: {
      get () {
        return this.value ?? []
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    validTagsWithoutFilter () {
      const tags = this.tagsArray
        .filter(tag => {
          const alreadyAdded = this.selectedTags.find(selectedTag => tag.name === selectedTag.name)
          return !alreadyAdded
        })
      return _.sortBy(tags, ['tagName'])
    },
    validTags () {
      return this.filterText.length ? this.validTagsWithoutFilter.filter(tag =>
        tag.tagName
          .toString()
          .toLowerCase()
          .indexOf(this.filterText.toLowerCase()) >= 0
      ) : this.validTagsWithoutFilter
    },
  },
}
</script>
