<template>
    <div class="form-group m-form__group">
        <input type="hidden" name="contentBlocks" id="contentBlocks" :value="contentBlocksJSON"/>
        <div class="d-flex flex-row-reverse">
            <button class="btn btn-primary float-end mb-4" type="button" @click="addContentBlock">
                {{ 'page.addContentBlockButton' | trans }}
            </button>
        </div>
        <div class="form-label">{{ 'page.sections' | trans }}</div>
        <div class="list-group">
            <div class="list-group-item" v-for="(contentBlock, index) in contentBlocks" :key="index">
                <div class="row">
                    <div class="col-lg-9 col-xl-10">
                        <HtmlEditor v-model="contentBlock.html"
                                    :key="contentBlock.id"
                                    :value="contentBlock.html"
                                    :defaultLangValue="defaultLangValue"
                                    :field="field"
                                    :label="label"
                                    :height="height"
                                    :multiple="multiple"
                                    :name="name"
                                    :fieldItem="fieldItem"
                                    :disabled="disabled"
                                    :enableUnsubcribePlugin="enableUnsubcribePlugin"
                                    :helpMessage="helpMessage"
                                    :colorList="colorList"
                                    :buttons="buttons"
                        />
                    </div>
                    <div class="col-lg-3 col-xl-2">
                        <div class="row align-items-end">
                            <div class="col-md-9 col-lg-12">
                                <HtmlParameters v-model="contentBlock.parameters"/>
                            </div>
                            <div class="col-md-3 col-lg-12">
                                <button class="btn btn-sm btn-danger mb-4 d-block w-100"
                                        type="button"
                                        @click="deleteContentBlock(index)"
                                        :id="'delete-block-' + index"
                                >
                                    {{ 'page.deleteContentBlockButton' | trans }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NbErrorBlock from '@/js/components/error_block/nb-error-block'
import HtmlEditor from '@/js/components/html_editor/HtmlEditor'
import HtmlParameters from '@/js/components/html_editor/HtmlParameters'
import {WidthParameters, BackgroundColorParameters} from '@/js/enums/Parameters'

export default {
    name: 'CmsPageContentBlocks',
    components: {
        NbErrorBlock,
        HtmlEditor,
        HtmlParameters
    },
    props: {
        currentContentBlocks: {
            type: Array,
            default: () => [],
        },
        value: {type: String, default: ''},
        defaultLangValue: {type: String, default: ''},
        field: {type: String, default: 'content'},
        label: {type: String, default: ''},
        height: {type: Number, default: 350},
        multiple: {Boolean, default: true},
        name: {String, default: ''},
        fieldItem: {type: String, default: ''},
        disabled: {Boolean, default: false},
        enableUnsubcribePlugin: {Boolean, default: false},
        helpMessage: {type: String, default: null},
        colorList: {type: Array, default: () => ['000', '111', '222', 'ffea00']},
        buttons: {
            type: Array,
            default: () => [
                ['viewHTML'],
                ['undo', 'redo'], // Only supported in Blink browsers
                ['formatting'],
                ['fontfamily'],
                ['fontsize'],
                ['strong', 'em', 'del'],
                ['superscript', 'subscript'],
                ['link'],
                ['emoji'],
                ['insertImage'],
                ['table'],
                ['foreColor', 'backColor'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
                ['repositoryFile'],
                ['fullscreen'],
            ],
        },
    },
    data() {
        return {
            contentBlocks: [],
            BackgroundColorParameters,
            WidthParameters,
            defaultParameters: {
                background_color: BackgroundColorParameters.White,
                width: WidthParameters.Content
            },
        }
    },
    computed: {
        contentBlocksJSON() {
            return JSON.stringify(this.contentBlocks)
        },
    },
    created() {
        this.contentBlocks = this.currentContentBlocks.map(contentBlock => {
            contentBlock.parameters = contentBlock.parameters ? JSON.parse(contentBlock.parameters) : this.defaultParameters
            return contentBlock
        })
    },
    methods: {
        addContentBlock() {
            this.contentBlocks.push(this.getEmptyContentBlock())
        },
        getEmptyContentBlock() {
            return {
                html: '',
                parameters: this.defaultParameters
            }
        },
        deleteContentBlock(index) {
            console.log(index)
            this.contentBlocks.splice(index, 1)
        },
    }
}
</script>

<style scoped>

</style>
