<template>
  <div class="layer-properties__color-graph  row mb-2 g-1 g-md-2">
    <div class="col">
      <div class="input-group mb-5">
        <span class="input-group-text"> {{ 'statistics.colors.selectColor' | trans }}</span>
        <div type="text" class="form-control p-0">
          <Swatches v-model="color"
                    :swatches="colors"
                    popover-x="right"
                    popover-y="top"
                    shapes="circles"
                    show-border
                    show-fallback
                    fallback-input-type="color"
                    :trigger-style="swatchesTriggerStyle"
                    :fallback-ok-text="$options.filters.trans('statistics.swatches.buttonText')"
                    :disabled="readonly"
                    class="is-marginless d-block"
          />
        </div>
      </div>
    </div>
    <div class="layer-properties__color-actions col-auto">
      <button class="btn btn-icon btn-light-danger" type="button" :disabled="readonly" @click="emitDelete"><i
          class="fas fa-minus"></i></button>
    </div>
  </div>
</template>
<script>
import Swatches from 'vue-swatches'

import SwatchesMixin from '@/js/mixins/Swatches'
import {colors, hexServerToString, stringToHexServer} from '@/js/helpers/ColorHelpers'

export default {
  name: 'StatisticsColors',
  components: {Swatches},
  mixins: [SwatchesMixin],
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    // readonly: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      colors,
      readonly: false,
    }
  },
  computed: {
    color: {
      get() {
        return this.value.color ?? null
      },
      set(value) {
        this.emit({color: value})
      },
    },
  },
  methods: {
    hexServerToString(color) {
      return hexServerToString(color)
    },
    stringToHexServer(color) {
      return stringToHexServer(color)
    },
    messageColor(color) {
      return color ? null : this.$options.filters.trans('statistics.colors.selectColor')
    },
    deleteRow(index) {
      this.selectedColors.splice(index, 1)
      this.emit()
    },
    emitDelete() {
      this.$emit('delete-color')
    },
    emit(value) {
      this.$emit('input', value)
    },
  },
}
</script>
