var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-xxl" }, [
    _c(
      "div",
      { staticClass: "row gy-2" },
      _vm._l(_vm.sections, function (section) {
        return _c(
          "div",
          {
            key: "section-" + section.id,
            staticClass:
              "col-lg-10 col-xl-8 col-xxl-6 offset-lg-1 offset-xl-2 offset-xxl-3",
          },
          [
            _c("div", { staticClass: "card card-xl-stretch" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("menu-item", {
                    attrs: {
                      item: section,
                      favorited: _vm.isFavorite(section),
                      "show-arrow": false,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }