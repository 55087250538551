var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-5 mx-4 mb-0" },
    [
      _c(
        "div",
        {
          staticClass:
            "col-12 mb-4 text-end c-survey-component__btn-add-option",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary m-btn m-btn--icon",
              attrs: {
                type: "button",
                disabled: _vm.nbReadOnly,
                id: "add-option",
              },
              on: { click: _vm.addSurvey },
            },
            [
              _c("i", { staticClass: "fa fa-plus" }),
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("trans")(
                      "conference.properties.property_surveyable.add_survey"
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.surveys.length
        ? _c("div", { staticClass: "fs-5 fw-bolder alert text-center my-20" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("trans")(
                    "conference.properties.property_surveyable.no_surveys"
                  )
                ) +
                "\n    "
            ),
          ])
        : _c("ConferencePropertySurveyableSurvey", {
            attrs: {
              "property-key": _vm.propertyKey,
              "survey-array": _vm.surveyArray,
            },
            model: {
              value: _vm.surveys,
              callback: function ($$v) {
                _vm.surveys = $$v
              },
              expression: "surveys",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }