<template>
  <div class="d-flex">
    <multiselect
      v-model="typeSelected"
      :options="typesArray"
      label="name"
      track-by="name"
    >
    </multiselect>
    <input type="hidden" name="entityType" v-model="entityType" />

    <input
      class="form-control "
      :disabled="typeSelected.name === allTypes"
      type="text"
      name="type"
      v-model="entityName"
    />

    <input type="hidden" name="entityName" v-model="entityName" />
  </div>
</template>

<script>
export default {
  name: "entity-type",
  props: {
    typesArray: Array,
    allTypes: String,
    typeOld: String,
    entityNameOld: String,
  },
  data() {
    return {
      entityName: null,
      typeSelected: null,
    };
  },
  created() {
    this.typeSelected =
      this.typeOld !== null
        ? this.typesArray.find((type) => type.name === this.typeOld)
        : this.typesArray[0];
    this.entityName = this.entityNameOld;
  },
  computed: {
    entityType() {
      return this.typeSelected.name;
    },
  },
  watch: {
    typeSelected(value) {
      if (value.name === this.allTypes) {
        this.entityName = null;
      }
    },
  },
};
</script>
