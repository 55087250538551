var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c("input", {
      attrs: { name: "sections", type: "hidden" },
      domProps: { value: _vm.listSectionsJSON },
    }),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: "locale" },
      domProps: { value: _vm.locale },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
      _c("h4", { staticClass: "d-flex align-items-center mb-5" }, [
        _c("em", { staticClass: "flaticon-list-2 mr-1" }),
        _vm._v(_vm._s(_vm._f("trans")("cms_header.section")) + "\n        "),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "mb-5 btn btn-primary m-btn btn-sm m-btn m-btn--icon",
          attrs: { type: "button", id: "add-link", disabled: _vm.nbReadOnly },
          on: {
            click: function ($event) {
              return _vm.addNewSection()
            },
          },
        },
        [_vm._m(0)]
      ),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table border gx-4 table-row-bordered" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [
            _vm._v(
              "\n                    " +
                _vm._s(_vm._f("trans")("cms_header.name")) +
                "* (" +
                _vm._s(_vm.locale.toUpperCase()) +
                ")\n                "
            ),
          ]),
          _vm._v(" "),
          _c("th", { attrs: { width: "1%" } }),
        ]),
      ]),
      _vm._v(" "),
      _vm.sections.length
        ? _c(
            "tbody",
            _vm._l(_vm.sections, function (section, index) {
              return _c("tr", [
                _c(
                  "td",
                  [
                    _c("label", {
                      staticClass: "sr-only",
                      attrs: { for: "name-link-" + index },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: section.name,
                          expression: "section.name",
                        },
                      ],
                      staticClass: "form-control-solid form-control m-input",
                      attrs: {
                        id: "name-link-" + index,
                        type: "text",
                        placeholder: "Nombre",
                        "aria-describedby": "basic-addon2",
                        disabled: _vm.nbReadOnly,
                      },
                      domProps: { value: section.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(section, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("nb-error-block", {
                      attrs: { path: `sections.${index}.name` },
                    }),
                    _vm._v(" "),
                    _vm.locale !== "es"
                      ? _c("p", { staticClass: "m-form__help pl-2" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(section.defaultLangValue) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                      attrs: {
                        type: "button",
                        id: "delete-link-" + index,
                        disabled: _vm.nbReadOnly,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteLink(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-minus p-0" })]
                  ),
                ]),
              ])
            }),
            0
          )
        : _c("tbody", [_vm._m(1)]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-plus" }),
      _vm._v(" "),
      _c("span", [_vm._v("Añadir sección")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center py-6", attrs: { colspan: "5" } }, [
        _c("strong", { staticClass: "text-muted text-uppercase" }, [
          _vm._v("Sin registros"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }