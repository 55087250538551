var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal fade",
      class: _vm.getCurrentClassesModal,
      attrs: {
        tabindex: _vm.keyboard ? "-1" : false,
        role: "dialog",
        "aria-labelledby": "modelTitleId",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          class: _vm.getCurrentClassesModalDialog,
          attrs: { role: "document" },
        },
        [
          _vm.visible
            ? _c(
                "div",
                { staticClass: "modal-content" },
                [
                  _c(
                    "loader",
                    { attrs: { loading: _vm.loading } },
                    [
                      !!this.$slots.header ||
                      !!this.$slots.title ||
                      _vm.buttonClose ||
                      _vm.buttonMaximize
                        ? _c(
                            "div",
                            {
                              staticClass: "modal-header",
                              class: _vm.classesHeader,
                            },
                            [
                              !!this.$slots.title
                                ? _c(
                                    "h4",
                                    { staticClass: "modal-title" },
                                    [_vm._t("title")],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._t("header"),
                              _vm._v(" "),
                              _vm.buttonMaximize
                                ? _c("button", {
                                    staticClass: "maximize",
                                    attrs: {
                                      type: "button",
                                      "aria-label": "Maximize",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.maximized = !_vm.maximized
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.buttonClose
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-icon btn-sm btn-active-light-primary ms-2",
                                      attrs: {
                                        type: "button",
                                        "aria-label": "Close",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleModal(false)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "svg-icon svg-icon-2x" },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                              },
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  opacity: "0.5",
                                                  x: "6",
                                                  y: "17.3137",
                                                  width: "16",
                                                  height: "2",
                                                  rx: "1",
                                                  transform:
                                                    "rotate(-45 6 17.3137)",
                                                  fill: "black",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("rect", {
                                                attrs: {
                                                  x: "7.41422",
                                                  y: "6",
                                                  width: "16",
                                                  height: "2",
                                                  rx: "1",
                                                  transform:
                                                    "rotate(45 7.41422 6)",
                                                  fill: "black",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", { attrs: { loading: _vm.refreshing } }, [
                        _c(
                          "div",
                          { staticClass: "modal-body", class: _vm.classesBody },
                          [_vm._t("default")],
                          2
                        ),
                        _vm._v(" "),
                        !!this.$slots.footer
                          ? _c(
                              "div",
                              {
                                staticClass: "modal-footer",
                                class: _vm.classesFooter,
                              },
                              [_vm._t("footer")],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }