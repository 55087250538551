<template>
    <modal-component :theRef="'modalReset'" :visible.sync="showModal" :backdrop="true" :classesFooter="['text-right']"
        :classesModalDialog="['modal-dialog-centered modal-lg']">
        <template #header>
            {{ 'forms_shippings.reset' | trans }}
        </template>
        <template>
            <div class="m-alert m-alert--icon m-alert--outline alert alert-danger alert-dismissible fade show mb-0"
                role="alert">
                <div class="m-alert__icon">
                    <i class="la la-warning"></i>
                </div>
                <div class="m-alert__text">
                    {{ 'forms_shippings.reset_message' | trans }}
                </div>
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-danger" @click="deleteResource()" id="reset">
                <i class="fa fa-power-off mr-1"></i>
                {{ 'forms_shippings.reset_button' | trans }}
            </button>
        </template>
    </modal-component>
</template>

<script>
 export default {
     name: 'ModalReset',
     data() {
         return {
             showModal: false,
         }
     },
 }
</script>