<template>
    <div class="form-group m-form__group col-md-12">
        <table class="table m-table table-striped table-bordered">
            <thead>
            <tr>
                <th colspan="2">Soportes</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(support, supportId) in supports">
                <td style="width: 200px;" class="align-middle">
                    {{ support }}
                </td>
                <td>
                    <select :name="fieldName(supportId)" class="js-select2 form-select "
                            data-allow-clear="true" :disabled="nbReadOnly" :id="fieldName(supportId)">
                        <option value=""></option>
                        <option v-for="(ticket, ticketId) in tickets" :value="ticketId"
                                :selected="selectOption(supportId, ticketId)">{{ ticket }}
                        </option>
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
 // TODO: EDITOR VISUAL Generalizar, creo que se quitó el componente del formulario, restaurar si es necesario
  export default {
    name: 'general-template',
    props: {
      supports: { type: Object, default: () => {} },
      tickets: { type: Object, default: () => {} },
      values: { type: Object, default: () => {} },
    },
    methods: {
      fieldName (supportId) {
        return `support_ticket[${supportId}]`
      },
      selectOption (supportId, ticketId) {
        if (this.values.hasOwnProperty(supportId)) {
          return parseInt(this.values[supportId]) === parseInt(ticketId)
        }

        return false
      }
    }
  }
</script>

<style scoped>

</style>
