var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CmsSection", {
        attrs: {
          sections: _vm.arraySections,
          locale: _vm.locale,
          defaultLangValue: _vm.defaultLangValue,
        },
        on: { addSection: _vm.addSection },
      }),
      _vm._v(" "),
      _c("CmsSelectorPage", {
        attrs: {
          links: _vm.links,
          pages: _vm.pages,
          sections: _vm.arraySections,
          locale: _vm.locale,
          defaultLangValue: _vm.defaultLangValue,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }