<template>
    <div class="col-8 mb-3">
        <multiselect v-model="surveySelected" :options="surveyOptions" label="name" track-by="id" />

        <SurveyResponsesComponent
            v-if="!loading"
            :surveyStructure="surveySelected.structure"
            :responses="responses"
            :showFilter="false"
        />
    </div>
</template>

<script>
import SurveyResponsesComponent from '@/js/components/survey/SurveyResponsesComponent'

export default {
    name: 'ConferenceSurveyDashboard',
    components: {
        SurveyResponsesComponent
    },
    props: {
        surveyOptions: {
            type: Array,
            default: () => []
        },
        surveyDefault: {
            type: Number,
            default: () => { }
        },
        conferenceId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            surveySelected: null,
            responses: [],
            loading: true
        }
    },
    created() {
        this.surveySelected = this.surveyOptions.find(survey => survey.id === this.surveyDefault)
    },
    watch:{
        surveySelected(){
            this.getResponses()
        }
    },
    methods: {
        async getResponses() {
            this.loading = true;
            const response = await axios.get(
                `/api/conferences/${this.conferenceId}/survey/${this.surveySelected.id}/responses`
            );

            this.responses = response.data;
            this.loading = false;
        }
    }
}
</script>
