var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.children.length && (_vm.passesFilter || !_vm.filtering)
    ? _c(
        "div",
        {
          staticClass: "menu-item",
          class: {
            "m-menu__item--submenu menu-accordion": true,
            "m-menu__item--active": _vm.item.isActive || !_vm.showArrow,
            "m-menu__item--expanded": _vm.item.isActive || !_vm.showArrow,
            "m-menu__item--open":
              _vm.item.isActive || !_vm.showArrow || _vm.childrenPassesFilter,
            show:
              _vm.item.isActive || !_vm.showArrow || _vm.childrenPassesFilter,
            "is-found": _vm.passesFilter,
          },
          attrs: {
            "data-menu-section-id": _vm.item.attributes.id,
            "data-menu-submenu-toggle": "hover",
            "aria-haspopup": "true",
            "data-kt-menu-sub": "accordion",
            "data-kt-menu-trigger": "click",
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "menu-link m-menu__toggle",
              attrs: {
                href: "javascript:",
                id: "menu-items_" + _vm.item.attributes.unique_id,
              },
            },
            [
              _vm.item.attributes.icon
                ? _c("em", {
                    staticClass: "menu-icon m-menu__link-icon",
                    class: _vm.item.attributes.icon,
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.item.attributes.icon
                ? _c("span", { staticClass: "menu-bullet" }, [
                    _c("span", { staticClass: "bullet bullet-dot" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "menu-title" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.item.title) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.showArrow
                ? _c("span", { staticClass: "menu-arrow" })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "m-menu__submenu menu-sub menu-sub-accordion menu-active-bg",
            },
            [
              _c("span", { staticClass: "m-menu__arrow" }),
              _vm._v(" "),
              _vm._l(_vm.item.children, function (child) {
                return _c("menu-item", {
                  key: child.id,
                  attrs: {
                    item: child,
                    favorited: _vm.isFavorite(child),
                    favorites: _vm.favorites,
                    "show-arrow": _vm.showArrow,
                  },
                })
              }),
            ],
            2
          ),
        ]
      )
    : _vm.passesFilter || !_vm.filtering
    ? _c(
        "div",
        {
          staticClass: "menu-item position-relative",
          class: {
            "m-menu__item--submenu menu-accordion": false,
            "m-menu__item--active": _vm.item.isActive,
            "m-menu__item--expanded": _vm.item.isActive,
            "m-menu__item--open": _vm.item.isActive || _vm.childrenPassesFilter,
            show: _vm.item.isActive || _vm.childrenPassesFilter,
            "is-found": _vm.passesFilter,
            "fst-italic": _vm.item.attributes.has_tenant,
          },
          attrs: {
            "data-menu-section-id": _vm.item.attributes.id,
            "data-menu-submenu-toggle": "hover",
            "aria-haspopup": "true",
          },
        },
        [
          _c("em", {
            staticClass:
              "m-menu__link-star position-absolute top-50 end-0 translate-middle-y px-7 py-4 fs-6 fa-star text-inverse-light text-hover-inverse-dark cursor-pointer",
            class: {
              "fas rotate-left": _vm.isFavorited,
              "far rotate-right": !_vm.isFavorited,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.isFavorited
                  ? _vm.unfavorite(_vm.item)
                  : _vm.favorite(_vm.item)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "menu-link",
              attrs: {
                href: _vm.getUrl(_vm.item),
                id: "item-children",
                id: "menu-item_" + _vm.item.attributes.unique_id,
              },
            },
            [
              _vm.item.attributes.icon
                ? _c("em", {
                    staticClass: "menu-icon m-menu__link-icon fa-fw",
                    class: _vm.item.attributes.icon,
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.item.attributes.icon
                ? _c("span", { staticClass: "menu-bullet" }, [
                    _c("span", { staticClass: "bullet bullet-dot" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "menu-title" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.item.title) + "\n        "
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }