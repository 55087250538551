<template>
    <div class="form-group m-form__group col-6">
        <div class="d-flex flex-row" v-if="!live">
            <input type="hidden" :name="`${name}_checkbox`" :value="customizeCheckbox ? 1 : 0">
            <div class="p-2">
                <label class="m-checkbox">
                    <input type="checkbox" @click="changeCheckBox(true)" title="Modificable" :disabled="nbReadOnly"
                           v-model="customizeCheckbox" :id="'customize-check-' + prefix + name"> {{label}}
                    <span></span>
                </label>
            </div>
        </div>
        <label v-else>
            {{label}}
        </label>
        <input type="hidden" :name="`${prefix}[${name}]`" v-model="usedFilterJSON" ref="saveField">
        <div class="row">
            <div class="col-5 col-select-one">
                <select class="form-select " size="5" :disabled="nbReadOnly" @dblclick="moveData()"
                        v-model="optionFilters" :multiple="multiple === E_SELECT_MULTIPLE"
                        :id="'filters-' + prefix + name">
                    <option v-for="(text, value) in optionFiltersList" :value="value">
                        {{text}}
                    </option>
                </select>
                <div class="row">
                    <div class="col-6">
                        <span class="m-form__help">{{ 'report_groupings.no_include' | trans }}</span>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="m-btn btn btn-sm btn-link" :disabled="nbReadOnly"
                                @click="moveAllData()" :id="'move-all-filters-' + prefix + name">
                            {{ 'report_groupings.include_all' | trans }}
                            <i class="fa fa-angle-double-right"></i>
                        </button>
                    </div>
                </div>
                <nb-error-block :path="`filters.${name}`"></nb-error-block>
            </div>

            <div class="col-2 col-select-center">
                <div class="btn-group-vertical">
                    <button type="button" class="btn btn-outline-metal btn-sm" :disabled="nbReadOnly"
                            @click="moveData()" :id="'move-filters-' + prefix + name">
                        <i class="fa fa-angle-right"></i>
                    </button>

                    <button type="button" class="btn btn-outline-metal btn-sm" :disabled="nbReadOnly"
                            @click="returnData()" :id="'return-filters-' + prefix + name">
                        <i class="fa fa-angle-left"></i>
                    </button>
                </div>
            </div>

            <div class="col-5 col-select-one">
                <select class="form-select " size="5" @dblclick="returnData()" v-model="usedFilters"
                        :disabled="nbReadOnly" :multiple="multiple === E_SELECT_MULTIPLE"
                        :id="'used-filters-' + prefix + name">
                    <option v-for="id in usedFiltersList" :value="id">
                        {{optionsAux[id]}}
                    </option>
                </select>
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="m-btn btn btn-sm btn-link" :disabled="nbReadOnly"
                                @click="returnAllData()" :id="'return-all-filters-' + prefix + name">
                            <i class="fa fa-angle-double-left"></i>
                            {{ 'report_groupings.remove_all' | trans }}
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <span class="m-form__help">{{ 'report_groupings.include' | trans }}</span>
                    </div>
                </div>
                <nb-error-block :path="`filters.${name}`"></nb-error-block>
            </div>
        </div>
    </div>
</template>
<script>

  const E_SELECT_MULTIPLE = 5
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'list-box-fields',
    props: {
      options: { type: Array, default: [] },
      live: { type: Boolean, default: false },
      prefix: { type: String, default: '' },
      name: { type: String, default: '' },
      label: { type: String, default: '' },
      customizable: { type: Boolean, default: false },
      multiple: { type: Number, default: 0 },
      default_values: { type: Array, default: [] },
      all_selected_null: { type: Boolean, default: true },
    },
    created: function () {
      this.options.forEach(option => {
        this.optionFiltersList[parseInt(option.value)] = option.text
      })
      this.optionsAux = this.optionFiltersList
      this.customizeCheckbox = this.customizable

      if (this.default_values.length > 0) {
        this.usedFiltersList = this.default_values
        this.optionFiltersList = this.setOptionFilters(this.default_values, false)
      }

      this.changeCheckBox()
    },
    computed: {
      usedFilterJSON () {
        $(this.$refs.saveField).trigger('change')
        if (this.all_selected_null && Object.keys(this.optionFiltersList).length <= 0) {
          return null
        }
        return JSON.stringify(this.usedFiltersList.map(filter => parseInt(filter)))
      }
    },
    data () {
      return {
        optionsAux: [],
        optionFilters: [],
        usedFilters: [],
        usedFiltersList: [],
        optionFiltersList: {},
        E_SELECT_MULTIPLE: E_SELECT_MULTIPLE,
        customizeCheckbox: false
      }
    }
    ,
    methods: {
      moveAllData () {
        let values = this.options.map(
          (obj) => {
            return obj.value
          }
        )

        this.moveDataOption(values)
      },
      moveData () {
        let values = this.usedFiltersList.concat(this.optionFilters)

        this.moveDataOption(values)
      },
      moveDataOption (data) {
        this.usedFiltersList = data
        this.optionFilters = []

        this.optionFiltersList = this.setOptionFilters(this.usedFiltersList, false)
      },
      returnAllData () {
        this.moveDataOption([])
      },
      returnData () {
        let optionFilterKeys = Object.keys(this.optionFiltersList)
          .concat(this.usedFilters)

        this.usedFilters = []

        this.optionFiltersList = this.setOptionFilters(optionFilterKeys, true)

        this.usedFiltersList = Object.keys(this.optionsAux)
          .filter(key => !optionFilterKeys.map(option => parseInt(option)).includes(parseInt(key)))
      },
      setOptionFilters (keys, include) {
        keys = keys.map(key => parseInt(key))
        return Object.keys(this.optionsAux)
          .filter(key => include ? keys.includes(parseInt(key)) : !keys.includes(parseInt(key)))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: this.optionsAux[key]
            }
          }, {})
      },
      changeCheckBox (change = false) {

        if (change) {
          this.customizeCheckbox = !this.customizeCheckbox
        }
      }
    }
  }
</script>

<style scoped>
    .col-select-one {
        flex: 0 0 45%;
        max-width: 45%;
    }

    .col-select-one:first-child {
        padding-right: 0px;
    }

    .col-select-one:last-child {
        padding-left: 0px;
    }

    .col-select-center {
        flex: 0 0 10%;
        max-width: 10%;
        text-align: center !important;
        margin-top: 25px;
    }
</style>
