var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-status" }, [
    _c(
      "div",
      {
        staticClass: "m-portlet__head-btn-container d-inline-flex text-nowrap",
      },
      [
        _c("span", [
          _vm.active
            ? _c(
                "span",
                { staticClass: "badge m-badge pl-2 pr-2 campaign-status" },
                [
                  _vm._v("\n        Activa\n        "),
                  _vm.recurrent
                    ? _c("em", {
                        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                        staticClass: "fa fa-fw fa-clock-o",
                        attrs: { title: "Con programación" },
                      })
                    : _vm.triggerEnabled
                    ? _c("em", {
                        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                        staticClass: "fa fa-fw fa-hand-o-right",
                        attrs: { title: "Con disparador" },
                      })
                    : _vm._e(),
                ]
              )
            : _c(
                "span",
                { staticClass: "badge m-badge pl-2 pr-2 campaign-status" },
                [_vm._v("Inactiva")]
              ),
          _vm._v(" "),
          _vm.active
            ? _c(
                "button",
                {
                  directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                  staticClass:
                    "mr-1 btn btn-sm bg-danger m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white",
                  attrs: {
                    title: "Desactivar campaña",
                    type: "button",
                    disabled: _vm.nbReadOnly,
                    id: "deactivate-campaign" + _vm.campaignId,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.deactivateCampaign.apply(null, arguments)
                    },
                  },
                },
                [_c("em", { staticClass: "fa fa-fw fa-stop" })]
              )
            : _c(
                "button",
                {
                  directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                  staticClass:
                    "mr-1 btn btn-sm bg-success m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white",
                  attrs: {
                    title: "Activar campaña",
                    type: "button",
                    disabled: _vm.nbReadOnly,
                    id: "activate-campaign" + _vm.campaignId,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.activateCampaign.apply(null, arguments)
                    },
                  },
                },
                [_c("em", { staticClass: "fa fa-fw fa-play" })]
              ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [{ name: "tooltip", rawName: "v-tooltip" }],
              staticClass:
                "mr-1 btn btn-sm m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white bg-primary",
              attrs: {
                type: "button",
                title: "Generar copia",
                id: "duplicate-campaign-" + _vm.campaignId,
                disabled: _vm.nbReadOnly,
              },
              on: { click: _vm.duplicateCampaign },
            },
            [_c("em", { staticClass: "fa fa-fw fa-files-o" })]
          ),
          _vm._v(" "),
          !_vm.inForm
            ? _c(
                "button",
                {
                  directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                  staticClass:
                    "mr-1 btn btn-sm m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white bg-danger",
                  attrs: {
                    type: "button",
                    title: "Borrar",
                    id: "remove-campaign-" + _vm.campaignId,
                    disabled: _vm.nbReadOnly,
                  },
                  on: { click: _vm.removeCampaign },
                },
                [_c("em", { staticClass: "fa fa-fw fa-trash" })]
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }