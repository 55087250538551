<template>
    <div class="form-group m-form__group col-12">
        <input name="sections" type="hidden" :value="listSectionsJSON" />
        <input type="hidden" name="locale" :value="locale" />
        <div class="d-sm-flex justify-content-between">
            <h4 class="d-flex align-items-center mb-5">
                <em class="flaticon-list-2 mr-1"></em
                >{{ "cms_header.section" | trans }}
            </h4>
            <button
                type="button"
                class="mb-5 btn btn-primary m-btn btn-sm m-btn m-btn--icon"
                id="add-link"
                @click="addNewSection()"
                :disabled="nbReadOnly"
            >
                <span>
                    <i class="fa fa-plus"></i>
                    <span>Añadir sección</span>
                </span>
            </button>
        </div>
        <table class="table border gx-4 table-row-bordered">
            <thead>
                <tr>
                    <th >
                        {{ "cms_header.name" | trans }}* ({{
                            locale.toUpperCase()
                        }})
                    </th>
                    <th width="1%"></th>
                </tr>
            </thead>
            <tbody v-if="sections.length">
                <tr v-for="(section, index) in sections">
                    <td>
                        <label
                            :for="'name-link-' + index"
                            class="sr-only"
                        ></label>
                        <input
                            :id="'name-link-' + index"
                            type="text"
                            class="form-control-solid form-control m-input"
                            placeholder="Nombre"
                            aria-describedby="basic-addon2"
                            :disabled="nbReadOnly"
                            v-model="section.name"
                        />
                        <nb-error-block
                            :path="`sections.${index}.name`"
                        ></nb-error-block>
                        <p v-if="locale !== 'es'" class="m-form__help pl-2">
                            {{ section.defaultLangValue }}
                        </p>
                    </td>
                    <td class="align-middle text-center">
                        <button
                            type="button"
                            class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                            :id="'delete-link-' + index"
                            :disabled="nbReadOnly"
                            @click="deleteLink(index)"
                        >
                            <i class="fa fa-minus p-0"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="5" class="text-center py-6">
                        <strong class="text-muted text-uppercase"
                            >Sin registros</strong
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
    name: "CmsSection",
    components: { NbErrorBlock },
    props: {
        sections: {
            type: Array,
            default: () => []
        },
        locale: {
            type: String
        },
        defaultLangValue: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
        };
    },
    computed: {
        listSectionsJSON() {
            return JSON.stringify(
                this.sections.map(section => {
                    return {
                        id: section.id,
                        name: section.name,
                    };
                })
            );
        }
    },
    methods: {
        addNewSection() {
            console.log('addNewSection');
            this.$emit("addSection");
        },
        deleteLink(index) {
            let section = this.sections[index].section;
            let order = this.sections[index].order ? this.sections[index].order.id : 1;

            this.sections.splice(index, 1);

            for (let i = 0; i < this.sections.length; i++) {
                if (
                    this.sections[i].section == section &&
                    this.sections[i].order.id > order
                ) {
                    this.sections[i].order.id =
                        this.sections[i].order.id - 1;
                }
            }
        },
        // TODO: Hacer la parte de esta traducción mejor
        getLocale(id, locale, name) {
            let lang = null;
            if (id !== null) {
                let object = this.sections.find(object => object.id === id);
                console.log(object);
                if (object.translations !== undefined) {
                    lang = object.translations.find(
                        translation => translation.locale === locale
                    );
                    if (lang !== undefined) {
                        lang = lang.name;
                    }
                } else {
                    lang = object.name;
                }
            }else{
                lang=name;
            }
            return lang;
        }
    }
};
</script>

<style scoped></style>
