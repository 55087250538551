<template>
    <div class="row row-cols-1 row-cols-md-auto mt-5 mx-4 mb-0">
        <div class="col">
            <label class="d-block">
                <span class="fw-bold">Desde</span>
                <input v-model="startDate"
                       type="datetime-local"
                       class="form-control mb-4 w-100"
                       :disabled="nbReadOnly"
                       placeholder="Inicio"
                       name="startDate"
                />
                <NbErrorBlock :path="`properties.${propertyKey}.start_date`"/>
            </label>
        </div>
        <div class="col">
            <label class="d-block">
                <span class="fw-bold">Hasta</span>
                <input v-model="endDate"
                       type="datetime-local"
                       class="form-control mb-4 w-100"
                       :disabled="nbReadOnly"
                       placeholder="Inicio"
                       name="endDate"
                />
                <NbErrorBlock :path="`properties.${propertyKey}.end_date`"/>
            </label>
        </div>
    </div>
</template>


<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import StartAndEndDate from '@/js/components/conference/properties/StartAndEndDates';

export default {
  name: "ConferencePropertySchedule",
  mixins: [ StartAndEndDate ],
  props: {
    lang: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      required: true
    },
    propertyKey: {
      type: String,
      required: true
    }
  },
  components: {
    NbErrorBlock
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    emit (value) {
      this.$emit('input', { ...this.value, ...value })
    },
    parseDate (date) {
      return moment(date).format('YYYY-MM-DDTHH:mm')
    }
  }
}
</script>
