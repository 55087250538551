var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "form-control m-input btn btn-secondary",
          attrs: { type: "button", id: "sort" },
          on: {
            click: function ($event) {
              _vm.showModal = true
            },
          },
        },
        [_vm._v("\n        Ordenar\n    ")]
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            theRef: "sortableModal",
            id: `sortable${_vm._uid}`,
            visible: _vm.showModal,
            backdrop: true,
            classesBody: ["text-center js-body-sortable"],
            classesModalDialog: ["modal-dialog-centered"],
          },
          on: {
            "update:visible": function ($event) {
              _vm.showModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_vm._v("\n            Ordenar\n        ")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", id: "close-modal" },
                      on: {
                        click: function ($event) {
                          _vm.showModal = false
                        },
                      },
                    },
                    [_vm._v("\n                Cerrar\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", id: "save" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            _c(
              "SlickList",
              {
                staticClass: "o-ordered-list",
                attrs: { lockAxis: "y" },
                model: {
                  value: _vm.itemsSort,
                  callback: function ($$v) {
                    _vm.itemsSort = $$v
                  },
                  expression: "itemsSort",
                },
              },
              _vm._l(_vm.itemsSort, function (item, index) {
                return _c(
                  "SlickItem",
                  {
                    key: index,
                    staticClass:
                      "draggable-handle border-bottom p-4 o-ordered-list__item text-start",
                    staticStyle: { cursor: "grab" },
                    attrs: { index: index },
                  },
                  [
                    _c("i", {
                      staticClass: "bi bi-grip-vertical align-middle",
                      staticStyle: { "font-size": "1.4rem" },
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(item.name))]),
                  ]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }