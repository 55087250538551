<template>
    <div>
    </div>
</template>

<script>

export default {
    name: 'ConferencePropertyForum',
    props: {
        lang: {
            type: String,
            default: null,
        },
        value: {
            type: Object,
            required: true
        },
        propertyKey: {
            type: String,
            required: true
        }
    },
}
</script>
