var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ticket-editor__edition-board" }, [
    _c(
      "div",
      { staticClass: "ticket-editor__preview" },
      [_c("ticket-preview", { attrs: { powerUserLoged: _vm.powerUserLoged } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ticket-editor__edition-tools" },
      [
        _c("ticket-select-elements"),
        _vm._v(" "),
        _c("ticket-properties", {
          attrs: { powerUserLoged: _vm.powerUserLoged },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }