<template>
  <div>
    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
      <thead>
        <tr>
          <th class="fw-bold">
            {{ 'conference.properties.property_resources.title_title' | trans }}
          </th>
            <th class="fw-bold">
            {{ 'conference.properties.property_resources.description_title' | trans }}
          </th>
            <th class="fw-bold">
            {{ 'conference.properties.property_resources.file_title' | trans }}
          </th>
          <th width="1%" v-if="!lang"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(resource, indexResource) in resources" :key="indexResource" class="align-top">
          <td>
            <div class="m-form__control">
                <input v-model="resource.title" class="form-control m-input"
                       placeholder="Opcion" :disabled="nbReadOnly" :id="'option-' + indexResource"
                       @input="emit"
                >
                <NbErrorBlock :path="`properties.${propertyKey}.resources.${indexResource}.title`" />
            </div>
          </td>
          <td>
            <div class="m-form__control">
                <input v-model="resource.description" class="form-control m-input"
                       placeholder="Opcion" :disabled="nbReadOnly" :id="'option-' + indexResource"
                       @input="emit"
                >
                <NbErrorBlock :path="`properties.${propertyKey}.resources.${indexResource}.description`" />
            </div>
          </td>
          <td class="table__td-resource">
            <repository-button
                v-model="resource.resource_id"
                @input="emit"
                :is-disabled="false"
                group-id="1"
                group-type="App\Models\Rep\FileGroup"
            />
            <NbErrorBlock :path="`properties.${propertyKey}.resources.${indexResource}.resource_id`" />
          </td>
          <td class="align-middle text-nowrap">
            <div class="actions text-center">
              <button type="button" class="btn btn-icon btn-danger btn-sm"
                      @click="deleteOption(indexResource)" :disabled="nbReadOnly"
                      :id="'delete-option-' + indexResource">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
          </tr>
          </tbody>
      </table>
      <template>
        <modal-component :theRef="'modalReset'" :visible.sync="showModal" :backdrop="true" :classesFooter="['text-right']"
            :classesModalDialog="['modal-dialog-centered modal-lg']">
            <template #title>
              {{ 'conference.properties.property_resources.modal.title' | trans }}
                </template>
            <template>
                    <div >
                        {{ 'conference.properties.property_resources.modal.text' | trans }}
                    </div>
            </template>
            <template #footer>
                <button type="button" class="btn btn-primary" @click="deleteResource()">
                    {{ 'conference.properties.property_resources.modal.confirm' | trans }}
                </button>
                <button type="button" class="btn btn-secondary" @click="showModal = false">
                    {{ 'conference.properties.property_resources.modal.cancel' | trans }}
                </button>
            </template>
        </modal-component>
      </template>
  </div>
</template>


<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'

export default {
  name: 'ConferencePropertyResourcesFile',
  components: {
    RepositoryButton,
    NbErrorBlock
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    lang: {
      type: String,
      default: null,
    },
    propertyKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      resourceIndex: null,
    }
  },
  computed: {
    resources: {
      get() {
        return this.value ? this.value : []
      },
      set() {
        this.emit()
      },
    },
  },
  methods: {
    deleteOption (index) {
      this.showModal = true
      this.resourceIndex = index
      // Vue.delete(this.resources, index)
    },
    deleteResource () {
      Vue.delete(this.resources, this.resourceIndex)
      this.showModal = false
    },
    emit () {
      this.$emit('input', this.resources)
    }
  },
}
</script>

<style lang="scss" scoped>
    .table__td-resource > .form-group{
        width: 100% !important;
        margin-bottom: 0 !important;
    }
</style>
