<template>
    <div class="form-group m-form__group col-md-6 mb-6">
        <div class="d-flex flex-row">
            <input v-if="!generateTab" type="hidden" :name="`${name}_checkbox`" :value="customizeCheckbox ? 1 : 0">
            <input type="hidden" :name="`${prefix}[${name}]`" :value="active ? 1 : 0">
            <div class="p-2">
                <label class="m-checkbox" v-if="!live">
                    <input type="checkbox" v-model="customizeCheckbox" :disabled="generateTab || nbReadOnly"
                           :id="'customize-check-' + name"> {{label}}
                    <span></span>
                </label>
                <label v-else>
                    {{label}}
                </label>
            </div>
        </div>
        <div class="form-group m-form__group  col-md-4">
            <div>
                <span class="m-switch m-switch--icon">
                    <label>
                        <input v-model="active" type="checkbox" value="1">
                        <span></span>
                    </label>
                    <label class="label-hidden">Activar filtro</label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'report-checkbox',
    props: {
      live: {
        type: Boolean,
        default: false
      },
      prefix: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      generateTab: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: 0
      },
      checked: {
        default: 0
      },
      initialCustomizeCheckbox: {
        type: Boolean,
        default: 0
      },
    },
    data () {
      return {
        customizeCheckbox: this.initialCustomizeCheckbox,
        active: this.checked,
      }
    },
  }
</script>
