import { render, staticRenderFns } from "./ConferencePropertyTutored.vue?vue&type=template&id=58c87258&"
import script from "./ConferencePropertyTutored.vue?vue&type=script&lang=js&"
export * from "./ConferencePropertyTutored.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58c87258')) {
      api.createRecord('58c87258', component.options)
    } else {
      api.reload('58c87258', component.options)
    }
    module.hot.accept("./ConferencePropertyTutored.vue?vue&type=template&id=58c87258&", function () {
      api.rerender('58c87258', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/conference/properties/ConferencePropertyTutored.vue"
export default component.exports