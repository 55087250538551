var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overlay", class: _vm.getCurrentClassesLoading },
    [
      _c("div", { staticClass: "overlay-wrapper" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "overlay-layer bg-dark bg-opacity-5",
          class: _vm.getCurrentClassesAnimation,
        },
        [_vm._m(0)]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border text-primary", attrs: { role: "status" } },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Espere...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }