var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.event
      ? _c("div", { staticClass: "ticket-edit-bar__relations-text" }, [
          _c("label", { staticClass: "m-checkbox mb-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.activeScope,
                  expression: "activeScope",
                },
              ],
              attrs: { type: "checkbox", id: "enable-select-ticket" },
              domProps: {
                checked: Array.isArray(_vm.activeScope)
                  ? _vm._i(_vm.activeScope, null) > -1
                  : _vm.activeScope,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.activeScope,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.activeScope = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.activeScope = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.activeScope = $$c
                  }
                },
              },
            }),
            _vm._v("\n            Activar el ambito: "),
            _c("strong", [_vm._v(_vm._s(_vm.event.name))]),
            _vm._v(" "),
            _c("span"),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "ticket-edit-bar__relations-list" },
          [
            _c("multiselect", {
              staticClass: "mt-1 multiselect-sm m-1",
              staticStyle: {
                width: "300px",
                "white-space": "nowrap",
                "z-index": "10",
              },
              attrs: {
                options: _vm.scopes,
                "group-values": "options",
                "group-label": "type",
                "group-select": false,
                "track-by": "id",
                label: "name",
                id: "scope-select-ticket",
                placeholder: "Selector de ámbito",
              },
              on: { input: _vm.applyScope },
              model: {
                value: _vm.selectedScope,
                callback: function ($$v) {
                  _vm.selectedScope = $$v
                },
                expression: "selectedScope",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }