var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "form-group w-100" }, [
        _c("label", { attrs: { for: "displayValue" } }, [
          _vm._v("Mostrar valor"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select form-select-sm",
            attrs: { id: "displayValue" },
            domProps: { value: _vm.activeElement.barcode.displayValue },
            on: {
              input: function ($event) {
                return _vm.updateBarcode($event, "displayValue")
              },
            },
          },
          [
            _c("option", { domProps: { value: true } }, [_vm._v("Si")]),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [_vm._v("No")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group w-100 mb-1" },
        [
          _c("label", { attrs: { for: "elementContent" } }, [
            _vm._v("Contenido almacenado en código de barras"),
          ]),
          _vm._v(" "),
          _c("ValueSuggester", { on: { layoutChange: _vm.layoutChange } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }