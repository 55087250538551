var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c(
      "div",
      { staticClass: "mx-2 mt-4" },
      [
        _c(
          "h4",
          {
            staticClass: "d-flex align-items-center m-0 float-start pt-5 mt-2",
          },
          [
            _vm._v("\n            Listado de preguntas\n            "),
            _vm.loading
              ? _c("div", {
                  staticClass: "m-loader m-loader--info",
                  staticStyle: { width: "30px", display: "inline-block" },
                })
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("bootstrap-table", {
          ref: "table",
          staticClass:
            "table table-bordered border-dark table-striped table-row-gray-300 align-middle gs-0 gy-2 table-disable-hover",
          attrs: {
            data: _vm.questionsData,
            columns: _vm.columns,
            options: _vm.options,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }