import axios from 'axios'

export const state = {
  extraData: [],
}

export const getters = {
  getExtraData: state => state.extraData,
}

export const mutations = {
  setExtraData: (state, extraData) => state.extraData = extraData,
}

export const actions = {
  async GET_CUSTOMER_TYPES () {
    const response = await axios.get('/api/customer-types')
    return response.data
  },
  async GET_AUDIENCE_TYPES () {
    const response = await axios.get('/api/audience-types')
    return response.data
  },
  async POST_CREATE_CUSTOMER (context, customer) {
    const response = await axios.post('/api/customer', customer)
    return response.data.data
  },
  async setExtraData ({ state, commit }, payload) {
    console.log('setExtraData', payload)
    commit('setExtraData', payload)
  },
}

export const Customer = { state, mutations, actions, getters }

export default Customer
