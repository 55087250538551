export function init(Survey, values) {

  Survey.ComponentCollection.Instance.add({
    name: "type_id",
    title: values.title,
    questionJSON: {
      name: "type_id",
      title: values.title,
      type: "dropdown",
      placeholder: values.placeholder,
      choices: values.types,
      allowClear: false
    },
  });

}