<template>
    <div class="row">
        <div class="col-3">
            <h5 class="mb-0 mt-1">
                <i :class="evaluator.icon"></i>
                {{ evaluator.name }}
            </h5>
        </div>
        <div class="col-8">
            <div class="row">
                <div class="col-12">
                    <input type="hidden" :value="type">
                    <div class="btn-group btn-block m-btn-group" role="group">
                        <button type="button" class="btn m-btn" @click="changeType('operator')" :disabled="nbReadOnly"
                                :class="{'btn-brand': type === 'operator', 'btn-secondary': type !== 'operator'}"
                                id="operator-type">
                            Operador
                        </button>
                        <button type="button" class="btn m-btn" @click="changeType('day')" :disabled="nbReadOnly"
                                :class="{'btn-primary': type === 'day', 'btn-secondary': type !== 'day'}" id="days">
                            Días
                        </button>
                        <button type="button" class="btn m-btn" @click="changeType('range')" :disabled="nbReadOnly"
                                :class="{'btn-brand': type === 'range', 'btn-secondary': type !== 'range'}" id="range">
                            Rangos
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="row" v-if="type === 'operator'">
                        <div class="col-2">
                            <input type="hidden" :value="operator">
                            <select class="form-select " :disabled="nbReadOnly" v-model="operator" id="operator-select">
                                <option :value="key" v-for="(operator, key) in operators">{{ operator }}</option>
                            </select>
                        </div>
                        <div class="col-10">
                            <input type="hidden" :value="date">
                            <input type="datetime-local" v-model="date" class="form-control" id="date"/>
                        </div>
                    </div>
                    <div class="row" v-if="type === 'day'">
                        <div class="col-12 mb-3">
                            <input type="hidden" :value="dayOperator">
                            <div class="btn-group btn-block m-btn-group" role="group">
                                <button type="button" class="btn m-btn" @click="changeDayOperator(operatorKey)"
                                        :disabled="nbReadOnly" id="operator-date"
                                        :class="getClassDay(dayOperator, operatorKey)"
                                        v-for="(operatorShow, operatorKey) in operatorsTexts">
                                    {{ operatorShow }}
                                    <i class="fa fa-fw fa-info-circle" :title="operatorsPlaceholder[operatorKey]"
                                        v-tooltip></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="type === 'day'">
                        <div class="col-4">
                            <input type="hidden" :value="day">
                            <div class="btn-group btn-block m-btn-group" role="group">
                                <button type="button" class="btn m-btn" @click="changeDay(dayKey)" :disabled="nbReadOnly"
                                        :class="{'btn-brand': day === dayKey, 'btn-secondary': day !== dayKey}"
                                        v-for="(dayShow, dayKey) in days" :id="'day-' + dayKey">
                                    {{ dayShow }}
                                </button>
                            </div>
                        </div>
                        <div class="col-2 px-0">
                            <input type="hidden" :value="dayNumber">
                            <input type="number" v-model="dayNumber" class="form-control" :disabled="dayDisabled()"
                                    id="day-number"/>
                        </div>
                        <div class="col-6">
                            <input type="hidden" :value="dayGroup">
                            <div class="btn-group btn-block m-btn-group" role="group">
                                <button type="button" class="btn m-btn" :id="'day-group-' + dayKey"
                                        @click="changeDayGroup(dayKey)"
                                        :class="{'btn-brand': dayGroup === dayKey, 'btn-secondary': dayGroup !== dayKey}"
                                        :disabled="dayDisabled()"
                                        v-for="(dayShow, dayKey) in daysGroup">
                                    {{ dayShow }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="type === 'range'">
                        <div class="col-2 text-center">
                            <div class="m-type">
                                Desde
                            </div>
                        </div>
                        <div class="col-4">
                            <input type="hidden" :value="startDate">
                            <input type="datetime-local" v-model="startDate" :disabled="nbReadOnly" class="form-control"
                                    id="start-date"/>
                        </div>
                        <div class="col-2 text-center">
                            <div class="m-type">
                                Hasta
                            </div>
                        </div>
                        <div class="col-4">
                            <input type="hidden" :value="endDate">
                            <input type="datetime-local" v-model="endDate" :disabled="nbReadOnly" class="form-control"
                                    id="end-date"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'evaluator-date',
    props: {
      evaluator: { type: Object, default: () => {} },
      value: {
        type: Object,
        default: () => ({
          type: 'operator',
          operator: 'same',
          date: 'now',
          day: 'now',
          startDate: 'now',
          endDate: 'now',
          dayOperator: 'same',
          dayGroup: 'day',
          dayNumber: 1,
        })
      },
      operators: {
        type: Object,
        default: () => ({
          same: '=',
          greaterThan: '>',
          smallerThan: '<',
          greaterThanOrEqual: '>=',
          smallerThanOrEqual: '<=',
        })
      },
      operatorsTexts: {
        type: Object,
        default: () => ({
          same: 'Igual a',
          greaterThanOrEqual: 'Desde',
          smallerThanOrEqual: 'Hasta',
        })
      },
      operatorsPlaceholder: {
        type: Object,
        default: () => ({
          same: 'Igual a la fecha relativa indicada',
          greaterThanOrEqual: 'Desde la fecha relativa indicada',
          smallerThanOrEqual: 'Hasta la fecha relativa indicada',
        })
      },
      days: {
        type: Object,
        default: () => ({
          now: 'Hoy',
          less: 'Menos',
          plus: 'Más'
        })
      },
      daysGroup: {
        type: Object,
        default: () => ({
          day: 'Día/s',
          week: 'Semana/s',
          month: 'Mes/es',
        })
      },
    },
    data () {
      return {
        type: 'operator',
        operator: 'same',
        date: 'now',
        day: 'now',
        startDate: 'now',
        endDate: 'now',
        dayOperator: 'same',
        dayGroup: 'day',
        dayNumber: 1,
        dataValue: '',
      }
    },
    created () {
      this.type = this.value.type
      this.operator = this.value.operator
      this.day = this.value.day
      this.date = this.dateFormat(this.value.date) + 'T00:00'
      this.startDate = this.dateFormat(this.value.startDate) + 'T00:00'
      this.endDate = this.dateFormat(this.value.endDate) + 'T00:00'
      this.dayOperator = this.value.dayOperator
      this.dayGroup = this.value.dayGroup
      this.dayNumber = this.value.dayNumber
    },
    computed: {
      model() {
        return {
          type: this.type,
          operator: this.operator,
          date: moment(this.date).format('YYYY-MM-DD'),
          day: this.day,
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.endDate).format('YYYY-MM-DD'),
          dayNumber: this.dayNumber,
          dayOperator: this.dayOperator,
          dayGroup: this.dayGroup,
        }
      }
    },
    methods: {
      dateFormat (date) {
        if (date === 'now') {
          date = moment().format('YYYY-MM-DD')
        }
        return date
      },
      changeDay (day) {
        this.day = day
      },
      dayDisabled () {
        return this.day === 'now' || this.nbReadOnly
      },
      changeType (type) {
        this.type = type
        if (this.type === 'day' && !this.day) {
          this.day = 'now'
        }
      },
      changeDayOperator (operator) {
        this.dayOperator = operator
      },
      changeDayGroup (group) {
        this.dayGroup = group
      },
      changeCheckBox () {
        this.customizeCheckbox = !this.customizeCheckbox
        EventBus.$emit('filterStatusChange', this.id, this.label, !this.customizeCheckbox)
      },
      getClassDay (dayOperator, operatorKey) {
        return dayOperator === operatorKey ? 'btn-brand' : 'btn-secondary'
      }
    },
    watch: {
      model(val) {
        this.$emit('input', val)
      }
    },
  }
</script>

<style scoped>

</style>
