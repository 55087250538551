<template>
    <div>
        <div style="display: inline-block;">
            <div @click="changeVisible" class="btn btn-secondary ">
                <span>
                    <em class="fa fa-user fa-fw me-1"></em>
                    <span>Importar</span>
                </span>
            </div>
        </div>

        <modal-component :classesModalDialog="['modal-xl modal-dialog-scrollable']" :visible.sync="visible">
            <template #header>
                Importar clientes
            </template>

            <div class="m-entity-list-search form-group m-form__group col-12">

                <div role="alert" class="m-alert m-alert--square alert alert-dismissible fade show"
                         v-if="message" :class="[successMessage ? 'alert-success' : 'alert-danger']">
                        <div class="m-alert__text">
                            {{ message }}
                        </div>
                        <div class="m-alert__close">
                            <button type="button" data-bs-dismiss="alert" aria-label="Close" class="btn btn-icon close">
                                 <em class="fa fa-times fa-fw me-1"></em>
                            </button>
                        </div>
                    </div>

                <div class="row col-12 py-2 mt-4">
                    <div class="col-4 mb-4">
                        <input type="file" ref="file" name="data" id="data" @change="setFile" :disabled="nbReadOnly"
                            v-show="false">
                        <label for="file" class="btn btn-block btn-primary m-btn--icon" @click="$refs.file.click()"
                            :disabled="nbReadOnly || loading">
                            <em class="fa fa-plus-circle fa-fw me-1"></em>
                            Seleccionar archivo
                        </label>
                    </div>

                    <div v-if="file">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="checkbox" v-model="deactivateOutsideList"
                                id="deactivateOutsideList">
                            <label class="form-check-label" for="deactivateOutsideList">
                                Desactivar los usuarios activos fuera del listado importado
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="checkbox" v-model="importAsActive" id="importAsActive">
                            <label class="form-check-label" for="importAsActive">
                                Importar los usuarios como activos en la plataforma
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="checkbox" v-model="importEmailAsValidated"
                                id="importEmailAsValidated">
                            <label class="form-check-label" for="importEmailAsValidated">
                                Importar el mail de los usuarios como validado
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="checkbox" v-model="twoStepLogin" id="twoStepLogin">
                            <label class="form-check-label" for="twoStepLogin">
                                Los usuarios importados realizarán el login en dos pasos
                            </label>
                        </div>
                        <div class="form-group">
                            <div class="form-check mb-2">
                                <input class="form-check-input" type="checkbox" v-model="register" id="register">
                                <label class="form-check-label">
                                    Los usuarios importados se inscribirán en las actividades seleccionadas
                                </label>
                            </div>

                            <div class="mb-2">
                                <multiselect
                                    v-model="selectedConferences"
                                    :disabled="!register"
                                    :options="conferences"
                                    track-by="id"
                                    label="name"
                                    :multiple="true"
                                    :id="conferences"
                                    placeholder="Seleccione las actividades"
                                />
                            </div>


                        </div>


                        <div class="col-8 text-right">
                            <button type="button" class="btn btn-primary mt-3" id="import" @click="importCustomers"
                                :disabled="nbReadOnly || loading">
                                <span>
                                    <i class="la la-refresh"></i>
                                    <span>Importar</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal-component>
    </div>
</template>

<script>

import ModalComponent from '@/js/components/widgets/ModalComponent'
import ListSimple from '@/js/components/widgets/ListSimple'

export default {
    name: 'CustomerImport',
    components: { ModalComponent, ListSimple },
    props: {
        conferences: {type: Array, required: true, default: () => []}
        // tenant : {type: Number, required: true},
    },
    data() {
        return {
            visible: false,
            file: null,
            filename: '',
            deactivateOutsideList: false,
            importAsActive: true,
            importEmailAsValidated: false,
            twoStepLogin: false,
            register: false,
            selectedConferences: [],
            message: null,
            successMessage: null,
            loading: false
        }
    },
    methods: {
        changeVisible() {
            if (this.nbReadOnly) return
            this.visible = true
        },
        setFile(event) {
            this.file = event.target.files[0]
            this.filename = this.file.name
            event.target.value = ''
        },
        setMessage(msg, success) {
            this.$nextTick(() => {
                this.message = msg
                this.successMessage = success
            })
        },
        importCustomers() {
            if(this.register && this.selectedConferences.length == 0) {
                console.log('No hay actividades para inscribir');
                this.setMessage('No hay actividades para inscribir', false)
            }else{
                this.uploadFile()
            }
        },
        async uploadFile() {
            const formData = new FormData();

            let conferences = this.selectedConferences.map((conference) => conference.id)

            formData.append("file", this.file);
            formData.append("deactivateOutsideList", this.deactivateOutsideList);
            formData.append("importAsActive", this.importAsActive);
            formData.append("importEmailAsValidated", this.importEmailAsValidated);
            formData.append("twoStepLogin", this.twoStepLogin);
            formData.append("register", this.register);
            formData.append("selectedConferences", conferences);

            try {
                this.loading = true
                const response = await axios.post(route('API.customers.import'), formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })

                this.setMessage('Clientes importados con exito', true)
                this.selectedConferences = []
            } catch (error) {
                this.setMessage(`Ha ocurrido un error al cargar los clientes: ${error.response.data.message}`, false)
            } finally {
                this.loading = false
                this.file = null
                this.filename = ''
            }
        }
    },
    watch: {
        register (val) {
            if (!val) {
                this.selectedConferences = []
            }
        }
    }
}
</script>
