var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12" }, [
    _c("input", {
      attrs: { name: "reportsUsers", type: "hidden" },
      domProps: { value: _vm.selectedsJSON },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "m-entity-list-search form-group m-form__group col-12" },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("multiselect", {
                attrs: {
                  placeholder: _vm.typesSalesChannelsSelected.length
                    ? ""
                    : "Filtrar por tipo de canal de venta",
                  "select-label": "<",
                  "deselect-label": "×",
                  "selected-label": "×",
                  "track-by": "id",
                  label: "name",
                  options: _vm.typesSalesChannels,
                  id: "salesChannel",
                  name: "salesChannel",
                  multiple: true,
                },
                model: {
                  value: _vm.typesSalesChannelsSelected,
                  callback: function ($$v) {
                    _vm.typesSalesChannelsSelected = $$v
                  },
                  expression: "typesSalesChannelsSelected",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("multiselect", {
                attrs: {
                  placeholder: _vm.salesChannelsSelected.length
                    ? ""
                    : "Filtrar por canal de venta",
                  "select-label": "<",
                  "deselect-label": "×",
                  "selected-label": "×",
                  "track-by": "id",
                  label: "name",
                  options: _vm.salesChannelsFiltered,
                  id: "salesChannel",
                  name: "salesChannel",
                  multiple: true,
                },
                model: {
                  value: _vm.salesChannelsSelected,
                  callback: function ($$v) {
                    _vm.salesChannelsSelected = $$v
                  },
                  expression: "salesChannelsSelected",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("span", { staticClass: "m-switch m-switch--icon" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applyToAll,
                      expression: "applyToAll",
                    },
                  ],
                  attrs: { type: "checkbox", disabled: _vm.nbReadOnly },
                  domProps: {
                    checked: Array.isArray(_vm.applyToAll)
                      ? _vm._i(_vm.applyToAll, null) > -1
                      : _vm.applyToAll,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.applyToAll,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.applyToAll = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.applyToAll = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.applyToAll = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span"),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "label" }, [
                _vm._v("Activar/Desactivar usuarios listados"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "button",
              {
                staticClass: "m-btn btn m-btn--icon btn-brand",
                attrs: { type: "button", title: "Aplicar" },
                on: { click: _vm.apply },
              },
              [_c("span", [_vm._v("Aplicar")])]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "table-responsive m--padding-left-30 m--padding-right-30 mt-4",
      },
      [
        _c(
          "table",
          { staticClass: "table table-striped m-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "paginate",
              {
                key: _vm.key,
                ref: "usersPagination",
                attrs: {
                  name: "usersPagination",
                  list: _vm.usersFiltered,
                  per: 5,
                  tag: "tbody",
                },
              },
              _vm._l(_vm.paginated("usersPagination"), function (user) {
                return _c("tr", { key: "customer-" + user.id }, [
                  _c("td", [
                    _c("span", { staticClass: "m-switch m-switch--icon" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: user.selected,
                              expression: "user.selected",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            disabled: _vm.nbReadOnly || user.blocked,
                          },
                          domProps: {
                            checked: Array.isArray(user.selected)
                              ? _vm._i(user.selected, null) > -1
                              : user.selected,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = user.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      user,
                                      "selected",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      user,
                                      "selected",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(user, "selected", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span"),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "label-hidden" }, [
                        _vm._v("Asignar informe"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(user.name))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(user.email))]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.paginated("usersPagination").length
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "pull-right" }, [
                  _c(
                    "nav",
                    { attrs: { "aria-label": "Paginación de usuarios" } },
                    [
                      _c("paginate-links", {
                        key: _vm.key,
                        attrs: {
                          limit: 4,
                          "show-step-links": true,
                          "step-links": { prev: "‹", next: "›" },
                          "hide-single-page": true,
                          classes: {
                            ul: "pagination",
                            li: "page-item",
                            a: "page-link",
                          },
                          async: true,
                          for: "usersPagination",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Asignado")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nombre")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }