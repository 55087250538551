var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "m-entity-list-search form-group m-form__group col-12" },
      [
        _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ranking-date-picker", {
                attrs: { id: "date", name: "date" },
                on: { "updated-date": _vm.updatedDate },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 row pr-0" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("multiselect", {
                  attrs: {
                    placeholder: "Ordenar por",
                    "select-label": "<",
                    "deselect-label": "×",
                    "selected-label": "×",
                    "track-by": "id",
                    label: "name",
                    options: _vm.orders,
                    id: "order",
                    name: "order",
                  },
                  model: {
                    value: _vm.orderType,
                    callback: function ($$v) {
                      _vm.orderType = $$v
                    },
                    expression: "orderType",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("multiselect", {
                  attrs: {
                    placeholder: "Ascendente/Descendente",
                    "select-label": "<",
                    "deselect-label": "×",
                    "selected-label": "×",
                    "track-by": "id",
                    label: "name",
                    options: _vm.orderAscDesc,
                    id: "asc-desc",
                    name: "asc-desc",
                  },
                  model: {
                    value: _vm.asc,
                    callback: function ($$v) {
                      _vm.asc = $$v
                    },
                    expression: "asc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-4 pr-0" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.quantity,
                    expression: "quantity",
                  },
                ],
                staticClass: "form-control m-input",
                attrs: {
                  name: "quantity",
                  type: "number",
                  id: "quantity",
                  min: "1",
                  placeholder: "Cantidad de elementos a mostrar",
                },
                domProps: { value: _vm.quantity },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.quantity = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 pr-0" },
              [
                _c("multiselect", {
                  staticClass: "mt-4",
                  attrs: {
                    placeholder: "Categorías",
                    "select-label": "<",
                    "deselect-label": "×",
                    multiple: true,
                    "selected-label": "×",
                    "track-by": "id",
                    label: "name",
                    options: _vm.categories,
                    id: "categories",
                    name: "categories",
                  },
                  model: {
                    value: _vm.categoriesSelected,
                    callback: function ($$v) {
                      _vm.categoriesSelected = $$v
                    },
                    expression: "categoriesSelected",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row py-2 mt-4" }, [
          _c("div", { staticClass: "col-12 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-brand btn m-btn--icon",
                attrs: {
                  type: "button",
                  id: "add-condition",
                  disabled: _vm.refreshing,
                },
                on: { click: _vm.requestRanking },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row m-form__section mt-0 body-padding pt-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", {
          staticClass: "m-separator m-separator--dashed m-separator--lg my-3",
        }),
        _vm._v(" "),
        _vm.refreshing
          ? _c(
              "div",
              { staticClass: "alert m-alert--default text-center py-4" },
              [_vm._v("\n                Consultando datos.\n            ")]
            )
          : _vm.isFirst
          ? _c(
              "div",
              { staticClass: "alert m-alert--default text-center py-4" },
              [
                _vm._v(
                  "\n                Introduzca un criterio de búsqueda.\n            "
                ),
              ]
            )
          : _vm.customers.length
          ? _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped m-table" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.customers, function (customer) {
                    return _c(
                      "tr",
                      { key: "customer-" + customer.customer_id },
                      [
                        _c("td", [_vm._v(_vm._s(customer.customer_id))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.surname))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.email))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.telephone))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(customer.times)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(customer.total) + " €"),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _c(
              "div",
              {
                staticClass:
                  "fs-5 fw-bolder alert m-alert--default text-center my-20",
              },
              [_vm._v("No existen compras con esos criterios.")]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "la la-refresh" }),
      _vm._v(" "),
      _c("span", [_vm._v("Ver Ranking")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nombre")]),
        _vm._v(" "),
        _c("th", [_vm._v("Apellidos")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Teléfono")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nº Operaciones")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }