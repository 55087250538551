var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _c("input", {
      attrs: { type: "hidden", name: "paymentMethods", id: "paymentMethods" },
      domProps: { value: _vm.paymentMethodsJSON },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "span",
          { staticClass: "field__span form-label field__span--required" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("trans")("event.payment_types")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.paymentTypes, function (paymentType, index) {
          return _c(
            "div",
            { key: paymentType.id, staticClass: "col col-4" },
            [
              _c(
                "span",
                { staticClass: "field__span form-label field__span--required" },
                [_vm._v("\n        " + _vm._s(paymentType.name) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: paymentType.value,
                      expression: "paymentType.value",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    input: function ($event) {
                      return _vm.setPayment($event, paymentType.id)
                    },
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        paymentType,
                        "value",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("trans")(
                            "event.payment_methods_select_placeholder"
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(paymentType.payment_methods, function (paymentMethod) {
                    return _c(
                      "option",
                      {
                        key: paymentMethod.id,
                        domProps: { value: paymentMethod.id },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(paymentMethod.name) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("nb-error-block", {
                attrs: { path: `paymentMethods.${index}.paymentMethod` },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }