var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      !("props" in _vm.meta)
        ? [
            _c(
              "td",
              { staticClass: "align-middle", staticStyle: { width: "1%" } },
              [_c("span", [_vm._v(_vm._s(_vm.meta.meta))])]
            ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "align-middle" },
              [
                !("type" in _vm.meta)
                  ? _c("span", [_vm._v(_vm._s(_vm.meta.value))])
                  : [
                      _vm.metaHasChoices(_vm.meta)
                        ? [
                            _vm.metaHasChoices(_vm.meta)
                              ? _c("multiselect", {
                                  attrs: {
                                    options: _vm.meta.choices,
                                    "tag-placeholder": "Añadir nueva opción",
                                    disabled: _vm.nbReadOnly,
                                    taggable: true,
                                    id: "meta",
                                  },
                                  on: {
                                    tag: function ($event) {
                                      return _vm.addTag(_vm.tag, $event)
                                    },
                                  },
                                  model: {
                                    value: _vm.meta.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.meta, "value", $$v)
                                    },
                                    expression: "meta.value",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.metaIsBoolean(_vm.meta)
                              ? _c("span", { staticClass: "m-switch mt-1" }, [
                                  _c(
                                    "label",
                                    { staticClass: "m--marginless" },
                                    [
                                      _c("span"),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.meta.value,
                                            expression: "meta.value",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          disabled: _vm.nbReadOnly,
                                          id: "meta-value",
                                        },
                                        domProps: {
                                          checked: Array.isArray(_vm.meta.value)
                                            ? _vm._i(_vm.meta.value, null) > -1
                                            : _vm.meta.value,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.meta.value,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.meta,
                                                    "value",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.meta,
                                                    "value",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(_vm.meta, "value", $$c)
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span"),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        : [
                            _vm.getFieldType(_vm.meta) === "checkbox"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.meta.value,
                                      expression: "meta.value",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled: _vm.nbReadOnly,
                                    title: "Valor",
                                    id: "field-type-" + _vm.meta.meta,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.meta.value)
                                      ? _vm._i(_vm.meta.value, null) > -1
                                      : _vm.meta.value,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.meta.value,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.meta,
                                              "value",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.meta,
                                              "value",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.meta, "value", $$c)
                                      }
                                    },
                                  },
                                })
                              : _vm.getFieldType(_vm.meta) === "radio"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.meta.value,
                                      expression: "meta.value",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled: _vm.nbReadOnly,
                                    title: "Valor",
                                    id: "field-type-" + _vm.meta.meta,
                                    type: "radio",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.meta.value, null),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.meta, "value", null)
                                    },
                                  },
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.meta.value,
                                      expression: "meta.value",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled: _vm.nbReadOnly,
                                    title: "Valor",
                                    id: "field-type-" + _vm.meta.meta,
                                    type: _vm.getFieldType(_vm.meta),
                                  },
                                  domProps: { value: _vm.meta.value },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.meta,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                          ],
                    ],
              ],
              2
            ),
          ]
        : [
            _c("td", { attrs: { colspan: "2" } }, [
              _c(
                "table",
                { staticClass: "table table-striped m-table table-bordered" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "sub-row-title",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.meta.meta) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.meta.props, function (submeta, i) {
                      return _c("schema-row", {
                        key: `submeta${i}`,
                        attrs: { nbReadOnly: _vm.nbReadOnly, meta: submeta },
                      })
                    }),
                    1
                  ),
                ]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }