var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("subevaluators", {
    ref: "subevaluators",
    attrs: {
      "route-prefix": _vm.routePrefix,
      evaluator: _vm.evaluator,
      group: _vm.group,
      value: _vm.value.subevaluators,
      nbReadOnly: _vm.nbReadOnly,
    },
    on: {
      "filter-group": _vm.filterGroup,
      input: function ($event) {
        return _vm.updateModel()
      },
      openModal: _vm.openModal,
    },
    model: {
      value: _vm.subevaluators,
      callback: function ($$v) {
        _vm.subevaluators = $$v
      },
      expression: "subevaluators",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }