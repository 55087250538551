<template>
    <div class="h-100">
        <input name="menu" type="hidden" :value="menuJSON" />
        <input name="locale" type="hidden" :value="locale" />
        <div class="d-flex justify-content-between mb-5">
            <button class="m-btn btn m-btn--icon btn-primary" type="button" @click="addChildren">
                <i class="fa fa-plus"></i>
                <span>Nueva entrada</span>
            </button>
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="form-label my-0 mx-3 flex-nowrap" style="white-space: nowrap">Filtro por área</label>
              <select class="form-select "
                v-model="selectedArea"
                title="Área"
              >
                <option selected value="">Todos</option>
                <option v-for="area in areas" :value="area">
                  {{ area }}
                </option>
              </select>
            </div>
        </div>
        <cms-menu-item v-model="items" :targets="targets" :areas="areas" :selectedArea="selectedArea" :nbErrors="nbErrors" />
    </div>
</template>

<script>
import CmsMenuItem from '@/js/components/cms/CmsMenuItem';

export default {
  name: 'CmsMenu',
  components: { CmsMenuItem },
  props: {
    targets: {
      type: Array,
      default: () => []
    },
    areas: {
      type: Array,
      default: () => []
    },
    menu: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String
    },
    defaultLangValue: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      items: this.menu,
      selectedArea: ""
    };
  },
  computed: {
    menuJSON() {
        return JSON.stringify(this.items)
    }
  },
  created() {
  },
  methods: {
      addChildren() {
        const newItem = {
          areas: [],
          name: null,
          navigable_type: null,
          navigable_id: null,
          navigable: null,
          children: [],
        }

        this.items.push(newItem)
      },
  }
}

</script>
<style scoped>
</style>
