var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
    _c(
      "label",
      {
        staticClass:
          "form-check form-switch form-check-custom form-check-solid",
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sameHomeAndBillingAddress,
              expression: "sameHomeAndBillingAddress",
            },
          ],
          staticClass: "form-check-input",
          attrs: {
            type: "checkbox",
            name: "same_home_and_billing_address",
            disabled: _vm.nbReadOnly,
            id: "same_home_and_billing_address",
            value: "1",
          },
          domProps: {
            checked: _vm.sameHomeAndBillingAddress,
            checked: Array.isArray(_vm.sameHomeAndBillingAddress)
              ? _vm._i(_vm.sameHomeAndBillingAddress, "1") > -1
              : _vm.sameHomeAndBillingAddress,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.sameHomeAndBillingAddress,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = "1",
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.sameHomeAndBillingAddress = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.sameHomeAndBillingAddress = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.sameHomeAndBillingAddress = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "form-check-label" }, [
          _vm._v("La dirección de facturación es la misma que la habitual"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }