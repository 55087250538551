var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mb-6 form-group m-form__group form-group-sm noprefetch m--padding-left-30 m--padding-right-30 py-0 m-form",
    },
    [
      _vm.label.length
        ? _c(
            "label",
            { staticClass: "form-label", attrs: { for: _vm.field } },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled && !_vm.nbReadOnly
        ? _c(
            "div",
            { staticClass: "reset-color" },
            [
              _c("trumbowyg", {
                style: "height:" + _vm.height + "px;",
                attrs: { id: "field", name: _vm.field, config: _vm.configs },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", _vm.valueTrumbowyg)
                  },
                },
                model: {
                  value: _vm.valueTrumbowyg,
                  callback: function ($$v) {
                    _vm.valueTrumbowyg = $$v
                  },
                  expression: "valueTrumbowyg",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.helpMessage
        ? _c("div", { staticClass: "fv-plugins-message-container" }, [
            _vm._v(" " + _vm._s(_vm.helpMessage) + " "),
          ])
        : _vm.defaultLangValue
        ? _c("div", { staticClass: "reset-color" }, [
            _c(
              "div",
              {
                staticClass: "alert m-alert--default noprefetch p-0 pt-5",
                attrs: { "aria-readonly": "true" },
              },
              [
                _c("iframe", {
                  staticClass: "border-0 p-0 px-4",
                  staticStyle: { background: "#f5f8fa" },
                  attrs: {
                    src: "about:blank",
                    srcdoc: _vm.getHtmlView,
                    width: "100%",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.fieldItem, id: _vm.fieldItem },
        domProps: { value: _vm.getFieldValue },
      }),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: _vm.field } }),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            id: "repositoryNavigation",
            tabindex: "-1",
            visible: _vm.showRepository,
            backdrop: true,
            keyboard: true,
            classesBody: ["box-scroll"],
            classesModalDialog: ["modal-dialog-centered", "modal-xl"],
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRepository = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("trans")("forms_repository.list_files")) +
                        "\n        "
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.multiple
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "m-btn btn btn-brand m-btn--icon",
                            attrs: {
                              id: "multiple",
                              type: "button",
                              disabled: !_vm.button,
                            },
                            on: { click: _vm.setOptions },
                          },
                          [
                            _c("span", [
                              _c("em", { staticClass: "fa fa-plus mr-1" }),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("trans")(
                                      "forms_repository.add_files"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          [
            _vm.showRepository
              ? _c("repository", {
                  attrs: {
                    "active-select": true,
                    types: _vm.fileTypes,
                    field: true,
                    multiple: _vm.multiple,
                    "clear-select": _vm.clearSelect,
                    allowedMimeTypes: _vm.allowedMimeTypes,
                    "group-id": _vm.groupId,
                    "group-type": _vm.groupType,
                  },
                  on: {
                    repositoryInput: _vm.setInput,
                    repositorySetOptions: _vm.setOptions,
                  },
                })
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }