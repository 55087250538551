<template>
    <div class="mt-5 mx-4 mb-0">
        <div class="col-auto">
            <label class="d-block">
            <span class="fw-bold">
            {{ 'conference.properties.property_chat.moderators' | trans }}
            </span>
            <multiselect v-model="moderators" :options="moderatorsOptions" label="name" track-by="id" :multiple="true" />
            <NbErrorBlock :path="`properties.${propertyKey}.moderators`" />
            </label>
        </div>
    </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import ConferencePropertyTutoredQuestions from '@/js/components/conference/properties/tutored/ConferencePropertyTutoredQuestions'

export default ({
    name: 'ConferencePropertyChat',
    components: { ConferencePropertyTutoredQuestions, NbErrorBlock },
    props: {
        moderatorsOptions: {
            type: Array,
            default: () => [],
        },
        lang: {
            type: String,
            default: null,
        },
        value: {
            type: Object,
            default: () => { },
        },
        propertyKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            speakerArray: Object.values(this.speakersOptions),
        }
    },
    computed: {
        moderators: {
            get() {
                return this.value.moderators ? this.value.moderators.map(item => this.moderatorsOptions.find(moderator => moderator.id === item.id)) : []
            },
            set(value) {
                this.$emit('input', { ...this.value, moderators: value });
            }
        },
    },
})
</script>
