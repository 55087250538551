<template>
    <modal-component
      v-if="accessControlLog"
        ref="modal"
        :visible.sync="showModalHistory"
    >
        <template v-slot:header>
            <h4 class="modal-title">{{ 'conference.survey_dashboard.log.title' | trans }}</h4>
        </template>
        <template>
            <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover table-checkable">
                    <thead>
                        <tr>
                            <th>{{ 'conference.survey_dashboard.log.date' | trans }}</th>
                            <th>{{ 'conference.survey_dashboard.log.action' | trans }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(access, index) in accessControlLog" :key="index">
                            <td>{{ access.datetime }}</td>
                            <td>{{ 'conference.survey_dashboard.log.' + access.in | trans }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </modal-component>

</template>

<script>
import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";

export default {
    name: "AccessControlLogModal",
    props: {
    },
    data() {
        return {
            showModalHistory: false,
            accessControlLog: null,
        };
    },
    created() {
        SurveyAnalyticsTabulator.TableExtensions.registerExtension({
            location: "details",
            name: "remove",
            visibleIndex: 1,
            render: (table, opt) => {
                const btn = SurveyAnalyticsTabulator.DocumentHelper.createElement(
                    "button",
                    "sa-table__btn",
                    {
                        type: 'button',
                        innerHTML: this.$options.filters.trans('conference.survey_dashboard.actions.remove_registration'),
                        onclick: (e) => {
                            e.stopPropagation();

                            this.$swal.fire({
                                title: this.$options.filters.trans('conference.survey_dashboard.warnings.remove_registration'),
                                showConfirmButton: true,
                                showCancelButton: true,
                                confirmButtonText: this.$options.filters.trans('conference.survey_dashboard.warnings.confirm'),
                                cancelButtonText: this.$options.filters.trans('conference.survey_dashboard.warnings.cancel'),
                                //ToDo: añadir como default
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                    cancelButton: 'btn btn-white'
                                },
                                buttonsStyling: false,
                            }).then((result) => {
                                // TODO: Hacer llamada a e endpoint del api y mostrar aviso de si ha ido bien o mal
                                if (result.isConfirmed) {
                                    const text = this.$options.filters.trans('conference.survey_dashboard.warnings.not_yet_implemented')
                                    this.$swal.fire(text, '', 'warning')
                                }
                            })
                        },
                    }
                )
                return btn
            },
        })

        SurveyAnalyticsTabulator.TableExtensions.registerExtension({
            location: "details",
            name: "manageAccess",
            visibleIndex: 1,
            render: (table, opt) => {
                const rowData = opt.row.getRowData()
                const hasAccess = rowData['registrable.checkin'] !== '-' || rowData['registrable.checkout'] !== '-'
                const buttonText = hasAccess
                    ? this.$options.filters.trans('conference.survey_dashboard.actions.remove_access')
                    : this.$options.filters.trans('conference.survey_dashboard.actions.new_access')

                const btn = SurveyAnalyticsTabulator.DocumentHelper.createElement(
                    "button",
                    "sa-table__btn",
                    {
                        type: 'button',
                        innerHTML: buttonText,
                        onclick: (e) => {
                            e.stopPropagation();

                            const swalText = hasAccess
                                ? this.$options.filters.trans('conference.survey_dashboard.warnings.remove_access')
                                : this.$options.filters.trans('conference.survey_dashboard.warnings.new_access')

                            this.$swal.fire({
                                title: swalText,
                                showConfirmButton: true,
                                showCancelButton: true,
                                confirmButtonText: this.$options.filters.trans('conference.survey_dashboard.warnings.confirm'),
                                cancelButtonText: this.$options.filters.trans('conference.survey_dashboard.warnings.cancel'),
                                //ToDo: añadir como default
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                    cancelButton: 'btn btn-white'
                                },
                                buttonsStyling: false,
                            }).then((result) => {
                                // TODO: Hacer llamada a e endpoint del api y mostrar aviso de si ha ido bien o mal
                                if (result.isConfirmed) {
                                    const text = this.$options.filters.trans('conference.survey_dashboard.warnings.not_yet_implemented')
                                    this.$swal.fire(text, '', 'warning')
                                }
                            })
                        },
                    }
                )
                return btn
            },
        })
    },
    mounted () {
        const item_id = 'registrable.log'

        const divElements = document.querySelectorAll(".tabulator-cell[tabulator-field='" + item_id + "']")

        divElements.forEach((divElement) => {
            const accessControlLog = JSON.parse(divElement.getAttribute('title'))

            divElement.textContent = ""
            divElement.className += ' text-center p-3'

            const button = document.createElement('button')
            button.type = 'button'
            button.className = 'btn btn-primary'
            button.textContent = this.$options.filters.trans('conference.survey_dashboard.log.show')

            divElement.appendChild(button)

            button.addEventListener('click', () => {
                this.accessControlLog = accessControlLog
                this.showModalHistory = true
            })
        })

    },
};
</script>

<style lang="scss">

</style>
