var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-6" }, [
      _c("h5", { staticClass: "mb-0 mt-2" }, [
        _c("i", { class: _vm.evaluator.icon }),
        _vm._v("\n            " + _vm._s(_vm.evaluator.name) + "\n        "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6 text-right pr-5" }, [
      _c(
        "button",
        {
          staticClass: "btn m-btn",
          class: { "btn-brand": _vm.status },
          attrs: { id: "include", type: "button", disabled: _vm.nbReadOnly },
          on: {
            click: function ($event) {
              return _vm.activeInclude()
            },
          },
        },
        [_vm._v("\n            Si\n        ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn m-btn",
          class: { "btn-brand": !_vm.status },
          attrs: { id: "no-include", type: "button", disabled: _vm.nbReadOnly },
          on: {
            click: function ($event) {
              return _vm.inactiveInclude()
            },
          },
        },
        [_vm._v("\n            No\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }