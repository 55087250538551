var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "m-form portlet__no-padding__top portlet__force-padding__sides",
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "m-portlet__body" },
          [
            _c("input", {
              ref: "savePoliciesField",
              attrs: { type: "hidden", name: "permissions" },
              domProps: { value: _vm.resolvedPermissions },
            }),
            _vm._v(" "),
            _c("input", {
              ref: "savePermissionsField",
              attrs: { type: "hidden", name: "policies" },
              domProps: { value: _vm.resolvedPolicies },
            }),
            _vm._v(" "),
            _vm.selectedRole || _vm.selectedUser
              ? _c("permissions-manager", {
                  attrs: {
                    role: _vm.selectedRole,
                    user: _vm.selectedUser,
                    sections: _vm.sections,
                    entities: _vm.entities,
                    permissions: _vm.permissions,
                    "permission-types": _vm.permissionTypes,
                    userRoleInfo: _vm.userRoleInfo,
                    nbReadOnly: _vm.nbReadOnly,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }