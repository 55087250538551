var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c("input", {
      attrs: { name: "tokens", type: "hidden" },
      domProps: { value: _vm.tokensDeleted },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "mx-2 mt-4" }, [
      _c(
        "table",
        {
          staticClass:
            "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.allTokens.length
            ? _c(
                "tbody",
                _vm._l(_vm.allTokens, function (token, ix) {
                  return _c("tr", [
                    _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(token.token) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDate(token.expiration_at)) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDate(token.refresh_expiration_at)) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDate(token.created_at)) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDate(token.updated_at)) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-icon btn-light-danger btn-sm me-1",
                          attrs: { disabled: _vm.nbReadOnly },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteToken(ix, token.id)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-plus" })]
                      ),
                    ]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(1)]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Token")]),
        _vm._v(" "),
        _c("th", [_vm._v("Expiración")]),
        _vm._v(" "),
        _c("th", [_vm._v("Expiración de refresco")]),
        _vm._v(" "),
        _c("th", [_vm._v("Creado")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actualizado")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "1%" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "fs-5 fw-bolder alert text-center py-20",
          attrs: { colspan: "6" },
        },
        [
          _vm._v(
            "\n                    Este cliente no tiene logins.\n                "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }