<template>
    <div>
        <div class="form-group">
            <label for="lineWeight">Ancho de linea</label>
            <input type="number" id="lineWeight" :value="activeElement.barcode.width"
                   @input="updateBarcode($event, 'width')" class="form-control  form-control-sm"
                   placeholder="Ancho de linea">
        </div>

        <div class="form-group">
            <label for="value">Valor</label>
            <input type="number" id="value" :value="activeElement.barcode.value" @input="updateBarcode($event, 'value')"
                   class="form-control form-control-sm" placeholder="Valor de muestra">
        </div>

        <div class="form-group mb-1">
            <label for="rotation">Rotación de texto</label>
            <select class="form-select  form-select-sm" id="rotation" :value="activeElement.style.rotation"
                    @input="updateStyle($event, 'rotation')">
                <option :value="0">0</option>
                <option :value="90">90</option>
                <option :value="180">180</option>
                <option :value="270">270</option>
            </select>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapMutations } from 'vuex'
  import StylesMixin from '@/js/components/ticket_editor/tabs/element-tabs/StylesMixin'

  export default {
    name: 'BarcodeFieldStyles',
    mixins: [StylesMixin],
    props: {},

    methods: {
      ...mapMutations('ticketEditor', {
        updateBarcodeProperties: 'updateBarcodeProperties',
      }),

      updateBarcode (event, prop) {
        this.updateBarcodeProperties({ prop, value: event.target.value })
        this.layoutChange()
      },
    },
  }
</script>

<style lang="scss">
</style>
