var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-xl-4 col-lg-6 col-md-8 col-sm-10" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "bg-light-dark p-3 mb-2 rounded-2" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "bg-light p-4 mb-2 rounded-2" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "bg-light p-4 mb-2 rounded-2" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "bg-light p-8 mb-2 rounded-2" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "bg-dark p-4 mb-2 rounded-2" }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }