var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        attrs: { type: "hidden", name: "survey" },
        domProps: { value: _vm.jsonSurvey },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "theme" },
        domProps: { value: _vm.jsonTheme },
      }),
      _vm._v(" "),
      _c("div", { attrs: { id: "surveyCreator" } }),
      _vm._v(" "),
      _vm._l(_vm.nbErrors, function (error, key) {
        return _c("nb-error-block", { key: key, attrs: { path: key } })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }