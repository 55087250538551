var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layer-properties__color-graph row mb-2 g-1 g-md-2" },
    [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "input-group mb-5" }, [
          _c("span", { staticClass: "input-group-text" }, [
            _vm._v(
              " " + _vm._s(_vm._f("trans")("statistics.colors.selectColor"))
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-control p-0", attrs: { type: "text" } },
            [
              _c("Swatches", {
                staticClass: "is-marginless d-block",
                attrs: {
                  swatches: _vm.colors,
                  "popover-x": "right",
                  "popover-y": "top",
                  shapes: "circles",
                  "show-border": "",
                  "show-fallback": "",
                  "fallback-input-type": "color",
                  "trigger-style": _vm.swatchesTriggerStyle,
                  "fallback-ok-text": _vm.$options.filters.trans(
                    "statistics.swatches.buttonText"
                  ),
                  disabled: _vm.readonly,
                },
                model: {
                  value: _vm.color,
                  callback: function ($$v) {
                    _vm.color = $$v
                  },
                  expression: "color",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layer-properties__color-actions col-auto" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-icon btn-light-danger",
            attrs: { type: "button", disabled: _vm.readonly },
            on: { click: _vm.emitDelete },
          },
          [_c("i", { staticClass: "fas fa-minus" })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }