var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-middle justify-content-center align-items-center",
    },
    [
      _vm.pageItemsCount
        ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.pageItemsCount))])])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ml-auto" }, [
        _c(
          "ul",
          { staticClass: "pagination m-0" },
          [
            _c(
              "li",
              {
                staticClass: "paginate_button page-item first",
                class: { disabled: !_vm.links.prev },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "page-link",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setPageNumber(1)
                      },
                    },
                  },
                  [_c("em", { staticClass: "la la-angle-double-left" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "paginate_button page-item previous",
                class: { disabled: !_vm.links.prev },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "page-link",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setPageNumber(_vm.currentPage - 1)
                      },
                    },
                  },
                  [_c("em", { staticClass: "la la-angle-left" })]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(
              _vm.pagination(_vm.meta.current_page, _vm.meta.last_page),
              function (pageElement) {
                return _c(
                  "li",
                  {
                    staticClass: "paginate_button page-item",
                    class: { active: pageElement === _vm.meta.current_page },
                  },
                  [
                    _vm.isNumber(pageElement)
                      ? _c(
                          "button",
                          {
                            staticClass: "page-link",
                            attrs: { type: "button", tabindex: "0" },
                            on: {
                              click: function ($event) {
                                return _vm.setPageNumber(pageElement)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(pageElement) +
                                "\n                    "
                            ),
                            pageElement === _vm.loadingPageNumber &&
                            _vm.loadingData
                              ? _c("span", {
                                  staticClass:
                                    "m-loader m-loader--danger in-pagination-loader",
                                })
                              : _vm._e(),
                          ]
                        )
                      : _c("span", { staticClass: "page-link" }, [
                          _vm._v("..."),
                        ]),
                  ]
                )
              }
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "paginate_button page-item next",
                class: { disabled: !_vm.links.next },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "page-link",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setPageNumber(_vm.currentPage + 1)
                      },
                    },
                  },
                  [_c("em", { staticClass: "la la-angle-right" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "paginate_button page-item last",
                class: { disabled: !_vm.links.next },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "page-link",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setPageNumber(_vm.meta.last_page)
                      },
                    },
                  },
                  [_c("em", { staticClass: "la la-angle-double-right" })]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }