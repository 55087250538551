var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "field is-marginless form-group mb-6" },
    [
      _c("label", { staticClass: "field__span form-label" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          options: _vm.validTags,
          "track-by": "tagName",
          disabled: _vm.disabled,
          label: "tagName",
          multiple: true,
          placeholder: _vm.placeholder,
        },
        model: {
          value: _vm.selectedTags,
          callback: function ($$v) {
            _vm.selectedTags = $$v
          },
          expression: "selectedTags",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }