<template>
    <div id="surveyTable">
        <div v-if="showFilter" class="col-8 mb-3">
            <multiselect v-model="selectedOptions" :options="optionsFilter" label="name" track-by="id" :multiple="true"
                :placeholder="filterPlaceholder" />
        </div>
        <div class="mt-3" id="surveyPanel" ref="surveyPanel" />

        <template v-if="tableRendered">
            <component
                v-for="component in customComponents"
                :key="component"
                :is="component"
                :table="vizPanel"
                :survey-structure="surveyStructure"
                :responses="responses"
            />
        </template>
    </div>
</template>

<script>
import 'survey-analytics/survey.analytics.min.css';
import { Model } from 'survey-core';

import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";
import "tabulator-tables/dist/css/tabulator.min.css";
import "survey-analytics/survey.analytics.tabulator.css";
import SurveyDashboardFilter from '@/js/mixins/SurveyDashboardFilter'

const localizationSpanish = {
    "groupButton": "Agrupar por mí",
    "ungroupButton": "Desagrupar por mí",
    "selectButton": "Seleccionarme",
    "hideColumn": "Ocultar columna",
    "showColumn": "Mostrar columna",
    "makePrivateColumn": "Hacer columna privada",
    "makePublicColumn": "Hacer columna pública",
    "moveToDetail": "Mover a Detalle",
    "showAsColumn": "Mostrar como Columna",
    "filterPlaceholder": "Buscar...",
    "removeRows": "Eliminar filas",
    "showLabel": "Mostrar",
    "entriesLabel": "entradas",
    "visualizer_text": "Textos en tabla",
    "visualizer_wordcloud": "Nube de palabras",
    "visualizer_histogram": "Histograma",
    "visualizer_number": "Promedio",
    "visualizer_choices": "Tabla",
    "visualizer_selectBase": "Gráfico",
    "chartType_bar": "Barras",
    "chartType_vbar": "Barras Verticales",
    "chartType_stackedbar": "Barras Apiladas",
    "chartType_doughnut": "Rosquilla",
    "chartType_pie": "Circular",
    "chartType_scatter": "Dispersión",
    "chartType_gauge": "Medidor",
    "chartType_bullet": "Balas",
    "hideButton": "Ocultar",
    "makePrivateButton": "Hacer privado",
    "makePublicButton": "Hacer público",
    "showButton": "Mostrar",
    "filter": "Filtro",
    "resetFilter": "Restablecer filtro",
    "changeLocale": "Cambiar idioma",
    "en": "Inglés",
    "fr": "Francés",
    "ru": "Ruso",
    "de": "Alemán",
    "clearButton": "Limpiar",
    "addElement": "Elegir pregunta para mostrar...",
    "defaultOrder": "Orden predeterminado",
    "ascOrder": "Ascendente",
    "descOrder": "Descendente",
    "showMinorColumns": "Mostrar columnas menores",
    "otherCommentTitle": "Otros elementos y comentarios",
    "showPercentages": "Mostrar porcentajes",
    "hidePercentages": "Ocultar porcentajes",
    "pdfDownloadCaption": "PDF",
    "xlsxDownloadCaption": "Excel",
    "csvDownloadCaption": "CSV",
    "saveDiagramAsPNG": "Descargar gráfico como PNG",
    "hideEmptyAnswers": "Ocultar respuestas vacías",
    "showEmptyAnswers": "Mostrar respuestas vacías",
    "topNValueText-1": "Todas las respuestas",
    "topNValueText5": "Principales 5 respuestas",
    "topNValueText10": "Principales 10 respuestas",
    "topNValueText20": "Principales 20 respuestas",
    "hideMissingAnswers": "Ocultar respuestas faltantes",
    "showMissingAnswers": "Mostrar respuestas faltantes",
    "missingAnswersLabel": "Respuestas faltantes",
    "noVisualizerForQuestion": "Este tipo de pregunta aún no se visualiza",
    "noResults": "Todavía no hay resultados",
    "showPerValues": "Por Valores",
    "showPerColumns": "Por Columnas",
    "answer": "Respuesta",
    "correctAnswer": "Respuesta correcta: ",
    "percent": "Porcentaje",
    "responses": "Respuestas"
}


export default {
    name: 'SurveyResponsesTableComponent',
    mixins: [SurveyDashboardFilter],
    props: {
        surveyName: {
            type: String,
            required: true,
        },
        customerUrl: {
            type: String,
            default: ''
        },
        customComponents: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            tableRendered: false,
        }
    },
    mounted() {
        window.jsPDF = jsPDF;
        window.XLSX = XLSX;

        setTimeout(() => {
            this.renderTable();
        }, 3000)

    },
    methods: {
        async renderTable() {
            const vizPanelOptions = {
                haveCommercialLicense: true,
                downloadButtons: ["csv", "xlsx"],
                downloadOptions: {
                    fileName: "Respuestas de " + this.surveyName,
                },
                downloadHiddenColumns: true,
            }

            const survey = new Model(this.surveyStructure);

            this.additionalConfiguration();

            this.vizPanel = new SurveyAnalyticsTabulator.Tabulator(
                survey,
                this.filterData,
                vizPanelOptions,
            );
            this.vizPanel.setPageSize(10)

            const surveyPanel = this.$refs.surveyPanel;
            await this.vizPanel.render(surveyPanel)
            this.tableRendered = true;

            this.addLinkToItem();

            this.surveyStructure.pages[0].elements
                .filter(item => item.visible === false)
                .forEach(item => {
                    this.vizPanel.setColumnVisibility(item.name, false);
                });
        },
        additionalConfiguration() {

            SurveyAnalyticsTabulator.Tabulator.prototype.createDownloadsBar = function () {
                var _this = this;
                var createDownloadButton = function (type, caption) {
                    var btn = SurveyAnalyticsTabulator.DocumentHelper.createElement("button", "sa-table__btn sa-table__btn--small sa-table__btn--gray", {
                        innerText: caption,
                        onclick: function (e) {
                            _this.download(type);
                            e.stopPropagation();
                            e.preventDefault();
                        },
                    });
                    return btn;
                };
                var container = SurveyAnalyticsTabulator.DocumentHelper.createElement("div", "sa-tabulator__downloads-bar");
                this._options.downloadButtons.forEach(function (type) {
                    container.appendChild(createDownloadButton(type, SurveyAnalyticsTabulator.localization.getString("".concat(type, "DownloadCaption"))));
                });
                return container;
            };

            SurveyAnalyticsTabulator.localization.defaultLocaleValue = "es";
            SurveyAnalyticsTabulator.localization.locales["es"] = localizationSpanish;
            SurveyAnalyticsTabulator.localization.localeNames["es"] = "spanish"

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "column",
                "movetodetails"
            ).render = (table, options) => {
                const button = SurveyAnalyticsTabulator.DocumentHelper.createSvgButton("movetodetails");
                button.title = SurveyAnalyticsTabulator.localization.getString("moveToDetail");
                button.onclick = (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    table.setColumnLocation(options.columnName, 1);
                };
                return button;
            };

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "column",
                "sort"
            ).render = function (table, options) {
                const descTitle = SurveyAnalyticsTabulator.localization.getString("descOrder");
                const ascTitle = SurveyAnalyticsTabulator.localization.getString("ascOrder");
                var btn = SurveyAnalyticsTabulator.DocumentHelper.createSvgButton("sorting");
                btn.title = "";
                var direction = "asc";
                btn.onclick = (e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (direction == "asc") {
                        btn.title = descTitle;
                        direction = "desc";
                    } else {
                        btn.title = ascTitle;
                        direction = "asc";
                    }
                    table.sortByColumn(options.columnName, direction);
                };
                btn.ondrag = (e) => {
                    e.stopPropagation();
                };
                return btn;
            }

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "column",
                "hide"
            ).render = function (table, options) {
                var btn = SurveyAnalyticsTabulator.DocumentHelper.createSvgButton("hide");
                btn.title = SurveyAnalyticsTabulator.localization.getString("hideColumn");
                btn.onclick = (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    table.setColumnVisibility(options.columnName, false);
                };
                return btn;
            }

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "column",
                "filter",
            ).render = function (table, options) {
                var el = SurveyAnalyticsTabulator.DocumentHelper.createInput(
                    "sa-table__filter",
                    SurveyAnalyticsTabulator.localization.getString("filterPlaceholder")
                );
                el.onclick = (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                };
                el.onchange = (e) => {
                    table.applyColumnFilter(options.columnName, el.value);
                };
                return el;
            }

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "column",
                "makepublic",
            ).render = function (table, options) {
                const button = SurveyAnalyticsTabulator.DocumentHelper.createElement("button");
                const makePrivateSvg = SurveyAnalyticsTabulator.DocumentHelper.createSvgElement("makeprivate");
                const makePublicSvg = SurveyAnalyticsTabulator.DocumentHelper.createSvgElement("makepublic");
                const column = table.getColumnByName(options.columnName);

                updateState(column);
                button.appendChild(makePrivateSvg);
                button.appendChild(makePublicSvg);
                button.onclick = (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    column.isPublic = !column.isPublic;
                    updateState(column);
                    table.onPermissionsChangedCallback &&
                        table.onPermissionsChangedCallback(table);
                };

                function updateState(column) {
                    if (column.isPublic) {
                        button.className = "sa-table__svg-button";
                        button.title = SurveyAnalyticsTabulator.localization.getString("makePrivateColumn");
                        makePrivateSvg.style.display = "none";
                        makePublicSvg.style.display = "block";
                    } else {
                        button.className = "sa-table__svg-button sa-table__svg-button--active";
                        button.title = SurveyAnalyticsTabulator.localization.getString("makePublicColumn");
                        makePrivateSvg.style.display = "block";
                        makePublicSvg.style.display = "none";
                    }
                }
                return button;
            }

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "row",
                "details",
            ).render = (table, options) => {
                const btn = SurveyAnalyticsTabulator.DocumentHelper.createSvgButton("detail");
                btn.title = SurveyAnalyticsTabulator.localization.getString("showMinorColumns");
                btn.className += " sa-table__row-extension";
                btn.onclick = (e) => {
                    e.preventDefault();
                    options.row.toggleDetails();
                };
                return btn;
            }

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "header",
                "filter",
            ).render = (table, options) => {
                const input = SurveyAnalyticsTabulator.DocumentHelper.createInput(
                    "sa-table__global-filter sa-table__header-extension",
                    SurveyAnalyticsTabulator.localization.getString("filterPlaceholder")
                );

                input.onchange = (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    table.applyFilter(evt.target.value);

                    return false;
                }

                input.onkeydown = (evt) => {
                    if (evt.key === 'Enter') {
                        evt.preventDefault();
                        evt.stopPropagation();
                        table.applyFilter(evt.target.value);

                        return false;
                    }
                }

                return input;
            }

            SurveyAnalyticsTabulator.TableExtensions.findExtension(
                "column",
                "filter",
            ).render = (table, options) => {
                const el = SurveyAnalyticsTabulator.DocumentHelper.createInput(
                    "sa-table__filter",
                    SurveyAnalyticsTabulator.localization.getString("filterPlaceholder")
                );

                el.onchange = (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    table.applyColumnFilter(options.columnName, el.value);

                    return false;

                }

                el.onkeydown = (evt) => {
                    if (evt.key === 'Enter') {
                        evt.preventDefault();
                        evt.stopPropagation();
                        table.applyColumnFilter(options.columnName, el.value);

                        return false;
                    }
                }

                return el;
            }

            const survey = new Model(this.surveyStructure);
            const vizPanelOptions = {
                haveCommercialLicense: true,
                downloadButtons: ["csv", "xlsx"],
                downloadOptions: {
                    fileName: "Respuestas de " + this.surveyName,
                },
            }

            this.vizPanel = new SurveyAnalyticsTabulator.Tabulator(
                survey,
                this.filterData,
                vizPanelOptions,
            );

            const surveyPanel = this.$refs.surveyPanel;

            this.vizPanel.render(surveyPanel)
        },
        addLinkToItem() {
            let url = "";
            let item_id = "";
            let item_name = "";

            url = this.customerUrl
            item_id = 'customer_id'


            let divElements = document.querySelectorAll(".tabulator-cell[tabulator-field='" + item_id + "']");

            const self = this;

            divElements.forEach(function (divElement) {
                let id = divElement.getAttribute('title');
                divElement.textContent = ""

                let link = document.createElement('a');
                link.href = url + '/' + id + "/edit";

                let item = self.filterData.find(response => response[item_id] == id);

                link.textContent = item ?
                    item['customer_name'] + ' ' + item['customer_surname']
                    : "--";
                link.style.color = "black";
                link.style.fontWeight = "bold";

                divElement.appendChild(link);

                link.addEventListener('click', function () {
                    window.location.href = url;
                });
            });
        }
    },
    watch: {
        selectedOptions() {
            this.renderTable();
        },
        '$data': {
            handler: function () {
                this.addLinkToItem();
            },
            deep: true
        }
    }

}
</script>
