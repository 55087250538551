var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a",
        {
          staticClass: "menu-link px-2",
          attrs: {
            href: "javascript:",
            "data-bs-toggle": "tooltip",
            title: "Support Center",
            "data-placement": "left",
          },
          on: {
            click: function ($event) {
              _vm.visibleModal = !_vm.visibleModal
            },
          },
        },
        [
          _vm._v("\n        Información\n        "),
          _c("em", {
            staticClass:
              "m-nav__link-icon flaticon-info m--icon-font-size-lg2 ml-1 align-middle",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            visible: _vm.visibleModal,
            backdrop: true,
            "classes-modal-dialog": ["c-support-center"],
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleModal = $event
            },
            "close-modal": _vm.closeModal,
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_vm._v("\n            Centro de ayuda\n        ")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "modal-footer-buttons" }, [
                    _c("div", { staticClass: "pull-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger m-btn--icon",
                          attrs: { type: "button", id: "close" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        Cerrar\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" "), [_c("div")]],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }