var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mb-5" }, [
    _c("div", { staticClass: "card-header border-0 pt-5 pb-0" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-toolbar" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary m-btn m-btn--custom m-btn--icon",
            attrs: { id: "reports-button" },
            on: { click: _vm.goToReports },
          },
          [
            _vm._v("\n                    Ir a informes\n                    "),
            _c("i", { staticClass: "fa fa-chart-bar fa-fw ms-1" }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body pt-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("relative-date-picker", {
            on: { valuesUpdated: _vm.emitDateFilters },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "m-portlet__head-title w-auto" }, [
      _c("span", { staticClass: "m-portlet__head-icon" }, [
        _c("i", { staticClass: "flaticon-dashboard" }),
      ]),
      _vm._v(" "),
      _c(
        "h3",
        {
          staticClass:
            "card-title align-items-start flex-column m-portlet__head-text",
        },
        [_vm._v("\n                    Cuadros de mando\n                ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }