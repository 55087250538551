<template>
    <div class="col-12">
        <input type="hidden" :name="lang" v-model="actualtranslatesJSON">
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="d-flex align-content-start flex-wrap">
                    <div class="col-12 col-md-5 col-lg-4 me-3 mb-5">
                        <div class="m-input-icon m-input-icon--right position-relative">
                            <label for="qSearch"
                                   class="sr-only"></label>
                            <input type="text" placeholder="Buscar en las traducciones"
                                   class="form-control  pe-10"
                                   v-model="baseTranslateSearch" aria-label="path"
                                   :id="'search-translations-' + lang" spellcheck="false"
                                   data-ms-editor="true">
                            <div class="position-absolute translate-middle-y top-50 end-0 me-3"><span
                                class="svg-icon svg-icon-2hx"><svg width="24px" height="24px" viewBox="0 0 24 24"
                                                                   version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                   xmlns:xlink="http://www.w3.org/1999/xlink"><g
                                id="Stockholm-icons-/-General-/-Search" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd"><rect id="bound" x="0" y="0" width="24" height="24"></rect> <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                id="Path-2" fill="#000000" fill-rule="nonzero" opacity="0.3"></path> <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                id="Path" fill="#000000" fill-rule="nonzero"></path></g></svg></span></div>
                        </div>
                    </div>
                    <div class="mb-5 d-flex">
                        <button type="button" class="me-3 m-input btn btn-primary m-btn--icon" :id="'search-' + lang">
                            <em class="fa fa-fw fa-search fa-fw me-1"></em>
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gy-2">
            <div class="col-md-6 col-lg-12 col-xl-6 col-xxl-4" v-for="(translate, key) in translateKeys">
                <div class="translate-box card card-bordered h-100">
                    <div class="card-body p-4">
                        <div class="form-label text-break translate-path">{{ translate.path }}</div>
                        <div class="translate-field mb-2">
                        <textarea class="form-control " cols="0" rows="1"
                                  v-model="actualTranslates[translate.path]"
                                  :disabled="nbReadOnly" :id="'translate-' + lang + key"
                                  :class="{'translate-big-text': bigText(actualTranslates[translate.path])}"></textarea>
                        </div>
                        <div class="translate-label">
                            <div class="row">
                                <div class="col form-text text-muted text-truncate"
                                     :class="{'text-wrap': canViewText(translate.path)}">
                                    {{ translate.label }}
                                </div>
                                <div class="col-auto">
                                    <button type="button" :id="'translate-button-' + lang + key" :disabled="nbReadOnly"
                                            class="btn btn-icon btn-secondary btn-sm m-btn m-btn--icon m-btn--icon-only"
                                            v-if="bigText(translate.label)" @click="showText(translate.path)">
                                        <em class="fa"
                                            :class="{'fa-minus': canViewText(translate.path), 'fa-plus': !canViewText(translate.path)}"></em>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row px-3" v-if="baseTranslateSearch && !translateKeys.length">
            <div class="col-12">
                No hay resultados en la búsqueda
            </div>
        </div>
    </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
    name: 'translations-form',
    props: {
        baseTranslates: {
            type: Array,
            default: () => []
        },
        translates: {
            type: Object,
            default: () => {
            }
        },
        spanishTranslates: {
            type: Object,
            default: () => {
            }
        },
        lang: {
            type: String,
            default: () => {
            }
        },
    },
    data() {
        return {
            actualTranslates: [],
            baseTranslateSearch: null,
            viewText: {}
        }
    },
    created() {
        this.actualTranslates = this.translates
        $(document).on('submit', 'form', (e) => {
            e.preventDefault()
            return false
        })
    },
    computed: {
        actualtranslatesJSON() {
            return JSON.stringify(this.actualTranslates)
        },
        translateKeys() {
            const listTranslate = this.baseTranslates.map((base) => {
                return {
                    path: base,
                    text: this.actualTranslates[base],
                    label: this.spanishTranslates[base],
                }
            })

            if (!this.baseTranslateSearch) {
                return listTranslate
            }

            return this.searchText(listTranslate, this.baseTranslateSearch)
        },
    },
    methods: {
        searchText(list, text) {
            return (new Fuse(list, {
                shouldSort: false,
                keys: ['path', 'text', 'label'],
                threshold: 0.3,
            })).search(text)
        },
        bigText(text) {
            if (text) {
                return text.length > 50
            }

            return false
        },
        canViewText(path) {
            if (this.viewText.hasOwnProperty(path)) {
                return this.viewText[path]
            }

            return false
        },
        showText(path) {
            if (this.viewText.hasOwnProperty(path)) {
                this.viewText[path] = !this.viewText[path]
            } else {
                this.viewText[path] = true
            }

            this.viewText = Object.assign({}, this.viewText)
        },
    }
}
</script>

<style scoped>

</style>
