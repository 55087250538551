var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "col-12 mb-4 text-right c-survey-component__btn-add-option",
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "configuration" },
          domProps: { value: _vm.configurationJSON },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary m-btn m-btn--icon",
            attrs: {
              type: "button",
              disabled: _vm.nbReadOnly,
              id: "add-option",
            },
            on: { click: _vm.addFile },
          },
          [
            _c("span", [
              _c("i", { staticClass: "fa fa-plus" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("trans")(
                        "forms_crm_external_register.configuration_component.add_config_button"
                      )
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.configurationArray.length
      ? _c("div", { staticClass: "fs-5 fw-bolder alert text-center my-20" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm._f("trans")(
                  "forms_crm_external_register.configuration_component.no_data"
                )
              ) +
              "\n  "
          ),
        ])
      : _c(
          "table",
          {
            staticClass:
              "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("trans")(
                          "forms_crm_external_register.configuration_component.name"
                        )
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("th", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("trans")(
                          "forms_crm_external_register.configuration_component.value"
                        )
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                !_vm.lang ? _c("th", { attrs: { width: "1%" } }) : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.configurationArray, function (configuration, key) {
                return _c("tr", { key: key, staticClass: "align-top" }, [
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "m-form__control" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: configuration.name,
                              expression: "configuration.name",
                            },
                          ],
                          staticClass: "form-control form-control-sm m-input",
                          attrs: {
                            placeholder: "Opcion",
                            disabled: _vm.nbReadOnly,
                            id: "option-" + key,
                          },
                          domProps: { value: configuration.name },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  configuration,
                                  "name",
                                  $event.target.value
                                )
                              },
                              _vm.emit,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("nb-error-block", {
                          attrs: { path: `configuration.${key}.name` },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "m-form__control" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: configuration.value,
                              expression: "configuration.value",
                            },
                          ],
                          staticClass: "form-control form-control-sm m-input",
                          attrs: {
                            placeholder: "Opcion",
                            disabled: _vm.nbReadOnly,
                            id: "option-" + key,
                          },
                          domProps: { value: configuration.value },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  configuration,
                                  "value",
                                  $event.target.value
                                )
                              },
                              _vm.emit,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("NbErrorBlock", {
                          attrs: { path: `configuration.${key}.value` },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-nowrap" }, [
                    _c("div", { staticClass: "actions text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-icon btn-danger btn-sm",
                          attrs: {
                            type: "button",
                            disabled: _vm.nbReadOnly,
                            id: "delete-option-" + key,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteOption(key)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-trash" })]
                      ),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }