var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c("input", {
      attrs: { name: "links", type: "hidden" },
      domProps: { value: _vm.listLinksJSON },
    }),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: "locale" },
      domProps: { value: _vm.locale },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
      _c("h4", { staticClass: "d-flex align-items-center mb-5" }, [
        _c("em", { staticClass: "flaticon-list-2 mr-1" }),
        _vm._v(
          _vm._s(_vm._f("trans")("cms_selector_page.linkType")) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "mb-5 btn btn-primary m-btn btn-sm m-btn m-btn--icon",
          attrs: { type: "button", id: "add-link", disabled: _vm.nbReadOnly },
          on: {
            click: function ($event) {
              return _vm.addLink()
            },
          },
        },
        [_vm._m(0)]
      ),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table border gx-4 table-row-bordered" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { attrs: { width: "15%" } }, [
            _vm._v(_vm._s(_vm._f("trans")("cms_selector_page.type"))),
          ]),
          _vm._v(" "),
          _c("th", { attrs: { width: "30%" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm._f("trans")("cms_selector_page.name")) +
                "* (" +
                _vm._s(_vm.locale.toUpperCase()) +
                ")\n                "
            ),
          ]),
          _vm._v(" "),
          _c("th", { attrs: { width: "15%" } }, [
            _vm._v(_vm._s(_vm._f("trans")("cms_selector_page.link")) + "*"),
          ]),
          _vm._v(" "),
          _c("th", { attrs: { width: "15%" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm._f("trans")("cms_selector_page.section")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("th", { attrs: { width: "10%" } }, [
            _vm._v(_vm._s(_vm._f("trans")("cms_selector_page.order"))),
          ]),
          _vm._v(" "),
          _c("th", { attrs: { width: "1%" } }),
        ]),
      ]),
      _vm._v(" "),
      _vm.arrayLinks.length
        ? _c(
            "tbody",
            _vm._l(_vm.arrayLinks, function (arrayLink, index) {
              return _c("tr", [
                _c(
                  "td",
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: arrayLink.idLink,
                          expression: "arrayLink.idLink",
                        },
                      ],
                      attrs: { type: "hidden" },
                      domProps: { value: arrayLink.idLink },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(arrayLink, "idLink", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.selectorData,
                        "track-by": "id",
                        disabled: _vm.nbReadOnly,
                        label: "name",
                        multiple: false,
                        id: "type-" + index,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setToNull(index)
                        },
                      },
                      model: {
                        value: arrayLink.linkType,
                        callback: function ($$v) {
                          _vm.$set(arrayLink, "linkType", $$v)
                        },
                        expression: "arrayLink.linkType",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("label", {
                      staticClass: "sr-only",
                      attrs: { for: "name-link-" + index },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: arrayLink.nameLink,
                          expression: "arrayLink.nameLink",
                        },
                      ],
                      staticClass: "form-control-solid form-control m-input",
                      attrs: {
                        id: "name-link-" + index,
                        type: "text",
                        placeholder: "Nombre",
                        "aria-describedby": "basic-addon2",
                        disabled: _vm.nbReadOnly,
                      },
                      domProps: { value: arrayLink.nameLink },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(arrayLink, "nameLink", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("nb-error-block", {
                      attrs: { path: `links.${index}.name` },
                    }),
                    _vm._v(" "),
                    _vm.locale !== "es"
                      ? _c("p", { staticClass: "m-form__help pl-2" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getLocale(arrayLink.idLink, "es")) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    arrayLink.linkType.id === 1
                      ? [
                          _c("label", {
                            staticClass: "sr-only",
                            attrs: { for: "link-" + index },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: arrayLink.link,
                                expression: "arrayLink.link",
                              },
                            ],
                            staticClass:
                              "form-control-solid form-control m-input",
                            attrs: {
                              id: "link-" + index,
                              type: "url",
                              placeholder: "Url",
                              "aria-describedby": "basic-addon2",
                              disabled: _vm.nbReadOnly,
                            },
                            domProps: { value: arrayLink.link },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(arrayLink, "link", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("nb-error-block", {
                            attrs: { path: `links.${index}.link` },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    arrayLink.linkType.id === 2
                      ? [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.pagesData,
                              "track-by": "id",
                              disabled: _vm.nbReadOnly,
                              label: "name",
                              multiple: false,
                              id: "pages-" + index,
                            },
                            model: {
                              value: arrayLink.page,
                              callback: function ($$v) {
                                _vm.$set(arrayLink, "page", $$v)
                              },
                              expression: "arrayLink.page",
                            },
                          }),
                          _vm._v(" "),
                          _c("nb-error-block", {
                            attrs: { path: `links.${index}.page_id` },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.sections,
                        disabled: _vm.nbReadOnly,
                        "track-by": "id",
                        label: "name",
                        multiple: false,
                        id: "sections-" + index,
                      },
                      on: {
                        remove: function ($event) {
                          return _vm.removeSection(index)
                        },
                      },
                      model: {
                        value: arrayLink.section,
                        callback: function ($$v) {
                          _vm.$set(arrayLink, "section", $$v)
                        },
                        expression: "arrayLink.section",
                      },
                    }),
                    _vm._v(" "),
                    _c("nb-error-block", {
                      attrs: { path: `links.${index}.section_id` },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.listArrayLink(
                          arrayLink.order,
                          arrayLink.section
                        ),
                        disabled: _vm.nbReadOnly,
                        "track-by": "id",
                        label: "id",
                        multiple: false,
                        id: "orders-" + index,
                      },
                      model: {
                        value: arrayLink.order,
                        callback: function ($$v) {
                          _vm.$set(arrayLink, "order", $$v)
                        },
                        expression: "arrayLink.order",
                      },
                    }),
                    _vm._v(" "),
                    _c("nb-error-block", {
                      attrs: { path: `links.${index}.order` },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                      attrs: {
                        type: "button",
                        id: "delete-link-" + index,
                        disabled: _vm.nbReadOnly,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteLink(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-minus p-0" })]
                  ),
                ]),
              ])
            }),
            0
          )
        : _c("tbody", [_vm._m(1)]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-plus" }),
      _vm._v(" "),
      _c("span", [_vm._v("Añadir enlace")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center py-6", attrs: { colspan: "5" } }, [
        _c("strong", { staticClass: "text-muted text-uppercase" }, [
          _vm._v("Sin registros"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }