var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-8 mb-3" },
    [
      _c("multiselect", {
        attrs: { options: _vm.surveyOptions, label: "name", "track-by": "id" },
        model: {
          value: _vm.surveySelected,
          callback: function ($$v) {
            _vm.surveySelected = $$v
          },
          expression: "surveySelected",
        },
      }),
      _vm._v(" "),
      !_vm.loading
        ? _c("SurveyResponsesComponent", {
            attrs: {
              surveyStructure: _vm.surveySelected.structure,
              responses: _vm.responses,
              showFilter: false,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }