<template>
    <div class="form-group m-form__group col-12">
        <input name="links" type="hidden" :value="listLinksJSON" />
        <input type="hidden" name="locale" :value="locale" />
        <div class="d-sm-flex justify-content-between">
            <h4 class="d-flex align-items-center mb-5">
                <em class="flaticon-list-2 mr-1"></em
                >{{ "cms_selector_page.linkType" | trans }}
            </h4>
            <button
                type="button"
                class="mb-5 btn btn-primary m-btn btn-sm m-btn m-btn--icon"
                id="add-link"
                @click="addLink()"
                :disabled="nbReadOnly"
            >
                <span>
                    <i class="fa fa-plus"></i>
                    <span>Añadir enlace</span>
                </span>
            </button>
        </div>
        <table class="table border gx-4 table-row-bordered">
            <thead>
                <tr>
                    <th width="15%">{{ "cms_selector_page.type" | trans }}</th>
                    <th width="30%">
                        {{ "cms_selector_page.name" | trans }}* ({{
                            locale.toUpperCase()
                        }})
                    </th>
                    <th width="15%">{{ "cms_selector_page.link" | trans }}*</th>
                    <th width="15%">
                        {{ "cms_selector_page.section" | trans }}
                    </th>
                    <th width="10%">{{ "cms_selector_page.order" | trans }}</th>
                    <th width="1%"></th>
                </tr>
            </thead>
            <tbody v-if="arrayLinks.length">
                <tr v-for="(arrayLink, index) in arrayLinks">
                    <td>
                        <input type="hidden" v-model="arrayLink.idLink" />
                        <multiselect
                            v-model="arrayLink.linkType"
                            :options="selectorData"
                            track-by="id"
                            :disabled="nbReadOnly"
                            label="name"
                            :multiple="false"
                            :id="'type-' + index"
                            @input="setToNull(index)"
                        >
                        </multiselect>
                    </td>
                    <td>
                        <label
                            :for="'name-link-' + index"
                            class="sr-only"
                        ></label>
                        <input
                            :id="'name-link-' + index"
                            type="text"
                            class="form-control-solid form-control m-input"
                            placeholder="Nombre"
                            aria-describedby="basic-addon2"
                            :disabled="nbReadOnly"
                            v-model="arrayLink.nameLink"
                        />
                        <nb-error-block
                            :path="`links.${index}.name`"
                        ></nb-error-block>
                        <p v-if="locale !== 'es'" class="m-form__help pl-2">
                            {{ getLocale(arrayLink.idLink, "es") }}
                        </p>
                    </td>
                    <td>
                        <template v-if="arrayLink.linkType.id === 1">
                            <label
                                :for="'link-' + index"
                                class="sr-only"
                            ></label>
                            <input
                                :id="'link-' + index"
                                type="url"
                                class="form-control-solid form-control m-input"
                                placeholder="Url"
                                aria-describedby="basic-addon2"
                                :disabled="nbReadOnly"
                                v-model="arrayLink.link"
                            />
                            <nb-error-block
                                :path="`links.${index}.link`"
                            ></nb-error-block>
                        </template>
                        <template v-if="arrayLink.linkType.id === 2">
                            <multiselect
                                v-model="arrayLink.page"
                                :options="pagesData"
                                track-by="id"
                                :disabled="nbReadOnly"
                                label="name"
                                :multiple="false"
                                :id="'pages-' + index"
                            >
                            </multiselect>
                            <nb-error-block
                                :path="`links.${index}.page_id`"
                            ></nb-error-block>
                        </template>
                    </td>
                    <td>
                        <multiselect
                            v-model="arrayLink.section"
                            :options="sections"
                            :disabled="nbReadOnly"
                            track-by="id"
                            label="name"
                            :multiple="false"
                            :id="'sections-' + index"
                            @remove="removeSection(index)"
                        >
                        </multiselect>
                        <nb-error-block
                            :path="`links.${index}.section_id`"
                        ></nb-error-block>
                    </td>
                    <td>
                        <multiselect
                            v-model="arrayLink.order"
                            :options="
                                listArrayLink(
                                    arrayLink.order,
                                    arrayLink.section
                                )
                            "
                            :disabled="nbReadOnly"
                            track-by="id"
                            label="id"
                            :multiple="false"
                            :id="'orders-' + index"
                        >
                        </multiselect>
                        <nb-error-block
                            :path="`links.${index}.order`"
                        ></nb-error-block>
                    </td>
                    <td class="align-middle text-center">
                        <button
                            type="button"
                            class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                            :id="'delete-link-' + index"
                            :disabled="nbReadOnly"
                            @click="deleteLink(index)"
                        >
                            <i class="fa fa-minus p-0"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="5" class="text-center py-6">
                        <strong class="text-muted text-uppercase"
                            >Sin registros</strong
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
    name: "CmsSelectorPage",
    components: { NbErrorBlock },
    props: {
        links: {
            type: Array,
            default: () => []
        },
        pages: {
            type: Object,
            default: () => {}
        },
        sections: {
            type: Array,
            default: () => []
        },
        locale: {
            type: String
        },
        defaultLangValue: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            selectorData: [
                { id: 1, name: "Url" },
                { id: 2, name: "Página" }
            ],
            linkType: null,
            pagesData: [],
            arrayLinks: [],
            newLink: {
                idLink: null,
                nameLink: null,
                link: null,
                page: null,
                order: null,
                linkType: { id: 1, name: "Url" },
                section: null
            }
        };
    },
    created() {
        this.pagesData = Object.keys(this.pages).map(key => {
            return {
                id: key,
                name: this.pages[key]
            };
        });

        if (this.links.length > 0) {
            this.arrayLinks = this.links.map(object => {
                return {
                    idLink: object.id,
                    nameLink: this.getLocale(
                        object.id,
                        this.locale,
                        object.name
                    ),
                    link: object.link,
                    page:
                        object.page_id !== null
                            ? this.pagesData.find(
                                  page =>
                                      parseInt(page.id) ===
                                      parseInt(object.page_id)
                              )
                            : null,
                    order: { id: object.order },
                    linkType:
                        object.link !== null
                            ? this.setLinkType(1)
                            : this.setLinkType(2),
                    section: object.section ?? null
                };
            });
        }
    },
    computed: {
        listLinksJSON() {
            return JSON.stringify(
                this.arrayLinks.map(arrayLink => {
                    return {
                        id: arrayLink.idLink,
                        name: arrayLink.nameLink,
                        link: arrayLink.link,
                        page_id:
                            arrayLink.page !== null ? arrayLink.page.id : null,
                        order:
                            arrayLink.order !== null
                                ? arrayLink.order.id
                                : null,
                        section:
                            arrayLink.section ?? null                             
                    };
                })
            );
        }
    },
    methods: {
        /**
         * Añade una nueva zona al array
         */
        addLink() {
            this.arrayLinks.push(Object.assign({}, this.newLink));
        },

        /**
         * Elimina la zona con el index pasado
         * por parametro
         * @param index
         */
        deleteLink(index) {
            let section = this.arrayLinks[index].section;
            let order = this.arrayLinks[index].order ? this.arrayLinks[index].order.id : 1;

            this.arrayLinks.splice(index, 1);

            for (let i = 0; i < this.arrayLinks.length; i++) {
                if (
                    this.arrayLinks[i].section == section &&
                    this.arrayLinks[i].order.id > order
                ) {
                    this.arrayLinks[i].order.id =
                        this.arrayLinks[i].order.id - 1;
                }
            }
        },
        setToNull(index) {
            if (this.arrayLinks[index].linkType === null) {
                this.arrayLinks[index].linkType = this.setLinkType(1);
            }

            if (this.arrayLinks[index].linkType.id === 1) {
                this.arrayLinks[index].page = null;
            } else {
                this.arrayLinks[index].link = null;
            }
        },
        setLinkType(id) {
            return this.selectorData.find(data => data.id === id);
        },

        listArrayLink(element, section) {
            if(section === null) {
                return [];
            }

            let arrayLinksSection = this.arrayLinks.filter(
                arrayLink => arrayLink.section !== null && arrayLink.section.id === section.id
            );

            let arrayOrder = arrayLinksSection.map((arrayLinksSection, ix) => {
                return {
                    id: ix + 1
                };
            });

            let orders = arrayLinksSection.map(arrayLink =>
                arrayLink.order !== null ? arrayLink.order.id : null
            );

            arrayOrder = arrayOrder.filter(order => !orders.includes(order.id));
            if (element !== null) {
                arrayOrder.push({ id: element.id });
            }

            return Object.values(_.pickBy(arrayOrder));
        },
        // TODO: Hacer la parte de esta traducción mejor
        getLocale(id, locale, name) {
            let lang = null;
            if (id !== null) {
                let object = this.links.find(object => object.id === id);
                if (object.translations !== undefined) {
                    lang = object.translations.find(
                        translation => translation.locale === locale
                    );
                    if (lang !== undefined) {
                        lang = lang.name;
                    }
                } else {
                    lang = object.name;
                }
            }else{
                lang=name;
            }
            return lang;
        },
        removeSection(index, value) {
            this.arrayLinks[index].order = null;
        }
    },
};
</script>

<style scoped></style>
