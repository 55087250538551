var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group m-form__group col-md-6 mb-6" },
    [
      _c("label", [_vm._v(_vm._s(_vm._f("trans")(_vm.selectLabel)))]),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          name: _vm.name,
          selectLabel: _vm.selectLabel,
          selectGroupLabel: _vm.selectGroupLabel,
          selectedLabel: _vm.selectedLabel,
          deselectLabel: _vm.deselectLabel,
          deselectGroupLabel: _vm.deselectGroupLabel,
          showLabels: _vm.showLabels,
          limit: _vm.limit,
          maxHeight: _vm.maxHeight,
          limitText: _vm.limitText,
          loading: _vm.loading,
          disabled: _vm.disabled,
          openDirection: _vm.openDirection,
          showNoOptions: _vm.showNoOptions,
          showNoResults: _vm.showNoResults,
          tabindex: _vm.tabindex,
          internalSearch: _vm.internalSearch,
          options: _vm.options,
          value: _vm.value,
          label: _vm.label,
          multiple: _vm.multiple,
          id: _vm.multiselectId,
          trackBy: _vm.trackBy,
          searchable: _vm.searchable,
          clearOnSelect: _vm.clearOnSelect,
          hideSelected: _vm.hideSelected,
          placeholder: _vm.placeholder,
          allowEmpty: _vm.allowEmpty,
          resetAfter: _vm.resetAfter,
          closeOnSelect: _vm.closeOnSelect,
          customLabel: _vm.customLabel,
          taggable: _vm.taggable,
          tagPlaceholder: _vm.tagPlaceholder,
          tagPosition: _vm.tagPosition,
          max: _vm.max,
          optionsLimit: _vm.optionsLimit,
          groupValues: _vm.groupValues,
          groupLabel: _vm.groupLabel,
          groupSelect: _vm.groupSelect,
          blockKeys: _vm.blockKeys,
          preserveSearch: _vm.preserveSearch,
          preselectFirst: _vm.preselectFirst,
          showPointer: _vm.showPointer,
          optionHeight: _vm.optionHeight,
        },
        model: {
          value: _vm.current_value,
          callback: function ($$v) {
            _vm.current_value = $$v
          },
          expression: "current_value",
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentHiddenValue,
            expression: "currentHiddenValue",
          },
        ],
        attrs: { type: "hidden", name: _vm.id, id: _vm.id },
        domProps: { value: _vm.currentHiddenValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.currentHiddenValue = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }