var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-5 mx-4 mb-0" }, [
    _c("div", { staticClass: "col-auto" }, [
      _c(
        "label",
        { staticClass: "d-block" },
        [
          _c("span", { staticClass: "fw-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("trans")(
                    "conference.properties.property_chat.moderators"
                  )
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("multiselect", {
            attrs: {
              options: _vm.moderatorsOptions,
              label: "name",
              "track-by": "id",
              multiple: true,
            },
            model: {
              value: _vm.moderators,
              callback: function ($$v) {
                _vm.moderators = $$v
              },
              expression: "moderators",
            },
          }),
          _vm._v(" "),
          _c("NbErrorBlock", {
            attrs: { path: `properties.${_vm.propertyKey}.moderators` },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }