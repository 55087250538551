<template>
  <div>
    <iframe width="100%" height="600" frameborder="0" :src="mapSrc" />
  </div>
</template>

<script>
export default {
  name: 'cms-staticMap',
  props: {
    query: { type: String, required: true , default:'Parque Científico y Tecnológico de Albacete Paseo de la Innovación, 3 · Albacete, Albacete 02006'},
    title: { type: String, default: '' },
  },
  computed: {
    mapSrc () {
      return `https://maps.google.com/maps?hl=es&q=${this.query}+(${this.title})&;z=14&ie=UTF8&iwloc=B&output=embed`
    }
  },
}

</script>
