<template>
    <div class="form-group mb-6 col-md-6">
        <input type="hidden" name="additionalRooms" :value="jsonAdditionalRooms" />
        <label class="form-label">{{ label }}</label>
        <multiselect v-model="roomsSelect" :options="rooms" label="name" track-by="id" :multiple="true">
        </multiselect>
        <nb-error-block :path="additionalRooms" />
        <nb-error-block :path="getPathError()" />
    </div>
</template>
  
<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
    name: "SelectAdditionalRooms",
    components: { NbErrorBlock },
    props: {
        label: String,
        additionalRooms: Array,
        rooms: Array,
        main_room_id: Number
    },
    data() {
        return {
            roomsSelect: [],
            mainRoomSelected: [],
        };
    },
    created() {
        this.roomsSelect = this.rooms.filter((room) => this.additionalRooms.map((additionalRoom) => Number(additionalRoom)).includes(room.id));
    },
    computed: {
        jsonAdditionalRooms() {
            return JSON.stringify(this.roomsSelect.map((room) => String(room.id)));
        },
    },
    methods: {
        getPathError() {
            const duplicateIndex = this.additionalRooms.findIndex((roomId) => Number(roomId) == this.main_room_id);
            return duplicateIndex ? 'additionalRooms.' + duplicateIndex : null
        },

    },
};
</script>
  