<template>
  <div class="w-100">
    <input type="hidden" name="paymentMethods" id="paymentMethods" :value="paymentMethodsJSON" />
    <div class="row">
      <span class="field__span form-label field__span--required">
        {{ 'event.payment_types' | trans }}
      </span>
      <div v-for="(paymentType, index) in paymentTypes" :key="paymentType.id" class="col col-4">
        <span class="field__span form-label field__span--required">
          {{ paymentType.name }}
        </span>
        <select v-model="paymentType.value" class="form-control" @input="setPayment($event, paymentType.id)">
          <option :value="null">
            {{ 'event.payment_methods_select_placeholder' | trans }}
          </option>
          <option v-for="paymentMethod in paymentType.payment_methods" :key="paymentMethod.id" :value="paymentMethod.id">
            {{ paymentMethod.name }}
          </option>
        </select>
        <nb-error-block :path="`paymentMethods.${index}.paymentMethod`"></nb-error-block>
      </div>
    </div>
  </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

  export default {
    name: 'Payment',
    components: {
      NbErrorBlock
    },
    props: {
      paymentTypes: {
        required: true,
        type: Array,
      },
      oldPayments: {
        required: true,
        type: Array,
      },
    },
    data: function () {
      return {
        payments: [],
      }
    },
    created () {
      this.init()
    },
    computed: {
      paymentMethodsJSON () {
        return JSON.stringify(this.payments)
      },
    },
    methods: {
      init () {
        for (const paymentType of this.paymentTypes) {
          const oldPayment = this.oldPayments.find(payment => payment.paymentType === paymentType.id)
          const paymentMethod = oldPayment ? oldPayment.paymentMethod : ''
          const payment = {
            paymentType: paymentType.id,
            paymentMethod,
          }
          this.payments.push(payment)
        }
      },
      setPayment (event, paymentTypeId) {
        const paymentMethodId = event.target.value ?? null
        const index = this.payments.findIndex(payment => payment.paymentType === paymentTypeId)
        this.payments[index].paymentMethod = paymentMethodId
      }
    },
  }
</script>

<style scoped>

</style>
