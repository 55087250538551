<template>
    <div class="form-group m-form__group col-12">
        <div class="mx-2 mt-4">
            <h4 class="d-flex align-items-center m-0 float-start pt-5 mt-2">
                Listado de preguntas
                <div v-if="loading" class="m-loader m-loader--info" style="width: 30px; display: inline-block;"></div>
            </h4>
            <bootstrap-table ref="table"
                class="table table-bordered border-dark table-striped table-row-gray-300 align-middle gs-0 gy-2 table-disable-hover"
                :data="questionsData" :columns="columns" :options="options">
            </bootstrap-table>
        </div>
    </div>
</template>

<script>
import Paginator from "@/js/components/widgets/Paginator";
import "bootstrap-table/dist/bootstrap-table.min.js";
import BootstrapTable from "bootstrap-table/dist/bootstrap-table-vue.esm.js";

export default {
    name: "ConferencePropertyTutoredQuestions",
    components: {
        Paginator,
        "bootstrap-table": BootstrapTable,
    },
    props: {
        lang: {
            type: String,
            default: null
        },
        questions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            questionsData: this.questions,
            loading: false,
            columns: [
                {
                    title: "ID",
                    field: "id",
                    visible: false
                },
                {
                    title: "Pregunta",
                    field: "text",
                    sortable: true
                },
                {
                    title: "Cliente",
                    field: "customer.name",
                    sortable: true
                },
                {
                    align: "center",
                    clickToSelect: false,
                    formatter: (value, row) => {
                        return `
                        <button id="validate" type="button" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" ${row.validate ? "disabled" : ""}>
                            <i class="fa fa-check"></i>
                        </button>
                        <button id="remove" type="button" class="btn btn-icon btn-light-danger btn-sm me-1" ${this.lang !== null ? "disabled" : ""}>
                            <i class="fa fa-trash"></i>
                        </button>
                        `;
                    },
                    events: {
                        "click #validate": (e, value, row, index) => {
                            this.validateQuestion(row.id);
                        },
                        "click #remove": (e, value, row, index) => {
                            this.deleteQuestion(row.id);
                        },
                    },
                },
            ],
            options: {
                showColumns: true,
                pagination: true,
                sidePagination: "client",
                pageList: [10, 25, 50, 100],
                pageSize: 8,
            },
        };
    },
    methods: {
        async deleteQuestion(id) {
            let questionsFilter = this.questionsData.filter(item => item.id !== id);
            this.questionsData = questionsFilter;

            this.$emit('update-questions', this.questionsData);

        },
        validateQuestion(id) {
            let questionsArray = this.questionsData.map(item => {
                if (item.id === id) {
                    item.validate = true;
                }
                return item;
            });
            
            this.$emit('update-questions', questionsArray);
        },
    }
};
</script>
