var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      attrs: { type: "hidden", name: "properties" },
      domProps: { value: _vm.propertiesJSON },
    }),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass:
          "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
      },
      [
        _c(
          "tbody",
          _vm._l(_vm.propertiesStructure, function (property, key) {
            return _c("tr", { key: key }, [
              _c("td", { staticClass: "align-top py-5" }, [
                _c("div", { staticClass: "m-form__control" }, [
                  _c("label", { staticClass: "bg-light p-4" }, [
                    _c("input", {
                      staticClass: "form-check-input me-2",
                      attrs: {
                        type: "checkbox",
                        name: "posted",
                        id: "posted",
                        "data-bs-target": "#kt_accordion_1_body_1",
                      },
                      domProps: { checked: _vm.existsProperty(key) },
                      on: {
                        click: function ($event) {
                          return _vm.checkProperty(key, $event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "cursor-pointer" }, [
                      _vm._v(_vm._s(property.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.existsProperty(key)
                    ? _c(
                        "div",
                        {
                          staticClass: "accordion-body",
                          attrs: { id: "kt_accordion_1_body_1" },
                        },
                        [
                          _c(
                            property.component,
                            _vm._b(
                              {
                                tag: "component",
                                attrs: {
                                  value: _vm.selectedProperties[key],
                                  "property-key": key,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setProperty($event, key)
                                  },
                                },
                              },
                              "component",
                              property.props,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }