var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c("div", { staticClass: "mx-2 mt-4" }, [
      _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
        _c("h4", { staticClass: "d-flex align-items-center mb-5" }, [
          _c("em", { staticClass: "flaticon-alert mr-1" }),
          _vm._v("Listado de preguntas\n                "),
          _vm.loading
            ? _c("div", {
                staticClass: "m-loader m-loader--info",
                staticStyle: { width: "30px", display: "inline-block" },
              })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table",
        {
          staticClass:
            "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.questions.length
            ? _c(
                "tbody",
                _vm._l(_vm.questions, function (question) {
                  return _c("tr", { key: question.id }, [
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(question.text) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(question.customer.name) +
                          "\n                        " +
                          _vm._s(question.customer.surname) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "min-col" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-icon btn-danger btn-sm",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteOption(question.id)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-trash" })]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle text-center" }),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(1)]),
        ]
      ),
      _vm._v(" "),
      _vm.thereIsData && _vm.hasPagination
        ? _c(
            "div",
            { staticClass: "text-right clearfix" },
            [
              _c("paginator", {
                staticClass: "pb-6",
                attrs: {
                  "current-page": _vm.currentPage,
                  links: _vm.paginationLinks,
                  meta: _vm.paginationMeta,
                  "loading-data": _vm.loading,
                  "loading-page-number": _vm.loadingPageNumber,
                },
                on: { "set-page": _vm.setPageNumber },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Pregunta")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cliente")]),
        _vm._v(" "),
        _c("th"),
        _vm._v(" "),
        _c("th", { attrs: { width: "1%" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center py-4", attrs: { colspan: "6" } }, [
        _vm._v(
          "\n                        No hay preguntas para esta conferencia.\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }