var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("input", {
        attrs: {
          type: "hidden",
          name: "custom_form_type",
          id: "custom_form_type",
        },
        domProps: { value: _vm.selectedType.id },
      }),
      _vm._v(" "),
      _c("label", { staticClass: "form-label" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("trans")("custom_form.custom_form_type")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("multiselect", {
        staticClass: "field--select field--select-grey p-4",
        attrs: {
          id: "custom-form-type",
          options: _vm.types,
          label: "name",
          "track-by": "id",
        },
        model: {
          value: _vm.selectedType,
          callback: function ($$v) {
            _vm.selectedType = $$v
          },
          expression: "selectedType",
        },
      }),
      _vm._v(" "),
      _c("div", [_vm._v("\n    " + _vm._s(_vm.variablesMessage) + "\n  ")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n    " + _vm._s(_vm.telephoneMessage) + "\n  ")]),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: "custom_form_type" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }