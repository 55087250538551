<template>
    <div class="mb-6" :class="getClass">
        <input type="hidden" :name="field" :id="field" :value="getFieldValue">
        <label class="form-label" v-if="name">{{ name }}</label>
        <!-- ToDo: https://preview.keenthemes.com/metronic8/demo1/documentation/forms/image-input.html" -->
        <!-- ToDo: https://preview.keenthemes.com/metronic8/demo1/documentation/forms/dropzonejs.html#basic" -->
        <div class="list-files form-control ">
            <div class="list-show" v-if="listFiles.length && multiple" :class="{'list-show-read-only': nbReadOnly}">
                <div class="row">
                    <div class="list-show-col"
                         :class="{'col-6': !largeVersion, 'col-4 list-show-col-large': largeVersion}"
                         v-for="(file, key) in listFiles">
                        <div class="list-show-file">
                            <div class="list-show-file-delete" v-show="!nbReadOnly">
                                <button type="button" :id="'remove-file-' + key"
                                        class="m-btn btn btn-danger m-btn--icon btn-sm m-btn--icon-only"
                                        @click="removeFile(key)">
                                    <em class="fa fa-trash fa-fw"></em>
                                </button>
                            </div>
                            <div class="list-show-file-text"
                                 :class="{'list-show-file-text-short' : thumb, 'list-show-file-text-read-only': nbReadOnly}">
                                {{ file.name }}
                            </div>
                            <div class="list-show-file-img" v-if="thumb && isShowable(file)">
                                <img :src="file.url" :alt="file.name" @error="imgDefault" @click="showModalView(file)"/>
                            </div>
                            <div class="list-show-file-download" v-else>
                                <button type="button" :id="'download-file-' + key"
                                        class="m-btn btn btn-brand m-btn--icon btn-sm m-btn--icon-only"
                                        @click="downloadFile(file)">
                                    <em class="fa fa-download fa-fw"></em>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show-file" v-if="listFiles.length && showFile"
                 :class="{'show-file-short-version': shortVersion, 'show-file-read-only': nbReadOnly}">
                <img :src="showFile.url" :alt="showFile.name" v-if="thumb && isShowable(showFile)"
                     @click="showModalView(showFile)" @error="imgDefault"/>
                <span>{{ showFile.name }}</span>
                <div class="list-show-file-download" v-if="!(thumb && isShowable(showFile))">
                    <button type="button" id="show-download"
                            class="m-btn btn btn-brand m-btn--icon btn-sm m-btn--icon-only"
                            @click="downloadFile(showFile)">
                        <em class="fa fa-download fa-fw"></em>
                    </button>
                </div>
            </div>
            <div v-if="nbReadOnly">
                <div v-if="!listFiles.length && !multiple">
                    {{ 'forms_repository.file_not_selected' | trans }}
                </div>
                <div v-if="!listFiles.length && multiple">
                    {{ 'forms_repository.files_not_selected' | trans }}
                </div>
            </div>
            <div class="row" v-show="!nbReadOnly" v-if="!isDisabled">
                <div class="d-flex align-items-center list-files-select"
                     :class="{'col-12': shortVersion, 'col-6': !shortVersion && !largeVersion, 'col-10': largeVersion}">
                    <span class="m-badge m-badge--info m-badge--wide" v-if="multiple">
                        {{ listFiles.length }} {{ 'forms_repository.selected' | trans }}
                    </span>
                    <div v-if="!multiple">
                        <button type="button" id="remove-all-files"
                                class="m-btn btn btn-danger m-btn--icon btn-sm"
                                :class="{'btn-block mb-2': shortVersion}"
                                @click="removeAllFile()"
                                v-if="listFiles.length">
                            <em class="fa fa-trash fa-fw me-1"></em>
                            {{ 'forms_repository.clear_files' | trans }}
                        </button>
                        <div class="list-files-select-text text-muted" :class="{'mb-3': shortVersion}" v-else>
                            {{ 'forms_repository.selecte_file' | trans }}
                        </div>
                    </div>
                </div>
                <div    class="text-end"
                        :class="{'col-12': shortVersion, 'col-6': !shortVersion && !largeVersion, 'col-2': largeVersion}">
                    <button v-if="!listFiles.length && !loading" type="button" class="p-0 m-btn btn btn-brand m-btn--icon btn-sm btn-block"
                            @click="showRepository = true"
                            :id="'short-version-' + field" :disabled="loading">
                        <div class="m-loader m-loader--sm m-loader--light py-3" v-if="loading"></div>
                        <span v-if="!listFiles.length && !loading">
                            <em class="fa fa-plus fa-fw me-1"></em>
                            <span v-if="multiple">{{ 'forms_repository.add_files' | trans }}</span>
                            <span v-else>{{ 'forms_repository.add_file' | trans }}</span>
                        </span>
                    </button>
                    <button v-if="listFiles.length && !loading" type="button" class="btn btn-dark m-btn btn btn-brand m-btn--icon btn-sm btn-block"
                            @click="showRepository = true"
                            :id="'short-version-' + field" :disabled="loading">
                        <div class="m-loader m-loader--sm m-loader--light py-3" v-if="loading"></div>
                        <span>
                            <em class="fa fa-pencil-alt fa-fw me-1"></em>
                            {{ 'forms_repository.edit_files' | trans }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <nb-error-block :path="field"></nb-error-block>

        <modal-component id="repositoryModal"
                         :visible.sync="openModal"
                         :backdrop="true"
                         :keyboard="true">
            <template #title>
                {{ image.name }}
            </template>
            <template>
                <div class="modal-body-image">
                    <img :src="image.url" :alt="image.name" @error="imgDefault"/>
                </div>
            </template>
        </modal-component>

        <modal-component id="repositoryNavigation"
                         tabindex="-1"
                         :visible.sync="showRepository"
                         :backdrop="true"
                         :keyboard="true"
                         :classesBody="['box-scroll']"
                         :classesModalDialog="['modal-dialog-centered', 'modal-xl']">
            <template #title>
                {{ 'forms_repository.list_files' | trans }}
            </template>
            <template>
                <repository v-if="showRepository" :active-select="true" :types="getListTypes"
                            :type-default="typeDefault"
                            :field="true" :select-files="listFiles" :multiple="multiple"
                            :clear-select="clearSelect" :allowed-mime-types="allowedMimeTypes"
                            :group-id="groupId"
                            :group-type="groupType"
                            :isPrivateUpload="isPrivateUpload"
                            @repositoryInput="setInput"
                            @repositorySetOptions="setOptions"
                            @close="showRepository = false">
                </repository>
            </template>
            <template #footer v-if="multiple">
                <button type="button" class="m-btn btn btn-brand m-btn--icon" @click="setOptions"
                        id="options-repo" :disabled="!button">
                    <span v-if="!listFiles.length">
                        <em class="fa fa-plus fa-fw me-1"></em>
                        {{ 'forms_repository.add_files' | trans }}
                    </span>
                    <span v-if="listFiles.length">
                        <em class="fa fa-pencil-square fa-fw me-1"></em>
                        {{ 'forms_repository.edit_files' | trans }}
                    </span>
                </button>
            </template>
        </modal-component>

    </div>
</template>

<script>
  import Repository from '@/js/components/repository_explorer/Repository'
  import ModalComponent from '@/js/components/widgets/ModalComponent'
  import NbErrorBlock from '@/js/components/error_block/nb-error-block'
  import { mapGetters } from 'vuex'

  export default {
    name: 'repository-button',
    components: { Repository, ModalComponent, NbErrorBlock },
    props: {
      name: { String, default: '' },
      field: { String, default: '' },
      value: null,
      multiple: { Boolean, default: false },
      thumb: { Boolean, default: true },
      classField: { String, default: null },
      types: { Object, default: () => ({}) },
      typeDefault: { Number },
      groupId: { Number },
      groupType: { String },
      allowedMimeTypes: { String, default: '*' }, // Regex que acepta cualquier cosa
      shortVersion: { Boolean, default: false },
      largeVersion: { Boolean, default: false },
      isPrivateUpload: { type: Boolean, default: false },
      isDisabled: { type: Boolean, default: false }
    },
    mounted () {
      this.$nextTick(() => {
        this.setValue(this.value)
        if (!Object.keys(this.types).length) {
          this.getTypes()
        }
      })
    },
    data () {
      return {
        button: false,
        loading: false,
        showRepository: false,
        clearSelect: false,
        image: false,
        openModal: false,
        options: [],
        listFiles: [],
        listTypes: {}
      }
    },
    computed: {
      ...mapGetters('Repository', {
        showable: 'getShowable'
      }),
      getListTypes () {
        if (Object.keys(this.listTypes).length) {
          return this.listTypes
        }
        return this.types
      },
      getFieldValue () {
        let list = []

        for (const file of this.listFiles) {
          list.push(file.id)
        }

        if (list.length > 0) {
          if (!this.multiple) {
            return parseInt(list[0])
          }
          return JSON.stringify(list)
        }

        if (this.value !== null && this.listFiles.length) {
            if (!this.multiple) {
                return parseInt(this.value)
            }
            return JSON.stringify(this.value)
        }

        return ''
      },

      showFile () {
        if (!this.multiple && this.listFiles.length) {
          return this.listFiles[0]
        }
        return false
      },
      getClass () {
        if (this.classField) {
          return this.classField
        }
        return 'form-group m-form__group ' + (this.largeVersion ? 'col-md-12' : 'col-md-6')
      },

    },
    methods: {
      setOptions () {
        if (this.button && this.options.length > 0) {
          let key

          for (key in this.options) {
            this.setOptionsFile(this.options[key])
          }

          this.clearSelect = true
          this.showRepository = false
        }
      },
      getOptionsFile (fileSelect) {
        let remove = false
        let fileId = parseInt(fileSelect.id)
        let position = this.listFiles.findIndex(file => file.id === fileId)

        if (fileSelect.hasOwnProperty('delete')) {
          if (fileSelect.delete) {
            remove = true
          }
        }
        return [remove, position]
      },
      imgDefault (event) {
        event.target.src = '/images/logo/logo_default_light.png'
      },
      setValue (value) {
        if (value == null) {
          return
        }

        let listIds = value
        if (Array.isArray(listIds)) {
          listIds = listIds.join(',')
        } else {
          listIds = parseInt(listIds)
        }

        this.getFiles(listIds)
      },
      async getTypes () {
        this.loading = true
        try {
          let response = await axios.get(route('API.repository.types', {}, false))
          this.listTypes = response.data
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      },
      async getFiles (ids) {
        this.loading = true
        try {
          let response = await axios.get(route('API.repository.getFiles', {
            files: ids,
            group_id: this.groupId,
            group_type: this.groupType,
          }, false))
          this.filterFiles(response.data)
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      },
      filterFiles (data) {
        this.listFiles = data.files
      },
      isShowable (file) {
        return this.showable.hasOwnProperty(file.mime_type)
      },
      setInput (list) {
        this.button = false
        this.options = []

        if (list.length > 0) {
          this.button = true
          this.options = list
          this.$emit('input', list.map(img => img.id).join(','))
        }
      },
      setOptionsFile (fileSelect) {
        let remove
        let position

        [remove, position] = this.getOptionsFile(fileSelect)

        if (fileSelect.hasOwnProperty('delete')) {
          if (fileSelect.delete) {
            remove = true
          }
        }

        if (!remove && position < 0) {
          this.listFiles.push(fileSelect)
        }

        if (remove && position >= 0) {
          this.listFiles.splice(position, 1)
        }

        if (!this.multiple && this.listFiles.length > 1) {
          this.listFiles.shift()
        }
      },
      removeFile (key) {
        this.listFiles.splice(key, 1)
      },
      removeAllFile () {
        this.listFiles = []
        this.$emit('clear-value')
      },
      showModalView (image) {
        if (this.isShowable(image)) {
          this.image = image
          this.openModal = true
        }
      },
      closeModalView () {
        this.image = null
        this.openModal = false
      },
      downloadFile (file) {
        let url = `/repository/files/${file.id}/0/download`
        window.open(url, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
    .list-files {
        //border: 1px solid #F1F1F1;
        //border-radius: 0.25rem;
        //padding: 10px;

        .list-show {
            background-color: #F9F9F9;
            border-radius: 0.25rem;
            padding: 10px 10px 0px 10px;
            margin-bottom: 10px;

            .list-show-file {
                margin-bottom: 10px;
                background-color: #F1F1F1;
                overflow: hidden;
                border-radius: 0.25rem;

                .list-show-file-delete {
                    float: left;
                    padding: 10px;
                }

                .list-show-file-download {
                    float: right;
                    padding: 10px;
                }

                .list-show-file-text {
                    float: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 80%;
                    padding-top: 15px;
                }

                .list-show-file-text-short {
                    width: 60%;
                }

                .list-show-file-text-read-only {
                    padding-left: 15px;
                }

                .list-show-file-img {
                    float: right;

                    img {
                        transition: 0.3s ease;
                        max-height: 50px;

                        &:hover {
                            cursor: pointer;
                            opacity: 0.5;
                        }
                    }
                }
            }

            .list-show-col:nth-child(2n+0) {
                padding-left: 0px;
            }

            .list-show-col-large {
                padding-left: 15px !important;
            }
        }

        .list-show-read-only {
            margin-bottom: 0;
        }

        .show-file {
            //background-color: #F9F9F9;
            border-radius: 0.25rem;
            padding: 10px 0;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            img {
                margin: 0px 5px 0px 0px;
                max-height: 50px;
                border-radius: 0.25rem;
                transition: 0.3s ease;

                &:hover {
                    cursor: pointer;
                    opacity: 0.5;
                }
            }

            span {
                vertical-align: sub;
            }

            .list-show-file-download {
                float: right;
            }
        }

        .show-file-read-only {
            margin-bottom: 0px;
        }

        .show-file-short-version {
            white-space: normal !important;
        }
    }

    .box-scroll {
        height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal-body-image {
        text-align: center;

        img {
            max-width: 100%;
        }
    }
</style>
