var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-5 mx-4 mb-0" }, [
    _c("div", { staticClass: "form-check form-switch" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.external,
            expression: "external",
          },
        ],
        staticClass: "form-check-input",
        attrs: {
          type: "checkbox",
          id: "switchPropertyEventExternal",
          disabled: "",
        },
        domProps: {
          checked: Array.isArray(_vm.external)
            ? _vm._i(_vm.external, null) > -1
            : _vm.external,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.external,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.external = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.external = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.external = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form-check-label",
          attrs: { for: "switchPropertyEventExternal" },
        },
        [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm._f("trans")("conference.properties.property_event.external")
              ) +
              "\n            "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    !_vm.external
      ? _c("div", [
          _c("span", [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm._f("trans")("conference.properties.property_event.event")
                ) +
                " " +
                _vm._s(_vm.value.eventName) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: `/events/${_vm.value.eventId}/edit` } }, [
            _vm._v(
              _vm._s(
                _vm._f("trans")(
                  "conference.properties.property_event.go_to_event"
                )
              )
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.external
      ? _c("div", { staticClass: "form-check form-switch" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.overwriteEventData,
                expression: "overwriteEventData",
              },
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: "switchPropertyEventOverwrite" },
            domProps: {
              checked: Array.isArray(_vm.overwriteEventData)
                ? _vm._i(_vm.overwriteEventData, null) > -1
                : _vm.overwriteEventData,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.overwriteEventData,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.overwriteEventData = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.overwriteEventData = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.overwriteEventData = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "switchPropertyEventOverwrite" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("trans")(
                      "conference.properties.property_event.overwrite_event_data"
                    )
                  ) +
                  "\n            "
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.external
      ? _c(
          "label",
          { staticClass: "mb-3" },
          [
            _c("span", { staticClass: "fw-bold" }, [
              _vm._v(
                _vm._s(
                  _vm._f("trans")("conference.properties.property_event.url")
                )
              ),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.url,
                  expression: "url",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.url },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.url = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.url` },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-check form-switch" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.directLink,
            expression: "directLink",
          },
        ],
        staticClass: "form-check-input",
        attrs: { type: "checkbox", id: "switchPropertyEventDirectLink" },
        domProps: {
          checked: Array.isArray(_vm.directLink)
            ? _vm._i(_vm.directLink, null) > -1
            : _vm.directLink,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.directLink,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.directLink = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.directLink = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.directLink = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form-check-label",
          attrs: { for: "switchPropertyEventDirectLink" },
        },
        [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm._f("trans")(
                  "conference.properties.property_event.direct_link"
                )
              ) +
              "\n            "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }