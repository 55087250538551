<template>
    <div class="mt-5 mx-4 mb-0">
        <div class="row mb-5 pb-5">
          <div class="col-12 col-lg-6 mb-4">
              <label class="d-block">
                  <span class="fw-bold">Desde</span>
                  <input v-model="startDate" type="datetime-local" class="form-control mr-4" :disabled="nbReadOnly"
                  placeholder="Inicio" name="startDate" />
                  <NbErrorBlock :path="`properties.${propertyKey}.start_date`" />
              </label>
          </div>
          <div class="col-12 col-lg-6 mb-4">
                <label class="d-block">
                    <span class="fw-bold">Hasta</span>
                    <input v-model="endDate" type="datetime-local" class="form-control mr-4" :disabled="nbReadOnly"
                    placeholder="Inicio" name="endDate" />
                    <NbErrorBlock :path="`properties.${propertyKey}.end_date`" />
                </label>
            </div>
        </div>
        <div class="mb-5 pb-5">
            <h4>Pago</h4>
            <div class="mb-3 form-check form-check-custom form-check-solid">
                <label class="form-check-label ms-0">
                    <input
                        id="RegistrableTypeRadioPaid"
                        class="form-check-input me-1"
                        type="radio"
                        :value="PropertyRegistrableType.PAID"
                        v-model="type"
                        disabled
                    />
                    <span class="fw-bold fs-6">De pago</span>
                </label>
            </div>

            <div class="mb-3 form-check form-check-custom form-check-solid">
                <label class="form-check-label ms-0">
                    <input
                        id="RegistrableTypeRadioFree"
                        class="form-check-input me-1"
                        type="radio"
                        :value="PropertyRegistrableType.FREE"
                        v-model="type"
                    />
                    <span class="fw-bold fs-6">Gratuita</span>
                </label>
            </div>

            <div class="mb-3 form-check form-check-custom form-check-solid">
                <label class="form-check-label ms-0">
                    <input
                        id="RegistrableTypeRadioExternal"
                        class="form-check-input me-1"
                        type="radio"
                        :value="PropertyRegistrableType.EXTERNAL"
                        v-model="type"
                    />
                    <span class="fw-bold fs-6">Externa</span>
                    <NbErrorBlock :path="`properties.${propertyKey}.type`"/>
                </label>
            </div>
            <div class="ms-4 ps-5 mb-5" v-if="type === PropertyRegistrableType.EXTERNAL">
                <label>
                    <span class="fw-bold">Url</span>
                    <input class="form-control" type="text" v-model="externalUrl"/>
                    <NbErrorBlock :path="`properties.${propertyKey}.externalUrl`"/>
                </label>
            </div>
        </div>

        <div class="my-5 pb-5" v-if="type === PropertyRegistrableType.PAID">
            <h4>Coste pago</h4>
            <label class="mb-3">
                <span class="fw-bold">Precio</span>
                <input class="form-control" type="number" v-model="price" min="0.01" step=".01"/>
                <NbErrorBlock :path="`properties.${propertyKey}.price`"/>
            </label>

            <label class="mb-3">
                <span class="fw-bold">IVA</span>
                <input class="form-control" type="number" v-model="vat" min="0" max="100"/>
                <NbErrorBlock :path="`properties.${propertyKey}.vat`"/>
            </label>

            <!-- TODO: PAYMENTS DE momento solo pago por redsys -->
            <label class="mb-3">
                <span class="fw-bold">TPV Redsys</span>
                <select class="form-control" id="paymentMethodId" v-model="paymentMethodId">
                    <option v-for="paymentMethod in paymentMethods" :value="paymentMethod.id">
                        {{ paymentMethod.name }}
                    </option>
                </select>
                <NbErrorBlock :path="`properties.${propertyKey}.paymentMethodId`"/>
            </label>
        </div>
        <div class="my-5 pb-5">
            <h4>Info</h4>
            <div class="mb-3 form-check">
                <label class="form-check-label">
                    <input id="NeedsMoreInfoCheck" class="form-check-input me-2" type="checkbox" v-model="needsMoreInfo"/>
                    <span class="fw-bold">Necesita más información</span>
                </label>
            </div>
            <div class="mb-5 ms-4 ps-5" v-if="needsMoreInfo">
                <label>
                    <span class="fw-bold">Formulario</span>
                    <select class="form-control" id="customFormId" v-model="customFormId">
                        <option v-for="customForm in customForms" :value="customForm.id">
                            {{ customForm.name }}
                        </option>
                    </select>
                    <NbErrorBlock :path="`properties.${propertyKey}.customFormId`"/>
                </label>
            </div>
        </div>

        <div class="my-5">
            <h4>Acceso</h4>
            <div class="mb-3">
                <div class="form-check">
                    <label class="form-check-label">
                        <input id="HasAccessControlCheck" class="form-check-input" type="checkbox" v-model="hasAccessControl"/>
                        <span class="fw-bold">Control de acceso</span>
                    </label>
                </div>
            </div>
            <div class="mb-5 ms-4 ps-5">
            <div v-if="hasAccessControl" class="form-check">
                <label class="form-check-label">
                    <input id="CertificatesEnabledCheck" class="form-check-input" type="checkbox"
                           v-model="certificatesEnabled"/>
                    <span class="fw-bold">Certificados asistencia</span>
                </label>
            </div>
            </div>
        </div>
    </div>
</template>


<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block"
import PropertyRegistrableType from  '@/js/components/conference/properties/PropertyRegistrableType'
import StartAndEndDate from '@/js/components/conference/properties/StartAndEndDates';

import money from 'dinero.js'

export default {
  name: "ConferencePropertyRegistrable",
  mixins: [ StartAndEndDate ],
  props: {
    customForms: {
        type: Array,
        default: () => [],
    },
    paymentMethods: {
        type: Array,
        default: () => [],
    },
    lang: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      required: true
    },
    propertyKey: {
      type: String,
      required: true
    }
  },
  components: {
    NbErrorBlock
  },
  data() {
    return {
      loading: false,
      PropertyRegistrableType,
    };
  },
  computed: {
    type: {
      get() {
        return this.value.type
      },
      set(value) {
        this.emit({ type: value })
      },
    },
    price: {
      get() {
        if (!this.value.price) {
            return null
        }
        // TODO: CURRENCIES Hay que pensar resto monedas
        const price = money({amount: this.value.price, currency: 'EUR'})
        const format = price.hasSubUnits(price) ? '0.00' : '0'
        return price.toFormat(format)
      },
      set(value) {
        // TODO: CURRENCIES Esto no vale para monedas que no tengan decimales
        const price = value.includes('.') ? value.replace('.', '') : value + '00'
        this.emit({ price: Number.parseInt(price) })
      },
    },
    vat: {
      get() {
        if (!this.value.vat) {
            return null
        }
        return this.value.vat * 100
      },
      set(value) {
        if (value.length === 1) {
            value = '0' + value.toString()
        }

        this.emit({ vat: '0.' + value })
      },
    },
    paymentMethodId: {
      get() {
        return this.value.paymentMethodId
      },
      set(value) {
        this.emit({ paymentMethodId: value })
      },
    },
    externalUrl: {
      get() {
        return this.value.externalUrl
      },
      set(value) {
        this.emit({ externalUrl: value })
      },
    },
    needsMoreInfo: {
      get() {
        return this.value.needsMoreInfo
      },
      set(value) {
        this.emit({ needsMoreInfo: value })
      },
    },
    customFormId: {
      get() {
        return this.value.customFormId
      },
      set(value) {
        this.emit({ customFormId: value })
      },
    },
    hasAccessControl: {
      get() {
        return this.value.hasAccessControl
      },
      set(value) {
        this.emit({ hasAccessControl: value })
      },
    },
    certificatesEnabled: {
      get() {
        return this.value.certificatesEnabled
      },
      set(value) {
        this.emit({ certificatesEnabled: value })
      },
    },
  },
  methods: {
    emit (value) {
      this.$emit('input', { ...this.value, ...value })
    },

  }
}
</script>
