var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "properties-box" }, [
    _c("div", { staticClass: "properties-box__text" }, [
      _vm._v("\n        Ancho (CM)\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "properties-box__field" }, [
      _c("div", { staticClass: "m-input-icon m-input-icon--right" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.ticket.width,
              expression: "ticket.width",
            },
          ],
          staticClass: "form-control m-input form-control-sm",
          attrs: {
            type: "number",
            step: "0.01",
            id: "ticketWidth",
            readonly: _vm.widthLocked,
          },
          domProps: { value: _vm.ticket.width },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.ticket, "width", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "m-input-icon__icon m-input-icon__icon--right",
            on: {
              click: function ($event) {
                _vm.widthLocked = !_vm.widthLocked
              },
            },
          },
          [
            _c("span", [
              _c("i", {
                staticClass: "fa",
                class: _vm.widthLocked ? "fa-lock" : "fa-unlock",
              }),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "properties-box__text" }, [
      _vm._v("\n        Altura (CM)\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "properties-box__field" }, [
      _c("div", { staticClass: "m-input-icon m-input-icon--right" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.ticket.height,
              expression: "ticket.height",
            },
          ],
          staticClass: "form-control m-input form-control-sm",
          attrs: {
            type: "number",
            step: "0.01",
            id: "ticketHeight",
            readonly: _vm.heightLocked,
          },
          domProps: { value: _vm.ticket.height },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.ticket, "height", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "m-input-icon__icon m-input-icon__icon--right",
            on: {
              click: function ($event) {
                _vm.heightLocked = !_vm.heightLocked
              },
            },
          },
          [
            _c("span", [
              _c("i", {
                staticClass: "fa",
                class: _vm.heightLocked ? "fa-lock" : "fa-unlock",
              }),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }