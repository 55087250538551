var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-5 mx-4 mb-0" }, [
    _c("div", { staticClass: "row mb-5 pb-5" }, [
      _c("div", { staticClass: "col-12 col-lg-6 mb-4" }, [
        _c(
          "label",
          { staticClass: "d-block" },
          [
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Desde")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.startDate,
                  expression: "startDate",
                },
              ],
              staticClass: "form-control mr-4",
              attrs: {
                type: "datetime-local",
                disabled: _vm.nbReadOnly,
                placeholder: "Inicio",
                name: "startDate",
              },
              domProps: { value: _vm.startDate },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.startDate = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.start_date` },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-6 mb-4" }, [
        _c(
          "label",
          { staticClass: "d-block" },
          [
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Hasta")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.endDate,
                  expression: "endDate",
                },
              ],
              staticClass: "form-control mr-4",
              attrs: {
                type: "datetime-local",
                disabled: _vm.nbReadOnly,
                placeholder: "Inicio",
                name: "endDate",
              },
              domProps: { value: _vm.endDate },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.endDate = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.end_date` },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-5 pb-5" }, [
      _c("h4", [_vm._v("Pago")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-3 form-check form-check-custom form-check-solid" },
        [
          _c("label", { staticClass: "form-check-label ms-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.type,
                  expression: "type",
                },
              ],
              staticClass: "form-check-input me-1",
              attrs: {
                id: "RegistrableTypeRadioPaid",
                type: "radio",
                disabled: "",
              },
              domProps: {
                value: _vm.PropertyRegistrableType.PAID,
                checked: _vm._q(_vm.type, _vm.PropertyRegistrableType.PAID),
              },
              on: {
                change: function ($event) {
                  _vm.type = _vm.PropertyRegistrableType.PAID
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "fw-bold fs-6" }, [_vm._v("De pago")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-3 form-check form-check-custom form-check-solid" },
        [
          _c("label", { staticClass: "form-check-label ms-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.type,
                  expression: "type",
                },
              ],
              staticClass: "form-check-input me-1",
              attrs: { id: "RegistrableTypeRadioFree", type: "radio" },
              domProps: {
                value: _vm.PropertyRegistrableType.FREE,
                checked: _vm._q(_vm.type, _vm.PropertyRegistrableType.FREE),
              },
              on: {
                change: function ($event) {
                  _vm.type = _vm.PropertyRegistrableType.FREE
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "fw-bold fs-6" }, [_vm._v("Gratuita")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-3 form-check form-check-custom form-check-solid" },
        [
          _c(
            "label",
            { staticClass: "form-check-label ms-0" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input me-1",
                attrs: { id: "RegistrableTypeRadioExternal", type: "radio" },
                domProps: {
                  value: _vm.PropertyRegistrableType.EXTERNAL,
                  checked: _vm._q(
                    _vm.type,
                    _vm.PropertyRegistrableType.EXTERNAL
                  ),
                },
                on: {
                  change: function ($event) {
                    _vm.type = _vm.PropertyRegistrableType.EXTERNAL
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "fw-bold fs-6" }, [_vm._v("Externa")]),
              _vm._v(" "),
              _c("NbErrorBlock", {
                attrs: { path: `properties.${_vm.propertyKey}.type` },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.type === _vm.PropertyRegistrableType.EXTERNAL
        ? _c("div", { staticClass: "ms-4 ps-5 mb-5" }, [
            _c(
              "label",
              [
                _c("span", { staticClass: "fw-bold" }, [_vm._v("Url")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.externalUrl,
                      expression: "externalUrl",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.externalUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.externalUrl = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("NbErrorBlock", {
                  attrs: { path: `properties.${_vm.propertyKey}.externalUrl` },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.type === _vm.PropertyRegistrableType.PAID
      ? _c("div", { staticClass: "my-5 pb-5" }, [
          _c("h4", [_vm._v("Coste pago")]),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "mb-3" },
            [
              _c("span", { staticClass: "fw-bold" }, [_vm._v("Precio")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.price,
                    expression: "price",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "0.01", step: ".01" },
                domProps: { value: _vm.price },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.price = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("NbErrorBlock", {
                attrs: { path: `properties.${_vm.propertyKey}.price` },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "mb-3" },
            [
              _c("span", { staticClass: "fw-bold" }, [_vm._v("IVA")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.vat,
                    expression: "vat",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number", min: "0", max: "100" },
                domProps: { value: _vm.vat },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.vat = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("NbErrorBlock", {
                attrs: { path: `properties.${_vm.propertyKey}.vat` },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "mb-3" },
            [
              _c("span", { staticClass: "fw-bold" }, [_vm._v("TPV Redsys")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.paymentMethodId,
                      expression: "paymentMethodId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "paymentMethodId" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.paymentMethodId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.paymentMethods, function (paymentMethod) {
                  return _c(
                    "option",
                    { domProps: { value: paymentMethod.id } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(paymentMethod.name) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("NbErrorBlock", {
                attrs: {
                  path: `properties.${_vm.propertyKey}.paymentMethodId`,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "my-5 pb-5" }, [
      _c("h4", [_vm._v("Info")]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3 form-check" }, [
        _c("label", { staticClass: "form-check-label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.needsMoreInfo,
                expression: "needsMoreInfo",
              },
            ],
            staticClass: "form-check-input me-2",
            attrs: { id: "NeedsMoreInfoCheck", type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.needsMoreInfo)
                ? _vm._i(_vm.needsMoreInfo, null) > -1
                : _vm.needsMoreInfo,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.needsMoreInfo,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.needsMoreInfo = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.needsMoreInfo = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.needsMoreInfo = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "fw-bold" }, [
            _vm._v("Necesita más información"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.needsMoreInfo
        ? _c("div", { staticClass: "mb-5 ms-4 ps-5" }, [
            _c(
              "label",
              [
                _c("span", { staticClass: "fw-bold" }, [_vm._v("Formulario")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customFormId,
                        expression: "customFormId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "customFormId" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.customFormId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.customForms, function (customForm) {
                    return _c(
                      "option",
                      { domProps: { value: customForm.id } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(customForm.name) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("NbErrorBlock", {
                  attrs: { path: `properties.${_vm.propertyKey}.customFormId` },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "my-5" }, [
      _c("h4", [_vm._v("Acceso")]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c("div", { staticClass: "form-check" }, [
          _c("label", { staticClass: "form-check-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.hasAccessControl,
                  expression: "hasAccessControl",
                },
              ],
              staticClass: "form-check-input",
              attrs: { id: "HasAccessControlCheck", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.hasAccessControl)
                  ? _vm._i(_vm.hasAccessControl, null) > -1
                  : _vm.hasAccessControl,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.hasAccessControl,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.hasAccessControl = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.hasAccessControl = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.hasAccessControl = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "fw-bold" }, [
              _vm._v("Control de acceso"),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-5 ms-4 ps-5" }, [
        _vm.hasAccessControl
          ? _c("div", { staticClass: "form-check" }, [
              _c("label", { staticClass: "form-check-label" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.certificatesEnabled,
                      expression: "certificatesEnabled",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { id: "CertificatesEnabledCheck", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.certificatesEnabled)
                      ? _vm._i(_vm.certificatesEnabled, null) > -1
                      : _vm.certificatesEnabled,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.certificatesEnabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.certificatesEnabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.certificatesEnabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.certificatesEnabled = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "fw-bold" }, [
                  _vm._v("Certificados asistencia"),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }