<template>
    <div class="mt-5 mx-4 mb-0">
        <div class="row">
            <div class="col-12 col-lg-3 mb-4">
                <label class="d-block">
                    <span class="fw-bold">Desde</span>
                    <input v-model="startDate" type="datetime-local" class="form-control mr-4" :disabled="nbReadOnly"
                    placeholder="Inicio" name="startDate" />
                    <NbErrorBlock :path="`properties.${propertyKey}.start_date`" />
                </label>
            </div>
            <div class="col-12 col-lg-3 mb-4">
                <label class="d-block">
                    <span class="fw-bold">Hasta</span>
                    <input v-model="endDate" type="datetime-local" class="form-control mr-4" :disabled="nbReadOnly"
                    placeholder="Inicio" name="endDate" />
                    <NbErrorBlock :path="`properties.${propertyKey}.end_date`" />
                </label>
            </div>
            <div class="col-12 col-lg-6 mb-4">
                <label class="d-block">
                    <span class="fw-bold">URL</span>
                    <input class="form-control " type="text" name="url" v-model="url"/>
                    <NbErrorBlock :path="`properties.${propertyKey}.url`"/>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import StartAndEndDate from '@/js/components/conference/properties/StartAndEndDates';

export default {
    name: 'ConferencePropertyVideomonitorable',
    mixins: [ StartAndEndDate ],
    props: {
        lang: {
            type: String,
            default: null,
        },
        value: {
            type: Object,
            required: true
        },
        propertyKey: {
            type: String,
            required: true
        }
    },
    components: {
        NbErrorBlock
    },
    computed: {
        url: {
            get() {
                return this.value.url
            },
            set(value) {
                this.emit({ url: value })
            },
        },
    },
    methods: {
        emit(value) {
            this.$emit('input', { ...this.value, ...value })
        },

    }
}
</script>
