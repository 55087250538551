<template>
  <div>
    <input type="hidden" name="survey" :value="jsonSurvey" />
    <input type="hidden" name="theme" :value="jsonTheme" />
    <div id="surveyCreator" />
    <nb-error-block v-for="(error, key) in nbErrors" :path="key" :key="key"></nb-error-block>
  </div>
</template>

<script>
import { surveyLocalization } from "survey-core";
import * as Survey from "survey-core";

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { SurveyCreator } from "survey-creator-knockout";

import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import { localization } from "survey-creator-core";
import { init as captchaWidget } from "@/extended/js/custom/survey/captchaWidget.js";
import { init as honeypotWidget } from "@/extended/js/custom/survey/honeypotWidget.js";
import { init as TypesUserWidget } from "@/extended/js/custom/survey/TypesUserWidget.js";

import NbErrorBlock from "@/js/components/error_block/nb-error-block";

Survey.setLicenseKey(
  "NzE4NTA0YjktNzBlZi00MjhjLTgzZGItMjM0N2MzYWFlODc0OzE9MjAyNC0wMy0xNCwyPTIwMjQtMDMtMTQsND0yMDI0LTAzLTE0"
);

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showTranslationTab: true,
  showThemeTab: true
};

captchaWidget(Survey, process.env.RECAPTCHA_SECRET_KEY);
honeypotWidget(Survey);

export default {
  name: "CustomForm",
  components: { NbErrorBlock },
  props: {
    surveyJson: {
      type: Object,
      default: {},
    },
    themeJson: {
      type: Object,
      default: {},
    },
    typesCustomer: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      surveyData: null,
      themeData: null,
    };
  },
  created() {
    this.surveyData = this.surveyJson ? JSON.stringify(this.surveyJson) : "{ \"logoPosition\": \"right\"}"
    this.themeData = this.themeJson ? this.themeJson : null

    let values = {
      types: this.typesCustomer,
      placeholder: "Seleccione una opción",
      title: "Tipo de usuario",
    }
    TypesUserWidget(Survey, values);
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      surveyLocalization.supportedLocales = ["es", "en", "pt"];
      surveyLocalization.defaultLocale = "es"
      localization.currentLocale = "es";
      const creator = new SurveyCreator(creatorOptions);
      creator.isAutoSave = true;
      creator.render("surveyCreator");
      creator.text = this.surveyData;

      if (this.themeData !== null) {
        creator.theme = this.themeData;
      }

      creator.onModified.add((sender, options) => {
        this.surveyData = creator.text;
      });

      creator.saveThemeFunc = (saveNo, callback) => {
        this.themeData = creator.theme;
      };

      creator.onQuestionAdded.add((sender, options) => {
        if (options.question.getType() === "type_id") {
          options.question.name = "type_id";
          options.question.title = "Tipo de usuario";
        }
      });

    },
  },
  computed: {
    jsonSurvey() {
      return this.surveyData;
    },
    jsonTheme() {
      return JSON.stringify(this.themeData);
    },
  },
};
</script>
