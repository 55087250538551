export default {
  created() {
    this.value.start_date = this.value.start_date ? this.parseDate(this.value.start_date) : null
    this.value.end_date = this.value.end_date ? this.parseDate(this.value.end_date) : null
  },
  computed: {
    startDate: {
      get() {
        return this.value.start_date ? this.parseDate(this.value.start_date) : null
      },
      set(value) {
        let valueDate = value !== "" ? value : null;
        this.emit({ start_date: valueDate })
      },
    },
    endDate: {
      get() {
        return this.value.end_date ? this.parseDate(this.value.end_date) : null
      },
      set(value) {
        let valueDate = value !== "" ? value : null;
        this.emit({ end_date: valueDate })
      },
    },
  },
  methods: {
    parseDate (date) {
      return moment(date).format('YYYY-MM-DDTHH:mm')
    },
  },
}
