<template>
    <span class="form-group m-form__group col-md-4">
        <label :for="id">{{ name }}</label>
        <select :disabled="nbReadOnly"
                :id="id"
                :name="id"
                class="js-select2"
                ref="select"
                data-allow-clear="true"
                v-show="!load"
                value="">
            <option :value="key" v-for="(value, key) in options">{{value}}</option>
        </select>
    </span>
</template>


<script>


  const play_icon = 'la-play'
  const stop_icon = 'la-stop'

  export default {
    props: {
      id: { type: String, default: null },
      name: { type: String, default: null },
      options: { type: Object, default: [] },
      default: { type: Number, default: null },
      path: { type: String, default: null },
    },
    data () {
      return {
        load: false,
        audio: null,
        playButton: null,
        running: false
      }
    },
    mounted () {
      let select = $(this.$refs.select)
      let results = $('.select2-dropdown.select2-dropdown--below')
      let over = false
      this.playButton =
        $(`<span class="select2-selection__play"><em class="la ${play_icon}"></em></span>`)

      select.on('change', (a) => {
        this.$nextTick(() => {
          if (select.val()) {
            $(`#select2-${this.id}-container`).append(this.playButton)
            this.playButton
              .click((event) => this.play())
              .mouseover(() => over = true)
              .mouseout(() => over = false)
            this.$nextTick(() => this.load = true)
          }
        })
      }).on('select2:opening', (event) => {
        if (over) {
          select.select2('close')
          event.preventDefault()
        }
      })

      $(`#select2-${this.id}-container`).ready((d) => {
        this.$nextTick(() => {
          select.val(this.default).trigger('change')
          select.removeClass('dirty')
        })
      })
    },
    methods: {
      play () {
        if (!this.$refs.select.value.length || this.running || !this.path) {
          this.stop()
          return
        }
        let number = this.$refs.select.value.toString()
        number = number.length < 2 ? '0' + number : number
        let soundActive = this.path.replace('$$', number)
        this.stop()
        this.playButton.html(`<em class="la ${stop_icon}"></em>`)
        this.playButton.addClass('playing')
        this.audio = new Audio(soundActive)
        this.audio.play()
        this.audio.onended = () => {
          this.stop()
        }
        this.running = true
      },
      stop () {
        if (this.audio) {
          this.audio.pause()
        }
        this.playButton.html(`<em class="la ${play_icon}"></em>`)
        this.playButton.removeClass('playing')
        this.running = false
      }
    },
  }

</script>


<style lang="css">
    .select2-selection__play {
        position: absolute;
        right: 4rem !important;
        display: inline-block;
        border: 1px solid #CCC;
        border-radius: 26px;
        width: 26px;
        height: 26px;
        text-align: center;
        padding: 0;
        top: 50%;
        font-size: 1.4rem;
        left: auto;
        margin-right: 0.5rem;
        margin-top: -0.9rem;
    }

    .select2-selection__play.playing {
        font-size: 18px;
        line-height: 26px;
    }

    .select2-selection__play {
        cursor: pointer;
    }
</style>
