var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group m-form__group col-12 text-right" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary m-btn m-btn--icon-right",
          attrs: {
            disabled: _vm.disabled,
            type: "button",
            id: "deviceQRModalButton",
          },
          on: { click: _vm.show },
        },
        [_vm._v("\n        Sincronizar\n    ")]
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            id: "deviceQRModal",
            visible: _vm.visibleModal,
            backdrop: true,
            refreshing: _vm.refreshingModal,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleModal = $event
            },
            "update:refreshing": function ($event) {
              _vm.refreshingModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            Escanea el código con el dispositivo\n        "
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.visibleModal = false
                        },
                      },
                    },
                    [_vm._v("Cerrar")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            _vm.status === 0
              ? _c("span", [_vm._v("Generando el código.")])
              : _vm.status === 1
              ? _c("span", [_vm._v("Ha habido un error generando el código.")])
              : _c("qr-code", {
                  attrs: { uuid: _vm.uuid, endpoint: _vm.endpoint },
                }),
            _vm._v("\n            Email:\n            "),
            _c("div", { staticClass: "c-qr-code__form" }, [
              _c("div", { staticClass: "c-qr-code__form-input" }, [
                _c("input", {
                  ref: "email",
                  staticClass: "form-control m-input m-input--solid",
                  attrs: { type: "text" },
                }),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-qr-code__form-submit btn btn-primary",
                  on: { click: _vm.sendMail },
                },
                [_vm._v("Enviar")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-qr-code__form-msg", class: _vm.classMsg },
                [
                  _c("em", {
                    staticClass: "c-qr-code__form-msg-icon la la-info-circle",
                  }),
                  _vm._v(" "),
                  _c("span", {
                    ref: "response",
                    staticClass: "c-qr-code__form-msg-txt",
                  }),
                ]
              ),
            ]),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }