var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group" }, [
    _c("div", { staticClass: "mb-2" }, [
      _c("div", { staticClass: "form-label" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("trans")(`page.parameters.title`)) +
            "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 col-lg-12" }, [
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "parameter_width" } },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm._f("trans")(`page.parameters.width.title`))
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.width,
                  expression: "width",
                },
              ],
              staticClass: "form-select form-select-sm",
              attrs: { id: "parameter_width", name: "parameter_width" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.width = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(
              Object.values(_vm.WidthParameters),
              function (parameter, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: parameter } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("trans")(`page.parameters.width.${parameter}`)
                        ) +
                        "\n                    "
                    ),
                  ]
                )
              }
            ),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-lg-12" }, [
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "label",
            {
              staticClass: "form-label",
              attrs: { for: "parameter_background_color" },
            },
            [
              _c("small", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("trans")(`page.parameters.background_color.title`)
                    )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.backgroundColor,
                  expression: "backgroundColor",
                },
              ],
              staticClass: "form-select form-select-sm",
              attrs: {
                id: "parameter_background_color",
                name: "parameter_background_color",
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.backgroundColor = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(
              Object.values(_vm.BackgroundColorParameters),
              function (parameter, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: parameter } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("trans")(
                            `page.parameters.background_color.${parameter}`
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                )
              }
            ),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }