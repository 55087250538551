<template>
    <!-- contenido -->
    <div>
        <component :is="componentLoader" v-bind="row"></component>
    </div>
    <!-- xxxxxxxxx -->
</template>

<script>

export default {
    name: 'cms-editor-view',
    props: {
        row: {type: Object, default: () => ({})},
    },
    computed: {
        componentLoader () {
            let basic = ['title','text','html','card','image','video','link','slide'] // El container no tiene representacion aparte
            if (basic.indexOf(this.row.type) != -1) {
                return () => import(`./views/${this.row.type}.vue`)
            } else if (this.row.type != 'container') {
                return () => import(`@/js/vendor/netberry/cms-editor/views/${this.row.type}.vue`)
            }
        }
    },
}
</script>

