var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12" }, [
    _c("input", {
      attrs: { name: "reportRoles", type: "hidden" },
      domProps: { value: _vm.selectedsJSON },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "table-responsive m--padding-left-30 m--padding-right-30 mt-4",
      },
      [
        _c("table", { staticClass: "table table-striped m-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.rolesData, function (role) {
              return _c("tr", { key: "role-" + role.id }, [
                _c("td", [
                  _c("span", { staticClass: "m-switch m-switch--icon" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: role.selected,
                            expression: "role.selected",
                          },
                        ],
                        attrs: { type: "checkbox", disabled: _vm.nbReadOnly },
                        domProps: {
                          checked: Array.isArray(role.selected)
                            ? _vm._i(role.selected, null) > -1
                            : role.selected,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = role.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(role, "selected", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    role,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(role, "selected", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span"),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "label-hidden" }, [
                      _vm._v("Asignar informe"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(role.visible_name))]),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Asignado")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nombre")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }