var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("h5", {}, [
        _c("i", { class: _vm.evaluator.icon }),
        _vm._v("\n            " + _vm._s(_vm.evaluator.name) + "\n        "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      _vm._l(_vm.status, function (customer) {
        return _c("span", { staticClass: "mr-3" }, [
          _vm._v("\n        " + _vm._s(customer.name) + "\n        "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }