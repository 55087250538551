var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.accessControlLog
    ? _c(
        "modal-component",
        {
          ref: "modal",
          attrs: { visible: _vm.showModalHistory },
          on: {
            "update:visible": function ($event) {
              _vm.showModalHistory = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("h4", { staticClass: "modal-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")(
                            "conference.survey_dashboard.log.title"
                          )
                        )
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            793894774
          ),
        },
        [
          _vm._v(" "),
          [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-striped table-bordered table-hover table-checkable",
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "conference.survey_dashboard.log.date"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "conference.survey_dashboard.log.action"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.accessControlLog, function (access, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(access.datetime))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")(
                                "conference.survey_dashboard.log." + access.in
                              )
                            )
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }