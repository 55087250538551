<template>
    <div :class="`form-group ${containerClass}`">
        <label v-if="label.length" :for="field" class="form-label">{{ label }}</label>
        <div :name="field" class="col-md-12">
            <div class="row flex-grow-1">
                <div class="col-7 col-md-8 col-lg-9 col-xl-10 d-flex flex-column">
                    <div class="card card-stretch card-bordered">
                        <div class="card-header">
                            <h4 class="card-title">Cuerpo</h4>
                            <div class="card-toolbar" v-if="preview">
                                <button type="button" class="btn btn-sm btn-light" data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_preview">
                                    Previsualizar
                                </button>
                                <div class="modal fade" tabindex="-1" id="kt_modal_preview">
                                    <div class="modal-dialog modal-dialog-centered modal-xl">
                                        <div class="modal-content shadow-none">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Previsualización</h5>
                                                <!--begin::Close-->
                                                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                     data-bs-dismiss="modal" aria-label="Close">
                                                    <i class="bi bi-x-lg"></i>
                                                </div>
                                                <!--end::Close-->
                                            </div>
                                            <div class="modal-body">
                                                <div class="ratio ratio-16x9 shadow-sm rounded">
                                                    <iframe :src="preview" class="scaled-frame" width="100%"></iframe>
                                                </div>
                                            </div>

                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body card-scroll">
                            <div class="bg-light rounded-3 p-2 pb-3 position-relative">

                                <div v-if="!rows.length" class="text-center">
                                    <input
                                        :name="field"
                                        type="hidden"
                                        value="">
                                    <p><i class="far fa-plus-square fa-10x"></i></p>
                                    <p>Arrastra un módulo para empezar a crear la página <i
                                        class="fa
                                        fa-arrow-right"></i>
                                    </p>
                                </div>
                                <draggable
                                    :list="rows"
                                    :nested="true"
                                    group="contenido"
                                    :options="dragOptions"
                                    :class="[rows.length === 0 ? 'position-absolute top-0 start-0 end-0 bottom-0 p-2' : '', 'row g-2']"
                                    handle=".handle"
                                    @change="changeEvent"
                                    ghost-class="opacity-75"

                                >
                                    <cms-editor-item :editor="self()" v-for="(row, idx) in rows"
                                        :key="row.id"
                                        :row="row"
                                        :idx="idx"
                                        :field="field"
                                        :prefix="prefix"
                                        :items="row.childrens"
                                        :class="'draggable position-relative ' + calculeWidth(row)">
                                    </cms-editor-item>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-md-4 col-lg-3 col-xl-2 d-flex flex-column pb-2">
                    <div class="card shadow-sm card-stretch position-sticky" style="top: 80px;">
                        <div class="card-header">
                            <h4 class="card-title">Módulos</h4>
                        </div>
                        <div class="card-body card-scroll">
                            <draggable
                                class="row gy-1 gx-0 bg-light p-2 pb-3 rounded-2"
                                :list="modules"
                                :group="{name: 'contenido', pull: 'clone', put: false }"
                                :clone="cloneModule"
                                :sort="false"
                            >
                                <div class="col-12 draggable" v-for="element in modules" :key="element.id">
                                    <div
                                        class="list-group-item draggable-handle border-dashed border-1 border-gray-400 rounded-2">
                                        {{ element.name }}
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nb-error-block :path="field" :json="jsonErrors" :subpath="prefix"></nb-error-block>
    </div>

</template>

<script>
import draggable from "vuedraggable";
import NbErrorBlock from '@/js/components/error_block/nb-error-block'
import CmsEditorItem from './CmsEditorItem'


export default {
    name: 'cms-editor',
    components: {NbErrorBlock, draggable, CmsEditorItem},
    props: {
        removeRowFlag: {type: String, default: ''},
        formModal: {type: Object, default: () => ({})},
        prefix: {type: String, default: ''},
        label: {type: String, default: ''},
        field: {type: String, default: ''},
        preview: {type: String, default: ''},
        value: {type: Array, default: () => []},
        initRow: {type: Object, default: () => ({display: 1})},
        options: {type: Object, default: () => ({})},
        radioOptions: {type: Object, default: () => ({})},
        widthClasses: {
            type: Object,
            default: () => ({
                w100: 'col-md-12',
                w75: 'col-md-9',
                w50: 'col-md-6',
                w66: 'col-md-8',
                w33: 'col-md-4',
                w25: 'col-md-3',
                wauto: 'col'
            })
        },
        canRemoveConfirm: {type: String, default: ''},
        containerClass: {type: String, default: () => 'col-md-12'},
        jsonErrors: {type: Boolean, default: false},
    },
    data() {
        return {
            rows: [],
            readOnlyClassesBasic: 'bg-secondary',
            readOnlyClasses: 'bg-secondary text-muted',
            unique: 1,
            nextRowId: 1,
            dragOptions: {
                animation: 300,
            },
            modules: [],
            renderComponent: true,
            showDrawer: true, // flag para forzar el repintado de los input del drawer. Algunos componentes como radiobuttons no se actualizaban bien despues de insertar nuevo item
        }
    },
    methods: {
        self: function() {
            return this;
        },
        muestraPreview: function () {
            const preview = document.querySelector('.js-preview-frame');
            preview.classList.toggle('d-none');
        },
        openDrawer(row) {
            const id = '#popup_' + this.sanitizedField() + '_' + this.prefix + row.drawerId;
            const drawerEl = document.querySelector(id);
            if (drawerEl) {
                if (row.nuevo) {
                    row.drawer = new KTDrawer(drawerEl);
                } else {
                    row.drawer = KTDrawer.getInstance(drawerEl);
                }
            }

            if (row.drawer) {
                let back = {};
                for (let key in this.formModal[row.type]) {
                    back[key] = row[key];
                }

                row.errores = {};
                row.backup = back;
                row.drawer.show();
            }
        },
        closeDrawer(row, parent) {
            if (row.drawer) {
                row.drawer.hide();
                if (row.nuevo) {
                    this.removeRow(row, parent);
                } else {
                    for (let key in row.backup) {
                        row[key] = row.backup[key];
                    }
                    let moduleType = row.type
                    let component = row.component
                    for (let key in this.formModal[moduleType]) {
                        if (this.formModal[moduleType][key].type == 'file') {
                            let ref = 'popup_' + this.sanitizedField() + '_' + this.prefix + '_' + row.drawerId + '_' + key;
                            this.$nextTick(() => {
                                if (row[key]) {
                                    component.$refs[ref].setValue(Array.isArray(row[key]) ? row[key] : JSON.parse(row[key]));
                                } else {
                                    component.$refs[ref].setValue([]);
                                }
                            });
                        }
                    }
                }
            }
        },
        validate(model) {
            let errores = {};
            for (let key in this.formModal[model.type]) {
                if (this.formModal[model.type].hasOwnProperty(key)) {
                    let field = this.formModal[model.type][key];
                    if (field.hasOwnProperty('rules')) {
                        if (field.rules == 'required' && (!model[key] || model[key] == '')) {
                            errores[key] = window.trans.trans('forms.' + field.rules);
                        }
                    }
                }
            }

            model.errores = errores;
            if (Object.keys(errores).length) {
                this.$forceUpdate();
                return false;
            }

            return true;
        },
        saveDrawer(row) {
            if (row.drawer) {
                if (!this.validate(row)) {
                    return
                }

                let ids = [];
                let loadFiles = false
                let moduleType = row.type
                let component = row.component
                for (let key in this.formModal[moduleType]) {
                    if (this.formModal[moduleType][key].type == 'file') {
                        delete row[key + '_url'];
                        loadFiles = true;
                        let ref = 'popup_' + this.sanitizedField() + '_' + this.prefix + '_' + row.drawerId + '_' + key;
                        row[key] = component.$refs[ref].getFieldValue ? JSON.parse(component.$refs[ref].getFieldValue) : null;
                        if (row[key]) {
                            if (this.formModal[moduleType][key].props.multiple) {
                                ids = ids.concat(this.value[idx][key]);
                            } else {
                                ids.push(row[key]);
                            }
                        }


                        if (row.type == 'video') {
                            row['image_url'] = null;
                        }
                    }
                }
                if (loadFiles) {
                    this.getFiles(ids);
                }

                delete row.nuevo
                delete row.backup
                row.drawer.hide();

                this.$forceUpdate();
            }
        },
        nextUnique(row) {
            if (!row.drawerId) {
                row.drawerId = this.unique++;
            }
            return row.drawerId;
        },
        removeRow: function (row,parent) {
            if (this.canRemoveConfirm != '' && (!this.removeRowFlag.length || row[this.removeRowFlag] > 0)) {
                Swal.fire({
                    title: this.canRemoveConfirm,
                    confirmButtonText: window.trans.trans('forms.confirm'),
                    cancelButtonText: window.trans.trans('forms.cancel'),
                    type: 'warning',
                    showCancelButton: true,
                }).then(async (result) => {
                    if (result.value === true) {
                        parent.list.splice(parent.list.indexOf(row), 1)
                    }
                })
            } else {
                parent.list.splice(parent.list.indexOf(row), 1)
            }
        },
        removeRows: function () {
            this.rows = [];
        },
        sanitizedField: function () {
            return this.field.replace(/\[/, '_').replace(/\]/, '_')
        },
        calculeWidth: function (row) {
            if (!row.width || !this.widthClasses.hasOwnProperty(row.width)) {
                return ' ' + this.widthClasses.w100;
            }
            return ' ' + this.widthClasses[row.width];
        },
        cloneModule: function (mod) {
            let modulo = {
                id: this.nextRowId++,
                type: mod.id,
                nuevo: true,
                display: 1,
            };

            if (mod.id == 'container') {
                modulo.childrens = [];
                modulo.fit = 'fitcontent'
                modulo.background = 'transparent'
            }
            if (this.formModal[mod.id].hasOwnProperty('width')){
                modulo.width = 'w100';
            }
            return modulo;
        },
        changeEvent: function (evt) {
            if (evt.hasOwnProperty('added')) {
                this.showDrawer = false;
                this.$nextTick(() => {
                    this.showDrawer = true;
                    this.openDrawer(this.rows[evt.added.newIndex]);
                });
            }
        },
        clearValue: function (row, key) {
            row[key] = null;
        },
        putImageUrl: function(row, file, key, multiple) {
            if (multiple) {
                if (row[key] && row[key].indexOf(parseInt(file.id)) != -1) {
                    if (typeof row[key + '_url'] == 'undefined') {
                        row[key + '_url'] = {};
                    }
                    row[key + '_url'][file.id] = file.url;
                }
            } else {
                if (row[key] == file.id) {
                    row[key + '_url'] = file.url;
                }
            }
        },
        initImageUrl: function(row, ids, key, multiple) {
            if (multiple) {
                if (row[key] && !Array.isArray(row[key])) {
                    row[key] = JSON.parse(row[key]);
                    ids = ids.concat(row[key]);
                }
            } else if (row[key]) {
                ids.push(row[key]);
                if (row.type == 'image') {
                    row[key + '_url'] = null;
                } else if (row.type == 'video') {
                    row['image_url'] = null;
                }
            }

            return ids;
        },
        async getFiles(ids) {
            try {
                let ids_comma = ids.join(',')
                let response = await axios.get(route('API.repository.getFiles', {
                    files: ids_comma,
                    group_id: this.groupId,
                    group_type: this.groupType,
                }, false))

                if (response.data) {
                    for (let i = 0; i < response.data.files.length; i++) {
                        for (let idx = 0; idx < this.rows.length; idx++) {
                            let moduleType = this.rows[idx].type
                            for (let key in this.formModal[moduleType]) {
                                if (this.formModal[moduleType][key].type == 'file') {
                                    this.putImageUrl(this.rows[idx], response.data.files[i], key, this.formModal[moduleType][key].props.multiple)
                                }
                            }
                            if (moduleType == 'container') {
                                for (let childIdx = 0; childIdx < this.rows[idx].childrens.length; childIdx++) {
                                    let moduleTypeChild = this.rows[idx].childrens[childIdx].type
                                    for (let key in this.formModal[moduleTypeChild]) {
                                        if (this.formModal[moduleTypeChild][key].type == 'file') {
                                            this.putImageUrl(this.rows[idx].childrens[childIdx], response.data.files[i], key, this.formModal[moduleTypeChild][key].props.multiple)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.$forceUpdate();
                    this.renderComponent = false;
                    this.$nextTick(() => {
                        this.renderComponent = true;
                    });
                }
            } catch (error) {
                console.log(error)
            }

        },
    },
    mounted() {
        for (let moduleKey in this.options['type']) {
            this.modules.push({
                id: moduleKey,
                name: this.options['type'][moduleKey],
            });
        }
        let ids = [];
        for (let i = 0; i < this.value.length; i++) {
            this.value[i].id = this.nextRowId++;
            let moduleType = this.value[i].type
            if (moduleType == 'container' && this.value[i].hasOwnProperty('childrens')) {
                if (this.value[i].childrens != null) {
                    for (let c = 0; c < this.value[i].childrens.length; c++) {
                        this.value[i].childrens[c].id = this.nextRowId++;
                    }
                } else {
                    this.value[i].childrens = [];
                }

            }
            for (let key in this.formModal[moduleType]) {
                if (this.formModal[moduleType][key].type == 'file') {
                    this.initImageUrl(this.value[i], ids, key, this.formModal[moduleType][key].props.multiple);
                }
            }
            if (moduleType == 'container') {
                for (let childIdx = 0; childIdx < this.value[i].childrens.length; childIdx++) {
                    let moduleTypeChild = this.value[i].childrens[childIdx].type
                    for (let key in this.formModal[moduleTypeChild]) {
                        if (this.formModal[moduleTypeChild][key].type == 'file') {
                            this.initImageUrl(this.value[i].childrens[childIdx], ids, key, this.formModal[moduleTypeChild][key].props.multiple)
                        }
                    }
                }
            }
        }
        this.rows = this.value;

        if (ids.length) {
            this.getFiles(ids);
        }
    },
}
</script>

<style lang="scss">
.draggable {
    > .toolbar-hoverable {
        opacity     : 0;
        visibility  : hidden;
        will-change : opacity;
        transition  : .15s ease-in-out;
    }

    &:hover {
        > .toolbar-hoverable {
            opacity    : 1;
            visibility : visible;
        }
    }

    .no-caret {
        caret-color : transparent;
        cursor      : pointer;
    }
}

.empty-draggable {
    height: 100px;
    &::before{
        content: "\f0fe";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        font-size: 8em;
        color: #CACACA;
        position: absolute;
        left: calc(50% - 45px);
        top: 0;
    }
}
</style>
