var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("iframe", {
      attrs: {
        width: "100%",
        height: "600",
        frameborder: "0",
        src: _vm.mapSrc,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }