import { render, staticRenderFns } from "./ConferencePropertyResourcesFile.vue?vue&type=template&id=bf1e7cd4&scoped=true&"
import script from "./ConferencePropertyResourcesFile.vue?vue&type=script&lang=js&"
export * from "./ConferencePropertyResourcesFile.vue?vue&type=script&lang=js&"
import style0 from "./ConferencePropertyResourcesFile.vue?vue&type=style&index=0&id=bf1e7cd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf1e7cd4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bf1e7cd4')) {
      api.createRecord('bf1e7cd4', component.options)
    } else {
      api.reload('bf1e7cd4', component.options)
    }
    module.hot.accept("./ConferencePropertyResourcesFile.vue?vue&type=template&id=bf1e7cd4&scoped=true&", function () {
      api.rerender('bf1e7cd4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/conference/properties/resources/ConferencePropertyResourcesFile.vue"
export default component.exports