<template>
    <div class="text-center">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10">
                <div class="row">
                    <div class="col-8">
                        <div class="bg-light-dark p-3 mb-2 rounded-2"></div>
                    </div>
                    <div class="col-12">
                        <div class="bg-light p-4 mb-2 rounded-2"></div>
                    </div>
                    <div class="col-12">
                        <div class="bg-light p-4 mb-2 rounded-2"></div>
                    </div>
                    <div class="col-12">
                        <div class="bg-light p-8 mb-2 rounded-2"></div>
                    </div>
                    <div class="col-6">
                        <div
                            class="bg-dark p-4 mb-2 rounded-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'cms-contactForm',
    props: {
        title: {type: String, default: ''},
    },
}
</script>

