var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showFilter
        ? _c(
            "div",
            { staticClass: "col-8 mb-3" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.optionsFilter,
                  label: "name",
                  "track-by": "id",
                  multiple: true,
                  placeholder: _vm.filterPlaceholder,
                },
                model: {
                  value: _vm.selectedOptions,
                  callback: function ($$v) {
                    _vm.selectedOptions = $$v
                  },
                  expression: "selectedOptions",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "surveyVizPanel" }),
      _vm._v(" "),
      _vm.dashboardRendered
        ? _vm._l(_vm.customComponents, function (component) {
            return _c(component, {
              key: component,
              tag: "component",
              attrs: {
                table: _vm.vizPanel,
                "survey-structure": _vm.surveyStructure,
                responses: _vm.responses,
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }