var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-component",
    {
      attrs: {
        theRef: "modalReset",
        visible: _vm.showModal,
        backdrop: true,
        classesFooter: ["text-right"],
        classesModalDialog: ["modal-dialog-centered modal-lg"],
      },
      on: {
        "update:visible": function ($event) {
          _vm.showModal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("trans")("forms_shippings.reset")) +
                  "\n    "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button", id: "reset" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteResource()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-power-off mr-1" }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("trans")("forms_shippings.reset_button")) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      [
        _c(
          "div",
          {
            staticClass:
              "m-alert m-alert--icon m-alert--outline alert alert-danger alert-dismissible fade show mb-0",
            attrs: { role: "alert" },
          },
          [
            _c("div", { staticClass: "m-alert__icon" }, [
              _c("i", { staticClass: "la la-warning" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-alert__text" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("trans")("forms_shippings.reset_message")) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }