var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ticket-editor__ticket",
      style: _vm.ticketStyle,
      attrs: { id: "ticket-editor", tabindex: "0" },
    },
    _vm._l(_vm.elements, function (element) {
      return element.visible !== false && _vm.layoutEnabled
        ? _c(
            "vue-draggable-resizable",
            {
              key: element.id,
              class: { blocked: _vm.isBlockElement(element) },
              attrs: {
                w: element.width,
                h: element.height,
                minw: 1,
                minh: 1,
                x: element.x,
                y: element.y,
                z: element.zIndex,
                parent: !!_vm.loading,
                active:
                  _vm.activeElement && element.id === _vm.activeElement.id,
                draggable: !_vm.isBlockElement(element),
                resizable: !_vm.isBlockElement(element),
              },
              on: {
                activated: function ($event) {
                  return _vm.changeActiveElement(element)
                },
                dragging: function ($event) {
                  return _vm.onDrag(element, ...arguments)
                },
                resizing: function ($event) {
                  return _vm.onResize(element, ...arguments)
                },
              },
            },
            [
              element.type === "text"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "ticket-editor__text-type d-flex justify-content-center align-items-center",
                      style: _vm.getElementWrapperStyle(element),
                    },
                    [
                      _c("div", { style: _vm.getElementStyle(element) }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.renderValue(element.value))),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              element.type === "image"
                ? _c(
                    "div",
                    {
                      staticClass: "ticket-editor__image-type d-flex",
                      style: _vm.getElementWrapperStyle(element),
                    },
                    [
                      _c("div", {
                        staticClass: "image",
                        style: _vm.getElementStyle(element),
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              element.type === "barcode"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "ticket-editor__barcode-type d-flex justify-content-center align-items-center",
                      style: _vm.getElementWrapperStyle(element),
                    },
                    [
                      _c("div", { style: _vm.getElementStyle(element) }, [
                        _c("img", {
                          staticClass: "ticket-editor__barcode-type-barcode",
                          attrs: { src: "#", id: `barcode${element.id}` },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              element.type === "qr"
                ? _c(
                    "div",
                    {
                      staticClass: "ticket-editor__qr-type",
                      style: _vm.getElementWrapperStyle(element),
                    },
                    [
                      _c("img", {
                        staticClass: "ticket-editor__qr-type-qr",
                        style: _vm.getElementStyle(element),
                        attrs: { src: "#", id: `qr${element.id}` },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }