var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "form-group m-form__group col-md-4" }, [
    _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.name))]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.load,
            expression: "!load",
          },
        ],
        ref: "select",
        staticClass: "js-select2",
        attrs: {
          disabled: _vm.nbReadOnly,
          id: _vm.id,
          name: _vm.id,
          "data-allow-clear": "true",
          value: "",
        },
      },
      _vm._l(_vm.options, function (value, key) {
        return _c("option", { domProps: { value: key } }, [
          _vm._v(_vm._s(value)),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }