var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-md-12" }, [
    _c("table", { staticClass: "table m-table table-striped table-bordered" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.supports, function (support, supportId) {
          return _c("tr", [
            _c(
              "td",
              { staticClass: "align-middle", staticStyle: { width: "200px" } },
              [
                _vm._v(
                  "\n                " + _vm._s(support) + "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  staticClass: "js-select2 form-select",
                  attrs: {
                    name: _vm.fieldName(supportId),
                    "data-allow-clear": "true",
                    disabled: _vm.nbReadOnly,
                    id: _vm.fieldName(supportId),
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }),
                  _vm._v(" "),
                  _vm._l(_vm.tickets, function (ticket, ticketId) {
                    return _c(
                      "option",
                      {
                        domProps: {
                          value: ticketId,
                          selected: _vm.selectOption(supportId, ticketId),
                        },
                      },
                      [_vm._v(_vm._s(ticket) + "\n                    ")]
                    )
                  }),
                ],
                2
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", { attrs: { colspan: "2" } }, [_vm._v("Soportes")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }