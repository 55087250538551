export const state = {
  showable: {
    'image/jpeg': 'jpg',
    'image/gif': 'gif',
    'image/png': 'png',
  },
  mimeTypes: {
    images: 'image',
  },
}

export const getters = {
  getShowable: state => state.showable,
  getMimeTypeImages: state => state.mimeTypes.images,
}

export const mutations = {}

export const actions = {}

export const Repository = { state, mutations, actions, getters }

export default Repository
