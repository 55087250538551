var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "item-container h-100 overflow-md-hidden",
            attrs: {
              tag: "div",
              handle: ".handle",
              list: _vm.list,
              value: _vm.value,
            },
            on: { input: _vm.emitter },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          _vm._l(_vm.realValue, function (item, index) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        item.areas.includes(_vm.selectedArea) ||
                        !_vm.selectedArea,
                      expression:
                        "item.areas.includes(selectedArea) || !selectedArea",
                    },
                  ],
                  staticClass: "item-group item border border-light",
                },
                [
                  _c("div", { staticClass: "row flex-nowrap" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-cursor col-auto p-4" },
                      [
                        _c("i", {
                          staticClass:
                            "fa fa-align-justify handle cursor-move ms-3",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group col p-4" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Texto*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.name,
                            expression: "item.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "name" + item.id,
                        },
                        domProps: { value: item.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col p-4" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Areas*"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: { options: _vm.areas, multiple: true },
                          model: {
                            value: item.areas,
                            callback: function ($$v) {
                              _vm.$set(item, "areas", $$v)
                            },
                            expression: "item.areas",
                          },
                        }),
                        _vm._v(" "),
                        _c("nb-error-block", {
                          attrs: {
                            path:
                              _vm.listType === "list"
                                ? `menu.${index}.areas`
                                : `menu.${_vm.parentIndex}.children.${index}.areas`,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !item.children || !item.children.length
                      ? _c(
                          "div",
                          { staticClass: "form-group col p-4" },
                          [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v("Destino"),
                            ]),
                            _vm._v(" "),
                            _c("multiselect", {
                              staticClass: "form-control p-0",
                              attrs: {
                                id: "target" + item.id,
                                options: _vm.targets,
                                "group-values": "targets",
                                "group-label": "groupLabel",
                                label: "label",
                                "track-by": "target",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.targetChanged($event, item)
                                },
                              },
                              model: {
                                value: item.navigable,
                                callback: function ($$v) {
                                  _vm.$set(item, "navigable", $$v)
                                },
                                expression: "item.navigable",
                              },
                            }),
                            _vm._v(" "),
                            _c("nb-error-block", {
                              attrs: {
                                path:
                                  _vm.listType === "list"
                                    ? `menu.${index}.navigable`
                                    : `menu.${_vm.parentIndex}.children.${index}.navigable`,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-group mt-5 p-4 col-auto d-flex align-items-center justify-content-end",
                      },
                      [
                        _vm.depth < _vm.maxDepth
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "me-3 mt-4 btn btn-primary btn-sm btn-icon icon-fs",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addChildren(item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-plus" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-sm btn-icon mt-4",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-minus" })]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.depth < _vm.maxDepth
                    ? _c("cms-menu-item", {
                        staticClass: "item-sub ps-5 ms-5 mb-5",
                        attrs: {
                          list: item.children,
                          targets: _vm.targets,
                          areas: _vm.areas,
                          depth: _vm.depth + 1,
                          nbErrors: _vm.nbErrors,
                          listType: "children",
                          parentIndex: index,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }