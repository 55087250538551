<template>
    <div>
        <draggable
            v-bind="dragOptions"
            tag="div"
            class="item-container h-100 overflow-md-hidden"
            handle=".handle"
            :list="list"
            :value="value"
            @input="emitter"
        >
            <template v-for="(item, index) in realValue" >
                <div class="item-group item border border-light" v-show="item.areas.includes(selectedArea) || !selectedArea">
                    <div class="row flex-nowrap">
                        <div class="form-group col-cursor col-auto p-4">
                            <i class="fa fa-align-justify handle cursor-move ms-3"></i>
                        </div>

                        <div class="form-group col p-4">
                            <label class="form-label">Texto*</label>
                            <input autocomplete="off" type="text" v-model="item.name" :id="'name' + item.id" class="form-control">
                        </div>

                        <div class="form-group col p-4">
                            <label class="form-label">Areas*</label>
                            <multiselect v-model="item.areas" :options="areas" :multiple="true" />
                            <nb-error-block :path="listType === 'list' ? `menu.${index}.areas` : `menu.${parentIndex}.children.${index}.areas`"></nb-error-block>

                        </div>

                        <div v-if="!item.children || !item.children.length" class="form-group col p-4">
                            <label class="form-label">Destino</label>
                            <multiselect
                                :id="'target' + item.id"
                                :options="targets"
                                v-model="item.navigable"
                                group-values="targets"
                                group-label="groupLabel"
                                label="label" track-by="target"
                                class="form-control  p-0"
                                @input="targetChanged($event, item)"
                            />
                            <nb-error-block :path="listType === 'list' ? `menu.${index}.navigable` : `menu.${parentIndex}.children.${index}.navigable`"/>
                        </div>

                        <div  class="form-group mt-5 p-4 col-auto d-flex align-items-center justify-content-end">
                            <button v-if="depth < maxDepth" class="me-3 mt-4 btn btn-primary btn-sm btn-icon icon-fs" type="button" @click="addChildren(item)">
                                <i class="fa fa-plus"></i>
                            </button>
                             <button class="btn btn-primary btn-sm btn-icon mt-4" type="button" @click="deleteItem(index)">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                    </div>

                    <cms-menu-item v-if="depth < maxDepth" class="item-sub ps-5 ms-5 mb-5" :list="item.children" :targets="targets" :areas="areas" :depth="depth + 1" :nbErrors="nbErrors" listType="children" :parentIndex="index"/>
                </div>
            </template>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import NbErrorBlock from '@/js/components/error_block/nb-error-block'

export default {
    name: 'CmsMenuItem',
    components: { NbErrorBlock, draggable },
    props: {
      targets: {
        type: Array,
        default: () => []
      },
      areas: {
        type: Array,
        default: () => []
      },
      value: {
        required: false,
        type: Array,
        default: null,
      },
      list: {
        required: false,
        type: Array,
        default: null
      },
      depth: {
        type: Number,
        default: 1,
      },
      maxDepth: {
        type: Number,
        default: 2,
      },
      selectedArea: {
        type: String,
        default: null
      },
      nbErrors:{
        type: Object,
        default: () => ({})
      },
      listType: {
        type: String,
        default: "list"
      },
      parentIndex: {
        type: Number,
        default: null
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 0,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      },
      realValue() {
        return this.value ? this.value : this.list
      }
    },
    methods: {
      emitter(value) {
        this.$emit("input", value);
      },
      targetChanged(value, item) {
        item.navigable_type = value.navigable_type
        item.navigable_id = value.navigable_id
      },
      addChildren(item) {
        const newItem = {
          areas: item.areas,
          name: null,
          navigable_type: null,
          navigable_id: null,
          navigable: null,
          children: [],
        }

        item.children.push(newItem)

        item.navigable_type = null
        item.navigable_id = null
      },
      // TODO: Ver si mejor hacerlo sin modificar las props
      deleteItem(index) {
        if (this.value) {
          this.value.splice(index, 1);
        } else {
          this.list.splice(index, 1);
        }

      },
    }
}

</script>

<style scoped>
.item:nth-child(odd) {
    background: #fafafa;
}
@media (min-width: 1138px){
    .item-container{
        //overflow: hidden;
        .item-container{
            overflow: visible;

        }
    }
}
.item .item{
    background: transparent;
}
.item-group{
    position: relative;
    min-width: 800px;
}
.item-group .item-group{
    position: static;
    overflow: visible;
    min-width: 100%;
}
.item .item:before{
    content:"";
    display: block;
    position: absolute;
    width: 1px;
    background: #ccc;
    left: 16px;
    top: 43px;
    bottom: 92px;
}
.item .item .col-cursor{
    position: relative;
}
.item .item .col-cursor:before{
    content:"";
    display: block;
    position: absolute;
    height: 1px;
    width: 17px;
    background: #cccccc;
    left: -7px;
    top: 21px;
    bottom: 10px;
}
</style>
