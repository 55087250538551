<template>
  <div class="col-12">
    <div class="row">

      <div class="form-group m-form__group col-md-6 mb-6">
        <label for="sendgrid_sender_id">Desde la dirección</label>
        <select class="form-select "
                :disabled="nbReadOnly"
                v-model="sender"
                title="Dirección de origen"
        >
          <option v-for="sender of senders" :value="sender">
            {{ sender.name }}
          </option>
        </select>
        <input type="hidden" name="sendgrid_sender_id" id="sendgrid_sender_id" :value="sender ? sender.id : null">
      </div>

      <div class="form-group m-form__group col-md-6 mb-6">
        <label for="currentSubject">{{ 'campaign_component.subject' | trans }}</label>
        <input id="currentSubject"
               type="text" name="subject"
               v-model="currentSubject"
               :disabled="nbReadOnly"
               class="form-control m-input">
        <nb-error-block path="subject"></nb-error-block>
      </div>

      <select-with-create :nb-read-only="nbReadOnly"
                          :fieldName="'crm_target_id'"
                          :model="'targets'"
                          :route="'API.targets.options'"
                          :initial="initialSelectedTargetId"
                          :initialData="initialSelectedTarget"
                          @dirty="dirty">
        Lista de la campaña
        <span class="m-badge m-badge--brand m-badge--wide ml-2" v-show="!loading">
                    {{ targetCount }} {{ 'campaign_component.apply' | trans }}
                </span>
        <span v-show="loading" class="m-loader m-loader--sm m-loader--brand mx-4"></span>
        <template #modalTitle>Crear nueva lista de la campaña</template>
      </select-with-create>

      <select-with-create :nb-read-only="nbReadOnly"
                          :fieldName="'crm_template_id'"
                          :model="'templates'"
                          :route="'API.templates.options'"
                          :initial="initialSelectedTemplateId"
                          :initialData="initialSelectedTemplate">
        Plantilla
        <template #modalTitle>Crear nueva plantilla</template>
      </select-with-create>

    </div>
  </div>
</template>

<script>
export default {
  name: 'campaign-data-fields-component',
  props: {
    subject: { type: String, default: () => null },
    initialSelectedTemplateId: { type: Number, default: null },
    initialSelectedTargetId: { type: Number, default: null },
    initialSelectedTemplate: { type: Object, default: null },
    initialSelectedTarget: { type: Object, default: null },
    senders: { type: Array, required: true },
    selectedSender: { type: Object, required: false, default: null },
  },
  data () {
    return {
      currentSubject: null,
      loading: false,
      targetCount: 0,
      customer: false,
      sender: null
    }
  },
  mounted () {
    this.currentSubject = this.subject
    this.updateTargetCount(this.initialSelectedTarget)
    this.sender = this.selectedSender
  },
  methods: {
    /**
     * Updates the targets account
     */
    updateTargetCount (current) {
      if (current) {
        this.loading = true
        var context = this
        window.axios.post(route('API.campaigns.count'), { target: current.id })
            .then(function (response) {
              context.targetCount = response.data
              context.loading = false
            })
            .catch(function (error) {
              console.log(error)
            })
      } else {
        this.targetCount = 0
      }
    },
    /**
     * Handler of the dirty event
     */
    dirty (name, current) {
      if (name === 'crm_target_id') {
        this.updateTargetCount(current)
      }
      this.$emit('dirty')
    },
  }
}
</script>
