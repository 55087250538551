<template>
    <div class="form-group m-form__group col-12">
        <div class="mx-2 mt-4">
            <div class="d-sm-flex justify-content-between">
                <h4 class="d-flex align-items-center mb-5">
                    <em class="flaticon-alert mr-1"></em>Listado de preguntas
                    <div
                        v-if="loading"
                        class="m-loader m-loader--info"
                        style="width: 30px; display: inline-block;"
                    ></div>
                </h4>
            </div>
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
                <thead>
                    <tr>
                        <th>Pregunta</th>
                        <th>Cliente</th>
                        <th></th>
                        <th width="1%"></th>
                    </tr>
                </thead>
                <tbody v-if="questions.length">
                    <tr v-for="question in questions" :key="question.id">
                        <td>
                            {{ question.text }}
                        </td>
                        <td>
                            {{ question.customer.name }}
                            {{ question.customer.surname }}
                        </td>
                        <td class="min-col">
                            <button
                                class="btn btn-icon btn-danger btn-sm"
                                @click.prevent="deleteOption(question.id)"
                            >
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                        <td class="align-middle text-center"></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6" class="text-center py-4">
                            No hay preguntas para esta conferencia.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                class="text-right clearfix"
                v-if="thereIsData && hasPagination"
            >
                <paginator
                    class="pb-6"
                    :current-page="currentPage"
                    :links="paginationLinks"
                    :meta="paginationMeta"
                    :loading-data="loading"
                    :loading-page-number="loadingPageNumber"
                    @set-page="setPageNumber"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Paginator from "@/js/components/widgets/Paginator";

export default {
    name: "ConferenceQuestionsComponent",
    components: { Paginator },
    props: {
        lang: {
            type: String,
            default: null
        },
        conferenceId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            loadingPageNumber: 1,
            questionsPagination: null,
            questions: []
        };
    },
    mounted() {
        this.loadPageQuestions(1);
    },
    computed: {
        thereIsData() {
            return (
                this.questionsPagination !== null &&
                this.questionsPagination.hasOwnProperty("data") &&
                this.questionsPagination.data.length > 0
            );
        },
        hasPagination() {
            if (!this.questionsPagination) return false;
            return (
                this.questionsPagination.next_page_url !== null ||
                this.questionsPagination.prev_page_url !== null
            );
        },
        currentPage() {
            if (!this.questionsPagination) return 1;
            return this.questionsPagination.meta.current_page;
        },
        paginationLinks() {
            if (!this.questionsPagination) return {};
            return {
                prev: this.questionsPagination.links.prev,
                next: this.questionsPagination.links.next
            };
        },
        paginationMeta() {
            if (!this.questionsPagination) return {};
            return {
                current_page: this.questionsPagination.current_page
                    ? this.questionsPagination.current_page
                    : this.questionsPagination.meta.current_page,
                last_page: this.questionsPagination.last_page
                    ? this.questionsPagination.last_page
                    : this.questionsPagination.meta.last_page
            };
        }
    },
    methods: {
        async deleteOption(index) {
            const response = await axios.post(
                `/api/conferences/conference/${this.conferenceId}/questions/${index}/delete`
            );
            this.loading = true;
            try {
                if (response.status === 200) {
                    this.questionsPagination = response.data;
                    this.questions = this.questionsPagination.data;
                }
            } catch (e) {
                console.dir(e);
            } finally {
                this.loading = false;
            }
        },
        async loadPageQuestions(page = null) {
            this.loading = true;
            try {
                const response = await axios.get(
                    `/api/conferences/conference/${this.conferenceId}/questions`,
                    {
                        params: { page: page ? page : 1 }
                    }
                );
                if (response.status === 200) {
                    this.questionsPagination = response.data;
                    this.questions = this.questionsPagination.data;
                }
            } catch (e) {
                console.dir(e);
            } finally {
                this.loading = false;
            }
        },
        setPageNumber(page) {
            this.loadingPageNumber = page;
            this.loadPageQuestions(page);
        }
    }
};
</script>
