<template>
  <div v-if="!loading && show">
    <input type="hidden" name="new_extra" :id="`new_extra[${formData.name}]`" :value="currentDataJson">
    <div :id="formData.name"></div>
  </div>

</template>

<script>
import SurveyJsCommons from '@/js/mixins/SurveyJsCommons'
import { mapActions, mapGetters } from "vuex"

export default {
  name: 'ExtraField',
  mixins: [SurveyJsCommons],
  computed: {
    ...mapGetters("Customer", {
      extraData: "getExtraData",
    }),
    currentDataJson () {
      return JSON.stringify(this.currentDataUpdated)
    },
  },
  data() {
    return {
      survey: null,
      loading: true,
    }
  },
  async mounted() {
    await this.init()
  },

  methods: {
    ...mapActions("Customer", {
      setExtraData: "setExtraData",
    }),
    async init() {
      this.loading = true

      this.getFormData()

      this.loading = false

      this.$nextTick(() => this.survey.render(this.formData.name))
    },
    parseData(data) {
      return Array.isArray(data) ? {} : data
    },
  },
}
</script>
