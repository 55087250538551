<template>
  <span>
    <input type="hidden" name="custom_form_type" id="custom_form_type" :value="selectedType.id">
    <label class="form-label">
      {{ 'custom_form.custom_form_type' | trans }}
    </label>
    <multiselect
        id="custom-form-type"
        :options="types"
        v-model="selectedType"
        label="name" track-by="id"
        class="field--select field--select-grey p-4"
    />
    <div>
      {{ variablesMessage }}
    </div>
    <div>
      {{ telephoneMessage }}
    </div>    <nb-error-block path="custom_form_type"></nb-error-block>
  </span>
</template>\

<script>
  import NbErrorBlock from '@/js/components/error_block/nb-error-block'

  export default {
    name: 'CustomFormType',
    components: {
      NbErrorBlock
    },
    props: {
      value: {
        type: Number,
        required: true,
      },
      types: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        selectedType: {},
      }
    },
    created () {
      this.selectedType = (this.value != null) ? this.types.find(type => type.id === this.value) : {}
    },
    computed: {
      variablesMessage () {
        if (this.selectedType.id !== null) {
          return this.selectedType.requiredFields.length ?
            window.trans.trans('custom_form.fields_help_message', { variables: this.selectedType.requiredFields.join(', ') }) :
            ''
        }
        return ''
      },
      telephoneMessage () {
        if (this.selectedType.id === 0 || this.selectedType.id === 1) {
          return window.trans.trans('custom_form.telephone_help_message')
        }
        return ''
      },
    }
  }
</script>

<style lang="scss">
</style>
