export default {
    props: {
        surveyStructure: {
            type: Object,
            required: true,
        },
        responses: {
            type: Array,
            required: true,
        },
        optionsFilter: {
            type: Array,
            default: () => ([]),
        },
        filterPlaceholder: {
            type: String,
            default: 'Filtrar por actividad',
        },
        type: {
            type: String,
        },
        showFilter: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            vizPanel: null,
            selectedOptions: [],
            loading: false,
        }
    },
    computed: {
        filterData() {
            let filteredResponses = [];

            if(this.selectedOptions.length === 0) {
                return this.responses.filter(response => response.responses)
                    .flatMap(response => response.responses);
            }

            filteredResponses = this.responses.filter((response) => {
                return this.selectedOptions.some(selectedOption => {
                    return response.id === selectedOption.id;
                });
            });

            let responsesData = filteredResponses.flatMap((response) => {
                return response.responses;
            });

            return responsesData;

        }
    },

}
