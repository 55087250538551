<template>
    <div class="row">
        <div class="col-8">
            <h5 class="mb-0 mt-1">
                <i :class="evaluator.icon"></i>
                {{ evaluator.name }}
            </h5>
        </div>
        <div class="col-2">
            <select id="operator"
                    ref="operator"
                    name="operator"
                    class="form-select form-select-sm "
                    :value="value.operator"
                    :disabled="nbReadOnly"
                    @input="updateModel()">
                <option v-for="(operator, key) in operators" :value="key">{{ operator }}</option>
            </select>
        </div>
        <div class="col-2">
            <label for="evaluator_operator_number" class="sr-only"></label>
            <input id="evaluator_operator_number"
                   ref="number"
                   name="number"
                   class="form-control form-control-sm"
                   type="number"
                   :disabled="nbReadOnly"
                   :value="value.number"
                   @input="updateModel()"/>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'evaluator-operator',
    props: {
      evaluator: { type: Object, default: () => { } },
      value: { type: Object, default: () => ({ number: null, operator: 'same', }) },
      operators: {
        type: Object,
        default: () => ({
          same: '=',
          greaterThan: '>',
          smallerThan: '<',
          greaterThanOrEqual: '>=',
          smallerThanOrEqual: '<=',
        })
      }
    },
    methods: {
      updateModel () {
        let number = this.$refs.number.value
        let operator = this.$refs.operator.value

        this.$emit('input', { number: parseInt(number), operator: operator, })
      }
    },
  }
</script>

<style scoped>

</style>
