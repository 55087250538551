<template>
    <div>
        <div class="form-group mb-0">
            <label>
                <input type="radio" v-model="type" :value="TYPES.TYPE_IMAGE">
                <span>Imagen</span>
            </label>
            <label>
                <input type="radio" v-model="type" :value="TYPES.TYPE_TEXT">
                <span>Variable</span>
            </label>
            <div v-show="type == TYPES.TYPE_IMAGE">
                <label>Ruta de imagen</label>
                <repository-button :allowed-mime-types="allowedMimeTypes" :short-version="true" v-model="selectedImage"
                                   v-on:clear-value="clearValue" class-field="col-md-12 px-0"
                                   :key="activeElement.id">
                </repository-button>
            </div>
            <div v-show="type == TYPES.TYPE_TEXT">
                <label for="image-value">Variable</label>
                <SuggesterText id="elementContent"
                               :placeholder="'Valor del elemento'"
                               :suggestions="availableImageVariables"
                               v-model="selectedText"
                >
                    <template slot-scope="{ suggestion }">
                        {{ suggestion }}
                        <span v-if="renderVariable(suggestion) !== suggestion"
                              class="ml-2 m-badge m-badge--brand m-badge--wide">
                            {{ renderVariable(suggestion) }}
                        </span>
                    </template>
                </SuggesterText>
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapActions, mapGetters } from 'vuex'
  import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'
  import SuggesterText from '@/js/components/ticket_editor/tabs/element-tabs/SuggesterText'
  import SuggesterScopeMixin from '@/js/components/ticket_editor/tabs/element-tabs/SuggesterScopeMixin'

  const TYPES = {
    TYPE_IMAGE: 1,
    TYPE_TEXT: 2,
  }

  export default {
    name: 'ImageFieldProperties',
    components: { RepositoryButton, SuggesterText },
    mixins: [SuggesterScopeMixin],
    watch: {
      selectedImage: {
        handler: function (value) {
          if (value !== null) {
            this.setImage(value)
          }
        },
        immediate: true,
        deep: true,
      },
      selectedText (value) {
        this.setVariable(value)
      },
      activeElement: {
        handler: function (value) {
          if (value.imageType === this.TYPES.TYPE_IMAGE) {
            this.type = value.imageType
            this.selectedImage = value.backgroundId
          } else if (value.imageType === this.TYPES.TYPE_TEXT) {
            this.type = value.imageType
            this.selectedText = value.value
          }
        },
        immediate: true,
        deep: true,
      },
    },
    data () {
      return {
        showRepository: false,
        selectedImage: null,
        listFiles: [],
        type: 1,
        selectedText: '',
        TYPES: TYPES,
      }
    },
    props: {},
    mounted () {
    },
    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
        availableImageVariables: 'availableImageVariables',
      }),
      ...mapGetters('Repository', {
        allowedMimeTypes: 'getMimeTypeImages',
      }),
    },
    methods: {
      ...mapActions('ticketEditor', {
        onEditorLoading: 'onEditorLoading',
        offEditorLoading: 'offEditorLoading',
      }),
      async setImage (img) {
        await this.getFiles(img)
        if (this.listFiles.length === 0) return

        this.activeElement.value = this.listFiles[0].url
        this.activeElement.backgroundId = this.listFiles[0].id
        this.activeElement.imageType = this.type
      },
      setVariable (text) {
        this.activeElement.value = text
        this.activeElement.backgroundId = null
        this.activeElement.imageType = this.type
      },
      async getFiles (ids) {
        this.onEditorLoading()
        try {
          let response = await axios.get(route('API.repository.getFiles', { files: ids }))
          this.filterFiles(response.data)
        } catch (error) {
          console.log(error)
        }
        this.offEditorLoading()
      },
      filterFiles (data) {
        this.listFiles = data.files
      },
      layoutChange () {
        this.$eventBus.$emit('layoutChange')
      },
      clearValue () {
        this.activeElement.value = ''
        this.activeElement.backgroundId = null
      },
    },
  }
</script>
