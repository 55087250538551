var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "flex-column-auto pt-5 pb-1 px-6",
        attrs: { id: "kt_aside_search" },
      },
      [
        _c("div", { staticClass: "btn-custom btn p-0 w-100" }, [
          _c("span", { staticClass: "btn-label" }, [_c("section-search")], 1),
          _vm._v(" "),
          _c("span", { staticClass: "svg-icon btn-icon svg-icon-2" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "24px",
                  height: "24px",
                  viewBox: "0 0 24 24",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Stockholm-icons-/-General-/-Search",
                      stroke: "none",
                      "stroke-width": "1",
                      fill: "none",
                      "fill-rule": "evenodd",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        id: "bound",
                        x: "0",
                        y: "0",
                        width: "24",
                        height: "24",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z",
                        id: "Path-2",
                        fill: "#000000",
                        "fill-rule": "nonzero",
                        opacity: "0.3",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z",
                        id: "Path",
                        fill: "#000000",
                        "fill-rule": "nonzero",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { attrs: { id: "kt_aside_favs" } }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: Object.entries(_vm.favorites).length,
              expression: "Object.entries(favorites).length",
            },
          ],
          staticClass: "m-aside-menu-favs",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "component-fade", mode: "out-in" } },
            [
              Object.entries(_vm.favorites).length < 5
                ? _c(
                    "transition-group",
                    {
                      staticClass: "aside-menu",
                      attrs: { name: "list", tag: "div" },
                    },
                    _vm._l(_vm.favorites, function (favorite) {
                      return _c(
                        "div",
                        {
                          key: favorite.attributes.id,
                          staticClass:
                            "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
                          attrs: { "aria-haspopup": "true" },
                        },
                        [
                          _c("div", { staticClass: "menu-item" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "m-aside-menu-favs__link menu-link position-relative",
                                attrs: {
                                  href: _vm.getUrl(favorite),
                                  id:
                                    "favs-menu_" +
                                    favorite.attributes.unique_id,
                                },
                              },
                              [
                                _c("em", {
                                  staticClass: "menu-icon fa-fw fa fa-star",
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "menu-title" }, [
                                  _vm._v(_vm._s(favorite.title)),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "flex-column-auto pt-5 pb-1 px-6" },
                    [
                      _c("div", { staticClass: "btn-custom btn p-0 w-100" }, [
                        _c(
                          "span",
                          { staticClass: "btn-label overflow-visible" },
                          [
                            _c("multiselect", {
                              staticClass:
                                "m-aside-menu-favs__select is-active",
                              attrs: {
                                id: "favs",
                                multiple: false,
                                placeholder: "Seleccione una sección",
                                options: _vm.favoritesSelect,
                                label: "title",
                                "track-by": "id",
                              },
                              on: { input: _vm.redirectToSection },
                              model: {
                                value: _vm.selectedFav,
                                callback: function ($$v) {
                                  _vm.selectedFav = $$v
                                },
                                expression: "selectedFav",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "btn-icon" }, [
                          _c("i", { staticClass: "fa fa-fw fa-star" }),
                        ]),
                      ]),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "aside-menu flex-column-fluid" }, [
      _c(
        "div",
        {
          staticClass: "hover-scroll-overlay-y",
          attrs: {
            id: "kt_aside_menu_wrapper",
            "data-kt-scroll": "true",
            "data-kt-scroll-activate": "{default: false, lg: true}",
            "data-kt-scroll-height": "auto",
            "data-kt-scroll-dependencies":
              "#kt_aside_logo, #kt_aside_footer, #kt_aside_search, #kt_aside_favs",
            "data-kt-scroll-wrappers": "#kt_aside_menu",
            "data-kt-scroll-offset": "0",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
              attrs: { id: "#kt_aside_menu", "data-kt-menu": "true" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "m-grid__item m-aside-left m-aside-left--skin-dark",
                  attrs: { id: "m_aside_left" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-light",
                      attrs: {
                        id: "m_ver_menu",
                        "data-menu-vertical": "true",
                        "data-menu-scrollable": "true",
                        "data-menu-dropdown-timeout": "500",
                      },
                    },
                    [
                      _c("div", { staticClass: "menu-item" }, [
                        _c(
                          "div",
                          { staticClass: "menu-content pt-4 pb-2" },
                          _vm._l(_vm.sections, function (childSections, key) {
                            return _c("div", { key: key }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "menu-section text-muted text-uppercase fs-8 ls-1",
                                },
                                [_vm._v(_vm._s(key))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.noResults,
                                      expression: "noResults",
                                    },
                                  ],
                                  staticClass:
                                    "p-3 text-center m-aside-menu__no-results",
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "70%",
                                      height: "90px",
                                      "border-radius": "20px",
                                    },
                                    attrs: {
                                      src: "/images/misc/tumbleweed.gif",
                                      alt: "no results",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      '\n                                            No se han encontrado secciones que contengan la palabra "' +
                                        _vm._s(_vm.getFilter) +
                                        '".\n                                        '
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
                                  class: {
                                    "js-filtered": _vm.getFilter !== null,
                                  },
                                },
                                _vm._l(childSections, function (section) {
                                  return _c("menu-item", {
                                    key: section.attributes.id,
                                    attrs: {
                                      item: section,
                                      favorited: _vm.isFavorite(section),
                                      favorites: _vm.favorites,
                                    },
                                  })
                                }),
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "aside-menu" }, [
      _c(
        "div",
        {
          staticClass:
            "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
        },
        [
          _c("div", { staticClass: "menu-item" }, [
            _c("div", { staticClass: "menu-content pt-4 pb-2" }, [
              _c(
                "span",
                {
                  staticClass:
                    "menu-section text-muted text-uppercase fs-8 ls-1",
                },
                [_vm._v("Favoritos")]
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }