var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mb-6 form-group m-form__group form-group-sm col-12 noprefetch m--padding-left-30 m--padding-right-30 py-0 m-form",
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "specialEvents", id: "specialEvents" },
        domProps: { value: _vm.specialEventsJSON },
      }),
      _vm._v(" "),
      _c(
        "article",
        { staticClass: "block position-relative c-contact pt-4 mb-5" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-end mb-4",
                    attrs: { type: "button" },
                    on: { click: _vm.addNewEvent },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("trans")("event.addEventButton")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("vue-cal", {
                class: {
                  "arrow--prev": _vm.startDate > _vm.minDate,
                  "arrow--next": _vm.endDate < _vm.maxDate,
                },
                attrs: {
                  "disable-views": ["years", "year", "month"],
                  events: _vm.events,
                  "split-days": _vm.splitDays,
                  locale: "es",
                  "show-all-day-events": false,
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate,
                  "selected-date": _vm.selectedDate,
                  "hide-weekends": "",
                  "time-from": 7 * 60,
                  "time-to": 24 * 60,
                  "time-step": 30,
                  "time-cell-height": 40,
                  "sticky-split-labels": "",
                  "editable-events": "",
                  "snap-to-time": 5,
                },
                on: {
                  "view-change": function ($event) {
                    return _vm.viewChanged($event)
                  },
                  "cell-click": _vm.changeSelectedDay,
                  "event-drag-create": function ($event) {
                    return _vm.addEvent($event)
                  },
                  "event-change": function ($event) {
                    return _vm.changeEvent($event)
                  },
                  "event-delete": function ($event) {
                    return _vm.deleteEvent($event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "split-label",
                    fn: function ({ split, view }) {
                      return [
                        _c("b-icon", {
                          staticClass: "me-2 d-none d-sm-inline-block",
                          style: `color: ${split.color}`,
                          attrs: { icon: "calendar3", "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _c("strong", { style: `color: ${split.color}` }, [
                          _vm._v(_vm._s(split.label)),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "event",
                    fn: function ({ event, view }) {
                      return [
                        _c("div", { staticClass: "h-100 w-100 scroll-y p-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "border-start border-dark text-start ps-2",
                              staticStyle: {
                                "border-left-width": "2px !important",
                              },
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "vuecal__event-title vuecal__event-title--edit text-start font-weight-bold overflow-hidden text-nowrap vac-text-ellipsis",
                                attrs: { contenteditable: "" },
                                domProps: { innerHTML: _vm._s(event.title) },
                                on: {
                                  blur: function ($event) {
                                    return _vm.changeEventTitle(
                                      event,
                                      $event.target.innerHTML
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vuecal__event-time font-size--t11 text-left overflow-hidden text-nowrap vac-text-ellipsis",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(event.start.formatTime("HH:mm"))
                                    ),
                                  ]),
                                  _vm._v(" - "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(event.end.formatTime("HH:mm"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }