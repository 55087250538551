var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-5 mx-4 mb-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-3 mb-4" }, [
        _c(
          "label",
          { staticClass: "d-block" },
          [
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Desde")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.startDate,
                  expression: "startDate",
                },
              ],
              staticClass: "form-control mr-4",
              attrs: {
                type: "datetime-local",
                disabled: _vm.nbReadOnly,
                placeholder: "Inicio",
                name: "startDate",
              },
              domProps: { value: _vm.startDate },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.startDate = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.start_date` },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-3 mb-4" }, [
        _c(
          "label",
          { staticClass: "d-block" },
          [
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Hasta")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.endDate,
                  expression: "endDate",
                },
              ],
              staticClass: "form-control mr-4",
              attrs: {
                type: "datetime-local",
                disabled: _vm.nbReadOnly,
                placeholder: "Inicio",
                name: "endDate",
              },
              domProps: { value: _vm.endDate },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.endDate = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.end_date` },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-6 mb-4" }, [
        _c(
          "label",
          { staticClass: "d-block" },
          [
            _c("span", { staticClass: "fw-bold" }, [_vm._v("URL")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.url,
                  expression: "url",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "url" },
              domProps: { value: _vm.url },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.url = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("NbErrorBlock", {
              attrs: { path: `properties.${_vm.propertyKey}.url` },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }