<template>
  <div class="mb-6 form-group m-form__group form-group-sm col-12 noprefetch m--padding-left-30 m--padding-right-30 py-0 m-form">
    <input type="hidden" name="scheduleGroupingType" id="scheduleGroupingType" :value="scheduleGroupingTypeId" />
    <article class="block position-relative c-contact pt-4 mb-5">
      <div class="container">

          <div style="padding-left: 20px;padding-right: 20px">

          <!--begin::Accordion-->
          <div class="accordion mb-8" id="kt_accordion_1">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="kt_accordion_1_header_1">
                      <button class="accordion-button fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1">
                          Instrucciones
                      </button>
                  </h2>
                  <div id="kt_accordion_1_body_1" class="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                      <div class="accordion-body">
                          <ul>
                          <li><strong>Editar titulo:</strong> Hacer clic sobre el titulo.</li>
                          <li><strong>cambiar de hora:</strong> mantener pulsado la card y moverla a la hora deseada.</li>
                          <li><strong>modificar duracion:</strong> poner el ratón en la base del card, hacer clic y arrastras a la hora fin deseada.</li>
                          <li><strong>eliminar:</strong> mantener pulsado sobre la card, hasta que aparezca la opción de borrar.</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
              <!--end::Accordion-->
              <div class="row">
                  <div class="col col-4">
                      <span class="field__span form-label field__span--required">{{
                              'event.schedule_grouping_type' | trans
                          }}</span>
                      <select v-model="scheduleGroupingType" class="form-control">
                          <option v-for="type in scheduleGroupingTypes" :key="type.id" :value="type">
                              {{ type.name }}
                          </option>
                      </select>
                  </div>
              </div>
          </div>

        <Calendar :special-events="specialEvents"
                  :start-date-event="startDateEvent"
                  :end-date-event="endDateEvent"
                  :split-days="splitDays"
        />
      </div>
    </article>
  </div>
</template>

<script>
import Calendar from '@/js/components/events/Calendar'

export default {
  name: 'Schedule',
  components: {
    Calendar,
  },
  props: {
    specialEvents: {
      type: Array,
      required: true,
    },
    startDateEvent: [String, null],
    endDateEvent: [String, null],
    scheduleGroupingTypes: {
      type: Array,
      required: true,
    },
    groupingTypes: {
      type: Array,
      required: true,
    },
    selectedScheduleGroupingTypeId: [String, Number],
  },
  data() {
    return {
      loading: false,
      // splitDays: [],
      scheduleGroupingType: null,
    }
  },
  computed: {
    splitDays () {
      const splits = []
      if (this.scheduleGroupingType.group_by) {
        const groupType = this.groupingTypes.find(type => type.name === this.scheduleGroupingType.group_by) ?? []
        let counter = 1
        for (const type of groupType.values) {
          const splitName = `split${counter}`
          splits.push({
            id: splitName,
            class: `u-skin-cal-${counter} u-skin-color u-skin-bg`,
            label: type,
            hide: false,
          })
          counter++
        }
      }

      return splits
    },
    scheduleGroupingTypeId () {
      return this.scheduleGroupingType ? this.scheduleGroupingType.id : this.scheduleGroupingTypes[0].id
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.selectedScheduleGroupingTypeId) {
        this.scheduleGroupingType = this.scheduleGroupingTypes.find(groupingType => groupingType.id == this.selectedScheduleGroupingTypeId)
      } else {
        this.scheduleGroupingType = this.scheduleGroupingTypes[0]
      }
    }
  }
}
</script>
