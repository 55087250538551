var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "table",
        {
          staticClass:
            "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2",
        },
        [
          _c("thead", [
            _c("tr", [
              _c("th", { staticClass: "fw-bold" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("trans")(
                        "conference.properties.property_resources.title_title"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "fw-bold" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("trans")(
                        "conference.properties.property_resources.description_title"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "fw-bold" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("trans")(
                        "conference.properties.property_resources.file_title"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              !_vm.lang ? _c("th", { attrs: { width: "1%" } }) : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.resources, function (resource, indexResource) {
              return _c(
                "tr",
                { key: indexResource, staticClass: "align-top" },
                [
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "m-form__control" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: resource.title,
                              expression: "resource.title",
                            },
                          ],
                          staticClass: "form-control m-input",
                          attrs: {
                            placeholder: "Opcion",
                            disabled: _vm.nbReadOnly,
                            id: "option-" + indexResource,
                          },
                          domProps: { value: resource.title },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(resource, "title", $event.target.value)
                              },
                              _vm.emit,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("NbErrorBlock", {
                          attrs: {
                            path: `properties.${_vm.propertyKey}.resources.${indexResource}.title`,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "m-form__control" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: resource.description,
                              expression: "resource.description",
                            },
                          ],
                          staticClass: "form-control m-input",
                          attrs: {
                            placeholder: "Opcion",
                            disabled: _vm.nbReadOnly,
                            id: "option-" + indexResource,
                          },
                          domProps: { value: resource.description },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  resource,
                                  "description",
                                  $event.target.value
                                )
                              },
                              _vm.emit,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("NbErrorBlock", {
                          attrs: {
                            path: `properties.${_vm.propertyKey}.resources.${indexResource}.description`,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "table__td-resource" },
                    [
                      _c("repository-button", {
                        attrs: {
                          "is-disabled": false,
                          "group-id": "1",
                          "group-type": "App\\Models\\Rep\\FileGroup",
                        },
                        on: { input: _vm.emit },
                        model: {
                          value: resource.resource_id,
                          callback: function ($$v) {
                            _vm.$set(resource, "resource_id", $$v)
                          },
                          expression: "resource.resource_id",
                        },
                      }),
                      _vm._v(" "),
                      _c("NbErrorBlock", {
                        attrs: {
                          path: `properties.${_vm.propertyKey}.resources.${indexResource}.resource_id`,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-nowrap" }, [
                    _c("div", { staticClass: "actions text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-icon btn-danger btn-sm",
                          attrs: {
                            type: "button",
                            disabled: _vm.nbReadOnly,
                            id: "delete-option-" + indexResource,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteOption(indexResource)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-trash" })]
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      [
        _c(
          "modal-component",
          {
            attrs: {
              theRef: "modalReset",
              visible: _vm.showModal,
              backdrop: true,
              classesFooter: ["text-right"],
              classesModalDialog: ["modal-dialog-centered modal-lg"],
            },
            on: {
              "update:visible": function ($event) {
                _vm.showModal = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("trans")(
                            "conference.properties.property_resources.modal.title"
                          )
                        ) +
                        "\n              "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteResource()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm._f("trans")(
                                "conference.properties.property_resources.modal.confirm"
                              )
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.showModal = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm._f("trans")(
                                "conference.properties.property_resources.modal.cancel"
                              )
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            [
              _c("div", [
                _vm._v(
                  "\n                      " +
                    _vm._s(
                      _vm._f("trans")(
                        "conference.properties.property_resources.modal.text"
                      )
                    ) +
                    "\n                  "
                ),
              ]),
            ],
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }