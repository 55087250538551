var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticket-select-elements" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "SortableList",
        {
          attrs: { lockAxis: "y", distance: 5 },
          on: { input: _vm.updateIndex },
          model: {
            value: _vm.elements,
            callback: function ($$v) {
              _vm.elements = $$v
            },
            expression: "elements",
          },
        },
        [
          _vm._l(_vm.elements, function (item, index) {
            return _vm.elements.length > 0
              ? _c("SortableItem", {
                  key: index,
                  attrs: {
                    index: index,
                    item: item,
                    "active-item": _vm.activeElement,
                  },
                  on: { itemActivated: _vm.setActiveItem },
                })
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.elements.length <= 0
            ? _c(
                "li",
                {
                  staticClass: "ticket-select-elements__add-message",
                  attrs: { id: "add-item" },
                  on: {
                    click: function ($event) {
                      return _vm.activeBar()
                    },
                  },
                },
                [
                  _c("em", { staticClass: "fa fa-fw fa-plus-circle" }),
                  _vm._v(
                    "\n            Añade un elemento a la plantilla\n        "
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ticket-select-elements__title" }, [
      _c("em", { staticClass: "fa fa-fw fa-object-group" }),
      _vm._v("\n        Elementos\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }