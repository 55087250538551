var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-color-picker", {
        attrs: {
          "class-field": "form-group",
          field: "frontColor",
          name: "Color de punto",
          "needs-target": true,
        },
        on: {
          input: function ($event) {
            return _vm.updateQRCode($event, "colorDark")
          },
        },
        model: {
          value: _vm.activeElement.qr.colorDark,
          callback: function ($$v) {
            _vm.$set(_vm.activeElement.qr, "colorDark", $$v)
          },
          expression: "activeElement.qr.colorDark",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [
            _c("i", {
              directives: [{ name: "tooltip", rawName: "v-tooltip" }],
              staticClass: "fa fa-fw fa-warning",
              attrs: {
                title:
                  "Algunos lectores tienen problemas para leer fondos distintos al blanco.",
              },
            }),
            _vm._v("\n            Color de fondo\n        "),
          ]),
          _vm._v(" "),
          _c("vue-color-picker", {
            attrs: {
              "class-field": "",
              field: "backColor",
              "needs-target": true,
            },
            on: {
              input: function ($event) {
                return _vm.updateQRCode($event, "colorLight")
              },
            },
            model: {
              value: _vm.activeElement.qr.colorLight,
              callback: function ($$v) {
                _vm.$set(_vm.activeElement.qr, "colorLight", $$v)
              },
              expression: "activeElement.qr.colorLight",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "margin" } }, [_vm._v("Margen")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: { type: "number", id: "margin", min: "1" },
          domProps: { value: _vm.activeElement.qr.margin },
          on: {
            input: function ($event) {
              return _vm.updateQRCode($event, "margin")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "size" } }, [_vm._v("Tamaño")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: { type: "number", id: "size", min: "1" },
          domProps: { value: _vm.activeElement.qr.width },
          on: {
            input: function ($event) {
              return _vm.updateQRCode($event, "width")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qr-styles-message" }, [
      _c("i", { staticClass: "fa fa-fw fa-warning" }),
      _vm._v(
        " Es importante que el código tenga un contraste y tamaño adecuado para su\n        correcta lectura.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }