<template>
  <div>
    <input type="hidden" name="properties" :value="propertiesJSON">
    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
      <tbody>
        <tr v-for="(property, key) of propertiesStructure" :key="key">
          <td class="align-top py-5">
            <div class="m-form__control">
                <label class="bg-light p-4">
                    <input
                        type="checkbox"
                        name="posted"
                        id="posted"
                        :checked="existsProperty(key)"
                        class="form-check-input me-2"
                        data-bs-target="#kt_accordion_1_body_1"
                        @click="checkProperty(key, $event)"
                    />
                    <span class="cursor-pointer">{{ property.name }}</span>
                </label>
              <div v-if="existsProperty(key)" id="kt_accordion_1_body_1" class="accordion-body">
                <component :is="property.component"
                           :value="selectedProperties[key]"
                           :property-key="key"
                           v-bind="property.props"
                           @input="setProperty($event, key)"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "ConferenceProperties",
  props: {
    lang: {
      type: String,
      default: null
    },
    properties: {
      type: Object,
      required: true
    },
    propertiesStructure: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      selectedProperties: {},
    }
  },
  created () {
    this.selectedProperties = this.properties ? { ...this.properties } : {}
    for (let component of this.propertyComponents) {
      Vue.component(component, () => import(`@/js/components/conference/properties/${component}`))
    }
  },
  computed: {
    propertyComponents () {
      return Object.values(this.propertiesStructure).map(property => property.component)
    },
    selectedPropertiesNames () {
      return Object.keys(this.selectedProperties)
    },
    propertiesJSON () {
      return JSON.stringify(this.selectedProperties)
    }
  },
  methods: {
    existsProperty(property) {
      return this.selectedPropertiesNames.includes(property)
    },
    checkProperty(property, event) {
      const checked = event.target.checked

      if (!checked) {
        Vue.delete(this.selectedProperties, property)
      } else {
        Vue.set(this.selectedProperties, property, {})
      }
    },
    setProperty (event, propertyKey) {
      Vue.set(this.selectedProperties, propertyKey, event)
    }
  }
};
</script>
