<template>
    <div class="mt-5 mx-4 mb-0">

            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="switchPropertyEventExternal" disabled v-model="external">
                <label class="form-check-label" for="switchPropertyEventExternal">
                    {{ 'conference.properties.property_event.external' | trans }}
                </label>
            </div>

            <div v-if="!external">
                <span>
                    {{ 'conference.properties.property_event.event' | trans }} {{ value.eventName }}
                </span>
                <a :href="`/events/${value.eventId}/edit`">{{ 'conference.properties.property_event.go_to_event' | trans }}</a>
            </div>

            <div v-if="!external" class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="switchPropertyEventOverwrite" v-model="overwriteEventData">
                <label class="form-check-label" for="switchPropertyEventOverwrite">
                    {{ 'conference.properties.property_event.overwrite_event_data' | trans }}
                </label>
            </div>

            <label v-if="external" class="mb-3">
                <span class="fw-bold">{{ 'conference.properties.property_event.url' | trans }}</span>
                <input class="form-control" type="text" v-model="url"/>
                <NbErrorBlock :path="`properties.${propertyKey}.url`"/>
            </label>

            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="switchPropertyEventDirectLink" v-model="directLink">
                <label class="form-check-label" for="switchPropertyEventDirectLink">
                    {{ 'conference.properties.property_event.direct_link' | trans }}
                </label>
            </div>
    </div>
</template>


<script>
  import NbErrorBlock from "@/js/components/error_block/nb-error-block"

  export default {
    name: 'ConferencePropertyEvent',
    components: {
      NbErrorBlock,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      lang: {
        type: String,
        default: null,
      },
      propertyKey: {
        type: String,
        required: true
      },
      eventName: {
        type: String,
        default: '',
      },
    },
    computed: {
      external: {
        get() {
            if (this.value.external == null) {
                this.emit({ external: true })
            }

            return this.value.external
        },
        set(value) {
            this.emit({ external: value })
        },
      },
      overwriteEventData: {
        get() {
            return this.value.overwriteEventData
        },
        set(value) {
            this.emit({ overwriteEventData: value })
        },
      },
      url: {
        get() {
            return this.value.url
        },
        set(value) {
            this.emit({ url: value })
        },
      },
      directLink: {
        get() {
            return this.value.directLink
        },
        set(value) {
            this.emit({ directLink: value })
        },
      },
    },
    methods: {
      emit (field) {
        this.$emit('input', { ...this.value, ...field })
      },
    },
  }
</script>
