var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "m-entity-list-search form-group m-form__group col-12" },
      [
        _c("div", { staticClass: "row col-12 py-2 mt-4" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("input", {
              ref: "file",
              attrs: {
                type: "file",
                name: "data",
                id: "data",
                disabled: _vm.nbReadOnly,
              },
              on: { change: _vm.setFile },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "btn btn-block btn-primary m-btn--icon",
                attrs: { for: "file", disabled: _vm.nbReadOnly || _vm.loading },
                on: {
                  click: function ($event) {
                    return _vm.$refs.file.click()
                  },
                },
              },
              [
                _c("em", { staticClass: "fa fa-plus-circle fa-fw me-1" }),
                _vm._v(
                  "\n                    Seleccionar archivo\n                "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-brand btn m-btn--icon",
                attrs: {
                  type: "button",
                  id: "preview",
                  disabled: _vm.nbReadOnly || !_vm.file || _vm.loading,
                },
                on: { click: _vm.preview },
              },
              [_vm._m(0)]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-brand btn m-btn--icon",
                attrs: {
                  type: "button",
                  id: "import",
                  disabled: _vm.nbReadOnly || !_vm.file || _vm.loading,
                },
                on: { click: _vm.importData },
              },
              [
                _c("span", [
                  _c("i", { staticClass: "la la-refresh" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Importar " + _vm._s(_vm.filename))]),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "m-form__section body-padding pt-0" }, [
      _vm.previewData
        ? _c("div", { staticClass: "row ml-2 mr-2" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "table",
                { staticClass: "table table-striped table-bordered m-table" },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(_vm.previewData.headers, function (header) {
                        return _c("th", [_vm._v(_vm._s(header))])
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.previewData.rows.length
                    ? _c(
                        "paginate",
                        {
                          ref: "rowsPaginator",
                          attrs: {
                            name: "rows",
                            list: _vm.previewData.rows,
                            per: 10,
                            tag: "tbody",
                          },
                        },
                        _vm._l(_vm.paginated("rows"), function (row) {
                          return _c(
                            "tr",
                            _vm._l(_vm.previewData.headers, function (header) {
                              return _c("td", [_vm._v(_vm._s(row[header]))])
                            }),
                            0
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.paginated("rows").length
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _vm.$refs.rowsPaginator
                      ? _c("span", [_vm._v(_vm._s())])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-8" }, [
                    _c("div", { staticClass: "pull-right" }, [
                      _c(
                        "nav",
                        { attrs: { "aria-label": "Paginación de filas" } },
                        [
                          _c("paginate-links", {
                            attrs: {
                              limit: 4,
                              "show-step-links": true,
                              "step-links": { prev: "‹", next: "›" },
                              "hide-single-page": true,
                              classes: {
                                ul: "pagination",
                                li: "page-item",
                                a: "page-link",
                              },
                              async: true,
                              for: "rows",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "la la-refresh" }),
      _vm._v(" "),
      _c("span", [_vm._v("Vista previa")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }