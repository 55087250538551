<template>
    <tr class="align-top">
        <td>
            <div class="m-form__control">
                <input v-model="startDate" type="datetime-local" class="form-control m-input" :disabled="nbReadOnly">
                <NbErrorBlock :path="`properties.${propertyKey}.surveys.${index}.start_date`" />
            </div>
        </td>
        <td>
            <div class="m-form__control">
                <input v-model="endDate" type="datetime-local" class="form-control m-input" :disabled="nbReadOnly">
                <NbErrorBlock :path="`properties.${propertyKey}.surveys.${index}.end_date`" />
            </div>
        </td>
        <td class="table__td-survey">
            <multiselect v-model="survey" :options="surveyArray" label="name" track-by="id" />
            <NbErrorBlock :path="`properties.${propertyKey}.surveys.${index}.survey`" />
        </td>
        <td class="align-middle text-nowrap">
            <div class="actions text-center">
                <button type="button" class="btn btn-icon btn-danger btn-sm" @click="deleteOption(index)"
                    :disabled="nbReadOnly">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>
</template>


<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'
import StartAndEndDates from '@/js/components/conference/properties/StartAndEndDates'

export default {
    name: 'ConferencePropertySurveyableSurveyRow',
    components: {
        RepositoryButton,
        NbErrorBlock
    },
    mixins: [ StartAndEndDates ],
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            required: true,
        },
        lang: {
            type: String,
            default: null,
        },
        propertyKey: {
            type: String,
            required: true
        },
        surveyArray: {
            type: Array,
            required: true
        }
    },
    computed: {
        survey: {
            get() {
                return this.value.survey
            },
            set(value) {
                this.emit({ survey: value })
            },
        },
    },
    methods: {
        deleteOption(index) {
            this.$emit('delete')
        },
        emit(value) {
            this.$emit('input', { ...this.value, ...value })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
