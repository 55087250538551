<template>

    <div class="w-100">
        <!-- Tipos -->
        <div v-if="!lang" class="c-survey-component__question-types form-group m-form__group mb-5">
            <h6 class="c-survey-component__title mb-5">
                <i class="fs-2 bi bi-file-earmark-text"></i>
                tipos de preguntas
            </h6>
            <div class="btn-group m-btn-group btn-block w-100" role="group">
                <button class="btn btn-outline-primary border border-primary" type="button"
                        v-for="type in question_types"
                        @click="selectType(type.id)"
                        :id="'question-type-' + type.id"
                        :key="'k-' + type.id"
                        :disabled="nbReadOnly"
                        :class="{'btn-primary': question.question_type_id === type.id, 'btn-outline-primary': question.question_type_id !== type.id}">
                    {{ type.name }}
                </button>
            </div>
        </div>
        <div v-else>
            <input type="hidden" name="question_type_id" v-model="selectedType"/>
        </div>

        <!-- Preguntas con opciones -->
        <div v-if="isWithOptions" class="c-survey-component__question-options form-group m-form__group">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h6 class="c-survey-component__title m-0">
                    <i class="fs-2 bi bi-file-earmark-text"></i>
                    Opciones de la pregunta
                </h6>
                <button type="button" class="btn btn-primary m-btn m-btn--icon" @click="addOption"
                        :disabled="nbReadOnly" id="add-option">
                    <span>
                        <i class="fa fa-plus"></i>
                        <span>Añadir opción</span>
                    </span>
                </button>
            </div>

            <table class="table table-striped table-bordered m-table m-0">
                <thead>
                <tr>
                    <th>Opción</th>
                    <th>Valor</th>
                    <th width="5%" class="text-center" v-if="!lang">Seleccionada</th>
                    <th width="1%" v-if="!lang"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(option, indexOption) in question.options" class="align-top">
                    <td class="p-2 align-middle">
                        <div class="m-form__control">
                            <input v-model="option.option" class="form-control form-control-sm m-input"
                                   placeholder="Opcion" :disabled="nbReadOnly" :id="'option-' + indexOption">
                            <p v-if="hasOptionHelpBlock(indexOption)" class="m-form__help">
                                {{ question.options_help_block[indexOption].option }}
                            </p>
                            <nb-error-block :path="`questions.${index}.options.${indexOption}.option`"></nb-error-block>
                        </div>
                    </td>
                    <td class="p-2 align-middle">
                        <div class="m-form__control">
                            <input v-model="option.value" class="form-control form-control-sm m-input"
                                   placeholder="Valor a devolver" :disabled="nbReadOnly"
                                   :id="'return-value-' + indexOption">
                            <span v-if="hasOptionHelpBlock(indexOption)" class="m-form__help">
                                    {{ question.options_help_block[indexOption].value }}
                                </span>
                            <nb-error-block :path="`questions.${index}.options.${indexOption}.value`"></nb-error-block>
                        </div>
                    </td>
                    <td v-if="!lang" class="text-center p-2 align-middle">
                        <label class="m-checkbox m-checkbox--brand pl-3 mt-2">
                            <input type="checkbox" checked="checked" v-model="option.is_selected"
                                   :id="'select-option-' + indexOption"
                                   @change="setOptionSelected(indexOption)" :disabled="nbReadOnly">
                            <span></span>
                        </label>
                    </td>
                    <td v-if="!lang" class="align-middle text-nowrap p-2 align-middle">
                        <div class="actions text-center">
                            <button type="button" class="btn btn-icon btn-sm btn-danger"
                                    @click="deleteOption(indexOption)" :disabled="nbReadOnly"
                                    :id="'delete-option-' + indexOption">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- Preguntas de tipo checkbox -->
        <div v-else-if="isCheckbox" class="c-survey-component__question-options form-group m-form__group">
            <span class="m-switch m-switch--icon">
                <label class="">Valor por defecto</label>
                <label>
                    <input
                            v-model="defaultValue"
                            :disabled="nbReadOnly"
                            type="checkbox">
                    <span></span>
                </label>
            </span>
        </div>
        <!-- Preguntas sin opciones -->
        <div v-else class="c-survey-component__question-options form-group m-form__group">
            <label for="default_value">Respuesta por defecto</label>
            <input :type="questionType.type" name="default_value" id="default_value"
                   :disabled="nbReadOnly"
                   v-model="defaultValue"
                   class="form-control m-input">
            <p v-if="question.default_help_block" class="m-form__help">{{ question.default_help_block }}</p>
        </div>
    </div>

</template>
<script>
  import NbErrorBlock from "@/js/components/error_block/nb-error-block";

  export default {
    components: { NbErrorBlock },
    props: {
      index: { type: Number, default: -1 },
      lang: { type: String, default: null },
      question: { type: Object, required: true },
      question_types: { type: Array, default: () => [] },
    },

    computed: {
      questionType () {
        return this.selectedType
      },
      isCheckbox: function () {
        return this.selectedType && this.selectedType.type === 'checkbox'
      },
      selectedType () {
        return this.question_types.find(type => type.id === this.question.question_type_id)
      },
      isSingle () {
        return this.selectedType.multiple === 1
      },
      isMultiple () {
        return this.selectedType.multiple === -1
      },
      isWithOptions () {
        return this.selectedType.multiple !== 0
      },
      defaultValue: {
        get () {
          const value = this.question.default_value
          switch (this.questionType.type) {
            case 'checkbox':
              if (typeof value === 'string')
                return parseInt(value)
              else
                return value
            default:
              return value
          }
        },
        set (value) {
          this.question.default_value = value
        }
      },
    },

    methods: {
      newEmptyOption () {
        return {
          option: null,
          value: null,
          is_selected: null,
        }
      },
      activeType (id) {
        return this.question.question_type === id
      },
      addOption () {
        let empty = this.newEmptyOption()
        this.question.options.push(empty)
      },
      selectType (typeId) {
        this.question.question_type_id = typeId
        this.$nextTick(this.checkSelecteds)
      },
      setDefaultValue (value) {
        this.question.default_value = value
      },
      hasOptionHelpBlock (index) {
        return this.question.options_help_block && this.question.options_help_block[index]
      },
      setOptionSelected (index) {
        if (this.question.options[index].is_selected && !this.isMultiple) {
          for (let i = 0; i < this.question.options.length; i++) {
            if (i !== index) {
              this.question.options[i].is_selected = false
            }
          }
        }
      },
      deleteOption (index) {
        this.question.options.splice(index, 1)
      },
      checkSelecteds () {
        if (this.isSingle) {
          let selected = false
          this.question.options.map(option => {
            if (option.is_selected) {
              if (selected)
                option.is_selected = false
              else
                selected = true
            }
          })
        }
      },
    },
  }
</script>
