var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.urlData,
          expression: "urlData",
        },
      ],
      attrs: { type: "hidden", name: "url_banner" },
      domProps: { value: _vm.urlData },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.urlData = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("table", { staticClass: "table border gx-4 table-row-bordered" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { attrs: { width: "250" } }, [
            _vm._v(_vm._s(_vm._f("trans")("cms_selector_type.linkType"))),
          ]),
          _vm._v(" "),
          _c("th", [
            _vm.linkType.id === 1
              ? _c("span", [
                  _vm._v(_vm._s(_vm._f("trans")("cms_selector_type.link"))),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.linkType.id === 2
            ? _c(
                "th",
                { staticClass: "text-center", attrs: { width: "150" } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("trans")("cms_selector_type.hideOnFinish")
                      ) +
                      "\n            "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c("tr", [
          _c(
            "td",
            [
              _c("multiselect", {
                staticClass: "ms-3",
                attrs: {
                  options: _vm.selectorData,
                  "track-by": "id",
                  label: "name",
                  multiple: false,
                  disabled: _vm.nbReadOnly,
                  id: "basic-addon2",
                },
                on: { input: _vm.setToNull },
                model: {
                  value: _vm.linkType,
                  callback: function ($$v) {
                    _vm.linkType = $$v
                  },
                  expression: "linkType",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.linkType.id === 1
                ? [
                    _c(
                      "div",
                      { staticClass: "me-3" },
                      [
                        _c("label", {
                          staticClass: "sr-only",
                          attrs: { for: "type_url_data" },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.urlData,
                              expression: "urlData",
                            },
                          ],
                          staticClass:
                            "form-control-solid me-2 form-control m-input",
                          attrs: {
                            id: "type_url_data",
                            type: "url",
                            placeholder: "Url",
                            "aria-describedby": "basic-addon2",
                            disabled: _vm.nbReadOnly,
                          },
                          domProps: { value: _vm.urlData },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.urlData = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("nb-error-block", { attrs: { path: "url_banner" } }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group mb-10" }, [
      _c("div", { staticClass: "input-group-prepend" }, [
        _c("span", { staticClass: "input-group-text" }, [_vm._v("Enlace")]),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", "aria-label": "First name" },
      }),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", "aria-label": "Last name" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }