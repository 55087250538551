var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group m-form__group col-md-8 mr-0" }, [
    _c("input", {
      attrs: { type: "hidden", name: _vm.fieldName, id: _vm.fieldName },
      domProps: { value: _vm.returnData },
    }),
    _vm._v(" "),
    _c("label", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-row" }, [
      _c("div", { staticClass: "w-100", staticStyle: { width: "40%" } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-between" },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedGroup,
                    expression: "selectedGroup",
                  },
                ],
                staticClass: "form-control mb-1",
                attrs: { title: "", disabled: _vm.nbReadOnly },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedGroup = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.currentData, function (group) {
                return _c(
                  "option",
                  { key: group.id, domProps: { value: group.id } },
                  [_vm._v(_vm._s(group.name))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedToAdd,
                    expression: "selectedToAdd",
                  },
                ],
                staticClass: "form-control",
                attrs: { multiple: "", title: "", disabled: _vm.nbReadOnly },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedToAdd = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(
                _vm.currentData[_vm.selectedGroup].elements,
                function (element) {
                  return _c(
                    "option",
                    { key: element.id, domProps: { value: element.id } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(element.name) +
                          "\n                    "
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-4 align-self-center" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-between" },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary m-btn m-btn--sm btn-sm m-btn--icon m-btn--icon-right text-nowrap mb-1",
                attrs: {
                  id: "add-button",
                  type: "button",
                  disabled: _vm.nbReadOnly || _vm.addButtonDisabled,
                },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [
                _vm._v("\n                    Añadir "),
                _c("i", { staticClass: "fa fa-fw fa-caret-right" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-danger m-btn m-btn--sm btn-sm m-btn--icon text-nowrap",
                attrs: {
                  id: "remove-button",
                  type: "button",
                  disabled: _vm.nbReadOnly || _vm.removeButtonDisabled,
                },
                on: {
                  click: function ($event) {
                    return _vm.remove()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-fw fa-caret-left" }),
                _vm._v(" Quitar\n                "),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100", staticStyle: { width: "40%" } }, [
        _c("div", { staticClass: "h-100" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedToRemove,
                  expression: "selectedToRemove",
                },
              ],
              staticClass: "form-select h-100",
              attrs: { multiple: "", title: "", disabled: _vm.nbReadOnly },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedToRemove = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _vm._l(_vm.currentChosen, function (group) {
                return _vm._l(group.elements, function (element) {
                  return _c(
                    "option",
                    {
                      key: group.id.toString() + "-" + element.id.toString(),
                      domProps: {
                        value:
                          group.id.toString() + "-" + element.id.toString(),
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(group.name) +
                          " - " +
                          _vm._s(element.name) +
                          "\n                        "
                      ),
                    ]
                  )
                })
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }