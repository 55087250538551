<template>
    <div style="display: inline-block;">
        <div data-js-action="addItems" @click="changeVisible" class="btn btn-secondary js-custom-control-button" :data-check-selected="false">
            <span>
                <em class="fa fa-user fa-fw me-1"></em>
                <span>{{ 'forms_crm_customers.customersSendMail.buttonTitle' | trans }}</span>
            </span>
        </div>

        <modal-component
                :refreshing="refreshing"
                :backdrop="true"
                :classesModalDialog="['modal-xl modal-dialog-scrollable']"
                :visible.sync="visible">

            <template #title>
              {{ 'forms_crm_customers.customersSendMail.title' | trans({number: customersNumber}) }}
            </template>

            <template>
                <div class="m-form">
                  <div role="alert" class="m-alert m-alert--square alert alert-dismissible fade show"
                         v-if="message" :class="[successMessage ? 'alert-success' : 'alert-danger']">
                        <div class="m-alert__text">
                            {{ message }}
                        </div>
                        <div class="m-alert__close">
                            <button type="button" data-bs-dismiss="alert" aria-label="Close" class="close"></button>
                        </div>
                    </div>
                <tr v-for="(sendingType, key) in Object.values(CustomEmailSendingType)" :key="key" class="align-top">
                  <td>
                    <label class="form-check-label ms-0">
                      <input
                        id="RegistrableTypeRadioPaid"
                        class="form-check-input me-1"
                        type="radio"
                        :value="sendingType"
                        v-model="selectedSendingType"
                      />
                      <span class="fw-bold fs-6">{{ `forms_crm_customers.customersSendMail.sendingTypes.${sendingType}` | trans }}</span>
                    </label>
                  </td>
                </tr>
                  <div>
                    <span class="fw-bold fs-6 mt-4">{{ 'forms_crm_customers.customersSendMail.sendingModesTitle' | trans }}</span>
                    <tr v-for="(sendingMode, key) in Object.values(CustomEmailSendingMode)" :key="key" class="align-top">
                      <td>
                        <label class="form-check-label ms-0 mt-1">
                          <input
                            id="RegistrableTypeRadioPaid"
                            class="form-check-input me-1"
                            type="radio"
                            :value="sendingMode"
                            v-model="selectedSendingMode"
                          />
                          <span class="fw-bold fs-6">{{ `forms_crm_customers.customersSendMail.sendingModes.${sendingMode}` | trans }}</span>
                        </label>
                      </td>
                    </tr>
                  </div>
                  <div class="mt-4">
                      <div class="col-12 col-lg-3 mb-4">
                          <label class="d-block">
                              <span class="fw-bold">
                                {{ 'forms_crm_customers.customersSendMail.subjectTitle' | trans }}
                              </span>
                              <input v-model="mail.subject" class="form-control mr-4" :disabled="nbReadOnly"
                                    placeholder="Introduce un asunto" name="subject"/>
                          </label>
                          <p class="m-form__help">{{ 'forms_crm_customers.customersSendMail.helpBlocks.plainText' | trans }}</p>
                      </div>
                      <div v-if="selectedSendingMode == 'email'" class="col-lg-9 col-xl-10">
                        <HtmlEditor v-model="mail.body"
                                      :value="mail.body"
                                      field="body"
                                      :height="220"
                                      :label="$options.filters.trans('forms_crm_customers.customersSendMail.bodyTitle')"
                                      :buttons="htmlButtons"
                          />
                          <p class="m-form__help">{{ 'forms_crm_customers.customersSendMail.helpBlocks.richText' | trans }}</p>
                      </div>
                      <div v-else class="col-lg-9 col-xl-10">
                        <span class="fw-bold">
                          {{ 'forms_crm_customers.customersSendMail.bodyTitle' | trans }}
                        </span>
                        <input v-model="mail.body" class="form-control mr-4" :disabled="nbReadOnly"/>
                        <p class="m-form__help">{{ 'forms_crm_customers.customersSendMail.helpBlocks.plainText' | trans }}</p>
                      </div>
                      </div>

                <button type="button"
                        class="btn btn-primary m-btn m-btn--icon"
                        @click="sendMail"
                >
                    <span>
                        {{ 'forms_crm_customers.customersSendMail.buttonTitle' | trans }}
                    </span>
                </button>
                </div>
            </template>
        </modal-component>

    </div>
</template>

<script>

  import { CustomEmailSendingType } from '@/js/enums/CustomEmailSendingType'
  import { CustomEmailSendingMode } from '@/js/enums/CustomEmailSendingMode'
  import ModalComponent from '@/js/components/widgets/ModalComponent'
  import HtmlEditor from '@/js/components/html_editor/HtmlEditor'

  export default {
    name: 'CustomerSendMail',
    components: { ModalComponent, HtmlEditor },
    data () {
      return {
        visible: false,
        refreshing: false,
        message: null,
        successMessage: null,
        mail: {
          subject: null,
          body: null,
        },
        selectedCustomers: [],
        selectedSendingType: null,
        selectedSendingMode: null,
        CustomEmailSendingType,
        CustomEmailSendingMode,
        htmlButtons: [
            ['undo', 'redo'], // Only supported in Blink browsers
            ['formatting'],
            ['fontfamily'],
            ['fontsize'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['emoji'],
            ['table'],
            ['foreColor', 'backColor'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['removeformat'],
            ['fullscreen'],
        ]
      }
    },
    props: {
      customers: {
        type: Array,
        required: true,
      }
    },
    watch: {
      visible (val) {
        if (!val) {
          delete window.itemsSelected
          this.reset()
        }
      },
      selectedSendingType (val) {
        if (val === CustomEmailSendingType.Selected) {
          this.selectedCustomers = window.itemsSelected ?? []
        } else if (val) {
          this.selectedCustomers = this.customers
        }
      },
      selectedSendingMode () {
        this.mail.body = null
      },
    },
    computed: {
      params () {
        const urlParams = new URLSearchParams(window.location.search)
        const entries = urlParams.entries()
        return this.paramsToObject(entries)
      },
      customersNumber () {
        return this.selectedCustomers.length
      },
      complete () {
        return this.mail.subject && this.mail.body && this.selectedSendingType && this.selectedSendingMode
      }
    },
    methods: {
      changeVisible () {
        if (this.nbReadOnly) return
        this.visible = true
      },
      setMessage (msg, success) {
        this.$nextTick(() => {
          this.message = msg
          this.successMessage = success
        })
      },
      async sendMail () {
        this.refreshing = true
        if (!this.complete) {
          this.setMessage('Completa todos los campos', false)
        } else {
          try {
            await axios.post(
              route('send-customer-mail'),
              { mail: this.mail, customers: this.selectedCustomers, sendingType: this.selectedSendingType, sendingMode: this.selectedSendingMode },
              { params: this.params }
            )
            this.reset()
            this.setMessage('Email enviado', true)
            setTimeout(() => location.reload(), 1000)
          } catch (error) {
            console.log(error)
            this.setMessage('Error', false)
          }
        }
        this.refreshing = false
      },
      reset () {
        this.mail = {
          subject: null,
          body: null,
        }
        this.selectedSendingType = null
        this.selectedSendingMode = null
        this.selectedCustomers = []
      },
      paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
          result[key] = value;
        }
        return result;
      }
    },
  }
</script>
