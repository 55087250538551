var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "border-dashed border-1 border-gray-400 bg-white rounded-2 draggable-handle px-2 py-3",
        on: {
          dblclick: function ($event) {
            $event.stopPropagation()
            return _vm.editor.openDrawer(_vm.row)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "handle" },
          [
            _c("div", { staticClass: "text-muted align-top" }, [
              _vm._v(_vm._s(_vm.editor.options["type"][_vm.row.type])),
            ]),
            _vm._v(" "),
            _vm.editor.renderComponent
              ? _c("cms-editor-view", { attrs: { row: _vm.row } })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.row.type == "container"
          ? [
              _vm.row.childrens.length === 0
                ? _c("input", {
                    attrs: {
                      name:
                        _vm.field + "[" + _vm.prefix + _vm.idx + "][childrens]",
                      type: "hidden",
                      value: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "draggable",
                {
                  class: [
                    _vm.row.childrens.length === 0
                      ? "empty-draggable handle w-100 p-2"
                      : "",
                    "row g-2 mt-2 pb-2",
                    _vm.row.hasOwnProperty("background")
                      ? "bg-" + _vm.row.background
                      : "",
                    _vm.row.hasOwnProperty("fit") && _vm.row.fit == "fitcontent"
                      ? "px-20"
                      : 0,
                  ],
                  attrs: {
                    list: _vm.row.childrens,
                    nested: true,
                    group: "contenido",
                    options: _vm.editor.dragOptions,
                    handle: ".handle",
                    "ghost-class": "opacity-75",
                  },
                  on: { change: _vm.changeEvent },
                },
                _vm._l(_vm.row.childrens, function (row2, idx2) {
                  return _c("cms-editor-item", {
                    key: row2.id,
                    class:
                      "draggable position-relative " +
                      _vm.editor.calculeWidth(row2),
                    attrs: {
                      editor: _vm.editor,
                      row: row2,
                      idx: idx2,
                      field:
                        _vm.field + "[" + _vm.prefix + _vm.idx + "][childrens]",
                      items: _vm.row.childrens,
                    },
                  })
                }),
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "py-1 position-absolute top-0 end-0 me-3 toolbar-hoverable",
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-icon btn-light-info",
            attrs: { type: "button", title: "Editar", noprefetch: "" },
            on: {
              click: function ($event) {
                return _vm.editor.openDrawer(_vm.row)
              },
            },
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-icon btn-light-danger js-btn-delete",
            attrs: { type: "button", title: "Eliminar", noprefetch: "" },
            on: {
              click: function ($event) {
                return _vm.editor.removeRow(_vm.row, _vm.$parent)
              },
            },
          },
          [_vm._m(1)]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "bg-white drawer-container",
        attrs: {
          id:
            "popup_" +
            _vm.editor.sanitizedField() +
            "_" +
            _vm.prefix +
            _vm.editor.nextUnique(_vm.row),
          "data-kt-drawer": "true",
          "data-kt-drawer-activate": "true",
          "data-kt-drawer-permanent": "true",
          "data-kt-drawer-escape": "false",
          "data-kt-drawer-overlay": "true",
          "data-kt-drawer-width": "625px",
        },
        on: {
          dblclick: function ($event) {
            $event.stopPropagation()
          },
          mousedown: function ($event) {
            $event.stopPropagation()
          },
        },
      },
      [
        _c("div", { staticClass: "card rounded-0 w-100" }, [
          _c("div", { staticClass: "card-header pe-5" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("Editar Modulo")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "btn btn-icon btn-sm btn-active-light-primary ms-2",
                attrs: { "aria-label": "Close" },
                on: {
                  click: function ($event) {
                    return _vm.editor.closeDrawer(_vm.row, _vm.$parent)
                  },
                },
              },
              [_c("i", { staticClass: "bi bi-x-lg" })]
            ),
          ]),
          _vm._v(" "),
          _vm.editor.showDrawer
            ? _c("div", { staticClass: "card-body hover-scroll-overlay-y" }, [
                _c(
                  "div",
                  { staticClass: "row ml-2 mr-2" },
                  _vm._l(
                    _vm.editor.formModal[_vm.row.type],
                    function (popup_field, key) {
                      return _c(
                        "div",
                        { class: "form-group mb-6 " + popup_field.class },
                        [
                          _c(
                            "label",
                            { staticClass: "form-label", attrs: { for: key } },
                            [_vm._v(_vm._s(popup_field.label))]
                          ),
                          _vm._v(" "),
                          _c("cms-field-hub", {
                            attrs: {
                              type: popup_field.type,
                              popup_field: popup_field,
                              row: _vm.row,
                              field_type: key,
                              prefix: _vm.prefix,
                              field: _vm.field,
                              idx: _vm.idx,
                              editor: _vm.editor,
                            },
                          }),
                          _vm._v(" "),
                          _vm.row.hasOwnProperty("errores") &&
                          _vm.row.errores.hasOwnProperty(key)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "fv-plugins-message-container invalid-feedback",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.row.errores[key]) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.editor.closeDrawer(_vm.row, _vm.$parent)
                  },
                },
              },
              [_vm._v("Cancelar\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.editor.saveDrawer(_vm.row)
                  },
                },
              },
              [_vm._v("Guardar\n                ")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa fa-edit" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa fa-trash" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }