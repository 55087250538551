import { StylesManager, surveyLocalization } from 'survey-core'
import 'survey-core/defaultV2.min.css';
import "survey-core/survey.i18n";
import { Survey } from 'survey-knockout-ui';
import { mapActions, mapGetters } from "vuex"

StylesManager.applyTheme("defaultV2");

export default {
  props: {
    currentData: {
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      default: 'es'
    },
    show: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      currentDataUpdated: null,
    }
  },
  watch: {
    extraData: {
      deep: true,
      immediate: true,
      handler (val) {
        this.setSurveyData(val)
      },
    },
  },
  computed: {
    ...mapGetters("Customer", {
      extraData: "getExtraData",
    }),
  },
  methods: {
    ...mapActions("Customer", {
      setExtraData: "setExtraData",
    }),
    async getFormData() {
      surveyLocalization.defaultLocale = 'es'

      const surveyJson = this.formData.survey
      const customCss = this.getCss()

      this.survey = new Survey(surveyJson)
      this.survey.locale = this.locale
      //this.survey.css = customCss
      this.survey.onComplete.add(this.surveyComplete)

      // this.survey.onTextMarkdown.add(this.convertToHtml)

      this.survey.onValueChanged.add((survey, options) => {
        this.changeCurrentData(options.name, options.value)
      })
      this.survey.onAfterRenderQuestion.add((survey, options) => {
        if (options.question.name === 'password' || options.question.name === 'password_confirmation') {
          const input = options.htmlElement.querySelector('input')
          const button = document.createElement('button')
          button.type = 'button'
          button.className = 'btn btn-lg btn-input-icon btn-light position-absolute border-0'
          button.innerHTML = '<?xml version="1.0" encoding="UTF-8"?>\n' +
              '<svg class="bi-eye b-icon bi" width="1em" height="1em" fill="currentColor" aria-hidden="true" aria-label="eye" focusable="false" role="img" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>\n' +
              '<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>\n' +
              '</svg>\n'
          button.addEventListener('click', () => {
            if (input.type === 'password') {
              input.type = 'text'
              button.innerHTML = '<?xml version="1.0" encoding="UTF-8"?>\n' +
                  '<svg class="bi-eye-slash b-icon bi" width="1em" height="1em" fill="currentColor" aria-hidden="true" aria-label="eye slash" focusable="false" role="img" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">\n' +
                  '<path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>\n' +
                  '<path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>\n' +
                  '<path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z"/>\n' +
                  '</svg>\n'
            } else {
              input.type = 'password'
              button.innerHTML = '<?xml version="1.0" encoding="UTF-8"?>\n' +
                  '<svg class="bi-eye b-icon bi" width="1em" height="1em" fill="currentColor" aria-hidden="true" aria-label="eye" focusable="false" role="img" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">\n' +
                  '<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>\n' +
                  '<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>\n' +
                  '</svg>\n'
            }
          })
          input.parentNode.appendChild(button)
        }
      })

      this.survey.onAfterRenderSurvey.add(() => {
        document.querySelectorAll('[ref="btnSave"]').forEach(btn => {
          btn.addEventListener('click', this.surveyComplete)
      })})

      this.setExtraData(this.parseData(this.currentData))

      // TODO: Llevar a mixin SurveyJsCommons todo lo que se pueda de formularios personalizados
      // TODO:Incluir aviso en plantilla se se quiere dar información al cliente de las fechas
      this.survey.mode = this.formData.opened ? 'edit' : 'display'
      this.survey.showPageDescription = false
      this.survey.showTitle = false
      this.survey.showNavigationButtons = false
    },
    async surveyComplete(sender) {
      this.loadingSavePersonalInformation = true
      this.survey.mode = 'edit'
      this.survey.clear(false)
      const data = this.parseDataBeforeSave(this.survey.data)
      try {
      await this.save(data)
        if (this.notShowModal) {
          return
        }
        this.loadingSavePersonalInformation = false
        this.$swal({
          type: 'success',
          animation: false,
          title: this.$t('Profile.commons.extraDataSaved.okTitle'),
          text: this.$t('Profile.commons.extraDataSaved.okMessage'),
          showCancelButton: false,
        })
      } catch (err) {
        console.log(err)
        if (this.notShowModal) {
          throw err
        }
        this.$swal({
          type: 'error',
          animation: false,
          title: this.$t('Profile.commons.extraDataSaved.errorTitle'),
          text: this.$t('Profile.commons.extraDataSaved.errorMessage'),
        })
      }

    },
    parseData(data) {
      return data
    },
    parseDataBeforeSave(data) {
      return data
    },
    getDefaultCss() {
      return {
        header:'mb-4',
        title:'text-uppercase font-weight-bold',
        headerText:'font-weight-light',
        description:'font-weight-light',
        row: "sd-row sd-clearfix d-flex flex-column flex-md-row gap-4",
        panel:{
          container: 'mb-3',
          title: 'px-0 mb-3 text-uppercase',
          description:'mb-3',
          requiredText: 'text-secondary',
        },
        navigation:{
          complete:'btn btn-primary btn-lg ml-auto',
        },
        text: {
          root: 'form-control pr-4',
          onError:'is-invalid d-block border border-danger'

        },
        comment:{
          root:'form-control',
          onError:'border border-danger'
        },
        question: {
          content: 'sd-question__content form-group position-relative',
          description:'small font-weight-light  mb-2 sd-description sd-question__description ',
          descriptionUnderInput:'small font-weight-light mt-2 sd-description sd-question__description ',
          mainRoot: 'sv_q p-0 sd-question-item d-flex flex-column h-100',
          hasError:'sd-question--error',
          titleOnError:'text-danger'
         /* mainRoot:'sd-element sd-question sd-row__question form-group position-relative',*/

        },
        error:{
          root:'invalid-feedback d-block',
        },

        checkbox:{
          item:'custom-control custom-checkbox',
          label: 'sd-selectbase__label d-flex align-items-baseline',
          itemControl: 'custom-control-input',
          controlLabel: 'custom-control-label',
          itemOnError:'sd-item--error is-invalid'

        },
        radiogroup: {

          item: 'custom-control custom-checkbox',
          label: 'sd-selectbase__label d-flex align-items-baseline',
          itemControl:'custom-control-input',
          controlLabel: 'custom-control-label',
          column: "sd-selectbase__column mr-3",
          itemOnError: 'is-invalid',
          rootMultiColumn: "sd-selectbase--multi-column d-flex",
        },
        dropdown:{
          other:' mt-auto',
          control: 'form-control sd-dropdown pr-4',
          onError:'sd-input--error border border-danger',
          controlValue:'h-100 d-flex align-items-center position-relative',
          filterStringInput:'border-0 bg-transparent h-100 w-100 font-weight-light outline-0 cursor-pointer text-primary position-absolute left-0 top-0',
          cleanButton:'d-none'

        },
        actionBar:{
          root:'p-0',
          item:'btn btn-primary ml-auto',
        },
        boolean:{
          root:'sd-scrollable-container',
          item:'sd-boolean',
          itemOnError:'is-invalid',
          control:'sd-boolean__control sd-visuallyhidden',
          itemChecked:'sd-boolean--checked',
          itemIndeterminate:'sd-boolean--indeterminate',
          itemDisabled:'sd-boolean--disabled',
          label:'sd-boolean__label',
          switch:'sd-boolean__switch',
          disabledLabel:'sd-checkbox__label--disabled',
          sliderText: 'sd-boolean__thumb-text',
          slider: 'sd-boolean__thumb',
          checkboxItemOnError:'is-invalid'
        },

      }
    },
    getCss() {
      return this.getDefaultCss()
    },
    // convertToHtml(survey, options) {
    //   var converter = new Converter()
    //   // convert the markdown text to html
    //   var str = converter.makeHtml(options.text);
    //   // remove root paragraphs <p></p>
    //   str = str.substring(3);
    //   str = str.substring(0, str.length - 4);
    //   // set html
    //   options.html = str;
    // }
    changeCurrentData (key, value) {
      this.$set(this.currentDataUpdated, key, value)
      this.setExtraData(this.currentDataUpdated)
    },
    setSurveyData (data) {
      this.currentDataUpdated = data
      this.survey.data = data
    }
  },

}
