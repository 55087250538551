var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _c("input", {
        attrs: { name: "menu", type: "hidden" },
        domProps: { value: _vm.menuJSON },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: "locale", type: "hidden" },
        domProps: { value: _vm.locale },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between mb-5" }, [
        _c(
          "button",
          {
            staticClass: "m-btn btn m-btn--icon btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.addChildren },
          },
          [
            _c("i", { staticClass: "fa fa-plus" }),
            _vm._v(" "),
            _c("span", [_vm._v("Nueva entrada")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form-group d-flex justify-content-between align-items-center",
          },
          [
            _c(
              "label",
              {
                staticClass: "form-label my-0 mx-3 flex-nowrap",
                staticStyle: { "white-space": "nowrap" },
              },
              [_vm._v("Filtro por área")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedArea,
                    expression: "selectedArea",
                  },
                ],
                staticClass: "form-select",
                attrs: { title: "Área" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedArea = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { selected: "", value: "" } }, [
                  _vm._v("Todos"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.areas, function (area) {
                  return _c("option", { domProps: { value: area } }, [
                    _vm._v(
                      "\n              " + _vm._s(area) + "\n            "
                    ),
                  ])
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("cms-menu-item", {
        attrs: {
          targets: _vm.targets,
          areas: _vm.areas,
          selectedArea: _vm.selectedArea,
          nbErrors: _vm.nbErrors,
        },
        model: {
          value: _vm.items,
          callback: function ($$v) {
            _vm.items = $$v
          },
          expression: "items",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }