<template>
    <!-- contenido -->
    <component :is="fieldLoader"
                                :popup_field="popup_field"
                                :row="row"
                                :field_type="field_type"
                                :prefix="prefix"
                                :field="field"
                                :idx="idx"
                                :editor="editor"></component>
    <!-- xxxxxxxxx -->
</template>

<script>

export default {
    name: 'cms-field-hub',
    props: {
        type: {type: String, default: ''},
        field_type: {type: String, default: ''},
        prefix: {type: String, default: ''},
        field: {type: String, default: ''},
        idx: {type: Number, default: 0},
        row: {type: Object, default: () => ({})},
        popup_field: {type: Object, default: () => ({})},
        editor: {type: Object, default: () => ({})},
    },
    computed: {
        fieldLoader () {
            return () => import(`./fields/${this.type}.vue`)
        }
    },
}
</script>

