<template>
    <div class="mb-6 form-group m-form__group form-group-sm noprefetch
     m--padding-left-30 m--padding-right-30 py-0 m-form">
        <label v-if="label.length" :for="field" class="form-label">{{ label }}</label>
        <div class="reset-color" v-if="!disabled && !nbReadOnly">
            <trumbowyg id="field" :name="field" :style="'height:' + height + 'px;'"
                       @input="$emit('input', valueTrumbowyg)"
                       v-model="valueTrumbowyg" :config="configs"></trumbowyg>
        </div>
        <div v-if="helpMessage" class="fv-plugins-message-container"> {{ helpMessage }} </div>
        <div v-else-if="defaultLangValue" class="reset-color">
            <div class="alert m-alert--default  noprefetch p-0 pt-5" aria-readonly="true">
                <iframe src="about:blank" class="border-0 p-0 px-4" style="background: #f5f8fa;" :srcdoc="getHtmlView"
                        width="100%">
                </iframe>
            </div>
        </div>

        <input type="hidden" :name="fieldItem" :id="fieldItem" :value="getFieldValue">

        <nb-error-block :path="field"></nb-error-block>

        <modal-component id="repositoryNavigation"
                         tabindex="-1"
                         :visible.sync="showRepository"
                         :backdrop="true"
                         :keyboard="true"
                         :classesBody="['box-scroll']"
                         :classesModalDialog="['modal-dialog-centered', 'modal-xl']">
            <template #title>
                {{ 'forms_repository.list_files' | trans }}
            </template>
            <template>
                <repository v-if="showRepository"
                            :active-select="true"
                            :types="fileTypes"
                            :field="true"
                            :multiple="multiple"
                            :clear-select="clearSelect"
                            :allowedMimeTypes="allowedMimeTypes"
                            :group-id="groupId"
                            :group-type="groupType"
                            @repositoryInput="setInput"
                            @repositorySetOptions="setOptions">
                </repository>
            </template>
            <template #footer v-if="multiple">
                <button id="multiple"
                        type="button"
                        class="m-btn btn btn-brand m-btn--icon"
                        :disabled="!button"
                        @click="setOptions">
                    <span>
                        <em class="fa fa-plus mr-1"></em>
                        {{ 'forms_repository.add_files' | trans }}
                    </span>
                </button>
            </template>
        </modal-component>

    </div>
</template>


<script>
  require('trumbowyg/dist/plugins/table/trumbowyg.table')
  require('trumbowyg/dist/plugins/table/ui/trumbowyg.table.css')

  require('trumbowyg/dist/plugins/colors/trumbowyg.colors')
  require('trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css')

  require('trumbowyg/dist/plugins/emoji/trumbowyg.emoji')
  require('trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.css')

  require('trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily')
  require('trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize')

  import 'jquery'
  import trumbowyg from 'vue-trumbowyg'
  import 'trumbowyg/dist/langs/es.min.js'
  import 'trumbowyg/dist/ui/sass/trumbowyg.scss'
  import Repository from '@/js/components/repository_explorer/Repository'
  import OptionsHelpers from '@/js/mixins/OptionsHelpers'
  import { mapGetters } from 'vuex'
  import '@/js/components/html_editor/plugins/unsubscribe/trumbowyg.unsubscribe.js'
  import '@/js/components/html_editor/plugins/trackable_links/trumbowyg.trackable_links.js'
  import '@/js/components/html_editor/plugins/base64/trumbowyg.base64.js'
  import NbErrorBlock from '@/js/components/error_block/nb-error-block'

  export default {
    name: 'HtmlEditor',
    components: { trumbowyg, Repository, NbErrorBlock },
    mixins: [OptionsHelpers],
    props: {
      value: { type: String, default: '' },
      field: { type: String, default: 'content' },
      label: { type: String, default: '' },
      height: { type: Number, default: 350 },
      multiple: { Boolean, default: true },
      name: { String, default: '' },
      fieldItem: { type: String, default: '' },
      disabled: { Boolean, default: false },
      enableUnsubcribePlugin: { Boolean, default: false },
      helpMessage: { type: String, default: null },
      defaultLangValue: {type: String, default: ''},
      colorList: { type: Array, default: () => ['000', '111', '222', 'ffea00']},
      groupId: { type: Number, default: 1 },
      groupType: { type: String,  default: 'App\\Models\\Rep\\FileGroup'},
      buttons: {
        type: Array,
        default: () => [
            ['viewHTML'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['formatting'],
            ['fontfamily'],
            ['fontsize'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['link'],
            ['emoji'],
            ['insertImage'],
            ['table'],
            ['foreColor', 'backColor'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['removeformat'],
            ['repositoryFile'],
            ['fullscreen'],
          ],
      },
      enableBase64Plugin: { Boolean, default: false },
      hiddenButtons: { type: Array,  default: () => []},
    },
    data () {
      return {
        valueTrumbowyg: null,
        fileTypes: [],
        configs: {
          // Any option from
          // https://alex-d.github.io/Trumbowyg/documentation/#basic-options
          lang: 'es',
          autogrow: false,
          imageWidthModalEdit: true,
          removeformatPasted: true,
          btnsDef: {
            repositoryFile: {
              fn: () => {
                this.showRepository = true
              },
              title: 'Repositorio de imágenes',
              ico: 'upload',
            }
          },
          btns: this.buttons,
          semantic: false,
          plugins: {
            colors: {
              colorList: this.colorList
            }
          }
        },
        showRepository: false,
        clearSelect: false,
        listFiles: [],
        options: [],
        button: false,
      }
    },
    async created () {
      if (this.enableUnsubcribePlugin === true) {
        this.configs.btns.push(['unsubscribe'])
        this.configs.btns.push(['trackable_links'])
      }
      if (this.enableBase64Plugin === true) {
        this.configs.btns.push(['base64'])
      }
      let finalButtons = []
      for (let i = 0; i < this.configs.btns.length; i++){
        let panel = [];
        for (let b = 0; b < this.configs.btns[i].length; b++){
            if (this.hiddenButtons.indexOf(this.configs.btns[i][b]) == -1){
                panel.push(this.configs.btns[i][b]);
            }
        }
        finalButtons.push(panel);
      }
      this.configs.btns = finalButtons;

      try {
        let response = await axios.get(route('API.repository.types'))
        this.fileTypes = response.data
      } catch (error) {
        console.log(error)
      }
    },
    mounted () {
      this.valueTrumbowyg = this.value

      document.addEventListener('updateTextArea', (e) => {
        this.valueTrumbowyg = e.detail
      }, false)
    },
    computed: {
      ...mapGetters('Repository', {
        allowedMimeTypes: 'getMimeTypeImages',
      }),
      getHtmlView: function () {
        let content = this.defaultLangValue

        return `
            <html>
                <head>
                    <link href="https://fonts.googleapis.com/css?family=Lato:wght@300;400;600;700" rel="stylesheet">
                    <style>
                        body {
                            margin: 0px;
                            padding: 0px;
                            font-size: 14px;
                            font-weight: 400;
                            font-family: 'Lato';
                        }
                    </style>
                </head>
                <body>
                    ${content}
                </body>
            </html>`
      },
      getFieldValue: function () {
        let list = []

        for (const file of this.listFiles) {
          list.push(file.id)
        }

        if (list.length > 0) {
          if (!this.multiple) {
            return parseInt(list[0])
          }
          return JSON.stringify(list)
        }
        return ''
      },
    },
    methods: {
      setInput (list) {
        this.button = false
        this.options = []

        if (list.length > 0) {
          this.button = true
          this.options = list
        }
      },
      setOptionsFile (fileSelect) {
        let remove
        let position

        [remove, position] = this.getOptionsFile(fileSelect)

        if (this.valueTrumbowyg !== null) {
          this.valueTrumbowyg += `<img width='100' src='${fileSelect.url}'/>`
        } else {
          this.valueTrumbowyg = `<img width='100' src='${fileSelect.url}'/>`
        }

        if (!remove && position < 0) {
          this.listFiles.push(fileSelect)
        }

        if (remove && position >= 0) {
          this.listFiles.splice(position, 1)
        }

        if (!this.multiple && this.listFiles.length > 1) {
          this.listFiles.shift()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
    .has-danger {
        .form-control-feedback {
            position: relative;
            top: -15px !important;
            margin-bottom: 10px;
        }

        label {
            color: rgb(244, 81, 108);
        }

        .reset-color {
            border-left: 2px solid #f4516c;
        }
    }

    .box-scroll {
        height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .htmleditor-disable {
        border: 1px solid;
        width: 100%;
    }
</style>
