var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        attrs: { type: "hidden", name: "configuration", id: "configuration" },
        domProps: { value: _vm.statisticsJSON },
      }),
      _vm._v(" "),
      !_vm.loadingData
        ? _c("QueryBuilder", {
            attrs: {
              "cubejs-api": _vm.cubejsApi,
              query: _vm.query,
              "disable-heuristics": true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "builder",
                  fn: function ({
                    setMeasures,
                    availableMeasures,
                    setDimensions,
                    availableDimensions,
                    setFilters,
                  }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "columns columns--fields row" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-half col-md-6 col-lg-4 height-auto u-py-0",
                            },
                            [
                              _c("TagInputWithTranslations", {
                                attrs: {
                                  "tags-array":
                                    _vm.addTranslations(availableMeasures),
                                  title: _vm.$options.filters.trans(
                                    "statistics.measures.title"
                                  ),
                                  placeholder: _vm.$options.filters.trans(
                                    "statistics.measures.placeholder"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    setMeasures($event.map((tag) => tag.name)),
                                      setFilters(_vm.filtersToSend)
                                  },
                                },
                                model: {
                                  value: _vm.selectedMeasures,
                                  callback: function ($$v) {
                                    _vm.selectedMeasures = $$v
                                  },
                                  expression: "selectedMeasures",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-half col-md-6 col-lg-4 height-auto u-py-0",
                            },
                            [
                              _c("TagInputWithTranslations", {
                                attrs: {
                                  "tags-array":
                                    _vm.addTranslations(availableDimensions),
                                  title: _vm.$options.filters.trans(
                                    "statistics.dimensions.title"
                                  ),
                                  placeholder: _vm.$options.filters.trans(
                                    "statistics.dimensions.placeholder"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    setDimensions(
                                      $event.map((tag) => tag.name)
                                    ),
                                      setFilters(_vm.filtersToSend)
                                  },
                                },
                                model: {
                                  value: _vm.selectedDimensions,
                                  callback: function ($$v) {
                                    _vm.selectedDimensions = $$v
                                  },
                                  expression: "selectedDimensions",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-half col-md-6 col-lg-4 u-py-0",
                            },
                            [
                              _c("div", { staticClass: "form-group mb-6" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "field__span form-label field__span--required",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("trans")("statistics.chartType")
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.chartType,
                                        expression: "chartType",
                                      },
                                    ],
                                    staticClass: "form-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.chartType = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  _vm._l(_vm.chartTypes, function (type, name) {
                                    return _c(
                                      "option",
                                      { key: name, domProps: { value: name } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("trans")(
                                                `statistics.chartTypes.${name}`
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "statistics-filters d-flex justify-content-end",
                        },
                        [
                          _c("div", { staticClass: "btn-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  "data-bs-toggle": "dropdown",
                                  "data-bs-auto-close": "outside",
                                  "data-bs-display": "static",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-filter" }),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "statistics.filters.title"
                                        )
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-end w-325px w-md-600px w-lg-900px w-xl-1000px p-2",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "section section-mini u-px-0 u-pb-0",
                                  },
                                  _vm._l(_vm.filters, function (filter, index) {
                                    return _c(
                                      "div",
                                      { key: filter.id },
                                      [
                                        _c("StatisticsFilter", {
                                          attrs: {
                                            filters: _vm.getFilters(
                                              availableMeasures,
                                              availableDimensions
                                            ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.testFilters(setFilters)
                                            },
                                            "delete-filter": function ($event) {
                                              return _vm.deleteFilter(
                                                index,
                                                setFilters
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.filters[index],
                                            callback: function ($$v) {
                                              _vm.$set(_vm.filters, index, $$v)
                                            },
                                            expression: "filters[index]",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                !(_vm.filters.length > 0)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "alert bg-light-info w-100 p-5 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-center pe-0 pe-sm-10",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("trans")(
                                                    "statistics.filters.noFilters"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-light",
                                    attrs: { id: "new-filter", type: "button" },
                                    on: { click: _vm.getNewFilter },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-fw fa-plus",
                                    }),
                                    _c("span", [_vm._v("Añadir filtro")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "section section-mini u-px-0 mw-50",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "columns columns--fields",
                                      },
                                      _vm._l(
                                        _vm.colors,
                                        function (color, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "column is-1",
                                            },
                                            [
                                              _c("StatisticsColor", {
                                                attrs: { value: color },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.setColor(
                                                      $event,
                                                      index
                                                    )
                                                  },
                                                  "delete-color": function (
                                                    $event
                                                  ) {
                                                    return _vm.deleteColor(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !(_vm.colors.length > 0)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "alert bg-light-info w-100 p-5 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-center pe-0 pe-sm-10",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("trans")(
                                                    "statistics.colors.noColors"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-light",
                                    attrs: { type: "button" },
                                    on: { click: _vm.getNewColor },
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-plus" }),
                                    _vm._v(
                                      "Añadir " +
                                        _vm._s(
                                          _vm._f("trans")(
                                            "statistics.colors.title"
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function ({ resultSet }) {
                    return [
                      _vm.showChart(resultSet)
                        ? _c("ApexChart", {
                            attrs: {
                              "result-set": resultSet,
                              "chart-type": _vm.chartType,
                              "pivot-x-axis": _vm.selectedPivotXAxis,
                              "pivot-y-axis": _vm.selectedPivotYAxis,
                              "prop-options": _vm.options,
                              height: "300",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              3177835263
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }