<template>
    <div class="text-center">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10">
                <div class="row mb-4">
                    <input class="form-control form-control-solid" type="email" id="login-email" placeholder="Email" name="email" autocomplete="off" disabled />
                </div>
                <div class="row mb-4">
                    <input class="form-control form-control-solid" id="login-password" type="password" placeholder="Password" name="password" autocomplete="off" disabled />
                </div>
                <div class="row my-4">
                    <div class="col d-flex mb">
                        <!-- <div class="row justify-content-start"> -->
                            <label class="m-checkbox">
                                <input type="checkbox" disabled> Recordarme

                        </label>
                    </div>
                    <div class="col d-flex">
                        <span>
                            ¿Has olvidado tu contraseña?
                        </span>
                    </div>
                </div>
                <div class="mb-4">
                    <button type="button" class="btn btn-secondary mb-4" disabled>
                        Iniciar sesión
                    </button>
                </div>
                <div class="py-3 text-center border-top">
                    ¿No tienes cuenta? Crear cuenta
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'cms-login',
}
</script>

